<template lang="pug">
  #options-menu(
      :class="{'w-100' : menuOpened && !hideCopy, 'w-50' : menuOpened && hideCopy}"
      @click.stop=""
      @mouseover="menuOpened=true"
      @mouseleave="menuOpened=false")
    #options-menu-container.d-flex.align-items-center(:class="{'opened' : menuOpened}")
      .pr-3.pl-2.d-flex.justify-content-around.align-items-end
        delete-button
        copy-link-button(v-if="!hideCopy" :url="url")
        edit-button
    #dots.d-flex.align-items-center(@click.stop="" data-testid="menu")
      icon-menu#menu

</template>

<script>
import DeleteButton from './deleteButton.vue';
import CopyLinkButton from './copyLinkButton.vue';
import EditButton from './editButton.vue';

import iconMenu from '../../images/iconMenu.vue';

export default {
  components: {
    'delete-button' : DeleteButton,
    'copy-link-button': CopyLinkButton,
    'edit-button': EditButton,
    'icon-menu': iconMenu
  },
  props: {
    url: { type: String, default: '/' },
    hideCopy: {type: Boolean, default: false}
  },
  data() {
    return {
      menuOpened: false
    };
  },
  mounted() {
    this.$on('tutorial:delete', () => {
      this.$parent.$emit('options:delete');
    });
    this.$on('edit:edit', () => {
      this.$parent.$emit('options:edit');
    });
  }
};
</script>

<style lang="stylus">
  @import '../../../utils/common/styleguide/functions.styl';
  #options-menu
    position absolute
    right 0
    height 100%
    width 6%
    cursor default
    top 0
    right 0
    overflow hidden
    &.opened
      width 100% 
    #options-menu-container
      overflow hidden
      position absolute
      top 0
      right 0
      background-color #F8F7FA
      width pxToRem(0)
      height 100%
      transition width .2s ease
      &.opened
        width 100%
      >div
        width 100%
    #dots
      cursor pointer
      position absolute
      height 100%
      top 0px
      right pxToRem(5)
      #menu
        fill $brt-primary-dark
        width 1.5rem
        height 1.5rem
</style>
