import UrlConfig from './urlConfig.js';

export default class TutorialURL {
  constructor(tutorial) {
    this.id = tutorial._id;
    this.webappUrl = '';
    this.suffix = '';
    this.route = '/tour';
    this.defaultTab = 'analytics';
    this.optionTab = 'options'

    this.setup();
  }

  setup() {
    const urlConfig = new UrlConfig();
    urlConfig.setup();

    this.webappUrl = urlConfig.webappUrl;
    this.suffix = urlConfig.suffix;
  }

  getPath(tab = this.defaultTab) {
    const path = this.route + '/' + this.id + '/' + tab;
    return path;

  }

  /**
   * 
   * @param {string} tab You can specify the tour tab: 'options' or 'analytics'. If you dont send a tab param the default is 'analytics'
   * @returns the tutorial link
   */
  getLink(tab) {
    const link = this.webappUrl + this.suffix + this.getPath(tab);
    return link;
  }
}
