<template lang="pug">
.row.mx-0.p-0.py-2
  .col-12.py-3.position-relative
    .minimized-details.d-inline.d-md-flex.align-items-center.justify-content-between
      .left-container.d-flex.align-items-center
        .pill-container.ml-2
          img(src="/assets/misc/greenCheck.svg" width="24" alt="green checkmark")
        .body-copy.d-flex.align-items-center.ml-2.text-nowrap.
          Script detected on:
      .right-container.d-flex.justify-content-between.align-items-center.w-100.mt-2.mt-md-0
        DomainsWithScript.ml-3( data-testid="domains-with-script")
</template>

<script>
import Spinner from '../../aspect/Spinner.vue'
import DomainsWithScript from '../common/DomainsWithScript.vue';

export default {
  components: { Spinner, DomainsWithScript},
}
</script>
