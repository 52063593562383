<template lang='pug'>
  .container(:class="{'brt-card': specific}")
    .row
      .d-none.d-lg-block.col-lg-3
        img.ml-2(src='/assets/launcher/launcher-abstract.svg' width="90%")
      .col-12.col-lg-9.text-left.pl-2
        p.brt-caption.brt-dark.mb-5 HOW CAN I USE THE LAUNCH WIDGET?
        .launcher-todos-container
          Launcher-To-Do.mb-3(v-for="(step, index) in steps"
            :specific="specific"
            :index="index"
            :step="step.text"
            :completed="step.completed")
        .links-container
          Launcher-External-Links.mt-5
</template>

<script>
import LauncherToDo from './LauncherToDo.vue';
import LauncherExternalLinks from './LauncherExternalLinks.vue';
import { mapGetters } from "vuex";

export default {
  components: { LauncherToDo, LauncherExternalLinks },
  props: {
    specific: { 
      type: Boolean,
      default: true
    }
  },
  mounted() {
    this.setStepCompletionStates();
  },
  data() {
    return {
      steps: [
        { 
          text: 'You need to have at least 1 tour created',
          completed: false 
        },
        { 
          text: 'You need to have the Bytes Route script installed',
          completed: false
        }
      ]
    }
  },
  methods: {
    setStepCompletionStates() {
      // tours created 
      this.steps[0].completed = this.domainTourCount > 0

      // script installed
      this.steps[1].completed = this.domainHasScript;
    }
  },
  computed: {
    ...mapGetters({
      domainTourCount: 'company/getSelectedDomainTourCount',
      domainHasScript: 'company/getSelectedDomainHasScript'
    })

  }
}
</script>