<template lang="pug">
.theme-creation-container
  Spinner(type="full" v-if='loading')
  .container(v-else)      
    .theme-container
      .container
        .row
          .col-12.col-lg-6
            #details-container.brt-card
              .my-2.pb-5
                span.brt-caption.
                  {{ titleText }} Theme
              input.brt-input(v-model='theme.name' brt-validate='notEmpty|alphaExtended|4-25' placeholder='Theme name')

              .brt-tabs-container
                .brt-tab(@click="activeTab='general'" :class="{'brt-active-tab': activeTab==='general'}")
                  svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
                    use(href="/assets/misc/iconBrush.svg#Layer_1")
                  span General Style

                .brt-tab(@click="activeTab='buttons'" :class="{'brt-active-tab': activeTab==='buttons'}")
                  svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
                    use(href="/assets/misc/iconButton.svg#Layer_1")
                  span Buttons Style
              
              #general-container.container(v-show="activeTab==='general'")
                .row.pb-3
                  .col-12.col-md-6.mb-3
                    span.small-copy.font-semibold.brt-muted-dark Background color
                    .color-container
                      input(type='color' v-model='theme.general.bg' @click='triggerClick("generalBg")')
                      input.brt-input(v-model='theme.general.bg' brt-validate='isHex' ref='generalBg')

                .row.pb-3
                  .col-12.col-md-6.mb-3
                    span.small-copy.font-semibold.brt-muted-dark Text color
                    .color-container
                      input(type='color' v-model='theme.general.text' @click='triggerClick("generalText")')
                      input.brt-input(v-model='theme.general.text' brt-validate='isHex' ref='generalText')
                  .col-12.col-md-6
                    span.small-copy.font-semibold.brt-muted-dark Progression steps text color
                    .color-container
                      input(type='color' v-model='theme.general.progress' @click='triggerClick("generalProgress")')
                      input.brt-input(v-model='theme.general.progress' brt-validate='isHex' ref='generalProgress')
                
              #buttons-container.container(v-show="activeTab==='buttons'")
                .row
                  .col-12.col-md-6
                    span.small-copy.font-semibold.brt-muted-dark Button State
                    select.brt-input(v-model='buttonsState')
                      option(value='idle') Idle state
                      option(value='hover') Hover state
                .row.mt-3.pb-4(v-show='buttonsState === "idle"')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Next step' text color
                    .color-container
                      input(type='color' v-model='theme.buttons.idle.primary.text' @click='triggerClick("bttnPrimIdleText")')
                      input.brt-input(v-model='theme.buttons.idle.primary.text' brt-validate='isHex' ref='bttnPrimIdleText')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Next step' background color
                    .color-container
                      input(type='color' v-model='theme.buttons.idle.primary.bg' @click='triggerClick("bttnPrimIdleBg")')
                      input.brt-input(v-model='theme.buttons.idle.primary.bg' brt-validate='isHex' ref='bttnPrimIdleBg')

                .row.pb-4(v-show='buttonsState === "idle"')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Stop tour' text color
                    .color-container
                      input(type='color' v-model='theme.buttons.idle.secondary.text' @click='triggerClick("bttnSecIdleText")')
                      input.brt-input(v-model='theme.buttons.idle.secondary.text' brt-validate='isHex' ref='bttnSecIdleText')
                  .col-12.col-md-6
                    span.small-copy.font-semibold.brt-muted-dark 'Stop tour' background color
                    .color-container
                      input(type='color' v-model='theme.buttons.idle.secondary.bg' @click='triggerClick("bttnSecIdleBg")')
                      input.brt-input(v-model='theme.buttons.idle.secondary.bg' brt-validate='isHex' ref='bttnSecIdleBg')
                .row.mt-3.pb-4(v-show='buttonsState === "hover"')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Next step' text color
                    .color-container
                      input(type='color' v-model='theme.buttons.hover.primary.text' @click='triggerClick("bttnPrimHoverText")')
                      input.brt-input(v-model='theme.buttons.hover.primary.text' brt-validate='isHex' ref='bttnPrimHoverText')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Next step' background color
                    .color-container
                      input(type='color' v-model='theme.buttons.hover.primary.bg' @click='triggerClick("bttnPrimHoverBg")')
                      input.brt-input(v-model='theme.buttons.hover.primary.bg' brt-validate='isHex' ref='bttnPrimHoverBg')
                .row.pb-4(v-show='buttonsState === "hover"')
                  .col-12.col-md-6.mb-2
                    span.small-copy.font-semibold.brt-muted-dark 'Stop tour' text color
                    .color-container
                      input(type='color' v-model='theme.buttons.hover.secondary.text' @click='triggerClick("bttnSecHoverText")')
                      input.brt-input(v-model='theme.buttons.hover.secondary.text' brt-validate='isHex' ref='bttnSecHoverText')
                  .col-12.col-md-6
                    span.small-copy.font-semibold.brt-muted-dark 'Stop tour' background color
                    .color-container
                      input(type='color' v-model='theme.buttons.hover.secondary.bg' @click='triggerClick("bttnSecHoverBg")')
                      input.brt-input(v-model='theme.buttons.hover.secondary.bg' brt-validate='isHex' ref='bttnSecHoverBg')

          .col-12.col-lg-6.mt-3
            #live-view.brt-card
              span#live-title.brt-caption.
                preview
              include ../utils/common/presentation/components/stepRun/stepRun.pug
          .col-12.text-center.mt-5
            button.secondary-button.mr-3.ml-5(@click="goBackToThemes()") Cancel
            button.small-cta(@click='createTheme()') {{ submitText }}

</template>

<script>
// THIS COMPONENT NEEDS TO BE SPLIT INTO MULTIPLE ONES (400+ lines for a component is crazy)
// Shut up Silviu, u are dead now ^
import Spinner from '../components/aspect/Spinner.vue';
import Validator from '../utils/common/utils/validator.js';
import Modal from '../components/aspect/Modal.vue';

import { TYPE } from "vue-toastification";
import {hexToHSL, hslToHex} from '../utils/colorModels.js'
import { mapGetters } from "vuex";

export default {
  name: 'ThemeCreation',
  components: { Spinner, Modal },
  data() {
    return {
      activeTab: 'general',
      buttonsState: 'idle',
      loading: false,
      submitted: false,
      modifyHover: false,
      theme: {
        name: '',
        general: {
          bg: '#fcfcfc',
          progress: '#1f1d22',
          text: '#1f1d22'
        },
        buttons: {
          idle: {
            primary: {
              text: '#410498',
              bg: '#fcfcfc'
            },
            secondary: {
              text: '#410498',
              bg: '#fcfcfc'
            }
          },
          hover: {
            primary: {
              text: '#fcfcfc',
              bg: '#6020bb'
            },
            secondary: {
              text: '#6020bb',
              bg: '#6020bb1a'
            }
          },
        } 
      },
      cssVars: [
        'general-bg',
        'general-progress',
        'general-text',
        'buttons-idle-primary-text',
        'buttons-idle-primary-bg',
        'buttons-idle-secondary-text',
        'buttons-idle-secondary-bg',
        'buttons-hover-primary-text',
        'buttons-hover-primary-bg',
        'buttons-hover-secondary-text',
        'buttons-hover-secondary-bg'
      ],
    };
  },
  created() {
    this.getTheme();
  },
  updated() {
    this.prepareRunStep();
    const validateContainer = document.querySelector('.theme-container');
    if (validateContainer) {
      this.validator =  new Validator(validateContainer);
    }
  },
  beforeRouteLeave (to, from , next) {
    if (this.submitted) {
      next()
    } else {
      const answer = window.confirm('Do you really want to leave the Theme Creation menu without saving? You will lose all your progress')
      if (answer) {
        next()
      } else {
        next(false)
      }
    }
  },
  watch: {
    $route() {
      this.getTheme();
    },
    'theme.general.bg': {handler(newColor) { this.updateLiveView('--general-bg', newColor); }},
    'theme.general.progress': {handler(newColor) { this.updateLiveView('--general-progress', newColor); }},
    'theme.general.text': {handler(newColor) { this.updateLiveView('--general-text', newColor); }},
    'theme.buttons.idle.primary.text': {handler(newColor) { this.updateLiveView('--buttons-idle-primary-text', newColor); }},
    'theme.buttons.idle.primary.bg': {handler(newColor) {
      if (newColor) {
        this.updateLiveView('--buttons-idle-primary-bg', newColor);
        if (this.modifyHover) {
          this.handleHover('primary', newColor);
        }
      }
    }},
    'theme.buttons.idle.secondary.text': {handler(newColor) { this.updateLiveView('--buttons-idle-secondary-text', newColor); }},
    'theme.buttons.idle.secondary.bg': {handler(newColor) { 
      if (newColor) {
        this.updateLiveView('--buttons-idle-secondary-bg', newColor);
        if (this.modifyHover) {
          this.handleHover('secondary', newColor);
        }

        // this hack will prevent first hover color
        // on first render, we don't need that
        this.modifyHover = true;
      }
    }},
    'theme.buttons.hover.primary.text': {handler(newColor) { this.updateLiveView('--buttons-hover-primary-text', newColor); }},
    'theme.buttons.hover.primary.bg': {handler(newColor) { this.updateLiveView('--buttons-hover-primary-bg', newColor); }},
    'theme.buttons.hover.secondary.text': {handler(newColor) { this.updateLiveView('--buttons-hover-secondary-text', newColor); }},
    'theme.buttons.hover.secondary.bg': {handler(newColor) { this.updateLiveView('--buttons-hover-secondary-bg', newColor); }},
  },
  methods: {
    async goBackToThemes() {
      this.$router
        .push({ path: '/themes'})
        .catch((err) => {})
    },
    async createTheme() {
      this.validator.removeErrors();
      if (this.validator.validateFields()) {
        let url = `${this.$config.default.apiUrl}/theme/`;
        try {
          this.loading = true;
          let response = '';
          if (this.$route.params.id !== 'new') {
            url += this.$route.params.id;
            response = await this.$http.put(url, this.theme);
            this.$toast("Theme updated successfully.", { type: TYPE.SUCCESS });
          } else {
            url += `company/${localStorage.getItem('brt-selected-team')}`
            delete this.theme._id;
            response = await this.$http.post(url, this.theme);
            this.$toast("Theme created successfully.", { type: TYPE.SUCCESS });
          }
          if (response.status === 201) {
            this.submitted = true;
            await this.$store.dispatch("company/getSelectedTeamThemes");
            this.$router.push({ path: '/themes'});
          }
        } catch (err) {
          console.log(err);
          this.$toast("Error during theme creation or update.", { type: TYPE.ERROR });
        } finally {
          this.loading = false;
        }
      }
    },
    async getTheme() {
      let url = `${this.$config.default.apiUrl}/theme/`;
      url += this.$route.params.id !== 'new' ? this.$route.params.id : 'default';
      if (this.$route.params.id === 'new') {
        this.modifyHover = true;
      }
      try {
        this.loading = true;
        const response = await this.$http.get(url);
        this.theme = response.data;
        if (this.$route.params.id === 'new') {
          if (!this.planHasThemes) {
            this.submitted = true;
            this.$router.push({ path: '/themes'})
          }
          this.theme.name = '';
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    prepareRunStep() {
      const box = document.querySelector('.brt-message-box');
      const allDoneBtn = document.querySelector('#brt-btn-end');
      const currStep = document.querySelector('#brt-curr-step');
      const totalSteps = document.querySelector('#brt-total-steps');
      if (box) {
        box.classList.remove('hide');
        currStep.innerHTML = 3;
        totalSteps.innerHTML = 7;
        allDoneBtn.classList.add('dspl-none');
      }
      this.cssVars.forEach(cssVar => {
        const cssVarSplit = cssVar.split('-');
        let themeProp = this.theme;
        cssVarSplit.forEach(cssV => themeProp = themeProp[cssV]);
        this.updateLiveView(`--${cssVar}`, themeProp);
      });
    },
    updateLiveView(cssVarName, newColor) {
      const liveView = document.querySelector('#live-view');
      if (liveView) {
        liveView.style.setProperty(cssVarName, newColor);
      }
    },
    triggerClick(ref){
      this.$refs[ref].focus();
    },
    handleHover(button, newColor) {
      const hsl = hexToHSL(newColor).replace('hsl(', '');
      let arr = hsl.split(',');
      let h = parseFloat(arr[0]);
      let s = parseFloat(arr[1]);
      let l = parseFloat(arr[2]);
      let newL = 0;
      if (l > 50) {
        newL = l - 20;
      } else {
        newL = l + 20;
      }
      this.$set(this.theme.buttons.hover[button], 'bg', hslToHex(h, s, newL));
    }
  },
  computed: {
    submitText() {
      return this.$route.params.id !== 'new' ? 'Save' : 'Create';
    },
    titleText() {
      return this.$route.params.id !== 'new' ? 'Edit' : 'Create a';
    },
    ...mapGetters({
      planHasThemes: "company/planHasThemes"
    })
  }
};
</script>

<style lang='stylus' scoped>
  @require '../utils/common/presentation/components/stepRun/stepRun.styl'

  #live-view
    --general-bg: $brt-white
    --general-progress: $brt-black
    --general-text: $brt-black
    --buttons-idle-primary-text: $brt-purple
    --buttons-idle-primary-bg: $brt-white
    --buttons-idle-secondary-text: $brt-purple
    --buttons-idle-secondary-bg: $brt-white
    --buttons-hover-primary-text: $brt-white
    --buttons-hover-primary-bg: $brt-purple-bright
    --buttons-hover-secondary-text: $brt-purple-bright
    --buttons-hover-secondary-bg: #6020bb1a

  .brt-input
    margin-bottom 0px

  .brt-tabs-container
    margin-top pxToRem(48)
  .secondary-button > a
    color: inherit
  .small-cta
    padding: .5rem 2rem
    font-size 1rem
  select.brt-input
    position: relative
    display: block
    font-size: 1rem
    padding: 0.1rem .3rem
    height: 3rem
  .color-container
    position relative
    input:nth-child(2)
      width 100%
      height 2rem
      padding-right pxToRem(60)
      font-size 1rem
    input:nth-child(1)
      width 50px
      height 25px
      cursor pointer
      position absolute
      top 4px
      right 4px
    
  #details-container, #live-view
    min-height 38rem
  #live-view
    display: flex
    align-items: center
    justify-content: center
    background-color: $brt-red-faded
    #live-title
      position: absolute
      top: 2rem
      right: 3rem
    .brt-message-box
      background-color: var(--general-bg)
      top: unset
      left: unset
      z-index 9000
      #brt-step-arrow
        left: 45%
        border-bottom-width: 20px
        border-bottom-style: solid
        border-bottom-color: inherit
        top: -18px
        border-bottom-color: var(--general-bg)
        &:after
          border: none
      #brt-step-info
        background-color: var(--general-bg)
        color: var(--general-progress)
      .brt-text,
      #powered-by-brt,
      #powered-by-brt > a
        color: var(--general-text)
      #brt-btn-next
        color: var(--buttons-idle-primary-text)
        background-color: var(--buttons-idle-primary-bg)
        &:hover
          color: var(--buttons-hover-primary-text)
          background-color: var(--buttons-hover-primary-bg) !important

      #brt-btn-stop
        color: var(--buttons-idle-secondary-text)
        background-color: var(--buttons-idle-secondary-bg)
        &:hover
          color: var(--buttons-hover-secondary-text)
          background-color: var(--buttons-hover-secondary-bg) !important
      

</style>
