<template lang="pug">
  .step-3-container.container.brt-onboard-card
    .row.p-0
      .col-12.offset-xl-1.col-xl-10
        .title-container.d-flex.align-items-center.mb-4
          img(src="/assets/misc/iconConnect.svg" alt="connect icon" width="60")
          h2 Connect your web app to Bytes Route
        Connect-How-Why
        .inner-sync-container.d-flex.justify-content-between.align-items-center.pt-5
          #inner-sync-text
            p.brt-caption.brt-dark CHECK CONNECTIVITY
            p.body-copy.brt-dark Install a code snippet on your domain. Copy the following line in your application source code.
          button.cta.flex-grow-1(@click="detectScript()" title="Check for list of domains with your script" data-testid="detect-script").
            Check connectivity
</template>

<script>
import ConnectHowWhy from '../common/ConnectHowWhy.vue';
import { checkForDomainsWithScript } from "../mixins/checkForDomainsWithScript.js"

export default {
  components: { ConnectHowWhy },
  mixins: [checkForDomainsWithScript],
}
</script>

<style lang="stylus" scoped>
  .inner-sync-container
    .cta
      min-width 15rem
      white-space nowrap
</style>
