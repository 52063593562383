<template lang="pug">
Modal(v-if="show"
  :enabled="tutorial.ignoreSubdomain"
  primaryON="Stop running the following tour on all subdomains?"
  primaryOFF="Run the following tour on all subdomains?"
  confirmTextON="Disable"
  confirmTextOFF="Enable"
  :highlight="tutorial.name"
  :confirmCb="toggleIgnoreSubdomain"
  :cancelCb="() => { this.show = false }"
)
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import updateTeamTours from '../mixins/updateTeamTours';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTeamTours],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      show: false
    };
  },
  mounted() {
    this.$ebus.$on("tour/toggleIgnoreSubdomain", tutorial => {
      this.tutorial = tutorial;
      this.show = true;
    });
  },
  methods: {
    toggleIgnoreSubdomainPre() {
      this.show = true;
    },
    async toggleIgnoreSubdomain() {
      try {
        this.show = false;

        const query = `${this.$config.default.apiUrl}/tutorial/${this.tutorial._id}`;
        await this.$http.put(query, { ignoreSubdomain: !this.tutorial.ignoreSubdomain });
        
        this.tutorial.ignoreSubdomain = !this.tutorial.ignoreSubdomain;
        this.$store.commit('tutorial/setSelectedTutorial', this.tutorial);

        this.$toast("Tour ignore subdomain updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour ignore subdomain. Please retry.", {
          type: TYPE.ERROR
        });
      }
    }
  }
};
</script>
