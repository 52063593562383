<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from '../mixins/updateTourStep.js';
export default {
  mixins: [updateTourStep],
  mounted() {
    this.$ebus.$on("tour/updateStepNextBtnText", data => {
      this.updateStepNextBtnText(data.tutorial, data.index, data.nextButtonText);
    });
  },
  methods: {
    async updateStepNextBtnText(tutorial, index, nextButtonText) {
      const newStep = tutorial.steps[index];
      newStep.nextButtonText = nextButtonText;
      await this.updateTourStep(tutorial, index, newStep, "next button text");
    }
  }
};
</script>
