<template lang="pug">
  .details-container.mt-3.mt-lg-0.ml-0.ml-lg-4
    .d-flex.align-items-center.mb-4
      svg.mr-3.mb-2.brt-tab-icon(width="55px" height="55px")
        use(href="/assets/misc/productTours.svg#Layer_1") 
      h2
        | Create a product tour
    p.body-copy.brt-muted-dark
      | Let's create your first product tour using Bytes Route.
    p.body-copy.mb-5.brt-muted-dark
      | After connecting your web application to Bytes Route you'll be able to publish your content live.
    Create-Tour-Button(:sizeBig="true")
</template>

<script>
import CreateTourButton from '../../tour/CreateTourButton.vue'

export default {
  components: { CreateTourButton }
}
</script>
<style lang='stylus' scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  .brt-tab-icon
    fill: $brt-red-light
</style>
