<template lang="pug">
  .container
    #product-tours-container.mb-2
      Plan-Limit.mb-4
      .domain-cards(v-if="domainsWithTours.length")
        .d-flex.justify-content-end
          Create-Tour-Button
        Domain-Card(
          v-for="domain in domainsWithTours"
          :domain="domain"
          :detailed="true"
        )
        Domains-Without-Tours(v-if="domainsWithoutTours.length")
      .empty-section.brt-card.container.my-4(v-else)
        .row.justify-content-lg-start.justify-content-center.ml-0.ml-lg-5
          img.my-3(src="/assets/notutorial/spotCreateTour.svg" role="img" aria-label="brt create tour")
          Build-Product-Tour
</template>

<script>
import DomainCard from '../components/domain/DomainCard.vue';
import DomainsWithoutTours from '../components/domain/DomainsWithoutTours.vue';
import BuildProductTour from '../components/onboard/common/BuildProductTour.vue';
import CreateTourButton from '../components/tour/CreateTourButton.vue'
import PlanLimit from '../components/plans/PlanLimit.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProductTours',
  metaInfo: {
    title: 'Your product tours created with Bytes Route ',
    meta: [{ name: 'description', content: 'Create and manage your product tours' }]
  },
  components: { DomainCard, DomainsWithoutTours, BuildProductTour, PlanLimit, CreateTourButton },
  computed: {
    ...mapGetters({
      planActiveTourLimitHit: 'company/planActiveTourLimitHit',
      planTeamLimitHit: "company/planTeamLimitHit",
      domainsWithTours: "company/getDomainsWithTours",
      domainsWithoutTours: "company/getDomainsWithoutTours",
      onboardStep: "onboarding/getOnboardStep",
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../utils/common/styleguide/functions.styl'
  @require '../utils/common/styleguide/colors.styl'
  .empty-section .row
    display flex
    justify-content space-around
    align-items center
</style>
