<template lang="pug">
#creating
  #creating-steps.position-relative(:class='classObj')
    step-list(
      v-if="currentIndex > 0 && !changingStepElement"
      :steps="steps"
      :currentStep="currentIndex"
      :creating="true"
      data-testid="steps-list")
    #steps-info
      how-to-step(
        :stepIdx='editStepIndex !== -1 ? editStepIndex : currentIndex'
        :stepType='stepType'
        :hideStepTypeOptions='changingStepElement'
      )
  .btn-group.d-block(:class='classObj')
    button.brt-btn.btn-big.btn-primary.mb-2(
      v-if="currentIndex && editStepIndex < 0 && !changingStepElement"
      @click="saveTour"
      title="Save the tour"
      data-testid="end-button") Save {{ endBtnText }}
    button.brt-btn.btn-big.btn-secondary(
      @click="cancelTour"
      title="Cancels tour creation."
      data-testid="end-button") Cancel
  create-step(
    v-if='step && stepType==="tooltip"'
    v-show='step && !changingStepElement'
    :currentIndex='editStepIndex !== -1 ? editStepIndex : currentIndex'
    :edit="editStepIndex !== -1"
    :details="step"
  )
  create-modal-step(
    v-if='step && stepType==="modal"'
    v-show='step && !changingStepElement'
    :currentIndex='editStepIndex !== -1 ? editStepIndex : currentIndex'
    :edit="editStepIndex !== -1"
    :details="step"
  )
  create-end-step(v-if='tourDetails' :editedTour='editedTour')
</template>

<script>
import stepList from './stepList.vue';
import howToStep from '../reusable/howToStep.vue';
import step from './create/step.vue';
import modalStep from './create/modalStep.vue';
import end from './create/end.vue';

import { mapGetters } from 'vuex';

export default {
  components: {
    'step-list': stepList,
    'how-to-step': howToStep,
    'create-step': step,
    'create-modal-step': modalStep,
    'create-end-step': end,
  },
  props:['steps', 'editedTour', 'currentIndex', 'editStepFromStorage', 'stepError'],
  data() {
    return {
      step: null,
      stepType: 'tooltip',
      editStepIndex: -1,
      tourDetails: null,
      stepPreviewAvailable: true,
      changingStepElement: false,
    }
  },
  watch: {
    editStepFromStorage: {
      handler(details) {
        if (details && (details.index || details.index === 0)) {
          this.editStep(details);
        }
      },
      deep: true
    },
  },
  mounted() {
    if (this.stepError !== false) {
      // did this details obj trick to fit in editStep method.
      const details = { 
        step: this.steps[this.stepError],
        index: this.stepError,
      };
      this.editStep(details);
      this.$parent.$emit('step:removeError');
    }
    this.$on('tutorial:deleteStep', index => {
      this.$parent.$emit('tutorial:deleteStep', index);
    });
    this.$on('tutorial:editStep', details => {
      this.editStep(details);
    });

    this.$on('create:howToStep:stepType', (stepType) => {
      this.stepType = stepType;
      this.$ievents.sendMessage('create', 'step:create:type', this.stepType);
    });
    
    this.$on('create:step:save', (details) => {
      this.$parent.$emit('create:step:save', { 
        ...details,
        step: {
          stepType: this.stepType,
          ...this.step,
          ...details.step
        }
      });
      this.step = null;
      this.editStepIndex = -1;
      this.stepPreviewAvailable = true;
      if (this.subscription.details.tier === 1) {
        this.stepType = 'tooltip';
      }
    });

    this.$on('create:step:updates', (step) => {
      this.step =  { ...this.step, ...step};
      const stepUpdate = this.step;
      if (!step.content) {
        delete stepUpdate['content'];
      }
      if (this.stepPreviewAvailable) {
        this.$ievents.sendMessage(
          'create',
          'step:preview',
          JSON.stringify({
            stepIndex: this.editStepIndex >=0 ? this.editStepIndex : this.currentIndex,
            step: stepUpdate,
            steps: this.steps,
            subscription: this.subscription
          })
        );
      }
    });

    this.$on('create:step:changeElement', () => {
      this.changeAnchor();
    });

    this.$ebus.$on('create:step:cancel', () => {
      this.step = null;
      this.tourDetails = null;
      this.stepPreviewAvailable = true;
      this.editStepIndex = -1;

      if (this.subscription.details.tier === 1) {
        this.stepType = 'tooltip';
      }

      this.$ievents.sendMessage('create', 'step:edit:cancel', this.stepType);
    });

    this.$ievents.handleMessage('create', 'step', (step) => { 
      const updates = JSON.parse(step);
      this.step ={ ...this.step, ...updates};
      const stepWithDelta ={
        ...this.step,
        content: {
          delta: this.step.content
        }
      };
      this.changingStepElement = false;
      this.stepPreviewAvailable = true;
      if (this.step.content || this.step.hasNext) {
        this.$ievents.sendMessage(
          'create',
          'step:preview',
          JSON.stringify({
            stepIndex: this.editStepIndex >=0 ? this.editStepIndex : this.currentIndex,
            step: this.step.content.delta ? this.step : stepWithDelta,
            steps: this.steps,
            subscription: this.subscription
          }));
      }
    });
    this.$ievents.handleMessage('create', 'step:edit:notFound', () => { 
      this.stepPreviewAvailable = false;
      this.$ievents.sendMessage('create', 'step:anchorNotFound');
    });

    this.$ievents.handleMessage('create', 'step:changeAnchor', () => { 
      this.changeAnchor();
    });
  },
  methods: {
    cancelTour() {
      if (!this.changingStepElement) {
        this.$parent.$emit('cancelCreation');
      } else {
        this.changingStepElement = false;
        if (!this.step.stepType) {
          this.step.stepType = this.stepType;
        }
        this.editStep({
          step: this.step,
          stepType: this.step.stepType,
          index: this.editStepIndex,
          anchor: this.changingStepElement,
          editedStep: !this.stepPreviewAvailable,
        });
      }
    },
    saveTour() {
      this.tourDetails = {};
      this.$ievents.sendMessage('create', 'preventCreating');
    },
    editStep(details) {
      this.step = details.step;
      this.stepType = details.step.stepType;
      const stepWithDelta = {
        ...details.step,
        content: {
          delta: details.step.content
        }
      }
      this.editStepIndex = details.index;
      this.changingStepElement = details.anchor;
      this.$ievents.sendMessage('create', 'step:preview', JSON.stringify({
        editedStep: details.hasOwnProperty('editedStep') ? details.editedStep : true,
        stepIndex: details.index,
        stepEditingAnchor: details.anchor,
        step: stepWithDelta.content.delta ? this.step : stepWithDelta,
        steps: this.steps,
        subscription: this.subscription
      }));
    },
    changeAnchor () {
      this.changingStepElement = true;
      this.$ievents.sendMessage('create', 'step:edit:element');
      if (this.editedTour) {
        sessionStorage.setItem('retest', true);
      }
    }
  },
  computed: {
    endBtnText() {
      return this.editedTour ? 'Edit' : 'Tour';
    },
    classObj() {
      return {
        withSteps: this.currentIndex && !this.changingStepElement,
        noSteps: !this.currentIndex || this.changingStepElement,
        changingStepElement: this.changingStepElement,
      }
    },
    ...mapGetters({
      subscription: "company/getTeamPlan"
    })
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/functions.styl';
  #creating
    height 99%
    position relative
    overflow hidden
  .btn-group
    &.withSteps
      height: pxToRem(130)
    &.noSteps
      height: pxToRem(90)
  #creating-steps
    &.withSteps
      height: calc(100% - 130px)
    &.noSteps
      height: calc(100% - 90px)
    #steps-info
        margin-top 2rem
</style>
