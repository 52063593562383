<template lang="pug">
  main.step.d-flex.px-2.py-2.mb-2(
    :class="{'fade-out': fadeOut, 'step-error': stepError === index}"
    data-testid="step-item-content"
    @mouseover="hover=true, reorderHover=true"
    @mouseleave="hover=false, reorderHover=false"
  )
    #index-section.d-flex.align-items-center
      .reorder-group.mr-1
        .text-center
          #reorder-arrow-up.reorder-arrow.d-inline-block(v-if="showUpArrow" @click="reorder(-1)" title="move step up")
        .pill-group(data-testid="step-number")
          .reorder-initiate(v-if="!inReorderState")
            .reorder-sign(v-if="creating && reorderHover && stepsLength > 1" @click="toggleReorder()" title='click to reorder')
              icon-rearrange(width="1.35rem" height="1.2rem")
            section.brt-pill.brt-bg-secondary(v-else :class="{'step-error': stepError === index}").
              {{ index + 1 }}
          .reorder-confirm(v-else @click="toggleReorder()" title="finish reordering")
            icon-check(width="1.2rem" height="1.2rem" aria-label='copyIcon')
        .text-center
          #reorder-arrow-down.reorder-arrow.d-inline-block(v-if="showDownArrow" @click="reorder(+1)" title="move step down")

    content-editor(
      :isEditable="false"
      :oldContent="step.content"
      :subscription="subscription"
      :editMode="false"
    )
    options-menu(v-if="hover && creating" :hideCopy='true')
</template>

<script>
import OptionsMenu from '../reusable/optionsMenu.vue';
import iconCheck from '../../images/iconCheck.vue';
import iconRearrange from '../../images/iconRearrange.vue';
import contentEditor from './contentEditor.vue';

import { mapGetters } from 'vuex';

export default {
  props: ['step', 'index', 'creating', 'stepsLength', "stepError"],
  components: {
    'options-menu' : OptionsMenu,
    'icon-check': iconCheck,
    'icon-rearrange': iconRearrange,
    'content-editor': contentEditor
  },
  data() {
    return {
      fadeOut: false,
      stepContent: this.step.content,
      oldContent: this.step.content,
      editMode: false,
      validEdit: true,
      hover: false,
      reorderHover: false,
      inReorderState: false,
      content: {}
    };
  },
  mounted() {
    this.$ebus.$on('step:editModeEnabled', () => {
      this.hover = false;
      this.fadeOut = true;
      this.setStepToDefaultStates();
    });

    this.$ebus.$on('step:editModeDisabled', () => {
      this.fadeOut = false;
      this.hover = false;
    });
    this.$ebus.$on('step:editSaved', () => {
      this.fadeOut = false;
      this.hover = false;
    });
    this.$ebus.$on('step:reorderStateEnabled', () => {
      this.setStepToDefaultStates();
    });
    this.$on('options:delete', () => {
      this.$parent.$emit('tutorial:deleteStep', this.index);
    });
    this.$on('options:edit', () => {
      this.$parent.$emit('tutorial:editStep', {step: this.step, index: this.index});
    });

  },
  watch: {
    editMode(val) {
      if (val && typeof document.body.scrollIntoViewIfNeeded === 'function') {
        this.$nextTick(() => {
          this.$refs['step-edit-buttons'].scrollIntoViewIfNeeded();
        });
      }
    }
  },
  computed: {
    showUpArrow() {
      return this.showArrow() && this.index !== 0;
    },
    showDownArrow() {
      return this.showArrow() && (this.index !== this.stepsLength - 1);
    },
    ...mapGetters({
      subscription: "company/getTeamPlan"
    })
  },
  methods: {
    showArrow() {
      return this.creating && this.inReorderState && this.stepsLength > 1;
    },
    reorder(indexChange) {
      this.$ebus.$emit('step:reorder', {
        currentIndex: this.index,
        indexChange: indexChange
      });
      this.hover = false;
    },
    toggleReorder() {
      if (this.inReorderState === true) {
        this.inReorderState = false;
      } else {
        this.$ebus.$emit('step:reorderStateEnabled');
        this.inReorderState = true;
        this.reorderHover = false;
      }
    },
    setStepToDefaultStates() {
      this.inReorderState = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  .fade-out
    opacity 0.3
  
  main.step-error
    border 1px solid $brt-red-light
  section.step-error
    background-color $brt-red-light
  .step
    height 100%
    min-height pxToRem(60)
    position relative
    background-color $brt-cyan-faded
    border-radius pxToRem(5)

    #index-section
      width 20px

    #editor
      border 0
      width 85%
      max-height pxToRem(500)

    .reorder-group
      .reorder-arrow
        cursor pointer
        width 0
        height 0
        border-left pxToRem(9) solid transparent
        border-right pxToRem(9) solid transparent
      #reorder-arrow-up
        border-bottom pxToRem(10) solid $brt-muted
        &:hover
          border-bottom pxToRem(10) solid $brt-secondary
        &:active
          border-bottom pxToRem(10) solid $brt-secondary-dark
      #reorder-arrow-down
        border-top pxToRem(10) solid $brt-muted
        margin-top pxToRem(3)
        &:hover
          border-top pxToRem(10) solid $brt-secondary
        &:active
          border-top pxToRem(10) solid $brt-secondary-dark
      .pill-group
        cursor pointer
        .reorder-initiate
          .reorder-sign
            fill $brt-secondary
        .reorder-confirm
          fill alpha($brt-secondary-dark, 20%)
          &:hover
            fill $brt-secondary
          &:active
            fill $brt-secondary-dark

    .step-text
      display inline-block
      width 100%
      word-break break-word
      outline none
      border none
      resize none
      background transparent
      margin 0
      padding-top pxToRem(9)
      cursor default

    .invalid-field
      border pxToRem(1) solid $brt-danger
      background $brt-danger-faded

    #edit-error
      position absolute
      left pxToRem(37)
      bottom pxToRem(10)

    .text-hover, .text-editing
      background-color alpha($brt-muted-bright, 30%)
      border-radius pxToRem(3)
      transition all 0.3s ease-in

    .text-hover
      text-decoration underline dashed $brt-primary-dark

    .step-edit-buttons
      border-radius pxToRem(1)
      position absolute
      bottom pxToRem(-26)
      left pxToRem(80)
      z-index 1
      background-color $brt-white
      box-shadow pxToRem(0) pxToRem(2) pxToRem(4) alpha($brt-dark, 30%)
      .step-save-edit
        cursor pointer
        display inline-block
        stroke $brt-secondary-dark
        color $brt-secondary-dark
      .step-cancel-edit
        cursor pointer
        display inline-block
        stroke $brt-danger
        color $brt-danger
      .step-save-edit:hover, .step-cancel-edit:hover
        opacity .8
</style>
