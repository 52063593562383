<template lang="pug">
.manager-container
  Modal(v-if="showConfirmBuy"
    emoji="✨"
    :titleText="buyPlanText"
    secondary="Visit our merchant site for purchase"
    confirmTextOFF="Visit Merchant"
    cancelText="Cancel"
    :confirmCb="navigateToMerchant"
    :cancelCb="() => { this.showConfirmBuy = false }"
  )
  Modal(v-if="showActivated"
    emoji="🎉"
    titleText="Plan activated"
    :secondary="activatedText"
    cancelText="Close"
    :cancelCb="() => { this.showActivated = false }"
  )
</template>

<script>
import Modal from '../../aspect/Modal.vue';
import { handleError } from '../../../mixins/handleError';

export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      showConfirmBuy: false,
      showActivated: false,
      planName: ''
    }
  },
  mounted() {
    this.$ebus.$on("company/activatePlan", plan => {
      this.planName = plan.name;
      this.link = plan.link;
      this.navigateToMerchant();
    });
  },
  methods: {
    navigateToMerchant() {
      window.open(this.link);
      this.showConfirmBuy = false;
    },
  },
  computed: {
    buyPlanText() {
      return `Buy ${this.planName} plan`
    },
    activatedText() {
      return `Enjoy your 15-day ${this.planName} subscription.`
    }
  }
}
</script>
