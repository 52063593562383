<template lang="pug">
.order-2.col-12.col-md-6.col-xl-4.mt-5.mt-xl-0
  .plan-card.position-relative.paid-plan
    .d-flex.justify-content-center
      p.current-plan.small-copy.border(v-show="showCurrentPlanStatusTop")
        | Expires on {{ expirationDate }} 
    .plan-header
      p.plan-title {{ name }}
      h2.d-inline-block.mt-2(v-if="billingType==='month'") ${{ price.monthly }}
      h2.d-inline-block.mt-2(v-else) ${{ price.yearly }}
      span.d-inline-block.body-copy.brt-muted-dark(v-if="price.monthly != 0") / team / {{ billingType }}
      p.billing-total-container(v-if="tier > 1 && billingType === 'year'")
        | 2 months free with yearly billing
    .plan-features
      p.main-text {{ mainText }}
      ul.feature-list
        li(v-for="feature in features") {{ feature }}
    .pricind-card-footer
      button.cta.w-sm-100(
        v-show="showPurchasePlanButton",
        @click="activatePlan()"
        :id="`cta-purchase-${tier}`"
      ) Purchase plan
      h5.brt-primary-bright.pt-3(v-show="showCurrentPlanStatusBottom").
        ✓ Current plan
      button.cta.w-sm-100(
        v-show="showStartTrialButton",
        @click="startTrial()"
        :id="`cta-trial-${tier}`"
      ) Get 15 days free trial
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    tier: { type: Number, required: true },
    name: { type: String, required: true },
    mainText: { type: String, required: true },
    billingType: { type: String, required: true },
    price: { type: Object, required: true },
    features: { type: Array, required: true },
    checkoutLink: { type: String },
  },
  data() {
    return {
      signature: "",
    };
  },
  async mounted() {
    const res = await this.$http.get(
      `${this.$config.default.apiUrl}/subscription/signature?teamId=${this.teamId}`
    );
    this.signature = res.data.signature;
  },
  methods: {
    startTrial() {
      this.$ebus.$emit('company/startTrial', {
        name: this.name,
        tier: this.tier
      });
    },
    activatePlan() {
      this.$ebus.$emit('company/activatePlan', {
        name: this.name,
        link: this.checkoutLinkFull
      })
    }
  },
  computed: {
    showCurrentPlanStatusTop() {
      if (this.teamPlanTier === 1) return false;
      return this.teamPlanTier === this.tier
    },
    showCurrentPlanStatusBottom() {
      return this.teamPlanTier === this.tier;
    },
    showPurchasePlanButton() {
      return (
        this.companyTrialUsed &&
        this.tier > 1
      );
    },
    showStartTrialButton() {
      return (
        !this.companyTrialUsed &&
        this.teamPlanTier === 1 &&
        this.tier > 1
      );
    },
    checkoutLinkFull() {
      return `${this.checkoutLink}${this.billingType}&order-ext-ref=${this.teamId}&signature=${this.signature}`;
    },
    ...mapGetters({
      expirationDate: "company/teamPlanExpirationDate",
      teamPlanTier: "company/getTeamPlanTier",
      companyTrialUsed: "company/companyTrialUsed",
      teamId: "company/getTeamID"
    }),

  },
};
</script>

<style lang="stylus" scoped>
@require '../../stylus/pricing-cards.styl';

.current-plan {
  position: absolute;
  top: -15px;
  background-color: $brt-white;
  width: pxToRem(275);
  padding: 5px 15px 5px 15px;
  text-align: center;
}
</style>
