<template lang="pug">
  .container(data-testid="register-success-view")
    .row.justify-content-center
      .col-10.col-sm-8.col-lg-6.col-xl-4.brt-dark.mb-4
        h3.mt-5.pt-5.mb-0 Kudos! &#x1F389
        h3.mb-4 Your account was activated
        h4.body-copy.font-regular(v-if="!loginFailed") Auto login in {{ waitingTime }} seconds...
        h4.body-copy.font-regular(v-else) Auto login in failed. go to #[router-link(:to="{ name: 'login'}") log in]

</template>
<script>
import { auth } from '../../utils/auth.js';

export default {
  name: 'RegisterSuccess',
  metaInfo: {
    title: 'Registered successfully | Bytes Route',
    meta: [{ name: 'description', content: 'You successfully registered for a Bytes Route account' }]
  },
  data() {
    return {
      waitingTime: 3,
      loginFailed: false,
      counterCalled: false,
    }
  },
  mounted() {
    if (this.counterCalled) return;
    this.counterCalled = true;

    this.wait(() => {
      auth.token = this.$route.query.token;
      auth.profile = auth.token.split('.')[1];
      const url = `${this.$config.default.apiUrl}/user/initialize`;
      this.$http.post(url, {
        email: auth.profile.email,
      })
      .then(() => {
        this.$router.push({ name: 'dashboard' });
      })
    });
  },
  methods: {
    wait(cb) {
      let wInterval = setInterval(() => {
        if (this.waitingTime === 1) {
          clearInterval(wInterval);
          cb();
        }
        this.waitingTime--;
      }, 1000)
    }
  }
};
</script>

