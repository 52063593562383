<template lang="pug">
  .manager-container
    Modal(v-if="showStartTrialConfirm"
      :emoji="'🎁'"
      :titleText="textTrialConfirm"
      secondary="Start your 15-day trial for this plan?"
      confirmTextOFF="Start Trial"
      :confirmCb="startTrial"
      :cancelCb="() => { this.showStartTrialConfirm = false}"
    )
    
    Modal(v-if="showTrialStartedInfo"
      emoji="🎉"
      titleText="Trial started"
      :secondary="textTrialStarted"
      cancelText="Close"
      :cancelCb="() => { this.showTrialStartedInfo = false }"
    )
</template>

<script>
import Modal from '../../aspect/Modal.vue';
import { handleError } from '../../../mixins/handleError';
import { mapGetters } from 'vuex';

export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      showStartTrialConfirm: false,
      showTrialStartedInfo: false,
      planName: '',
      planNameFull: '',
    }
  },
  mounted() {
    this.$ebus.$on("company/startTrial", plan => {
      this.planName = plan.name;
      this.planNameFull = "SUBSCRIPTION_" + plan.tier;
      this.showStartTrialConfirm = true;
    });
  },
  methods: {
    async startTrial() {
      this.showStartTrialConfirm = false;
      try {
        const url = `${this.$config.default.apiUrl}/company/${this.teamID}/subscription`;
        const body = { subscriptionType: this.planNameFull }
        const result = await this.$http.put(url, body); 
        if (result.status === 200) {
          await this.$store.dispatch("company/getSelectedTeamData");
          this.showTrialStartedInfo = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    textTrialConfirm() {
      return `${this.planName} Plan trial`
    },
    textTrialStarted() {
      return `Enjoy your 15-day ${this.planName} trial.`
    },
    ...mapGetters({
      teamID: 'company/getTeamID'
    })
  }
}
</script>
