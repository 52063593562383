export const handleError = {
  methods: {
    handleError(err) {
      // eslint-disable-next-line no-console
      console.error(err);
      // eslint-disable-next-line no-alert
      // alert('It seems that we have some problems, could you try again later?');
    }
  }
};
