<template lang="pug">
.brt-message-box(ref='container')
  #details.px-3.pt-4
    #header.mb-5
      span.brt-caption {{ 'Step ' + stepNo }} options
      button#anchor-btn.brt-btn.btn-primary(title="Change the selected element for this step" @click='changeElement')
        icon-anchor#anchor-icon.mr-1(width="1.2rem" height="1.2rem" aria-label="anchorIcon")
        | Change anchor
    content-editor(
      :isEditable="true"
      :oldContent="step.content"
      :subscription="subscription"
      :editMode="!!this.edit"
      ref="contentContainer"
    )
    .line-splitter
    span.brt-caption.brt-primary step type
    #step-optional-container.d-flex.justify-content-between.align-items-center.mt-3
      .radio-buttons-container.d-flex
        .radio-group
          input#step-mandatory(type="radio", :value="false", v-model="step.optional") 
          label(for="step-mandatory") Mandatory
        .radio-group.ml-3
          input#step-optional(type="radio", :value="true", v-model="step.optional")
          label(for="step-optional") Optional
    .line-splitter
    span.brt-caption.brt-primary next step is triggered by
    #step-navigation-container.mt-3
      .radio-buttons-container.d-flex.justify-content-between.align-items-center
        .radio-group
          input#next-button(type="radio", :value="true", v-model="step.hasNext") 
          label(for="next-button") Next step button
        .radio-group.ml-3
          input#click-element(type="radio", :value="false", v-model="step.hasNext")
          label(for="click-element") Clicking on the element
      #brt-elem-interact-container.mt-3(v-if="step.hasNext")
        input#brt-elem-interact.create-checkbox(v-model='allowInteraction' type="checkbox" name="elemInteract")
        label(for="brt-elem-interact") Allow element interaction
  .btn-group.d-block
    button.brt-btn.btn-big.btn-primary.mb-2(title="Save step to your tour" @click='save').
      Save step
    button.brt-btn.btn-big.btn-secondary(title="Cancel step options." @click='cancel').
      Cancel step options

</template>
<script>
import Validator from '../../../../utils/common/utils/validator';
import iconAnchor from '../../../images/anchor.vue';
import contentEditor from '../../reusable/contentEditor.vue';

import { mapGetters } from 'vuex';

export default {
  props: ['currentIndex', 'edit', 'details'],
  components: {
    'icon-anchor': iconAnchor,
    'content-editor': contentEditor
  },
  data () {
    return {
      step: {
        content: this.edit ? this.details.content : null,
        hasNext: this.edit ? this.details.hasNext : true,
        optional: this.edit ? this.details.optional : false,
        blockElem: this.edit ? this.details.blockElem : false,
      },
      validator: '',
      showAllowCheckbox: false,
      allowInteraction: this.edit && !this.details.blockElem && this.details.hasNext,
    }
  },
  watch: {
    step: {
      handler(updates){
        this.step.blockElem = updates.hasNext && !this.allowInteraction;
        this.$parent.$emit('create:step:updates', this.step);
      },
      deep: true
    },
    allowInteraction (allowNewVal) {
      this.step.blockElem = this.step.hasNext && !allowNewVal;
    },
  },
  mounted() {
    this.validator =  new Validator(this.$refs.container);
    this.$on('create:step:update:content', (content) => {
      this.$parent.$emit('create:step:updates', { content });
    });
  },
  methods: {
    save() {  
      const isValid = this.validateStep();
      if (isValid) {
        this.step.content = this.$refs.contentContainer.contentEditor.getContents();
        this.step.blockElem = this.step.hasNext && !this.allowInteraction;
        this.$parent.$emit('create:step:save', {step: this.step, index: this.currentIndex, edit: this.edit});
        this.$ievents.sendMessage('create', 'continue');
      }
    },
    cancel() {
      this.validator.reset();
      this.$ievents.sendMessage('create', 'continue');
      this.$ebus.$emit('create:step:cancel');
    },
    toggleAllowCheckboxVis(show) {
      this.showAllowCheckbox = show;
    },
    validateStep() {
      this.validator.removeErrors();
      const validFields = this.validator.validateFields();

      const contentChecker = this.$refs.contentContainer.isValid();

      const isValid = validFields && contentChecker;
      return isValid;
    },
    changeElement() {
      this.$parent.$emit('create:step:changeElement');
    }
  },
  computed: {
    stepNo() {
      return this.currentIndex ? this.currentIndex + 1 : 1;
    },
    ...mapGetters({
      subscription: "company/getTeamPlan"
    })
  }
};
</script>
<style lang="stylus" scoped>
  @import '../../../../utils/common/styleguide/colors.styl';
  @import '../../../../utils/common/styleguide/functions.styl';

  .brt-message-box
    position absolute
    height 100%
    width 100%
    z-index 100
    top 0
    background white
    display flex
    flex-direction column
    justify-content: space-between
    #details
      height 70%
      justify-content center
      display flex
      flex-direction column
      #header
        position relative
        height auto
        .brt-caption
          font-size 1rem
      #brt-elem-interact-container
        position absolute  
      #anchor-btn
        position absolute
        top pxToRem(-5)
        right 0
        width pxToRem(150)
        padding .5rem 0
        #anchor-icon
          margin-top pxToRem(-3)
    .create-checkbox
      margin-left 0px
      margin-right 5px
    #brt-checkbox-optional
      padding 5px
      display flex
    #brt-navigation-option
      margin-top 10px
      padding 5px
      border 2px solid transparent

      #brt-label-container
        display flex
        align-items center
        margin-bottom 5px

      #brt-radios-container
        display flex
        justify-content space-between
        input
          margin 0px 5px 0px 0px
          height 14px
        #option-next-container, #option-elem-container
          display flex
          align-items center
        #option-next-container
          margin-right 15px

      #brt-checkbox-container
        padding-top 5px
        display flex
        text-align left !important

    #brt-btn-save
      padding 5px 27px
    #brt-btn-fin
      padding 5px 27px

    .invalid-radio-selection
      border 2px solid $brt-danger !important

    #brt-nav-option-error
      color $brt-danger-dark
      font-size 13px
  .line-splitter 
    border-bottom 1px solid $brt-muted
    margin-top 2rem
    margin-bottom 2rem
</style>
