<template lang="pug">
.step-optional-container.d-flex.justify-content-between.align-items-center
  .radio-buttons-container.d-flex
    .radio-group
      input#step-mandatory(type="radio", :value="false", v-model="isOptional") 
      label(for="step-mandatory") Mandatory
    .radio-group.ml-3
      input#step-optional(type="radio", :value="true", v-model="isOptional")
      label(for="step-optional") Optional

  Icon-Tooltip(:text="tooltip.tour.step.optional", direction="top")
</template>

<script>
import IconTooltip from "../../icons/IconTooltip.vue";
import { tooltip } from "../../../jsons/tooltips.json";
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  props: {
    optional: Boolean,
    index: Number
  },
  data() {
    return {
      isOptional: true,
      tooltip
    };
  },
  async mounted() {
    this.setInitialStepOption();
  },
  watch: {
    optional() {
      this.setInitialStepOption();
    },
    isOptional() {
      if (this.optional === this.isOptional) return;
      this.updateStepType();
    }
  },
  methods: {
    setInitialStepOption() {
      this.isOptional = this.optional;
    },
    updateStepType() {
      this.$ebus.$emit("tour/updateStepType", {
        tutorial: this.tutorial,
        index: this.index,
        optional: this.isOptional
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
