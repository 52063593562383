<template lang="pug">
  #plan-Limit.position-relative(v-if="showPlanLimitHit")
    .brt-card.brt-bg-muted-faded.d-flex.justify-content-between
      img.green-lock(src='/assets/plans/greenLock.svg')
      .text-content.px-5
        p.brt-dark.body-copy.font-semibold
          span You have reached your plan limit for 
          span.brt-secondary-dark.
            {{ teamName }}.
        ul#limits-container.brt-muted-dark
          li.limit(v-if="planActiveTourLimitHit")
            | Active tours limit reached: 
            | {{ activeToursCount }} /
            | {{ planActiveTours }}
          li.limit(v-if="showTeamLimitHit")
            span Team members limit reached: 
            span {{ teamMembersCount }} / 
            span {{ planTeamMembers }}
        p.brt-muted-dark.mb-0 Upgrade your plan to use the service at it's full!
      .upgrade-plan-container.d-flex.align-items-center
        button.cta(@click="upgradePlan()") Upgrade plan
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    }
  },
  computed: {
    showPlanLimitHit() {
      return this.planActiveTourLimitHit || this.showTeamLimitHit
    },
    showTeamLimitHit() {
      return this.planTeamLimitHit && this.teamMembersCount > 1
    },
    ...mapGetters({
      teamMembersCount: 'company/getTeamMembersCount',
      activeToursCount: 'company/getActiveToursCount',
      planTeamMembers: 'company/getPlanTeamMembersCount',
      planTeamLimitHit: 'company/planTeamLimitHit',
      planActiveTours: 'company/getPlanActiveToursCount',
      planActiveTourLimitHit: 'company/planActiveTourLimitHit',
      teamName: 'company/getTeamName'
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
    .green-lock
      left 2rem
    #limits-container
      padding-left 0
      display flex
      list-style-type none

      .limit
        padding 0.5rem 1rem
        margin-right 0.5rem
        border-radius 2rem
        background-color $brt-danger-faded
</style>
