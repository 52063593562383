<template lang="pug">
  #how-to-step.pb-3
    div
      #how-to-step-header.px-4.d-flex.flex-row.justify-content-between.align-items-center
        #header-info.d-flex.flex-row
          Icon-Tooltips.mt-2.mr-3(v-if='stepType === "tooltip"')
          Icon-Modal.mt-2.mr-3(v-else)
          h3.h3.brt-dark.pb-0 {{ 'Step ' + stepNo }}
        #stepTypeSelector.d-flex.flex-row.align-items-center.mt-2(v-if='!hideStepTypeOptions' @click='openSelector')
          p.brt-caption {{ selectorOpen ? 'See Less' : stepType }}
          img.ml-1.mb-3(src='/assets/misc/chevron.svg' width="12px" alt="chevron arrow up"
            :class="{'rotate-180': selectorOpen}")
      #stepTypeContainer.brt-bg-muted-faded(v-if='!hideStepTypeOptions' :class="{'selectorOpen': selectorOpen}")
        .px-4.py-3
          p.small-copy.brt-dark.pb-2 Select step type
          .d-flex.flex-row.mt-4
            .stepTypeCard.pt-4.px-4.pb-3.mr-3(
              :class="{'selected': stepType === 'modal'}"
              @click="onStepTypeSelected('modal')"
              @mouseover="modalCardHovered=true"
              @mouseleave="modalCardHovered=false"
            )
              .disable-modals(
                v-if='subscription && subscription.details.tier === 1'
              )
                img.green-lock(src='/assets/plans/greenLock.svg')
                .upgrade-btn-container(:class='{"showUpgrade": modalCardHovered}')
                  button.small-cta(
                    @click='openSubscriptionPage'
                  ) 
                    img(src='/assets/plans/whiteLock.svg')
                    | Upgrade
              Icon-Modal.mb-3.size-modal
              .stepTypeCardText.d-flex.flex-row.align-items-center.mt-1
                span Modal
                Icon-Tooltip(
                  text="A modal is a graphic that grabs the user's attention, requiring interaction before returning to the main window."
                  direction="top"
                )
            .stepTypeCard.pt-4.px-4.pb-3.ml-3(
              :class="{'selected': stepType === 'tooltip'}"
              @click="onStepTypeSelected('tooltip')"
            )
              Icon-Tooltips.mb-2.size-tooltip
              .stepTypeCardText.d-flex.flex-row.align-items-center.mt-1
                span Tooltip
                Icon-Tooltip(
                  text="A tooltip is a graphic near an HTML element, often used for describing that element."
                  direction="left"
                )
      #step-info.py-3
        .text-left.px-4
          ul.brt-ul
            li.d-flex
              img.mb-3.icon-md(src="../../images/iconHelperTour.svg" alt="a line from start to finish")
              p.body-copy.ml-3 Navigate to the page where step 1 will be placed
            li.d-flex
              img.mb-3.icon-md(src="../../images/mouseIcon.svg" alt="computer mouse")
              p.body-copy.ml-3.mb-0 
                | {{ stepType === 'tooltip' ? 'Right click on an element to select it as the step anchor' : 'Right click anywhere on the page to place your modal' }}
        .enough-steps.pt-3.pb-1(v-if='stepIdx > 0 && !hideStepTypeOptions')
          p.body-copy.px-4.mx-3 Don't need other steps? Click on 'Save tour' ↓
</template>

<script>
import IconTooltips from '../../images/iconToolTips.vue';
import IconModal from '../../images/iconModal.vue';
import IconTooltip from '../../../components/icons/IconTooltip.vue';
import { mapGetters } from 'vuex';

export default {
  components: { IconTooltips, IconModal, IconTooltip },
  props: ['stepIdx', 'stepType', 'hideStepTypeOptions'],
  data() {
    return {
      selectorOpen: true,
      modalCardHovered: false,
    }
  },
  methods: {
    openSelector() {
      this.selectorOpen = !this.selectorOpen;
    },
    onStepTypeSelected(stepType) {
      if (this.subscription.details.tier !== 1) {
        this.$parent.$emit('create:howToStep:stepType', stepType);
      }
    },
    openSubscriptionPage() {
      window.open(`${this.$urlConfig.getDashboardLink()}subscription`, '_blank');
    }
  },
  computed: {
    stepNo() {
      return this.stepIdx ? this.stepIdx + 1 : 1;
    },
    ...mapGetters({
      subscription: "company/getTeamPlan"
    })
  }
};
</script>

<style lang="stylus">
  @import '../../../utils/common/styleguide/colors.styl'
  @import '../../../utils/common/styleguide/functions.styl'

  .enough-steps
    background-color $brt-gray-faded
  #stepTypeSelector
    cursor pointer
    user-select none
  #stepTypeContainer
    height 0
    overflow hidden
    transition height .4s ease
    &.selectorOpen
      height 14.5rem
    .small-copy
      font-size pxToRem(16)
    .stepTypeCard
      position relative
      background-color $brt-white-pure
      display flex
      flex-direction column
      flex: 1 1 0
      align-items center
      justify-content space-around
      border-radius pxToRem(8)
      outline 1px solid $brt-gray-bright
      &.selected
        position relative
        outline 2px solid $brt-cyan-dark
        &:before
          content 'SELECTED'
          position absolute
          top pxToRem(-10)
          font-size pxToRem(10)
          font-weight bold
          letter-spacing .5px
          background-color $brt-white-pure
          padding pxToRem(3) pxToRem(10)
          border-radius pxToRem(50)
          outline 2px solid $brt-cyan-dark
      .size-modal
        width pxToRem(52.5)
        height pxToRem(42)
      .size-tooltip
        width pxToRem(46)
        height pxToRem(46)
  .disable-modals
    position absolute
    display flex
    justify-content center
    align-items center
    top 0
    z-index 1
    width 100%
    height 100%
    border-radius pxToRem(8)
    background rgba(248, 247, 250, .5)
    transition all .2s ease-in-out 0s
    &:hover
      background rgba(248, 247, 250, .8)
    .green-lock
      right .5rem
      height 40%
    .upgrade-btn-container
      transition all .2s ease-in-out 0s
      opacity 0
      &.showUpgrade
        opacity 1
      .small-cta
        cursor pointer
        padding .2rem .5rem
        font-size .8rem
        img
          height 13px
          margin-top -5px
          margin-right 5px
</style>
