<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from "../mixins/updateTourStep.js";
export default {
  mixins: [updateTourStep],
  mounted() {
    this.$ebus.$on("tour/updateStepType", data => {
      this.updateTourStepType(data.tutorial, data.index, data.optional);
    });
  },
  methods: {
    async updateTourStepType(tutorial, index, optional) {
      const newStep = tutorial.steps[index];
      newStep.optional = optional;
      await this.updateTourStep(tutorial, index, newStep, "type");
    }
  }
};
</script>
