<template lang="pug">
  a(:href='link' alt="Bytes Route" title="Bytes Route" data-testid="home-button")
    img(width=120 src="/assets/logo/logoBR.svg")
</template>


<script>
export default {
  computed: {
    link() {
      return this.$route.name === 'login' || this.$route.name === 'register' ? 
      'https://www.bytesroute.com'
      :
      '/'
    }
  }
}
</script>