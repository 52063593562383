<template lang="pug">
  Modal-Duplicate-Tour(
    v-if='show'
    :confirmCb="duplicateTour"
    :cancelCb="() => { this.show = false }"
    :tourDomain="tutorial.domain"
  )
</template>

<script>
import ModalDuplicateTour from "../ModalDuplicateTour.vue";

import { handleError } from "../../../mixins/handleError";
import Validator from "../../../utils/common/utils/validator.js";
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  components: { ModalDuplicateTour },
  props: {
    event: Object
  },
  data() {
    return {
      show: false,
      tutorial: {}
    };
  },
  mounted() {
    this.$ebus.$on("tour/duplicateTour", tutorial => {
      this.tutorial = tutorial;
      this.show = true;
    });
  },
  methods: {
    async duplicateTour(domain) {
      this.validateField();
      const url = `${this.$config.default.apiUrl}/tutorial/${this.tutorial._id}/duplicate`;
      try {
        const res = await this.$http.post(url, { domain });
        if (res.status === 201) {
          this.show = false;
          await this.$store.dispatch("company/getSelectedTeamTours");
          this.$router.push({ path: `/tour/${res.data.tutorial._id}/analytics`});
          this.$toast("Tour successfully duplicated", { type: TYPE.SUCCESS });
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 409) {
          this.validator.invalidField(
            document.querySelector(".brt-validate-that input"),
            "Cannot duplicate this tour."
          );
        } else {
          console.error(err);
          this.$toast("Error duplicating the tour. Please retry.", {
            type: TYPE.ERROR
          });
        }
      }
    },
    validateField() {
      this.validator = new Validator(
        document.querySelector(".brt-validate-that")
      );
    }
  },
};
</script>
