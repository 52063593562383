<template lang="pug">
.row.mx-0.p-0.py-2
  .col-12.py-3.position-relative
    .minimized-details.d-inline.d-md-flex.align-items-center.justify-content-between
      .left-container.align-items-center
        .body-copy.d-flex.align-items-center.ml-3
          img(src="/assets/misc/code.svg" width="25" alt="code icon")
          span.text-nowrap.ml-2 Install the Script on your website
      .right-container.d-flex.justify-content-between.w-100.mt-3.mt-md-0.ml-4
        button.small-cta.small-copy(@click="detectScript()" title="Check for list of domains with your script" data-testid="detect-script").
          Check sync
</template>

<script>
import { checkForDomainsWithScript } from "../mixins/checkForDomainsWithScript.js";

export default {
  mixins: [checkForDomainsWithScript],
}
</script>
