<template lang="pug">
tr
  td.px-3.pt-4
    span.brt-pill.brt-white.brt-bg-secondary-dark.px-2 {{ index + 1 }}
  td.px-3.pt-4  {{ theme.name }}
  td.px-3.pt-4
    Theme-Preview(:theme="theme")
  td.px-3.pt-4 {{ theme.occurences }}
  td.px-3.pt-4
    button.icon-button(@click="goToEdit()" v-show='!theme.default')
      svg(width="1.2rem" height="1.2rem")
        use(href="/assets/misc/iconEdit.svg#Layer_1")
  td.px-3.pt-4
    button.delete-theme.icon-button(@click="deleteTheme()" v-show='!theme.default')
      svg(width="1.2rem" height="1.2rem")
        use(href="/assets/misc/iconDelete.svg#Layer_1")
</template>

<script>
import ThemePreview from "./ThemePreview.vue";
import { mapGetters } from 'vuex';

export default {
  props: {
    theme: { 
      type: Object, 
      required: true
    },
    index: {
      type: Number,
      required: true
    },
  },
  components: { ThemePreview },
  data() {
    return {
      deleteWarning: '',
      confirmDelete: false
    }
  },
  methods: {
    deleteTheme() {
      this.$ebus.$emit('company/deleteTheme', {
        theme: this.theme,
        teamID: this.teamID
      })
    },
    goToEdit() {
      this.$router.push({ name: 'themeCreation', params: { id: this.theme._id }});
    }
  },
  computed: {
    ...mapGetters({
      teamID: "company/getTeamID"
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/buttons.styl'

  .delete-theme
    color $brt-red-dark
    stroke $brt-red-dark
</style>
