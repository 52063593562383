<template lang='pug'>
  .brt-card.text-center.position-relative
    .body-copy.my-4.font-semibold.
      It's only you on the team right now.
    button.cta.mb-4(v-if="planTeamLimitHit" @click='upgradePlan()').
      Upgrade your plan and invite new members
    button.cta.mb-4(v-else @click="inviteTeamMember()").
      Invite new team member
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription'});
    },
    inviteTeamMember() {
      this.$ebus.$emit('company/inviteTeamMember')
    }
  },
  computed: {
    ...mapGetters({
      planTeamLimitHit: "company/planTeamLimitHit"
    })
  }
}
</script>

<style lang='stylus' scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/buttons.styl'

  .brt-card
    background-image url("/assets/team/team-spirit.svg")
    background-repeat no-repeat
    background-size 18%
    background-position-x 4%
    background-position-y 40%
</style>
