<template lang="pug">
.tutorial-header(v-if="tutorial")
  .row.mt-4.mb-5
    .col-12.col-sm-7
      Tour-Name-Manager
      p.body-copy.brt-dark.text-break.mb-0.
        {{ tutorialDescription }}
    .col-12.col-sm-5.d-flex.justify-content-end
      .script-missing.brt-card.px-2.py-2.d-flex.align-items-center.mr-3(v-if="!tutorialDomainHasScript")
        img.mx-2(src='/assets/misc/alert.svg' width="16")
        router-link.danger-link(to="/dashboard").small-copy.brt-danger-dark.ml-1.
          Install the script on this domain to gain full power
      .domain-and-date-container.d-flex.align-items-center
        .inner-container
          .domain-container.d-flex.justify-content-end
            .small-copy.brt-muted-dark.pl-2 {{ tutorialDomainName }}
          .small-copy.brt-muted-dark.text-right {{ formatedDate }}
</template>

<script>
import { mapGetters } from "vuex";
import TourNameManager from '../tour/TourNameManager.vue';

export default {
  components: { TourNameManager },
  computed: {
    formatedDate() {
      const dateFormat = {
        month: "short",
        day: "numeric",
        year: "numeric"
      };
      return new Date(this.tutorial.created).toLocaleDateString(
        "en-EN",
        dateFormat
      );
    },
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      tutorialName: "tutorial/getTutorialName",
      tutorialDescription: "tutorial/getTutorialDescription",
      tutorialDomainName: "tutorial/getDomainName",
      tutorialDomainHasScript: "tutorial/domainHasScript",
    })
  }
};
</script>
