<template lang="pug">
  .outer-container
    .d-flex.justify-content-between.align-items-center.mb-3
      .body-copy Run only once
      .toggle-container.d-flex.justify-content-between
        Toggle-Switch(
          v-model="tutorial.options.once"
          :titleEnabled="tooltip.tour.runOnce.enabled"
          :titleDisabled="tooltip.tour.runOnce.disabled"
          :disabled="!domain.hasScript || !tutorial.options.autoRun"
          @change="toggleRunOnce()"
          data-testid="runonce-toggle")
        Icon-Tooltip(:text="tooltip.tour.runOnce.explained")
</template>

<script>
import ToggleSwitch from "../../../utils/common/vuecomps/ToggleSwitch.vue";
import IconTooltip from "../../icons/IconTooltip.vue";
import { tooltip } from "../../../jsons/tooltips.json";
import { mapGetters } from "vuex";

export default {
  components: { ToggleSwitch, IconTooltip },
  data() {
    return {
      tooltip
    };
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      domain: "tutorial/getDomain"
    })
  },
  methods: {
    toggleRunOnce() {
      this.$ebus.$emit("tour/toggleRunOnce", this.tutorial);
    }
  }
};
</script>

<style lang="stylus" scoped>
.toggle-container
  min-width 6.5rem
</style>
