<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from '../mixins/updateTourStep.js';
export default {
  mixins: [updateTourStep],
  mounted() {
    this.$ebus.$on("tour/updateStepElementInteraction", data => {
      this.updateStepInteraction(data.tutorial, data.index, data.interaction);
    });
  },
  methods: {
    async updateStepInteraction(tutorial, index, interaction) {
      const newStep = tutorial.steps[index];
      newStep.blockElem = interaction;
      await this.updateTourStep(tutorial, index, newStep, "interaction type");
    }
  }
};
</script>
