/**
 * The purpose of this class is to provide a layer of indirection between clients.
 */
export default class InstrumentationProbe {
  constructor() {
    this.clients = [];
  }
  /**
   * Sends a custom event with the specified arguments to all registered clients.
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {string} value
   */
  event (category, action, label, value) {
    for (const client of this.clients) {
      client.event(category, action, label, value);
    }
  }

  /**
   * Sends a pageview event to all registered clients.
   * @param {string} title
   * @param {string} path
   */
  pageview (title, path) {
    for (const client of this.clients) {
      client.pageview(title, path);
    }
  }

  /**
   * Registers a clients to listen to events.
   * @param {BaseClient} logger
   */
  register (logger) {
    this.clients.push(logger);
  }
}
