<template lang="pug">
  .inner-container.py-3
    .pop-up-emoji.mt-4.mb-3 ⚠️
    p.big-copy.mb-4.font-semibold Autorun Conflict
    p.body-copy.brt-muted-dark.mb-1 Only 1 tour can be set to autorun on an URL within a domain. 
    p.body-copy.brt-muted-dark.mb-5 Proceed with the following changes?
    
    .changes-container.body-copy.mx-5
      .enable-container.d-flex.justify-content-start.mb-2
        .d-flex
          p.brt-secondary-dark ●
          p.text-nowrap.ml-2 Enable auto-run for:
        p.brt-primary-bright.ml-3.text-left {{ tutorial.name }}
      .disable-container.d-flex.justify-content-start
        .d-flex
          p.brt-danger-dark ●
          p.text-nowrap.ml-2 Disable auto-run for: 
        p.brt-primary-bright.ml-3.text-left {{ conflictingTutorial.name }}
</template>

<script>
export default {
  props: {
    tutorial: {
      type: Object,
      required: true
    },
    conflictingTutorial: {
      type: Object,
      required: true
    }
  },
}
</script>