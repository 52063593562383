<template lang="pug">
  .brt-card
    img.green-lock(v-if='!tourCanBecomeActive' src='/assets/plans/greenLock.svg')
    .small-copy.brt-muted-dark
      .d-flex.align-items-center.justify-content-between.mb-3
        p.brt-caption.mb-0 RUN OPTIONS
        button.small-cta.mr-5(v-if="!tourCanBecomeActive" @click="upgradePlan()").
          Upgrade plan to unlock
      p Customize the running options for this tour.

      Tour-Auto-Run.mb-3
      Tour-Run-Once.mb-3
      Tour-Run-Delay
</template>

<script>
import TourAutoRun from "./TourAutoRun.vue";
import TourRunOnce from "./TourRunOnce.vue";
import TourRunDelay from "./TourRunDelay.vue";

import tourActiveCondition from "../mixins/tourActiveCondition.js";
import { mapGetters } from "vuex";

export default {
  components: { TourAutoRun, TourRunOnce, TourRunDelay },
  mixins: [tourActiveCondition],
  methods: {
    upgradePlan() {
      this.$router.push({ path: "/subscription", hash: "#subscription-tab" });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
