import '@babel/polyfill';
import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import Vuex from 'vuex'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import toastOptions from './config/toastOptions.js'

import App from './App.vue';
import store from './store'
import { config } from './utils/config';
import { decorateRouter } from './routes';
import { setupInstrumentation } from './utils/instrumentation';
import { EventBus } from './utils/eventbus';
import UrlConfig from './utils/common/utils/urlConfig.js';
import IframeEvents from './utils/common/utils/iframeEvents.js';

Vue.use(VueRouter);
Vue.use(VueMeta);
Vue.use(Vuex);
Vue.use(Toast, toastOptions);

Vue.prototype.$http = axios;
Vue.prototype.$config = config;
Vue.prototype.$ebus = EventBus;

if (window.parent !== window) {
  Vue.prototype.$ievents = new IframeEvents('child', window);
}

const urlConfig = new UrlConfig();
urlConfig.setup();
Vue.prototype.$urlConfig = urlConfig;

const probe = setupInstrumentation(EventBus);
Vue.prototype.$probe = probe;
Vue.prototype.$urlConfig = urlConfig;
/* eslint-disable import/no-mutable-exports */
let app = null;

config.get().finally(() => {
  app = new Vue({
    el: '#gallery',
    store,
    router: decorateRouter(VueRouter, probe),
    render: (h) => h(App),
  });
});

export default app;
