<template lang="pug">
  .details-container
    Modal-Youtube(v-if='youtubeTitle' :title='youtubeTitle' :link='youtubeLink')
    p.brt-caption.brt-dark WHY CONNECTING TO BYTES ROUTE?
    p.body-copy.brt-muted-dark.pb-4 You need to connect your app to Bytes Route in order to share experiences live with your users.
    #how-container.py-5
      p.brt-caption.brt-dark HOW TO CONNECT?
      p.body-copy.brt-muted-dark Install a code snippet on your domain. Copy the following line in your application source code.
      Copy-Script(data-testid="copy-script")
      #how-to-platforms.p-4.mt-5
        p.brt-dark.font-italic.pb-2 Don't know how to install the code snippet? Try using one of these platforms
        .d-flex.flex-row.mt-4
          .platform-card.mr-4(@click="youtubeTitle=youtubeGTMTitle; youtubeLink=youtubeGTMLink")
            img(src="/assets/onboard/GTM.png" alt="connect icon" width="125")
          .platform-card(@click="youtubeTitle=youtubeWPTitle; youtubeLink=youtubeWPLink")
            img.mt-2(src="/assets/onboard/WP.png" alt="connect icon" width="125")
</template>

<script>
import CopyScript from '../common/CopyScript.vue';
import ModalYoutube from '../modals/ModalYoutube.vue';

export default {
  components: { CopyScript, ModalYoutube },
  data() {
    return {
      toggleYoutubeModal: false,
      youtubeGTMTitle: 'How to set up using Google Tag Manager',
      youtubeGTMLink: 'https://www.youtube.com/embed/LEg703v3cR4',
      youtubeWPTitle: 'How to set up using Wordpress',
      youtubeWPLink: 'https://www.youtube.com/embed/yKQvzC_Yti0',
      youtubeTitle: '',
      youtubeLink: '',

    }
  },
  mounted() {
    this.$on('closeYoutubeModal', () => {
      this.youtubeTitle = '';
    });
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'

  #how-container
    border-top 2px solid $brt-muted-faded
    border-bottom 2px solid $brt-muted-faded
    #how-to-platforms
      border 1.4px solid $brt-purple-bright
      border-radius 5px
      .platform-card
        cursor pointer
        box-shadow 0px 1px 10px #0000001F
        border-radius 8px
</style>
