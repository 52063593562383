 
<template lang="pug">
.analytics-container
  Basic-Analytics
  Advanced-Analytics
  Analytics-Actions
</template>

<script>
import BasicAnalytics from '../components/tour/analytics/BasicAnalytics.vue';
import AdvancedAnalytics from '../components/tour/analytics/AdvancedAnalytics.vue';
import AnalyticsActions from '../components/tour/analytics/AnalyticsActions.vue';

export default {
  components: { BasicAnalytics, AdvancedAnalytics, AnalyticsActions },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    }
  },
}
</script>
