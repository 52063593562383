import handleError from "../../commonjs/handleError.js";

export default {
  namespaced: true,
  state: () => ({
    onboardStep: -1,
    focusedStep: -1,
    teamInviteName: '',
    extensionDetected: false,
    minimized: false,
    modal: {
      scriptNotFound: false,
      welcome: false,
      teamInvite: false,
      createTour: false
    }
  }),
  getters: {
    getOnboardStep(state) {
      return state.onboardStep;
    },
    getFocusedStep(state) {
      return state.focusedStep;
    },
    getMinimizedState(state) {
      return state.minimized;
    },
    getExtensionDetected(state) {
      return state.extensionDetected;
    },
    getTeamInviteName(state) {
      return state.teamInviteName
    },
    showModalScriptNotFound(state) {
      return state.modal.scriptNotFound;
    },
    showModalWelcome(state) {
      return state.modal.welcome;
    },
    showModalTeamInvite(state) {
      return state.modal.teamInvite;
    },
    showModalCreateTour(state) {
      return state.modal.createTour;
    }
  },
  mutations: {
    checkForExtension(state) {
      state.extensionDetected = sessionStorage.getItem('brtState') ? true: false;
    },
    setOnboardStep(state, newStep) {
      state.onboardStep = newStep;
    },
    initializeFocusedStep(state) {
      if (state.onboardStep >= 4) {
        state.focusedStep = 3
      } else {
        state.focusedStep = state.onboardStep;
      }
    },
    setFocusedStep(state, step) {
      if (step === 4) {
        state.focusedStep = 3;
      } else {
        state.focusedStep = step;
      }
    },
    toggleMinimize(state) {
      state.minimized = !state.minimized
      localStorage.setItem('onboardMinimized', state.minimized);
    },
    setMinimizedState(state) {
      let storageMinimize = localStorage.getItem('onboardMinimized'); 
      if (storageMinimize) {
        state.minimized = (storageMinimize == 'true')
      }
    },
    setTeamInviteName(state, name) {
      state.teamInviteName = name;
    },
    toggleModalScriptNotFound(state, visibility) {
      state.modal.scriptNotFound = visibility;
    },
    toggleModalTeamInvite(state, visibility) {
      state.modal.teamInvite = visibility;
    },
    toggleModalWelcome(state, visibility) {
      state.modal.welcome = visibility;
    },
    toggleModalCreateTour(state, visibility) {
      state.modal.createTour = visibility;
    },
  },
  actions: {
    async getOnboardStepFromDB({ commit }) {
      const response = await this._vm.$http.get(`${this._vm.$config.default.apiUrl}/user/onboarding`);
      
      commit('setOnboardStep', response.data.onboardStep);
      commit('initializeFocusedStep');
    },
    async updateOnboardStep({ commit, state }, step) {
      // only perform api call if we are moving to a different step
      if (step != state.onboardStep) {
        await this._vm.$http.put(`${this._vm.$config.default.apiUrl}/user/onboarding/${step}`);
        commit('setOnboardStep', step);
        commit('setFocusedStep', step);
      }
    },
    async showTeamInvite({ commit }) {
      const teamInvite = localStorage.getItem('brt-team-invite');
      const { teamId } = JSON.parse(teamInvite);

      try {
        const response = await this._vm.$http.get(`${this._vm.$config.default.apiUrl}/company/${teamId}`);
        if (response.status === 200) {
          commit("setTeamInviteName", response.data.name)
          commit('toggleModalTeamInvite', true);
          localStorage.removeItem('brt-team-invite');
        }
      } catch (err) {
        handleError(err);
      }
    },
    async checkForOnboardingModals({ dispatch, commit, state }) {
      const teamInvite = localStorage.getItem('brt-team-invite');
      if (teamInvite) {
        await dispatch("showTeamInvite")
      } else if (state.onboardStep === 0) {
        commit('toggleModalWelcome', true);
      }
    },
    async updateOnboardingOnDomainsWithScriptChanges({ rootGetters, state, dispatch }) {
      const domainsWithScript = rootGetters['company/getDomainsWithScript'];
      if (domainsWithScript.length === 0) {
        if (state.onboardStep === 4) {
          await dispatch("updateOnboardStep", 3);
        }
      } else {
        if (state.onboardStep === 3) {
          await dispatch("updateOnboardStep", 4);
        }
      }
    },
    async advanceToStep3IfComingFromExtensionFlow({rootGetters, state, dispatch}) {
      const totalTours = rootGetters['company/getAllDomainsTours'];
      const condition1 = totalTours.length >= 1;
      const condition2 = state.onboardStep === 2;

      if (condition1 && condition2) {
        await dispatch('updateOnboardStep', 3)
      }
    },
  },
}