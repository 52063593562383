 
<template lang="pug">
  #brt-pie-chart
</template>

<script>
export default {
  props: {
    percentage: { type: String, required: true },
  },
  mounted() {
    this.addStyle();
  },
  watch: {
    percentage () {
      this.addStyle();
    }
  },
  methods: {
    async addStyle() {
      const background = `conic-gradient(#00B3B8 0 ${this.percentage}%, #D5D1DD 0 ${this.percentage}%)`;
      this.$el.style.background = background;
    },
  },
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'

  #brt-pie-chart
    width 5rem
    height 5rem
    border-radius 50%
</style>
