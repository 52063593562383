export default {
  computed: {
    tourCanBecomeActive() {
      // tour can be made active if tour limit isn't already hit or if it's already active from another run option
      return (
        !this.planActiveTourLimitHit ||
        this.tutorial.options.autoRun ||
        this.tutorial.options.show
      )
    },
    planActiveTourLimitHit() {
      return this.$store.getters["company/planActiveTourLimitHit"];
    }
  }
}
