<template lang="pug">
  .brt-card
    #art-container.d-flex.align-items-center.justify-content-center
      .info-container.pl-5
        h5 You don't have any custom themes yet 😔
        .button-container.mt-3
          router-link.cta(v-if="planHasThemes" :to="{ name: 'themeCreation', params: { id: 'new' }}").
            Create a Theme
          router-link.cta(v-else :to="{ path: '/subscription'}").
            Upgrade plan to create custom themes
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      planHasThemes: "company/planHasThemes",
    })
  }
};
</script>

<style lang="stylus" scoped>
#art-container
  background-image url('/assets/theme/noThemes.svg')
  background-repeat no-repeat
  background-size 26%
  background-position left
  min-height 20rem
</style>
