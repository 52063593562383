<template lang="pug">
form.text-input-wrapper(@submit.prevent="")
  .textarea-container
  
    content-editor(
      :isEditable='true'
      :oldContent='stepContent'
      :subscription='subscription'
      :editMode="false"
      ref="contentContainer"
    )

  .confirm-container.mt-4
    Confirm-Edit(
      :show="stepEditMode",
      :saveCb="updateStepDescription",
      :cancelCb="cancelEditMode"
    )
</template>

<script>
import ConfirmEdit from "../../aspect/ConfirmEdit.vue";
import contentEditor from '../../../editor/components/reusable/contentEditor.vue';

import { mapGetters } from "vuex";

export default {
  components: { ConfirmEdit, contentEditor },
  props: {
    stepContent: { type: Object, default: {} },
    index: { type: Number, default: 0 }
  },
  data() {
    return {
      stepContentNew: false,
      validator: "",
      stepEditMode: false,
      showUpdatedFeedback: false
    };
  },
  async mounted() {
    this.stepContentNew = this.stepContent;

    this.$on('create:step:set:content', content => {
      this.stepContentNew = content;
       if (JSON.stringify(this.stepContentNew) != JSON.stringify(this.stepContent)) {
        this.stepEditMode = true;
      } else {
        this.stepEditMode = false;
      }
    });
  },
  watch: {
    activeStep() {
      this.$refs.contentContainer.contentEditor.setContents(this.stepContent);
    }
  },
  methods: {
    updateStepDescription() {
      if (this.$refs.contentContainer.isValid()) {
        this.$ebus.$emit("tour/updateStepDescription", {
          tutorial: this.tutorial,
          index: this.index,
          content: this.stepContentNew
        });
        this.stepEditMode = false;
      }
    },
    cancelEditMode() {
      this.$refs.contentContainer.validator.reset();
      this.$refs.contentContainer.contentEditor.setContents(this.stepContent);
      this.stepContentNew = this.stepContent;
      this.stepEditMode = false;
    },
    handleSubmitViaEnter() {
      if (this.stepEditMode === true) {
        this.updateStepDescription();
      }
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      activeStep: "tutorial/getActiveStep",
      subscription: "company/getTeamPlan"
    })
  }
};
</script>

<style lang="stylus" scoped>
@require '../../../utils/common/styleguide/colors.styl';
@require '../../../utils/common/styleguide/functions.styl';

.hidden-controls
  resize none
</style>
