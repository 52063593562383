<template lang="pug">
  .manager-container
</template>

<script>
import { handleError } from "../../../mixins/handleError";
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  data() {
    return {
      tutorial: {}
    }
  },
  mounted() {
    this.$ebus.$on("tour/updateTheme", update => {
      this.tutorial = update.tutorial;
      this.updateTourTheme(update.theme);
      this.tutorial.theme = update.theme;
      this.$store.commit('tutorial/setSelectedTutorial', this.tutorial)
    });
  },
  methods: {
    async updateTourTheme(newTheme) {
      const query = `${this.$config.default.apiUrl}/tutorial/${
        this.tutorial._id
      }`;
      try {
        await this.$http.put(query, { theme: newTheme });
        this.$toast("Tour theme updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour theme. Please retry", {
          type: TYPE.ERROR
        });
      }
    }
  }
};
</script>
