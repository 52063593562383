<template lang="pug">
.manager-container
  Modal(
    v-if="showDeleteStep",
    primaryOFF="Are you sure you want to DELETE this step?",
    confirmTextOFF="Delete",
    cancelText="Cancel"
    :confirmCb="deleteTourStep",
    :cancelCb="() => { this.showDeleteStep = false; }",
  )

  Modal(
    v-if="showDeleteStepFinal",
    emoji="🚨",
    primaryOFF="This is the only step in this tour. Deleting this step will delete the tour. Are you sure you want to delete?",
    confirmTextOFF="DELETE TOUR",
    cancelText="Cancel"
    :confirmCb="deleteTour",
    :cancelCb="() => { this.showDeleteStepFinal = false; }",
  )
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      stepIndexToDelete: 0,
      showDeleteStep: false,
      showDeleteStepFinal: false,
    }
  },
  mounted() {
    this.$ebus.$on("tour/deleteStep", data => {
      this.tutorial = data.tutorial
      this.deleteTourStepPre(data.stepIndex);
    });
  },
  methods: {
    deleteTourStepPre(index) {
      if (this.tutorial.steps.length === 1) {
        this.showDeleteStepFinal = true;
      } else {
        this.stepIndexToDelete = index;
        this.showDeleteStep = true;
      }
    },
    async deleteTourStep() {
      
      let newSteps = this.tutorial.steps;
      newSteps.splice(this.stepIndexToDelete, 1);

      // if deleting the last step, make previous step as active 
      if (this.tutorial.steps.length === this.stepIndexToDelete) {
        this.$store.commit("tutorial/setActiveStep", this.stepIndexToDelete - 1)
      }

      try {
        const query = `${this.$config.default.apiUrl}/tutorial/${
          this.tutorial._id
        }`;
        await this.$http.put(query, { steps: newSteps });
        this.showDeleteStep = false;
        this.$toast("Step deleted", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast(
          "Error encountered while trying to delete the step. Please retry",
          { type: TYPE.ERROR }
        );
      }
    },
    deleteTour() {
      this.$ebus.$emit("tour/delete", {
        tutorial: this.tutorial,
        confirm: false
      });
      this.showDeleteStepFinal = false;
    }
  },

};
</script>
