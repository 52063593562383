import Analytics from './common/instrumentation/analytics';

function listenGlobalEvents (probe, ebus) {
  ebus.$meddle((event, ...args) => {
    probe.event('Activity', event);
  });
}

export function setupInstrumentation (ebus) {
  const probe = Analytics.getGalleryProbe();
  listenGlobalEvents(probe, ebus);
  return probe;
}


