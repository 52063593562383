<template lang='pug'>
  .container
    .row.loaded-container
      .col-12
        Team-Name-Manager
      .col-12
        Team-Members-Manager
</template>

<script>
import TeamNameManager from '../components/team/TeamNameManager.vue';
import TeamMembersManager from '../components/team/TeamMembersManager.vue';
import Spinner from '../components/aspect/Spinner.vue';

export default {
  name: 'TeamManagement',
  components: { TeamNameManager, TeamMembersManager, Spinner },
}
</script>