<template lang="pug">
  a.cta.text-decoration-none.text-white(
    :href="extensionURL"
    target='_blank'
    rel='noreferrer noopener'
    title="Go to store"
    data-testid="navBarGetExtBtn"
  )
    span Get the extension
</template>

<script>
export default {
  data() {
    return {
      extensionURL: this.$config.default.extUrl,
    }
  }
};
</script>
