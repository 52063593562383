/**
 * @author Silviu Iordache
 * DOCUMENTATION
 * This vuex module is used to manage logic for a single selected tutorial.
 * This is ONLY for tutorial operations under the TourDetails.vue component
 * DO NOT use this for tutorials operated from the ProductTours.vue view or from the TourRow.vue components
 */
import handleError from "../../commonjs/handleError.js";

export default {
  namespaced: true,
  state: () => ({
    selectedTutorial: {},
    activeStep: 0,
    domain: {},
    loading: false,
  }),
  getters: {
    getSelectedTutorial(state) {
      return state.selectedTutorial
    },
    getTutorialID(state) {
      return state.selectedTutorial._id
    },
    getTutorialName(state) {
      return state.selectedTutorial.name
    },
    getTutorialDescription(state) {
      return state.selectedTutorial.description
    },
    getDomain(state) {
      return state.domain
    },
    getDomainName(state) {
      return state.selectedTutorial.domain
    },
    statStarted(state) {
      return (
        state.selectedTutorial.stats &&
        state.selectedTutorial.stats.started
      )
    },
    statCompleted(state) {
      return (
        state.selectedTutorial.stats &&
        state.selectedTutorial.stats.completed
      )
    },
    statStopped(state) {
      return (
        state.selectedTutorial.stats &&
        state.selectedTutorial.stats.stopped
      )
    },
    statErrors(state) {
      return (
        state.selectedTutorial.stats &&
        state.selectedTutorial.stats.errors
      )
    },
    getTheme(state) {
      return state.selectedTutorial.theme;
    },
    getLanguage(state) {
      return state.selectedTutorial.language;
    },
    getSteps(state) {
      return state.selectedTutorial.steps;
    },
    getActiveStep(state) {
      return state.activeStep
    },
    getLoading(state) {
      return state.loading
    },
    domainHasScript(state) {
      return state.domain.hasScript
    }
  },
  mutations: {
    setTutorialLoading(state, loading) {
      state.loading = loading;
    },
    setSelectedTutorial(state, tutorial) {
      state.selectedTutorial = tutorial;
    },
    setDomain(state, domain) {
      state.domain = domain;
    },
    setActiveStep(state, index) {
      state.activeStep = index
    },
    resetSelectedTutorial(state) {
      state.selectedTutorial = {};
    }
  },
  actions: {
    async getTutorialFromDB({ commit, state }, id) {
      commit('setTutorialLoading', true);
      const url = `${this._vm.$config.default.apiUrl}/tutorial/${id}`;
      try {
        const response = await this._vm.$http.get(url);
        commit('setSelectedTutorial', response.data.tutorial);
        commit('setDomain', response.data.domain);
      } catch (err) {
        commit('setSelectedTutorial', null);
        handleError(err);
      } finally {
        commit('setTutorialLoading', false);
      }
    },
  },
}
