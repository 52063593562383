<template lang="pug">
  .brt-card
    .top-container.mb-4
      img.mr-2(src="/assets/misc/greenCheck.svg" alt="green checkmark" width="16")
      .small-copy.brt-tooltip.brt-tooltip-dashed.brt-muted-dark.font-weight-bold SCRIPT ALSO DETECTED ON THESE DOMAINS
        .brt-tooltiptext.brt-tooltip-top
          p These are domains where the script has been detected, but no tour has been built yet
    .domains-container
      .d-inline-block.small-copy.mr-5.brt-muted-dark.py-2(
        v-for="domain in domains").
        {{ domain.url }}
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      domains: 'company/getDomainsWithoutTours'
    })
  }
};
</script>