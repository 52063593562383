<template lang='pug'>
  .container
    .tours-loaded
      .row(v-if="showLauncherOnboarding")
        .col-12
          Launch-Widget-Onboarding
      .launcher-options-container(v-else)
        .row
          .col-12
            Launch-Widget-Domain-Selector
        .row.mt-4(v-if="!showLauncherOptions")
          .col-12
            Launch-Widget-Options-Unavailable
        .bottom-container(v-else)
          Launch-Widget-Options
</template>

<script>
import LaunchWidgetDomainSelector from '../components/launcher/LaunchWidgetDomainSelector.vue'
import LaunchWidgetOptions from '../components/launcher/LaunchWidgetOptions.vue'

import LaunchWidgetOptionsUnavailable from '../components/launcher/LaunchWidgetOptionsUnavailable.vue';
import LaunchWidgetOnboarding from '../components/launcher/LaunchWidgetOnboarding.vue';
import Spinner from '../components/aspect/Spinner.vue';
import { mapGetters } from "vuex";


export default {
  name: 'LauncherManagement',
  components: {
    LaunchWidgetDomainSelector,
    LaunchWidgetOptions,
    LaunchWidgetOptionsUnavailable,
    LaunchWidgetOnboarding,
    Spinner
  },
  computed: {
    showLauncherOptions() {
      return (
        this.domainTourCount > 0 &&
        this.domainHasScript
      )
    },
    showLauncherOnboarding() {
      const cond1 = this.domainsWithScript.length === 0;
      const cond2 = (
        this.domainTourCount === undefined || 
        this.domainTourCount === 0
      )
      if (cond1 && cond2) {
        return true
      }
    },
    ...mapGetters({
      domainHasScript: 'company/getSelectedDomainHasScript',
      domainTourCount: 'company/getSelectedDomainTourCount',
      domainsWithScript: 'company/getDomainsWithScript'
    })
  }
}
</script>