<template lang="pug">
  #copy-link.d-inline-block.text-center(
    @click.stop="copyLink()"
    :class="{'brt-copied': justCopied}")
    .position-relative
      copy-link-button#copy(width="1.2rem" height="1.2rem" aria-label='copyIcon')
      icon-check#copied(v-if='justCopied' width="0.7rem" height="0.7rem" aria-label='copyIcon')
    span.ext-body {{ copyLinkText }}
</template>

<script>
import copyLinkButton from '../../images/iconLinkExt.vue';
import iconCheck from '../../images/iconCheck.vue';
import clipboard from '../../../utils/common/utils/clipboard.js'

export default {
  components: {
    'copy-link-button': copyLinkButton,
    'icon-check': iconCheck
  },
  props: ['url'],
  data() {
    return {
      copyLinkText: 'Copy URL',
      justCopied: false
    };
  },
  methods: {
    copyLink() {
      this.copyLinkText = 'Copied';
      clipboard.copyFromValue(this.url);
      this.justCopied = true;

      setTimeout(() => { 
        this.copyLinkText = 'Copy Link';
        this.justCopied = false;
      }, 2500);
    }
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  #copy-link
    cursor pointer
    color $brt-primary-dark
    width 5rem
    #copied
      position absolute
      bottom pxToRem(-3)
      right pxToRem(18)
    #copy, #copied
      stroke $brt-primary-dark
    &:hover
      color $brt-primary-bright
      #copy, #copied
        stroke $brt-primary-bright
    &.brt-copied
      cursor default
</style>
