<template lang='pug'>
  .duplicate-tour-popup
    .dark-background
    .duplicate-tour-popup-container.text-center.brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' @click='closeModal()' title='Close this pop-up' data-testid='close-button')
        span(aria-hidden='true') &times;
      .inner-container.py-3

        .big-copy.mt-4.mb-3 🧭&#x2794;🧭
        .medium-copy.mb-5.font-semibold Duplicate Tour

        .body-copy.brt-muted-dark.text-left.pb-2.
          Initial Tour domain URL
        input.brt-input.disabled.mb-5(type='text' v-model='tourDomain' disabled)

        form.text-left(@submit.prevent='sendInvitation' @keyup.enter='sendInvitation')
          .body-copy.brt-muted-dark.pb-2.
            Provide the new domain URL for this tour
          .brt-validate-that
            input.brt-input(type='text' v-model='newDomain' placeholder='https://www.example.com' brt-validate='notEmpty')
          .mt-5.mb-3.text-center
            a.link.body-copy.mr-4(@click='closeModal()' title='Cancel Duplicate' data-testid='ok-button') Cancel
            button.cta(type='submit' title='Duplicate Tour' data-testid='ok-button') Duplicate Tour
</template>

<script>
import Validator from '../../utils/common/utils/validator.js';
import { scrollLock } from '../../mixins/scrollLock.js';

export default {
  mixins: [scrollLock],
  props: {
    confirmCb: Function,
    cancelCb: Function,
    tourDomain: String,
  },
  data() {
    return {
      newDomain: '',
      validator: ''
    }
  },
  async mounted() {
    this.validator =  new Validator(document.querySelector('.brt-validate-that'));
  },
  methods: {
    sendInvitation() {
      this.validator.removeErrors();
      if(this.validator.validateFields()){
        this.confirmCb(this.newDomain);
      }
    },
    closeModal() {
      this.cancelCb();
    }
  }
}
</script>

<style lang='stylus' scoped>
  @require '../../utils/common/styleguide/colors.styl'

  .duplicate-tour-popup-container
    width 36rem
    .inner-container
      padding-left 4rem
      padding-right 4rem
    .brt-validate-that
      height 5rem
    .brt-input
      background-color $brt-white
      color $brt-gray-dark
    .disabled
      background-color $brt-cyan-faded
      color $brt-gray-dark
      box-shadow none
</style>
