 <template lang="pug">
  #subscription-container.brt-card.d-flex.flex-column.justify-content-between
    .top-container.d-flex.justify-content-between.align-items-center
      p.brt-caption.mb-0 Subscription
      .d-flex.align-items-center
        p.brt-caption.mb-0 Active Tours
        Icon-Tooltip.ml-2(
          text="The number of tours that are set to Autorun or to be displayed in the Launch Widget"
          direction="left"
        )
    .bottom-container.d-flex.justify-content-between
      h2.mb-0
        .skeleton(v-if="loading")  ............
        div(v-else)
          span(v-if="planIsTrial") Trial 
          span {{ planName }}
          span.d-inline-block.small-copy.brt-muted.pl-2(v-if="showExpirationDate").
            Expires on {{ expirationDate }}
      h3.pt-2.mr-1
        .skeleton(v-if="loading") ......
        div(v-else) {{ activeTourCount }}

</template>

<script>
import IconTooltip from '../icons/IconTooltip.vue';
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  computed: {
    showExpirationDate() {
      return this.planTier !== 1  
    },
    ...mapGetters({
      expirationDate: "company/teamPlanExpirationDate",
      activeTourCount: "company/getActiveToursCount",
      loading: "company/getLoadingTeamDetails",
      planName: "company/getTeamPlanName",
      planTier: "company/getTeamPlanTier",
      planIsTrial: "company/planIsTrial",
    }),
  }
};
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/functions.styl'
  @require '../../utils/common/styleguide/colors.styl'
  
  #subscription-container
    background: linear-gradient(105deg, transparent 57%, $brt-muted-faded 25%)
</style>
