<template lang="pug">
  Modal(v-if="show"
    :enabled="domain.launcher.show"
    primaryON="Hide the Launch widget on the following domain?'"
    primaryOFF="Show the Launch widget on the following domain?'"
    confirmTextON="Hide"
    confirmTextOFF="Show"
    :highlight="domain.url"
    :confirmCb="toggleLauncherVisibility"
    :cancelCb="() => { this.show = false }"
  )
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';


export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.$ebus.$on("company/toggleLauncherVisibility", domain => {
      this.toggleLauncherVisibilityPre(domain);
    });
  },
  methods: {
    toggleLauncherVisibilityPre(domain) {
      this.domain = domain;
      this.show = true;
    },
    async toggleLauncherVisibility() {
      try {
        const queryUrl = `${this.$config.default.apiUrl}/company/${
          this.teamID
        }/launcher/show`;
        const body = {
          visibility: !this.domain.launcher.show,
          domain: this.domain.url
        };

        const result = await this.$http.put(queryUrl, body);
        if (result.status === 200) {
          await this.$store.dispatch("company/getSelectedTeamTours");
          await this.$store.dispatch("company/getSelectedTeamDetails");
          this.$toast("Launcher visibility toggled", { type: TYPE.SUCCESS });
        }
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating the launcher visibility. Please retry", {
          type: TYPE.ERROR
        });
      } finally {
        this.show = false;
      }
    }
  },
  computed: {
    ...mapGetters({
      teamID: "company/getTeamID"
    })
  }
};
</script>
