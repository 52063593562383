<template lang="pug">
  .container
    .dark-background
    .brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' title="Close Pop-up" @click="close" data-testid="close-button")
        span(aria-hidden='true') &times;
      .inner-container.py-3
        .pop-up-emoji.mt-4.mb-3 🎉
        .big-copy.mb-5.font-semibold(data-testid="welcome-message")
          p.mb-1 Welcome to team '{{ teamName }}',
          p {{ username }}

        .bottom-text-container.body-copy.brt-muted-dark
          .new-user-texts
            p.mb-2 Complete a few steps to get to full power.
            p Don’t worry, we’ll guide you all the way.

        button.cta.mt-5.pt-3.mb-3(@click="close" title="Close this pop-up" data-testid="begin-button")
          span Begin
</template>

<script>
import { scrollLock } from '../../../mixins/scrollLock.js';
import { auth } from "../../../utils/auth";
import { mapGetters } from 'vuex';

export default {
  mixins: [scrollLock],
  methods: {
    close() {
      this.$store.commit('onboarding/toggleModalTeamInvite', false)
      
      if (this.onboardStep === 0) {
        this.$store.dispatch('onboarding/updateOnboardStep', 1)
      }
    }
  },
  computed: {
    username() {
      return auth.username;
    },
    ...mapGetters({
      teamName: 'onboarding/getTeamInviteName',
      onboardStep: 'onboarding/getOnboardStep'
    })
  }
}
</script>
