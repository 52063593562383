<template lang="pug">
#actions-container.brt-card.mt-3
  span.brt-caption.
    ANALYTICS RESET ACTIONS
  .buttons-container.mt-4
    button.secondary-button(@click="resetAnalytics()").
      Reset tour analytics
    button.secondary-button.ml-4(@click="clearErrors()").
      Clear tour errors
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    resetAnalytics() {
      this.$ebus.$emit("tour/resetAnalytics", this.tutorial);
    },
    clearErrors() {
      this.$ebus.$emit("tour/clearErrors", this.tutorial);
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
    })
  }
};
</script>
