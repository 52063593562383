<template lang="pug">
  .step-container.d-flex(
    :class="{\
      'brt-card': highlightStep,\
      'hovered-card': hover\
    }"
    @click="changeActiveStep()"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  )
    .step-number-container.d-flex.align-items-center
      span.brt-pill.brt-white.brt-bg-secondary-dark.
        {{ index + 1 }}
    content-editor(
      :isEditable='false'
      :oldContent='content'
      :subscription="subscription"
      :editMode="false"
      ref='contentContainer')

</template>

<script>
import { mapGetters } from 'vuex';
import contentEditor from '../../../editor/components/reusable/contentEditor.vue';

export default {
  components: { 'content-editor': contentEditor },
  props: {
    content: { type: Object, default: {} },
    index: { type: Number, default: 0 },
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    changeActiveStep() {
      this.$store.commit("tutorial/setActiveStep", this.index)
    }
  },
  computed: {
    ...mapGetters({
      activeStep: 'tutorial/getActiveStep',
      subscription: 'company/getTeamPlan'
    }),
    highlightStep() {
      return this.activeStep === this.index;
    }
  }
};
</script>

<style lang='stylus' scoped>
@require '../../../utils/common/styleguide/colors.styl'
@require '../../../utils/common/styleguide/functions.styl'

.step-container
  cursor pointer
  border 1px solid transparent
  transition .2s ease-in-out
  padding 2rem
.hovered-card
  border 1px solid $brt-muted
  border-radius 0.71rem

</style>