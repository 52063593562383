import Vue from 'vue'
import Vuex from 'vuex'
import onboarding from './modules/onboarding';
import company from './modules/company';
import tutorial from './modules/tutorial'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    onboarding,
    company,
    tutorial
  }
})
