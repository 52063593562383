<template lang='pug'>
  .launch-widget-options
    .row.mt-4
      .col-12
        Domain-Card(:domain="domain")
    .row.mt-4
      .col-7
        Info-Card.h-100(title="WHAT IS THE LAUNCH WIDGET?")
          p.mt-4 The Launch Widget is a UI element that you can enable on your domain. Use the widget as a collection and a launch point of product tours relevant for your users. Find out more in the #[a.link(:href="$urlConfig.galleryUrl + '/docs/launch-widget/overview.html'" rel='noreferrer noopener') Docs section.]
      .col-5
        Launch-Widget-Description
    .row.mt-4
      .col-6
        Launch-Widget-Visibility
      .col-6
        Launch-Widget-Positioning
</template>

<script>
import DomainCard from '../domain/DomainCard.vue';
import InfoCard from "../aspect/InfoCard.vue";
import LaunchWidgetDescription from './LaunchWidgetDescription.vue';
import LaunchWidgetVisibility from './LaunchWidgetVisibility.vue';
import LaunchWidgetPositioning from './LaunchWidgetPositioning.vue';

import { tooltip } from '../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  components: {
    DomainCard,
    InfoCard,
    LaunchWidgetDescription,
    LaunchWidgetVisibility,
    LaunchWidgetPositioning,
  },
  data() {
    return {
      tooltip
    }
  },
  computed: {
    ...mapGetters({
      domain: 'company/getSelectedDomain'
    })
  }
}
</script>