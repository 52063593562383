<template lang="pug">
  #launch-widget-container
    .brt-card
      img.green-lock(v-if='!tourCanBecomeActive' src='/assets/plans/greenLock.svg')
      .small-copy.brt-muted-dark.mb-4
        .d-flex.align-items-center.justify-content-between.mb-3
          p.brt-caption.mb-0 LAUNCH WIDGET OPTIONS
          button.small-cta.mr-5(v-if="!tourCanBecomeActive" @click="upgradePlan()") Upgrade plan to unlock
        p This section allows to show this tour in the Launcher widget.
      #launcher-visibility-container.d-flex.justify-content-between.align-items-center
        .body-copy Visibility in Launcher widget
        .right-container.d-flex.align-items-center
          Toggle-Switch(
            v-model="tutorial.options.show"
            :titleEnabled="tooltip.tour.launcher.enabled"
            :titleDisabled="tooltip.tour.launcher.disabled"
            :disabled="!domain.launcher.show"
            @change="toggleLauncherVisibility()"
            data-testid="launcher-toggle")
          Icon-Tooltip(
            direction="left"
            :text="tooltip.tour.launcher.explained.primary"
            :secondary="tooltip.tour.launcher.explained.secondary"
            offsetY="1" offsetX="1.8")
    
    TourIgnoreSubdomain
</template>

<script>
import ToggleSwitch from '../../../utils/common/vuecomps/ToggleSwitch.vue';
import Modal from '../../aspect/Modal.vue';
import IconTooltip from '../../icons/IconTooltip.vue';
import TourIgnoreSubdomain from "./TourIgnoreSubdomain.vue";

import tourActiveCondition  from '../mixins/tourActiveCondition.js'
import { tooltip } from '../../../jsons/tooltips.json';
import { mapGetters } from "vuex";

export default {
  components: { ToggleSwitch, Modal, IconTooltip, TourIgnoreSubdomain },
  mixins: [tourActiveCondition],
  data() {
    return {
      tooltip
    }
  },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    },
    toggleLauncherVisibility() {
      this.$ebus.$emit("tour/toggleLauncherVisibility", this.tutorial);
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      domain: "tutorial/getDomain"
    })
  }
};
</script>
