<template lang="pug">
  .container
    .dark-background
    .brt-popup.text-center
      button.close.py-2.px-3(type='button' aria-label='Close' title="Close Pop-up" @click="closeCb" data-testid="close-button")
        span(aria-hidden='true') &times;

      .server-error-container(v-if="state == 'error'" data-testid="error-view")
        .maxin-texts.py-3
          .pop-up-emoji.my-4 😞
          .big-copy.mb-4 Server Error
          .body-copy.brt-muted-dark.mb-2 We are sorry, there seems to be a problem on our end.
          .body-copy.mb-4 Please try again later or contact us if the problem persists.
        button.small-cta.my-4(@click="close") Close

      .success-container(v-if="state == 'success'" data-testid="success-view")
        .main-texts.py-3
          .pop-up-emoji.my-4 🎉
          .big-copy.mb-4 Success
          .body-copy.brt-muted-dark.mb-2 You have created a team called
          .body-copy.mb-4 {{ teamName }}
        .tips-container.small-copy.brt-muted-dark
          p You can add new team members from the Team panel.
          p You can switch the team from the dropdown.
        button.small-cta.my-4(@click="closeCb") Close

      .creation-container(v-if="state == 'create'" data-testid="create-view")
        .text-container.py-3
          .pop-up-emoji.my-4 🏀
          .big-copy.mb-4.font-semibold Create a new team
          .body-copy.brt-muted-dark.mb-1 Creating a new team can help you better manage 
          .body-copy.brt-muted-dark your peers activity on Bytes Route.
        Spinner.my-5(v-if="loading" size='sm')
        .bottom-container(v-else)
          .input-container.mt-5.position-absolute.d-flex.justify-content-center
            .brt-input-group.text-left
              input.brt-input(required type='text' name='teamName' 
                v-model='teamName'
                brt-validate="notEmpty|alphaExtended|4-25"
                data-testid="teamNameInput")
              label.brt-label(for='teamName') Team Name
          .buttons-container
            button.secondary-button.py-2.px-4(@click="closeCb" data-testid="cancel-button") Cancel
            button.small-cta.px-4.ml-4(@click="createTeam" title="Create new team" data-testid="create-button") Create
</template>

<script>
import Spinner from '../aspect/Spinner.vue';
import { auth } from '../../utils/auth.js';
import Validator from '../../utils/common/utils/validator.js';
import { scrollLock } from '../../mixins/scrollLock.js';

export default {
  mixins: [scrollLock],
  components: { Spinner },
  props: {
    closeCb: Function
  },
  data() {
    return {
      teamName: '',
      loading: false,
      state: 'create',
      validator: '',
      inputContainer: '',
      myInputContainer: ''
    }
  },
  mounted() {
    this.setupValidator();
  },
  methods: {
    async createTeam() {
      if (this.validateTeamNameOnClient()) {
        this.makeCreateTeamRequest();
      }
    },
    async makeCreateTeamRequest() {
      this.loading = true;      

      let response;
      let data = {
        owner: auth.id,
        name: this.teamName
      }
      try {
        response = await this.$http.post(`${this.$config.default.apiUrl}/company/`, data);
        if (response.status === 201) {
          this.state = 'success';
          await this.$store.dispatch("company/getTeams");
        }
      } catch (err) {
        console.log(err);
        this.state = 'error';
      } finally {
        this.loading = false;
      }
    },
    validateTeamNameOnClient() {
      this.validator.removeErrors();
      let validateResult = this.validator.validateFields();
      return validateResult;
    },
    setupValidator() {
      this.myInputContainer = document.querySelector('.brt-input-group');
      this.validator =  new Validator(this.myInputContainer);
    }
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'

  .input-container
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: 48%;
    text-align: center;
    .brt-input-group
      width 26rem

  .buttons-container
    margin-top 8rem
    margin-bottom 3rem

</style>
