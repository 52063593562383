<template lang="pug">
  svg(
    xmlns='http://www.w3.org/2000/svg'
    :width="width"
    :height="height"
    :view-box.camel="`${this.vbx} ${this.vby} ${this.vbw_computed} ${this.vbh_computed}`"
    :aria-labelledby='iconName'
    role='presentation')
    g(:style="cssVars")
      slot
</template>

<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    color: {
      type: String,
      default: 'currentColor'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    vbx: {
      type: [Number, String],
      default: 0
    },
    vby: {
      type: [Number, String],
      default: 0
    },
    vbw: {
      type: [Number, String],
    },
    vbh: {
      type: [Number, String],
    },
  },
  computed: {
    cssVars() {
      return {
        'fill': this.color
      }
    },
    vbw_computed() {
      // set viewbox width to prop value, if present, otherwise asign the width prop as the value
      return this.vbw ? this.vbw : this.width;
    },
    vbh_computed() {
      // set viewbox height to prop value, if present, otherwise asign the height prop as the value
      return this.vbh ? this.vbh : this.height;
    }
  }
}
</script>
