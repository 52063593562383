import { mapGetters } from 'vuex';

export const checkForDomainsWithScript = {
  methods: {
    async detectScript() {
      await this.$store.dispatch("company/getSelectedTeamTours");
      if (this.domainsWithScript.length === 0) {
        this.$store.commit("onboarding/toggleModalScriptNotFound", true);
      }
      await this.$store.dispatch("onboarding/updateOnboardingOnDomainsWithScriptChanges");
    },
  },
  computed: {
    ...mapGetters({
      domainsWithScript: "company/getDomainsWithScript"
    })
  }
};