 
<template lang="pug">
  .manager-container
</template>

<script>
import { handleError } from '../../../mixins/handleError';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  mounted() {
    this.$ebus.$on("tutorial/updateName", update => {
      this.updateTourName(update.name, update.id);
    });
  },
  methods: {
    async updateTourName(name, id) {
      const query = `${this.$config.default.apiUrl}/tutorial/${id}`;
      try {
        await this.$http.put(query, { name });
        this.$toast("Tour name updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour name. Please retry", { type: TYPE.ERROR });
      }
    },
  }
}
</script>
