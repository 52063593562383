<template lang="pug">
.row.my-3
  .col-12.col-md-6
    Info-Card(title="ADDING NEW STEPS")
      p.mt-4 In order to add new steps to your product tours you need to use the #[strong Chrome Extension]. In the Chrome Extension, click on the three dots located at the right of the tour you need to edit. Click "Edit tour" and record the new steps as you require.

    .tour-steps-container.mt-3(v-for="(step, index) in steps")
      Tour-Step(
        :index="index",
        :content="step.content",
      )
  .col-12.col-md-6
    Step-Modal-Options(
      v-if="tutorial.steps[activeStep].stepType === 'modal'"
      :step="tutorial.steps[activeStep]"
      :index="activeStep"
      :tutorial="tutorial"
    )
    Step-Options(
      v-else
      :step="tutorial.steps[activeStep]"
      :index="activeStep"
      :tutorial="tutorial"
    )
</template>

<script>
import TourStep from "../components/tour/editSteps/TourStep.vue";
import StepOptions from "../components/tour/editSteps/StepOptions.vue";
import StepModalOptions from "../components/tour/editSteps/StepModalOptions.vue";
import InfoCard from "../components/aspect/InfoCard.vue";
import { mapGetters } from 'vuex';

export default {
  components: { TourStep, StepOptions, StepModalOptions, InfoCard },
  mounted() {
    this.$ebus.$on("Tutorial:updated", newStep => {
      this.steps[this.activeStep].content = newStep.content;
    });
  },
  computed: {
    ...mapGetters({
      tutorial: 'tutorial/getSelectedTutorial',
      steps: 'tutorial/getSteps',
      activeStep: 'tutorial/getActiveStep',
    }),
  }
};
</script>