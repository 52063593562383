<template lang="pug">
  .manager-container
</template>

<script>
import { TYPE } from "vue-toastification";

export default {
  async mounted() {
    this.$ebus.$on("company/updateName", data => {
      this.updateCompanyName(data.teamID, data.name);
    });
  },
  methods: {
    async updateCompanyName(teamID, name) {
      const url = `${this.$config.default.apiUrl}/company/${teamID}/updateName`;
      try {
        const res = await this.$http.put(url, { newName: name });
        if (res.status === 200) {
          this.$toast("Team name updated", { type: TYPE.SUCCESS });
          await this.$store.dispatch("company/getTeams");
        }
      } catch (err) {
        console.error(err);
        this.$toast("Error updating the team name. Please retry", {
          type: TYPE.ERROR
        });
      } finally {
        await this.$store.dispatch("company/getSelectedTeamDetails");
      }
    }
  }
};
</script>
