<template lang="pug">
  .container
    .dark-background
    .brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' title="Close Pop-up" @click="close" data-testid="close-button")
        span(aria-hidden='true') &times;
      .inner-container.py-4.px-5
        h4.mb-5 {{ title }}
        iframe.mb-5(
          width='610'
          height='365'
          :src='link'
          title='YouTube video player'
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
          allowfullscreen
        )
        p.small-copy.m-0.text-left Copy the following line in your application.
        Copy-Script.neg-margin(data-testid="copy-script")
</template>

<script>
import CopyScript from '../common/CopyScript.vue';
import { scrollLock } from '../../../mixins/scrollLock.js';
import { auth } from "../../../utils/auth";

import { mapGetters } from 'vuex';

export default {
  props: {
    title: { type: String, required: true },
    link: { type: String, required: true },
  },
  components: {CopyScript},
  mixins: [scrollLock],
  methods: {
    close() {
      this.$parent.$emit('closeYoutubeModal');
    }
  },
  computed: {
    username() {
      return auth.username;
    },
    ...mapGetters({
      extensionDetected: 'onboarding/getExtensionDetected'
    })
  }
}
</script>

<style lang="stylus" scoped>
  .neg-margin
    margin 0 -10px 0 -20px
</style>