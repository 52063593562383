<template lang='pug'>
  .domain-selector
    p Choose a domain to operate on
    .d-flex.align-items-center
      select.brt-select(
        @change="updateSelectedDomain()"
        v-model='currentDomain')
        option(v-for='(domain, index) in domains'
          :value='domain'
          :key='domain.url').
          {{ domain.url }}
      Icon-Tooltip.ml-3(
        :text="tooltip.launchWidget.domainsList"
        offsetY='-0.5')
</template>

<script>
import IconTooltip from '../../components/icons/IconTooltip.vue';
import { tooltip } from '../../jsons/tooltips.json';
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  data() {
    return {
      currentDomain: {},
      tooltip
    }
  },
  mounted() {
    this.currentDomain = this.selectedDomain;
  },
  watch: {
    selectedDomain() {
      this.currentDomain = this.selectedDomain;
    },
  },
  computed: {
    ...mapGetters({
      domains: 'company/getAllDomainsTours',
      selectedDomain: 'company/getSelectedDomain'
    })
  },
  methods: {
    updateSelectedDomain() {
      this.$store.commit('company/updateSelectedDomain', this.currentDomain);
    }
  }
}
</script>

<style lang="stylus" scoped>
  .brt-select
    width 25rem
</style>