<template lang="pug">
.container-fluid.h-100
  .row.h-100
    // LEFT CONTAINER - HIDE ON SMALL DISPLAYS
    #bubble-svg-container.col-12.col-xl-6.p-0.order-2.order-xl-1.d-none.d-xl-block
      .container.custom-padding

        Home-Button

        .list
          h1.h3.brt-primary-dark Create no-code Product Tours for your Web Applications
          h2.h4.brt-primary-dark BytesRoute helps you elevate your product strategy with
            ul.brt-ul.checkmark-list.mt-4
              li.brt-li.body-copy unlimited web domains to connect and use
              li.brt-li.body-copy unlimited guided tours for a given domain
              li.brt-li.body-copy unlimited steps defined per tour
              li.brt-li.body-copy unlimited assists/tour sessions
              li.brt-li.body-copy unlimited active users for your published tours
        
        .testimonial
          p.testimonial-text.body-copy.line-height-lg.pb-4.
            A simple and powerful solution for your onboarding needs. I got our first tour created in minutes, using the Bytes Route Chrome Extension.
          .author.d-flex
            img.author-thumb(width='50' height='50' alt='Author Photo' src='/assets/authors/walkmetestimonial.png')
            .author-details.pl-3
              h5.brt-primary-dark Phillip Petrescu
              p.small-copy.text-uppercase.brt-dark.pt-2.mb-0 FOUNDER / ADVANCED WEB RANKING

    // RIGHT CONTAINER
    .col-12.col-xl-6.p-0.order-1.order-xl-2.brt-bg-secondary-faded
      #register-section.container.custom-padding

        .d-block.d-xl-none.text-center.pb-5
          Home-Button

        #google-button-wrap.d-flex.justify-content-center.positon-relative
          #google-signin-button(v-show='!$route.query.from')
          .small-copy.text-center.brt-danger-dark.mb-2.auth-error.position-absolute(
            v-model='serverValidationError'
            :class="{'hide': !serverValidationFailed}"
            data-testid="errMessage").
              {{ serverValidationError }}

        .separator(v-show='!$route.query.from')

        form#brt-register-form.brt-card
          h1.h4.mb-2.brt-primary-dark Register with work email
          .small-copy.mb-4
            span All fields are required
          .brt-input-group.mb-5
            input#name.brt-input(
              autocomplete="name"
              name='name'
              type='text'
              v-model='username'
              brt-validate="notEmpty|alpha|4-20"
              required
              data-testid="userInput")
            label.brt-label(for='name') Name
          .brt-input-group.mb-5
            input#email.brt-input(
              autocomplete='username'
              name='email'
              type='text'
              @focus="emailErr='err'"
              :class="{'brt-error-border': emailErr!=='err'}"
              v-model='email'
              required
              brt-validate="notEmpty|isEmail"
              data-testid="emailInput")
            label.brt-label(for='email') Work email address
            small.brt-input-error(:class="{'hide': emailErr==='err'}") {{ emailErr }}
          .brt-input-group.mb-5
            input#password.brt-input(
              autocomplete="new-password"
              name="password"
              type='password'
              v-model='password'
              brt-validate="notEmpty|min=5"
              required
              data-testid="passInput")
            label.brt-label(for='password') Password

          button.cta(type='submit', @click.prevent='register' data-testid="registerBtn")
            div(v-if='registerRequestInProgress')
              .spinner-border(role='status')
              span.sr-only Loading...
            div(v-else).
              Register
          p.small-copy.pt-3
            | This site is protected by reCAPTCHA and the Google #[a.link(href='https://policies.google.com/privacy' target='_blank' ) Privacy Policy] and #[a.link(href='https://policies.google.com/terms') Terms of Service] apply.


          .body-copy.mt-5.mb-2
            | Been here before? You can
            router-link.link.ml-1(:to='{ path: "/login"}' data-testid="toLoginBtn").
              log in
          .body-copy
            router-link.link(:to='{ path: "/passResetReq"}' data-testid="toPassResetReq").
              Forgot password?

        p#terms-and-conditions.body-copy.brt-muted-dark By registering to Bytes Route, you agree to our #[a.link(:href="$urlConfig.galleryUrl + '/privacy-policy.pdf'") Privacy Policy] and #[a.link(:href="$urlConfig.galleryUrl + '/eula.pdf'") Terms and Conditions].
</template>


<script>
import { emailValidator } from '../../mixins/emailValidator.js';
import { recaptcha } from '../../mixins/recaptcha.js';
import { googleIdentity } from '../../mixins/googleIdentity.js';
import Validator from '../../utils/common/utils/validator.js'
import HomeButton from '../../components/header/HomeButton.vue';

export default {
  metaInfo: {
    title: 'Register for a Bytes Route account',
    meta: [{ name: 'description', content: 'Create a Bytes Route account and start building a better customer onboarding directly in your web application.' } ]
  },
  components: { HomeButton },
  mixins: [emailValidator, recaptcha, googleIdentity],
  data: function () {
    return {
      username: '',
      email: '',
      emailErr: 'err',
      password: '',
      registerRequestInProgress: false,
      serverValidationError: '',
      serverValidationFailed: false

    };
  },
  mounted() {
    this.$ebus.$on('login:google:failed', (errorMessage) => {
      this.serverValidationError =  errorMessage;
      this.serverValidationFailed = true;
    });
    this.setupValidator();

    // this method below will initialize google identity service 
    // check googleIdentity mixin
    this.addGoogleIdentityScript();
    
    // check if url it's from team invitation email
    const { from, teamId, email } = this.$route.query;
    if (from && teamId && email) {
      // autocomplete email field with the predefined value
      this.email = email;
      this.from = from;
      this.teamId = teamId;
    }
  },
  methods: {
    setupValidator() {
      const registrationContainer = document.querySelector('#brt-register-form');
      this.validator = new Validator(registrationContainer);
    },
    async register() {
      if (this.registerCredentialsValidatOnClient()) {
        this.runRecaptchaTest(() => {
          // if the link is accessed from a team invitation that
          // means we must update/activate an existing account
          if (this.from === 'invitation') {
            return this.activateAccount();
          }
          this.sendRegisterRequest();
        });
      }
    },
    async activateAccount() {
      this.registerRequestInProgress = true;
      try {
        const url = `${this.$config.default.apiUrl}/user/${this.email}/activate`;
        const response = await this.$http.put(url, {
          password: this.password,
          name: this.username
        });
        if (response.status === 200) {
          // accept team invitation
          const token = response.data.token;
          const url = `${this.$config.default.apiUrl}/user/${this.email}/confirm/${this.teamId}`;
          await this.$http.put(url);

          // add team invite to localstorage if any is found
          if (this.teamId && this.email) {
            localStorage.setItem('brt-team-invite', JSON.stringify({ 
              teamId: this.teamId,
              email: this.email })
            );
          }
          this.$router.push({ name: 'registerSuccess', query: { token }});
          this.registerRequestInProgress = false;
        }
      } catch (error) {
          this.emailErr = error.response.data.errMessage;
      } finally {
        this.registerRequestInProgress = false;
      }
    },
    registerCredentialsValidatOnClient() {
      this.validator.reset();
      this.emailErr = 'err';
      let validateResult = this.validator.validateFields();
      return validateResult;
    },
    async sendRegisterRequest() {
      this.registerRequestInProgress = true;
      this.serverValidationFailed = true;
      let url = `${this.$config.default.apiUrl}/user/register`;
      if (this.$route.query.subscription) {
        url = url.concat(`?subscription=${this.$route.query.subscription}`);
      }
      try {
        const res = await this.$http.post(url, {
          username: this.username.trim(),
          email: this.email,
          password: this.password,
          token: this.recaptcha.token
        });
        if (res.status === 201) {
          this.$router.push({ name: 'accountConfirmation', params: { email: this.email }});
        }
      } catch (err) {
          // duplicate email found
          if (err.response.status === 403) {
            this.emailErr = err.response.data.errMessage;
          }
      } finally {
        this.registerRequestInProgress = false;
      }
    },
  }
};
</script>

<style lang="stylus" scoped>

@require '../../utils/common/styleguide/functions.styl'
@require '../../utils/common/styleguide/colors.styl';


@media only screen and (min-width: 550px) 
  .custom-padding
    padding pxToRem(100) pxToRem(100) pxToRem(40) pxToRem(100)

.list
  padding-top pxToRem(100)
  padding-bottom pxToRem(100)

.auth-error
  margin-top 40px !important

.brt-card
  margin pxToRem(45) pxToRem(0) pxToRem(20) pxToRem(0)

#register-section
  max-width pxToRem(700)

.testimonial
  position relative
  padding pxToRem(10) pxToRem(10) pxToRem(10)
  z-index 2
    .testimonial-text
      position relative
      z-index 2
  &:before
    content url('/assets/small-icons/iconQuote.svg')
    position absolute
    z-index 1
    top pxToRem(-15)
    left pxToRem(-30)
    opacity .2
  .author
    .author-thumb
      border-radius 50%

#bubble-svg-container
    background-image url('/assets/small-icons/generic-pink-blob.svg')
    background-repeat no-repeat
    background-position pxToRem(-350) pxToRem(-330)

#google-button-wrap
  min-height 80px
  

.separator
  position relative
  border-bottom .1px solid $brt-gray
  width 100%
  padding-top pxToRem(20)
  padding-bottom pxToRem(20)
  margin-top -40px
  &:before
    position absolute
    content 'or'
    text-transform uppercase
    letter-spacing 2px
    left 40%
    color $brt-black
    top 28px
    width: 20%
    text-align center
    background-color $brt-cyan-faded
  

</style>
