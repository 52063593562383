<template lang="pug">
  ul#step-list.list-group.overflow-auto.p-4(:class="{'creating': creating}")
    li.list-group-item.brt-bg-white.py-1
    li.list-group-item.p-0(
      v-for="(step, index) in steps"
        :ref="index"
        :key="step.randomId || step._id"
        :class="{\
          'brt-step-current': index === currentStep,\
          'brt-step-neutral': (!creating && (index < currentStep || index > currentStep))}"
    )
      step(:step="step" :index="index" :creating="creating" :stepsLength="steps.length" :stepError="stepError")
</template>

<script>
  import step from '../reusable/step.vue';
  export default {
    components: { 'step': step },
    props: ['steps', 'currentStep', 'creating', 'stepError'],
    mounted() {
      this.scrollToCurrentStep();
      this.$on('tutorial:deleteStep', index => {
        this.$parent.$emit('tutorial:deleteStep', index);
      });
      this.$on('tutorial:editStep', details => {
        this.$parent.$emit('tutorial:editStep', details);
      });
    },
    methods: {
      scrollToCurrentStep() {
        if (this.steps.length > 0) {
          let targetRef;
          const refsObj = this.$refs;
          // if we are on steps 0, 1 or 2, focus step 0
          if (this.currentStep <= 2) {
            targetRef = 0;
          // if we are on the last step, focus the end message
          } else if (this.currentStep === this.steps.length - 1) {
            targetRef = 'end-message';
          // else just focus 1 step behind
          } else {
            targetRef = this.currentStep - 1;
          }
          const ref = this.$refs[targetRef];
          const elementToFocusOn = ref[0] ? ref[0] : ref;
          elementToFocusOn.scrollIntoView();
        }
      },
    },
  };
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl'
  #step-list
    &.creating
      max-height calc(100% - 350px)
    max-height 90%
    overflow auto
    .list-group-item
      height auto
      border 0
      color $brt-muted-dark
    .brt-step-prev
    .brt-step-neutral
      .step
        background-color $brt-gray-faded
        border-radius 5px
        .brt-pill
          background-color $brt-gray-bright
    .brt-step-neutral
      .step
        background-color $brt-gray-faded
        border-radius 5px
    .brt-step-current
      .step
        background-color $brt-secondary-faded
        border-radius 5px
        .brt-pill
          background-color $brt-cyan

</style>
