<!-- 🚨🚨🚨🚨🚨🚨 VERY IMPORTANT 🚨🚨🚨🚨🚨🚨 -->
<!-- READ THIS BEFORE DECIDING TO EXTEND THIS FILE -->
<!-- PLEASE KEEP THIS COMPONENT ONLY FOR MODALS WITH A QUESTION AND A CONFIRM AND CANCEL BUTTONS -->
<!-- DO NOT USE THIS COMPONENT FOR MODALS WITH INPUTS, MAKE A CUSTOM SEPARATE COMPONENT FOR THOSE -->
<!-- CHECK MODALS FOR INVITE TEAM MEMBER, CREATE TEAM OR RESET PASSWORDS FOR EXAMPLES -->
<!-- KEEP THIS COMPONENT SIMPLE PLEASE, @GEANI, I AM LOOKING AT YOU -->
<template lang="pug">
  .container
    .dark-background
    .brt-popup.brt-dark.py-2.px-4
      button.close.brt-close(type='button' aria-label='Close' title="Close Pop-up" @click="cancelCb()" data-testid="close-button")
        span(aria-hidden='true') &times;
      slot
        .inner-container.py-4
          .pop-up-emoji.my-3(v-if="emoji").
            {{ emoji }}
          #title-text.big-copy.mb-4.font-semibold(v-if="titleText").
            {{ titleText }}
          #primary-text.body-copy.px-5.my-4(v-if="primaryON || primaryOFF")
            span(v-if="enabled") {{ primaryON }}
            span(v-else) {{ primaryOFF }}
          #secondary-text.body-copy.brt-muted-dark.my-4(v-if="secondary").
            {{ secondary }}
          #highlight-text.body-copy.brt-primary.mx-1.mt-4.mb-3(v-if="highlight").
            '{{ highlight }}'

      .buttons-container.pb-4
        .confirm-buttons(v-if="confirmCb")
          button.secondary-button(@click="cancelCb()")
            span(v-if="cancelText") {{ cancelText}}
            span(v-else) Cancel
          button.small-cta.ml-4.px-4(@click="confirmCb()")
            span(v-if="confirmTextON || confirmTextOFF")
              span(v-if="enabled") {{ confirmTextON }}
              span(v-else) {{ confirmTextOFF }}
        .information-buttons(v-else)
          button.small-cta.px-4(@click="cancelCb()")
            span(v-if="cancelText") {{ cancelText }}
            span(v-else) OK
</template>

<script>
import { scrollLock } from '../../mixins/scrollLock.js';
export default {
  mixins: [scrollLock],
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    primaryON: {
      type: String,
    },
    primaryOFF: {
      type: String,
    },
    titleText: String,
    secondary: String,
    highlight: String,
    confirmCb:  Function,
    cancelCb: Function,
    confirmTextON: String,
    confirmTextOFF: String,
    cancelText: String,
    emoji: String
  },
}
</script>
