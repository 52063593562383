<template lang='pug'>
  .brt-card
    .brt-caption.mb-4 TEAM NAME
    form(@submit.prevent="")
      .team-name-container
        input.brt-input(
          v-model='companyName'
          @input="enterEditMode"
          brt-validate='notEmpty|alphaExtended|4-25'
          :disabled='inputIsDisabled')
      Confirm-Edit.mt-4(
        :show="editMode"
        :saveCb="updateCompanyName"
        :cancelCb="cancelEditMode"
        :loading="loading"
      )
</template>

<script>
import ConfirmEdit from '../aspect/ConfirmEdit.vue';
import { auth } from '../../utils/auth';
import Validator from '../../utils/common/utils/validator.js';
import { mapGetters } from 'vuex';

export default {
  components: { ConfirmEdit },
  data() {
    return {
      editMode: false,
      validator: '',
      companyName: ''
    };
  },
  async mounted() {
    this.companyName = this.teamName;
    this.validator =  new Validator(document.querySelector('.team-name-container'));
  },
  watch: {
    teamName() {
      this.companyName = this.teamName
    }
  },
  methods: {
    async updateCompanyName() {
      this.validator.removeErrors();
      if(this.validator.validateFields()){
        this.$ebus.$emit('company/updateName', {
          teamID: this.teamID,
          name: this.companyName
        })
        this.editMode = false;
      }
    },
    enterEditMode() {
      this.editMode = true;
    },
    cancelEditMode() {
      this.companyName = this.teamName;
      this.editMode = false;
      this.validator.reset();
    },
  },
  computed: {
    isOwner() {
      return auth.id === this.teamOwner;
    },
    inputIsDisabled() {
      const userIsOwner = auth.id === this.teamOwner;
      return !userIsOwner || this.loading;
    },
    ...mapGetters({
      teamName: 'company/getTeamName',
      teamID: 'company/getTeamID',
      teamOwner: 'company/getTeamOwnerID',
      loading: 'company/getLoadingTeamDetails'
    })
  }
}
</script>

<style lang='stylus' scoped>
  .team-name-container
    width 15rem
</style>
