/**
 * The base class that acts as a template for analytics clients.
 */
export class BaseClient {
  /**
   * Constructor
   * @param {string} id
   */
  constructor (id) {
    this.id = id;
  }

  /**
   * Custom event sent to google with the specified params.
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {string} value
   */
  event (category, action, label, value) {
    throw new SyntaxError('event not implemented');
  }

  /**
   * Send a pageview event.
   * @param {string} title
   * @param {string} path
   */
  pageview(title, path) {
    throw new SyntaxError('pageview not implemented');
  }
}
