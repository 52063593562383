<template lang="pug">
  section#onidle
    team-selector.px-2.py-3(:addNewTeamButton='false' :onchange='onTeamChange')
    #tutorial-container(v-show='!loading' data-testid="tuts-container")
      .tutorials-present.h-100(v-if="tutorials.length > 0")
        ul.list-group.overflow-auto.w-100.h-100
          TutorialCard(v-for="(tutorial, idx) in tutorials" :tutorial="tutorial")
        .fade-bottom
      no-tutorials(v-else :demoTutorial="demoTutorial" data-testid="no-tuts-message")
    button.brt-btn.btn-primary.btn-big(@click="createTutorial" data-testid="create-button" title='Start creating a new tutorial')
      span Create a tour
</template>

<script>
import SYNC from '../../../utils/common/utils/sync';
import TutorialCard from '../reusable/TutorialCard';
import noTutorials from '../reusable/noTutorials';
import TeamSelector from '../../../components/header/TeamSelector';

export default {
  components: {
    'TutorialCard': TutorialCard,
    'no-tutorials' : noTutorials,
    'team-selector' : TeamSelector,
  },
  data() {
    return {
      hover: false,
      demoTutorial: {},
      domain: '',
      jwt: '',
      justNowInterval: 60 * 60 * 1000,
      loading: true,
    };
  },
  async mounted() {
    this.demoTutorial = await SYNC.getDemoTutorial();
    this.initDeleteTutorialEvents();
  },
  methods: {
    initDeleteTutorialEvents() {
      this.$ebus.$on('tutorial:removeFromList', (id) => {
        const tutorialsIndex = this.$store.getters['company/getTeamTours'].findIndex(tutorial => tutorial._id === id);
        if (tutorialsIndex !== -1) {
          this.$store.getters['company/getTeamTours'].splice(tutorialsIndex, 1);
        }
      });
      this.$on('tutorial:delete', (tutorial) => {
        this.$parent.$emit('tutorial:delete', tutorial);
      });
    },
    createTutorial() {
      this.$parent.$emit('createTutorial');
    },
    orderByTime(tutorials) {
      if (tutorials && tutorials.length > 0) {
        const ordered = [tutorials[0]];
        for (let t = 1; t < tutorials.length; t++) {
          let added = false;
          for (let o = 0; o < ordered.length; o++) {
            if (new Date(tutorials[t].created) > new Date(ordered[o].created)) {
              added = true;
              ordered.splice(o, 0, tutorials[t]);
              break;
            }
          }
          if (!added) {
            ordered.push(tutorials[t]);
          }
        }
        return ordered;
      }
      return [];
    },
    async onTeamChange(id) {
      this.loading = true;
      await this.$store.dispatch("company/setSelectedTeam", id);
      await this.$store.dispatch("company/getSelectedTeamData");
    }
  },
  computed: {
    tutorials() {
      const tutorials = this.$store.getters['company/getTeamTours'];
      const parsedTutorials = [];


      if (tutorials) {
        this.loading  = false;
        this.$ebus.$emit('onIdle:tutorialsLength', tutorials.length);
        let justNowTutorials = [];
        for (let i=tutorials.length - 1; i>=0; i--) {
          const tutorial = tutorials[i];
          tutorial.createdJustNow = tutorial.created && (new Date(new Date().toISOString()) - new Date(tutorial.created)) <= this.justNowInterval;
          if (tutorial.createdJustNow) {
            justNowTutorials.push(tutorial);
          } else {
            parsedTutorials.push(tutorial);
          }
        }
        if (justNowTutorials.length > 0) {
          justNowTutorials = this.orderByTime(justNowTutorials);
          parsedTutorials.unshift(...justNowTutorials);
        }
      }

      return parsedTutorials;
    }
  },
};
</script>

<style lang="stylus" scoped>
#onidle
  position relative
  height 100%
  overflow hidden
  #tutorial-container
    height calc(100% - 130px)
  button
    position absolute
    bottom 1%
</style>
