class Clipboard {
  /**
  * Copies text to clipboard from a provided value
  * @param { String, Number, Variable } value - a value in the form of a variable or a string.
  * This value will be copied to the clipboard.
  * Achieved by creating a temporary input, filling it with the value for copying and then destroying it
  */
  copyFromValue(value) {
    const dynamicInput = document.createElement('input');
    dynamicInput.value = value;
    dynamicInput.style.position = 'fixed';
    dynamicInput.style.opacity = 0;

    document.body.appendChild(dynamicInput);

    dynamicInput.select();
    document.execCommand('copy');
    document.body.removeChild(dynamicInput);
  }

  /**
  * Copies text to clipboard from an existing input.
  * @param { String } inputSelector - input or textarea id or class (e.g. '.blue_input' or '#myUniqueInput')
  */
  copyFromInput(inputSelector) {
    const input = document.querySelector(inputSelector);
    if (!input) {
      throw new Error('Cannot find element for the specified inputSelector');
    }
    input.select();
    document.execCommand('copy');
  }
}

const clipboard = new Clipboard();
export default clipboard;
