<template lang="pug">
.row.my-3
  .option-container.col-12.col-md-6
    Tour-Run-Options(data-testid="run-options")
  .option-container.col-12.col-md-6
    Tour-Launcher-Options(data-testid="launcher-options")
  .option-container.col-12.col-md-6
    Tour-Theme-Options(data-testid="theme-options")
  .option-container.col-12.col-md-6
    Tour-Language-Options(data-testid="language-options")
  .option-container.col-12.col-md-6
    Tour-Run(data-testid="run-tutorial")
  .option-container.col-12.col-md-6
    Tour-ID(data-testid="tour-id")
</template>

<script>
import TourRunOptions from '../components/tour/options/TourRunOptions.vue';
import TourLauncherOptions from '../components/tour/options/TourLauncherOptions.vue';
import TourThemeOptions from '../components/tour/options/TourThemeOptions.vue';
import TourLanguageOptions from '../components/tour/options/TourLanguageOptions.vue';
import TourRun from '../components/tour/options/TourRun.vue';
import TourID from '../components/tour/options/TourID.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    TourRunOptions,
    TourLauncherOptions,
    TourThemeOptions,
    TourLanguageOptions,
    TourRun,
    TourID,
  },
  data() {
    return { 
      noScriptTourId: '64b526e8db43d60014f82c99',
      scriptTourId: '64b52715db43d60014f82c9e'
    }
  },
  mounted() {
    if (localStorage.getItem('runTour')) {
      if (this.domain.hasScript) {
        bytesroute.execute('run', this.scriptTourId);
      } else {
        bytesroute.execute('run', this.noScriptTourId);
      }
      localStorage.removeItem('runTour');
    }
  },
  computed: {
    ...mapGetters({
      domain: "tutorial/getDomain"
    })
  }
};
</script>

<style lang='stylus' scoped>
@require '../utils/common/styleguide/colors.styl'
@require '../utils/common/styleguide/functions.styl'

.option-container {
  margin-bottom: pxToRem(30);
}
</style>