<template lang="pug">
Modal(v-if="show"
  :enabled="tutorial.options.once"
  primaryON="Disable run-once for the following tour?"
  primaryOFF="Enable run-once for the following tour?"
  confirmTextON="Disable"
  confirmTextOFF="Enable"
  :highlight="tutorial.name"
  :confirmCb="toggleRunOnce"
  :cancelCb="() => { this.show = false }"
)
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import updateTourOptions from '../mixins/updateTourOptions';
import updateTeamTours from '../mixins/updateTeamTours';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTourOptions, updateTeamTours],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      show: false
    };
  },
  mounted() {
    this.$ebus.$on("tour/toggleRunOnce", tutorial => {
      this.tutorial = tutorial;
      this.show = true;
    });
  },
  methods: {
    toggleRunOncePre() {
      this.show = true;
    },
    async toggleRunOnce() {
      try {
        this.show = false;
        await this.updateTourOptions(this.tutorial, {
          once: !this.tutorial.options.once
        });
        await this.updateTeamTours();
        this.$toast("Tour run once updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour run once. Please retry.", {
          type: TYPE.ERROR
        });
      }
    }
  }
};
</script>
