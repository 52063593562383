<template lang="pug">
  .manager-container
    OnboardManager
    TeamManager
    TourManager
</template>

<script>
import OnboardManager from "../onboard/OnboardManager.vue";
import TeamManager from "../team/TeamManager.vue";
import TourManager from "../tour/TourManager.vue";

export default {
  components: { OnboardManager, TeamManager, TourManager },
};
</script>
