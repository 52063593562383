<template lang="pug">
.brt-card(:class="{'bg-image': planHasLanguages}")
  img.green-lock(v-if='!planHasLanguages' src='/assets/plans/greenLock.svg')
  .language-select-container
    .small-copy.brt-muted-dark
      .d-flex.align-items-center.justify-content-between.mb-3
        p.caption.font-semibold.mb-0 TOUR LANGUAGE
        button.small-cta.mr-5(v-if="!planHasLanguages" @click="upgradePlan()") Upgrade plan to unlock
      p Choose the language you want for your tour.
    .select-container
      select.brt-select.w-100(
        @change='updateTourLanguage'
        :disabled="!planHasLanguages"
        v-model="selectedLanguage")
        option(
          v-for='language in languages'
          :selected="language._id === language"
          :value='language._id'
          :key='languages._id').
          {{ language.name }}
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      languages: [],
      selectedLanguage: '',
    }
  },
  async mounted() {
    await this.getLanguages();
    this.selectedLanguage = this.language
  },
  methods: {
    async getLanguages() {
      const result = await this.$http.get(`${this.$config.default.apiUrl}/language/`)
      this.languages = result.data;
    },
    updateTourLanguage() {
      this.$ebus.$emit("tour/updateLanguage", {
        tutorial: this.tutorial,
        language: this.selectedLanguage
      });
    },
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    },
  },
  computed: {
    ...mapGetters({
      tutorial: 'tutorial/getSelectedTutorial',
      language: 'tutorial/getLanguage',
      loading: 'tutorial/getLoading',
      planHasLanguages: 'company/planHasLanguages'
    })
  }
};
</script>

<style lang="stylus" scoped>
  .bg-image
    background-image url('/assets/language/language.svg')
    background-repeat no-repeat
    background-size 35%
    background-position-x 90%
    background-position-y 50%
    @media (max-width: 1200px)
      background-image none
  
  .select-container
    width 15rem
    position relative
</style>
