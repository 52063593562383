<template lang="pug">
  .row.mx-0.brt-onboard-card.p-0
    .col-12.offset-xl-1.col-xl-10.d-flex.position-relative.justify-content-center.align-items-center.px-5.py-3
      .details-container.w-100
        .title-container.mb-4.d-flex
          img(src="/assets/misc/greenCheck.svg" alt="green checkmark" width="30px")
          h2.ml-2 Script is installed on:  
        Domains-With-Script.mb-5(data-testid="domains-with-script")
        .brt-divider.mb-5
        Connect-How-Why
</template>

<script>
import CopyScript from '../common/CopyScript.vue';
import Spinner from '../../aspect/Spinner.vue';
import DomainsWithScript from '../common/DomainsWithScript.vue';
import ConnectHowWhy from '../common/ConnectHowWhy.vue';

export default {
  components: { Spinner, CopyScript, DomainsWithScript, ConnectHowWhy },
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'

  .brt-divider
    border-bottom 2px solid $brt-muted-faded
</style>
