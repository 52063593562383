<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from "../mixins/updateTourStep.js";
export default {
  mixins: [updateTourStep], 
  mounted() {
    this.$ebus.$on("tour/updateStepDescription", data => {
      this.updateTourStepDescription(data.tutorial, data.index, data.content);
    });
  },
  methods: {
    async updateTourStepDescription(tutorial, index, content) {
      const newStep = tutorial.steps[index];
      newStep.content = content;
      await this.updateTourStep(tutorial, index, newStep, "description");
    }
  }
};
</script>