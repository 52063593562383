<template lang="pug">
  .brt-card.p-0.mb-4(v-if="domain.tutorials")
    Domain-Card-Header(:domain="domain")
    .tours-container.mt-4.pb-4
      .empty-tours-container.brt-muted-dark(v-if="!domain.tutorials.length" data-testid="tutorials-absent")
        .row.mb-4.pl-5
          .col-12 No tours detected
      .tours-detected-container(v-else)
        Tour-Rows-Header(:domain="domain" :detailed="detailed")
        .tutorials-container
          Tour-Row(
            v-for="(tutorial, index) in domain.tutorials"
            :detailed="detailed"
            :tutorial="tutorial"
            :index="index"
            :domain="domain"
          )
</template>

<script>
import DomainCardHeader from './DomainCardHeader.vue'
import TourRowsHeader from './TourRowsHeader.vue';
import TourRow from './TourRow.vue';

export default {
  props: {
    domain: { type: Object, required: true },
    detailed: { type: Boolean, default: false }
  },
  components: { DomainCardHeader, TourRow, TourRowsHeader },
};
</script>