<template lang="pug">
  button(@click="copy()" data-testid="copy-button" title="Copy to clipboard")
    span.small-copy {{ buttonText }}
</template>

<script>
import clipboard from '../../utils/common/utils/clipboard.js';

export default {
  props: ['valueToCopy'],
  data() {
    return {
      clipboard: clipboard,
      buttonText: 'Copy'
    }
  },
  methods: {
    copy() {
      this.clipboard.copyFromValue(this.valueToCopy);
      this.buttonText = 'Copied';
      setTimeout(() => {  this.buttonText = 'Copy'; }, 1000);
    }
  }
}
</script>
