export const dropDownToggle = {
  methods: {
    toggleDropdown(id) {
      let element = document.getElementById(id);
      if (element) {
        if (element.style.visibility !== 'visible') {
          element.style.visibility = 'visible';
        } else {
          element.style.visibility = 'hidden';
        }
      }
    },
    hideDropDown(id) {
      let element = document.getElementById(id);
      if (element) {
         element.style.visibility = 'hidden';
      }
    },
  }
};
