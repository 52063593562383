<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from '../mixins/updateTourStep.js';
export default {
  mixins: [updateTourStep],
  mounted() {
    this.$ebus.$on("tour/updateStepHasBackground", data => {
      this.updateStepHasBackground(data.tutorial, data.index, data.hasBackground);
    });
  },
  methods: {
    async updateStepHasBackground(tutorial, index, hasBackground) {
      const newStep = tutorial.steps[index];
      newStep.hasBackground = hasBackground;
      await this.updateTourStep(tutorial, index, newStep, "has background option");
    }
  }
};
</script>
