<template lang='pug'>
  .invite-member-popup
    .dark-background
    .add-member-popup.text-center.brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' @click='closeModal()' title='Close this pop-up' data-testid='close-button')
        span(aria-hidden='true') &times;
      .inner-container.py-3

        .big-copy.mt-4.mb-3 🏀
        .medium-copy.mb-4.font-semibold Invite a new team member

        .body-copy.brt-muted-dark.mb-1.
          Invite new people to your team by sending
        .body-copy.brt-muted-dark.
          them an invitation email.

        form(@submit.prevent='sendInvitation' @keyup.enter='sendInvitation')
          .m-5.px-5.brt-validate-that
            input.brt-input(type='text' v-model='email' placeholder='email' brt-validate='notEmpty|isEmail')
          .mt-5.mb-3
            a.link.body-copy.mr-4(@click='closeModal()' title='Cancel invitation' data-testid='ok-button') Cancel
            button.cta(type='submit' title='Send invitation' data-testid='ok-button') Send invitation
</template>

<script>
import Validator from '../../utils/common/utils/validator.js';
import { scrollLock } from '../../mixins/scrollLock.js';

export default {
  mixins: [scrollLock],
  props: {
    confirmCb: Function,
    cancelCb: Function
  },
  data() {
    return {
      email: '',
      validator: ''
    }
  },
  async mounted() {
    this.validator =  new Validator(document.querySelector('.brt-validate-that'));
  },
  methods: {
    sendInvitation() {
      this.validator.removeErrors();
      if(this.validator.validateFields()){
        this.confirmCb(this.email);
      }
    },
    closeModal() {
      this.cancelCb();
    }
  }
}
</script>

<style lang='stylus' scoped>
  .add-member-popup
    width 36rem
    .brt-validate-that
      height 5rem
</style>
