// options object for the vue-toastification package
// https://www.npmjs.com/package/vue-toastification
import { POSITION, TYPE } from "vue-toastification";

module.exports = {
    transition: "Vue-Toastification__bounce",
    maxToasts: 5,
    newestOnTop: true,
    position: POSITION.TOP_RIGHT,
    timeout: 1000 * 3
}
