<template lang="pug">
  #step-1-container.row.mx-0.brt-onboard-card.p-0
    .col-12.offset-xl-1.col-xl-10.d-flex.justify-content-center.align-items-center
      .details-container
        .title-container.d-flex.align-items-center.mb-5
          img(src="/assets/misc/chrome-logo.svg")
          h2.ml-3 Install the Chrome Extension
        p.brt-caption.brt-dark WHY A CHROME EXTENSION?
        .body-copy.mb-4.brt-muted-dark
          p.mb-1 Using an extension, Chrome's capabilities are enhanced.
          p You will be able to create and preview customised experiences within your web apps.
        a.cta.mb-5.mt-3(
          v-if="!extensionDetected"
          :href="extensionURL"
          target='_blank'
          rel='noreferrer noopener'
          title="Visit the Chrome Web Store to download the Bytes Route extension"
          data-testid="extension-url") Get the Extension
        .extension-detected-container(v-if="extensionDetected")
          .validation-container.d-flex.align-items-center.border-box.py-3.px-2.mt-5.mb-4
            img.px-1.pb-1.mr-2(src="../../../assets/misc/greenCheck.svg" alt="green checkmark" width="30")
            h5.body-copy Extension was detected
          a.link.body-copy.pt-4(@click="nextStep()" title="Proceed to the next step" data-testid="continue-button").
            Continue ->
        .extension-missing-container.border-box.px-3(v-else data-testid="no-extension-detected")
          .body-copy.mt-3.brt-muted-dark
            .alert-container.d-flex.align-items-end.mb-2
              img(src="/assets/onboard/alert.svg" width="24px" alt="alert icon")
              h5.ml-2.brt-dark Extension wasn't detected
          p.small-copy.brt-muted-dark
            | In order to proceed, make sure you've correctly installed the Bytes Route Chrome Extension in your browser.
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      extensionURL: this.$config.default.extUrl,
    }
  },
  mounted() {
    if (this.extensionDetected && this.onboardStep <= 1) {
      this.$store.dispatch('onboarding/updateOnboardStep', 2)
    }
  },
  methods: {
    nextStep() {
      this.$store.commit('onboarding/setFocusedStep', 2)
    },
    refreshPage() {
      location.reload();
    },
  },
  computed: {
    ...mapGetters({
      extensionDetected: 'onboarding/getExtensionDetected',
      onboardStep: 'onboarding/getOnboardStep'
    })
  }
}
</script>

<style lang="stylus">
  @require '../../../utils/common/styleguide/colors.styl'
  #step-1-container
    min-height 24rem
  .border-box
    border 1.4px solid $brt-purple-bright
    border-radius 5px
  .extension-missing-container
    .alert-container
      img
        margin-bottom 3px
  .extension-detected-container
    .link
      text-decoration underline !important
    img
      margin-top 6px
</style>
