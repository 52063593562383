<template lang="pug">
.tutorial-details
  .container.my-4
    .row
      .col-12.text-left
        router-link.link.body-copy.font-semibold(:to="{ path: '/experiences' }").
          ← Return to Product Tours
    .brt-card.mt-4(v-if="loading")
      Spinner(type='inline')
    #tour-loaded(v-else)
      Tour-Not-Found(v-if='!tutorial')
      .bottom-section(v-else)
        Tour-Details-Header
        Tour-Navigation(:activeTab="activeTab")
        Tour-Analytics(v-if="activeTab==='analytics'")
        Tour-Options(v-if="activeTab==='options'")
        Tour-Edit-Steps(v-if="activeTab==='editSteps'")
</template>

<script>
import TourNotFound from '../components/tour/TourNotFound.vue';
import TourDetailsHeader from '../components/tour/TourDetailsHeader.vue';
import TourNavigation from '../components/tour/TourNavigation.vue';
import TourAnalytics from './TourAnalytics.vue'
import TourOptions from './TourOptions.vue';
import TourEditSteps from './TourEditSteps.vue';
import Spinner from '../components/aspect/Spinner.vue';

import { mapGetters } from "vuex";

export default {
  name: 'TourDetails',
  components: { TourAnalytics, TourOptions, TourDetailsHeader, TourNotFound, TourNavigation, TourEditSteps, Spinner },
  data() {
    return {
      activeTab: 'analytics',
    };
  },
  async mounted() {
    await this.getCurrentTutorial();
    this.setNavbarTab();
    this.$on('changeTab', (tab) => {
      this.activeTab = tab;
    });
  },
  methods: {
    async getCurrentTutorial() {
      const tourID = this.$route.params.id;
      await this.$store.dispatch('tutorial/getTutorialFromDB', tourID);
      this.$store.commit("tutorial/setActiveStep", 0)
    },
    setNavbarTab() {
      const tabParam = this.$route.params.tab;
      if (tabParam) {
        this.activeTab = tabParam;
      }
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      loading: "tutorial/getLoading"
    })
  }
};
</script>
