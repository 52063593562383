<template lang="pug">
  button.mb-4(
    :class="{ 'cta': sizeBig, 'small-cta': !sizeBig}"
    :disabled="!hasExtension"
    @click="openCreateTourModal()"
    :title="createButtonTitle"
  ) Create a product tour
</template>

<script>
import { auth } from "../../utils/auth";

export default {
  props: {
    sizeBig: { type: Boolean, required: false, default: false},
  },
  methods: {
    openCreateTourModal() {
      this.$store.commit('onboarding/toggleModalCreateTour', true)
    }
  },
  computed: {
    hasExtension() {
      return auth.hasExtension();
    },
    createButtonTitle() {
      return this.hasExtension
        ? "Create a Tour"
        : "You must install extension first";
    }
  }
};
</script>
