 <template lang="pug">
  #steps-container.brt-card.mt-3.position-relative(data-testid="steps-analytics")
    img.green-lock(v-if="!advancedAnalytics" src='/assets/plans/greenLock.svg')

    span.brt-caption.d-flex.justify-content-between
      p ADVANCED ANALYTICS - STEPS STATS
      button.small-cta.mr-5(v-if="!advancedAnalytics" @click="upgradePlan()") Upgrade your plan to unlock step analytics
    table#brt-table.mt-3
      thead
        tr.small-copy.brt-muted-dark
          th.px-3(scope="col") Step
          th.px-3(scope="col") 
            .brt-tooltip.brt-tooltip-dashed Started
              .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.steps.started }}
          th.px-3(scope="col") 
            .brt-tooltip.brt-tooltip-dashed Completed
              .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.steps.completed }}
          th.px-3(scope="col") 
            .brt-tooltip.brt-tooltip-dashed Stopped
              .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.steps.stopped }}
          th.px-3(scope="col") 
            .brt-tooltip.brt-tooltip-dashed Errors
              .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.steps.errors }}
          th.px-3(scope="col") 
            .brt-tooltip.brt-tooltip-dashed Auto-skipped
              .brt-tooltiptext.brt-tooltip-top
                p.mb-1 {{ tooltip.analytics.steps.skipped.primary }}
                p.brt-muted-bright.font-italic.mb-0 {{ tooltip.analytics.steps.skipped.secondary }}
      tbody(v-for="(step, idx) in tutorialSteps")
        tr.py-1
          th.px-3(scope="row")
            span.brt-pill.brt-white.brt-bg-secondary-dark {{ idx + 1 }}
          td.px-3
            span(v-if="advancedAnalytics") {{ step.stats.started }}
            img(v-else src='/assets/plans/lock.svg')
          td.px-3
            span(v-if="advancedAnalytics") {{ step.stats.completed }}
            img(v-else src='/assets/plans/lock.svg')
          td.px-3
            span(v-if="advancedAnalytics") {{ step.stats.stopped }}
            img(v-else src='/assets/plans/lock.svg')
          td.px-3
            span(v-if="advancedAnalytics") {{ step.stats.errors }}
            img(v-else src='/assets/plans/lock.svg')
          td.px-3
            span(v-if="advancedAnalytics") {{ step.stats.skipped }}
            img(v-else src='/assets/plans/lock.svg')
</template>

<script>
import { tooltip } from '../../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      tooltip
    }
  },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    }
  },
  computed: {
    ...mapGetters({
      tutorialSteps: 'tutorial/getSteps',
      advancedAnalytics: 'company/planHasAdvancedAnalytics'
    }),
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'

  #brt-table
    border-collapse separate
    border-spacing pxToRem(0) pxToRem(25)

    td
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
    @media(min-width: 768px)
      td
        max-width 5rem
    @media(min-width: 992px)
      td
        max-width 20rem
    @media(min-width: 1200px)
      td
        max-width 30rem
    @media(min-width: 1500px)
      td
        max-width 45rem
    

</style>
