<template lang="pug">
  .outside-container
    Modal-Create-Team(
      v-if="showCreateTeamPopUp"
      :closeCb="() => { showCreateTeamPopUp = false }"
    )
    .team-info-container.d-flex(:class="{ 'justify-content-between': isInIframe }")
      .brt-dropdown-container.d-flex.align-items-center.ml-0.ml-sm-4(
        v-click-outside="hideTeamSelectDropDown"
        @click="toggleDropdown('team-select')"
        data-testid="team-selector"
      )
        .brt-dropdown-trigger.nav-elem.nav-dropdown.d-flex
          .skeleton-block(v-if="loading") .......................
          .d-inlie-block.text-truncate(v-else) {{ teamName }}
          img.ml-2(src='/assets/misc/chevron.svg' width="18px" alt="chevron arrow down")
        #team-select.brt-dropdown-list
          #select-small-label.small-copy.brt-muted(v-if="teams.length > 1").
            select team
          .brt-dropdown-item(v-for="team in teams" @click="onchange(team._id)")
            .check-mark-container.d-inline
              img.d-inline.mr-1(v-if="team.selected" src="/assets/misc/greenCheck.svg" alt="green checkmark" width="20")
              span.mr-4(v-else)
            span {{ team.name }}
          #team-create(v-if='addNewTeamButton' @click="showCreateTeamPopUp = true").
            + Add a new Team
      #pill-container.d-lg-flex.align-items-center.ml-3
        .subscription-pill
          .skeleton(v-if="loading")
          .actual-pill(v-else)
            span.mr-1(v-if="showTrialLabel") TRIAL
            span {{ planName }}
</template>

<script>
import ModalCreateTeam from '../team/ModalCreateTeam.vue';
import { ClickOutside } from '../../directives/clickOutside.js';
import { dropDownToggle } from '../../mixins/dropDownToggle.js';
import { mapGetters } from 'vuex';

export default {
  components: { ModalCreateTeam },
  directives: { 'click-outside': ClickOutside },
  props: {
    onchange: Function,
    addNewTeamButton: {
      type: Boolean,
      default: true
    },
  },
  mixins: [ dropDownToggle ],
  data() {
    return {
      showCreateTeamPopUp: false,
      isInIframe: window.parent !== window
    };
  },
  methods: {
    hideTeamSelectDropDown() {
      this.hideDropDown('team-select');
    },
  },
  computed: {
    showTrialLabel() {
      return (
        this.planTier > 1 && 
        this.planIsTrial
      )
    },
    ...mapGetters({
      teams: 'company/getTeams',
      loading: 'company/getLoadingGetTeams',
      teamName: 'company/getTeamName',
      planName: 'company/getTeamPlanName',
      planTier: 'company/getTeamPlanTier',
      planIsTrial: 'company/planIsTrial'
    })
  } 
};
</script>

<style lang="stylus" scoped>
@require '../../utils/common/styleguide/colors.styl'
@require '../../utils/common/styleguide/functions.styl'

  #team-select
    top 3.5rem

    #select-small-label
      margin-left 2.5rem
      cursor default

    #team-create
      cursor pointer
      border-top 2px solid $brt-muted-bright
      border-radius 0rem
      padding 0.6rem 1.7rem 0.6rem 2.5rem
      &:hover
        background-color $brt-muted-faded

  .text-truncate
    max-width pxToRem(170)
  .align-right
    float: right
</style>
