<template lang="pug">
  #no-tutorials.d-flex.flex-column.justify-content-center
    #no-tours.d-flex.align-items-center.justify-content-center
      h4.text-center.brt-primary-dark No tours created yet
    #demo-tutorial(@click="runDemo" v-if="demoTutorial")
      .tutorial-content
        .ext-body.bolder.mb-1 START DEMO TOUR
          .small-copy Looking for inspiration? See how a product tour looks like on our homepage.
</template>
<script>

export default {
  props: ['demoTutorial'],
  methods: {
    runDemo() {
      this.$ebus.$emit('runTutorial', this.demoTutorial);
    },
  }
};
</script>
<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  #no-tutorials
    height 100%

    .mb-15rem
      margin-bottom 15rem

    #no-tours
      background-image url("../../images/spotEmptyState.svg")
      background-repeat: no-repeat
      background-size cover
      height pxToRem(400)


    #demo-tutorial
      background-color $brt-secondary-dark
      margin pxToRem(20)
      width calc(100% - 40px)
      border-radius pxToRem(10)
      padding pxToRem(10) pxToRem(20) pxToRem(10) pxToRem(45)
      transition background-color .2s ease
      position relative
      .tutorial-content
        color $brt-white !important
      &::before
        content url('../../images/iconPlay.svg')
        position absolute
        top 50%
        transform translateY(-50%)
        left pxToRem(10)
        width pxToRem(25)
        transition width .3s ease
        filter contrast(0) brightness(0) invert(1)
      &:hover
        cursor pointer
        background-color alpha($brt-secondary-dark, .6)
</style>
