<template lang="pug">
  .container
    .dark-background
    .brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' @click="close" title="Close this pop-up" data-testid="close-button")
        span(aria-hidden='true') &times;
      .inner-container.py-3

        .pop-up-emoji.mt-4.mb-3 😔
        .medium-copy.mb-4.font-semibold Oops. We couldn’t detect the Script anywhere.

        .body-copy.brt-muted-dark.mb-1.
          Make sure the Script has been copied correctly
        .body-copy.brt-muted-dark.
          in your website's source code.

        .body-copy.brt-muted-dark.mt-5.mb-4
          span For more info, see our
            | 
            a.link(
              :href="$urlConfig.galleryUrl + '/docs/script-install/html-script.html'"
              target='_blank'
              rel='noreferrer noopener'
              data-testid="guide-link") Docs
        
        button.cta.pt-3.mb-3(@click="close" title="Close this pop-up" data-testid="ok-button") OK
</template>

<script>
import { scrollLock } from '../../../mixins/scrollLock.js';

export default {
  mixins: [scrollLock],
  methods: {
    close() {
      this.$store.commit('onboarding/toggleModalScriptNotFound', false)
    }
  }
}
</script>
