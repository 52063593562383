<template lang="pug">
.tour-navigation.container
  .brt-tabs-container.mt-0
    .brt-tab(@click="changeTab(tabs.tours)" :class="{'brt-active-tab': activeTab==='tours'}")
      svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
        use(href="/assets/misc/productTours.svg#Layer_1")
      span Product Tours

    .brt-tab(@click="changeTab(tabs.launcher)" :class="{'brt-active-tab': activeTab==='launcher'}")
      svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
        use(href="/assets/misc/questionTooltip.svg#Layer_1")
      span Launch Widget
        
</template>

<script>
export default {
  props: {
    activeTab: String
  },
  data() {
    return {
      tabs: {
        tours: 'tours',
        launcher: 'launcher'
      },
    };
  },
  methods: {
    changeTab(tab) {
      if (this.activeTab != tab) {
        this.$router
          .push({ path: `/experiences/${tab}` })
          .catch((err)=>{
            if (err.name != "NavigationDuplicated") {
              console.log(err)
            }
          });
        this.$parent.$emit('changeTab', tab);
      } 
    },
  }
};
</script>
