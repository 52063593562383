<template lang="pug">
  .ext-body.brt-bg-muted-faded.px-3.py-2.d-flex.align-items-center.justify-content-between

    a.link(@click="openTab('dashboard')")
      img#brt-logo(src="../../images/logoBR_128.png" alt="Bytes Route logo")
    
    section.d-flex.align-items-center
      span.brt-muted-dark(v-if='isLoggedIn')
        span {{ userName }}
        span.mx-1.
          •
        button.link(@click='logout').
          Log out
      span#close-btn(@click='closeIframe') ×


</template>

<script>
import { auth } from '../../../utils/auth';
import UrlConfig from '../../../utils/common/utils/urlConfig';

export default {
  props: ['state'],
  data () {
    return {
      pendingConfirmation: false,
      confirmDialogProperties: {
        dialogTitle: 'Are you sure?',
        dialogMessage: 'Confirm removal',
        confirmButtonText: 'Delete',
        usedFor: 'delete'
      }
    };
  },
  methods: {
    async openTab(option) {
      const urlConfig = new UrlConfig();
      await urlConfig.setup();
      switch (option) {
        case 'dashboard':
          this.$ievents.sendMessage('link', 'open', 'dashboard');
          break;
        default:
          throw new Error('Invalid option');
      }
    },
    closeIframe() {
      if (this.$route.name === 'login') {
        this.$ievents.sendMessage('execute', 'close');
        this.$ievents.sendMessage('execute', 'stopAll');
      } else {
        this.$ebus.$emit('iframe:close');
      }
    },
    logout() {
      this.$ebus.$emit('logout');
    }
  },
  computed: {
    userName() {
      return auth.username
    },
    isLoggedIn() {
      return auth.isAuthenticated()
    }
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/functions.styl';
  @import '../../../utils/common/styleguide/colors.styl';
  #brt-logo
    height pxToRem(32)
    width pxToRem(32)
  #close-btn
    padding 0px 10px 5px 10px
    background-color $brt-primary
    color $brt-white
    cursor pointer
    font-weight bold
    font-size 30px
    border-radius 50%
    display inline-block
    line-height 1
    scale 0.7
    
    &:hover
      background-color $brt-purple-bright
      transition .1s
    
</style>
