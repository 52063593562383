import axios from 'axios';

class Config {
  constructor() {
    this.default = {
      apiUrl: 'http://localhost:3000', //undo
      extUrl: 'https://chrome.google.com/webstore/detail/bytesroute-your-virtual-w/kfdoklilbkbflmbmkinaojhdhkojlhbh'
    };
  }

  async get() {
    const res = await axios.get('/config');
    if (document.location.origin.includes('localhost')) {
      this.default.apiUrl = res.data.dev.apiUrl;
    } else {
      this.default.apiUrl = res.data.prod.apiUrl;
    }
    this.default.extUrl = res.data.extension.storeUrl;
  }
}

export const config = new Config();
