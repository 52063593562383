<template lang="pug">
  .manager-container
    Modal(v-if="showDeleteTheme"
      emoji="🗑"
      primaryOFF="Delete this theme?"
      confirmTextOFF="Delete"
      :highlight="theme.name"
      :confirmCb="deleteTheme"
      :cancelCb="() => { this.showDeleteTheme = false }"
    )

    Modal(v-if="showDeleteThemeUsed"
      emoji="🚨"
      primaryOFF="Delete this theme?"
      :secondary="usedThemeDeleteWarning"
      confirmTextOFF="Delete"
      :highlight="theme.name"
      :confirmCb="deleteTheme"
      :cancelCb="() => { this.showDeleteThemeUsed = false }"
    )

</template>

<script>
import Modal from '../../aspect/Modal.vue';
import { handleError } from '../../../mixins/handleError';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      showDeleteTheme: false,
      showDeleteThemeUsed: false,
      theme: {},
      teamID: ''
    }
  },
  mounted() {
    this.$ebus.$on('company/deleteTheme', (data) => {
      this.theme = data.theme;
      this.teamID = data.teamID
      this.deleteThemePre()
    })
  },
  methods: {
    deleteThemePre() {
      if (this.theme.occurences > 0) {
        this.showDeleteThemeUsed = true;
      } else {
        this.showDeleteTheme = true;
      }
    },
    async deleteTheme() {
       const url = `${this.$config.default.apiUrl}/theme/${this.theme._id}/${this.teamID}`;
      try {
        const res = await this.$http.delete(url);
        if (res.status === 204) {
          this.$toast("Theme deleted", { type: TYPE.SUCCESS });
          await this.$store.dispatch("company/getSelectedTeamThemes");
        } 
      } catch (err) {
        this.handleError(err);
        this.$toast("Error deleting the theme. Please retry.", { type: TYPE.ERROR });
      } finally {
        this.showDeleteTheme = false;
        this.showDeleteThemeUsed = false;
      }
    }
  },
  computed: {
    usedThemeDeleteWarning() {
      return `This theme is used by ${this.theme.occurences} tours. Deleting this theme will revert these tours to the Bytes Route default theme.`
    }
  }
}
</script>
