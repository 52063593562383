<template lang="pug">
  .row.py-2.brt-dark.d-flex.align-items-center
    .col-1.text-center
      span.brt-pill.brt-white.brt-bg-secondary-dark {{ index + 1 }}
    .col-3.text-truncate {{ member.username ? member.username : '-' }}
    .col-5.text-truncate {{ member.email }}
    .col-2 {{ member.status }}
    .col-1(:class="{'invisible': !showDeleteButton}")
      button.delete-member.secondary-button(@click="deleteMember()")
        svg(width='1.2rem' height='1.2rem')
          use(href='/assets/misc/iconDelete.svg#Layer_1')
</template>

<script>
export default {
  props: {
    index: Number,
    member: Object,
    isOwner: Boolean
  },
  methods: {
    deleteMember() {
      this.$ebus.$emit("company/removeTeamMember", this.member);
    }
  },
  computed: {
    showDeleteButton() {
      return this.isOwner && this.member.status !== "owner";
    }
  }
};
</script>

<style lang="stylus" scoped>
@require '../../utils/common/styleguide/colors.styl'
@require '../../utils/common/styleguide/buttons.styl'

.delete-member
  color: $brt-red-dark
  stroke: $brt-red-dark
</style>
