<template lang="pug">
.container
  .row
    .col-6.text-center
      .inner-not-found-container.d-inline-block.text-left
        .status-code-text 404
        p.medium-copy.font-weight-bold Oops, page not found! :(
        p.body-copy.brt-muted-dark Sorry, but the page you are trying to view does not exist.
        .go-back-container.mt-5
          button.cta.link.body-copy.font-semibold(@click="goHome()").
            Take me home
    .col-6.d-flex.align-items-center
      .mascot-container.d-inline-block
        img(src='../assets/404/404_mascot.png' width="100%")
</template>
<script>
export default {
  mounted() {
    this.toggleBodyBackground();
  },
  beforeDestroy() {
    this.toggleBodyBackground();
  },
  methods: {
    goHome() {
      this.$router.push({ path: "/dashboard" });
    },
    toggleBodyBackground() {
      const main = document.getElementsByTagName('main')[0];
      main.classList.toggle('blobs-background')
    }
  },
};
</script>

<style lang="stylus">
  .blobs-background
    background-image url('../../www/assets/404/generic-blobs.svg')
    background-size auto
    background-position 40% 50%
    background-repeat no-repeat
    background-color transparent

  .inner-not-found-container
    padding-top 10rem
    padding-bottom 10rem
    .status-code-text
      display inline-block
      font-size 6rem
      font-weight 600
      background linear-gradient(110deg, #F777B5 20%, #8A38B9 80%)
      -webkit-background-clip text
      background-clip text
      color transparent
</style>
