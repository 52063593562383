<template lang="pug">
.brt-card
  img.green-lock(v-if='!planHasThemes' src='/assets/plans/greenLock.svg')
  .small-copy.brt-muted-dark
    .d-flex.align-items-center.justify-content-between.mb-3
      p.caption.font-semibold.mb-0 TOUR THEME
      button.small-cta.mr-5(v-if="!planHasThemes" @click="upgradePlan()") Upgrade plan to unlock
    p Change the UI theme of your tour.
    .d-flex.align-items-center.justify-content-between
      .select-container.w-50
        select.brt-select.w-100(
          @change='updateTheme'
          :disabled="!planHasThemes"
          v-model='selectedThemeID'
        )
          option(
            v-for='theme in themes'
            :value='theme._id'
            :key='theme._id'
          ) {{ theme.name }}
      Theme-Preview.ml-lg-4(v-if='showThemeColors' :theme="selectedThemeObj")
</template>

<script>
import ThemePreview from '../../themes/ThemePreview.vue'
import { handleError } from '../../../mixins/handleError';
import { mapGetters } from 'vuex';

export default {
  mixins: [handleError],
  components: { ThemePreview },
  data() {
    return {
      selectedThemeID: '',
      selectedThemeObj: {},
    }
  },
  async mounted() {
    this.selectedThemeID = this.tutorialThemeID;
    this.updateSelectedThemeObj();
  },
  watch: {
    async themes() {
      if(this.themes.length > 0) {
        await this.updateTourThemeIfBroken();
        this.updateSelectedThemeObj();
      }
    },
    selectedThemeID() {
      this.updateSelectedThemeObj();
    }
  },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    },
    updateTheme() {
      this.$ebus.$emit("tour/updateTheme", {
        tutorial: this.tutorial,
        theme: this.selectedThemeID
      });
    },
    async updateTourThemeIfBroken() {
      if (this.themes.length < 1)
        return
      
      // check if current tour theme is in the list of themes in the company
      const themeIndex = this.themes.findIndex((theme) => theme._id === this.selectedThemeID)

      // if current theme is not found, update the tour theme to the last theme in the array (should be the default theme)
      if (themeIndex === -1) {
        const defaultTheme = this.themes[this.themes.length - 1]._id
        this.$ebus.$emit("tour/updateTheme", {
          tutorial: this.tutorial,
          theme: defaultTheme
        })
        this.selectedThemeID = defaultTheme;
      }
    },
    updateSelectedThemeObj() {
      this.selectedThemeObj = this.themes.find((theme) => theme._id === this.selectedThemeID);
    },
    cancelEditMode() {
      this.selectedThemeID = this.tutorialThemeID;
      this.updateSelectedThemeObj();
      this.themeEditMode = false;
    }
  },
  computed: {
    showThemeColors() {
      return (
        this.selectedThemeObj &&
        this.selectedThemeObj.general &&
        this.selectedThemeObj.buttons
      )
    },
    ...mapGetters({
      themes: 'company/getThemes',
      planHasThemes: 'company/planHasThemes',
      tutorial: 'tutorial/getSelectedTutorial',
      loading: 'tutorial/getLoading',
      tutorialThemeID: 'tutorial/getTheme'
    }),
  }
};
</script>
