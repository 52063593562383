<template lang="pug">
  .brt-checkbox
    input#elem-interaction(type="checkbox", v-model="interaction" @change="updateElementInteraction")
    label.ml-2(for="elem-interaction") Allow element interaction

</template>

<script>
import IconTooltip from "../../icons/IconTooltip.vue";
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  props: {
    allowInteraction: Boolean,
    index: Number
  },
  data() {
    return {
      interaction: ""
    };
  },
  async mounted() {
    this.setInitialInteraction();
  },
  watch: {
    allowInteraction() {
      this.setInitialInteraction();
    }
  },
  methods: {
    setInitialInteraction() {
      this.interaction = this.allowInteraction;
    },
    updateElementInteraction() {
      this.$ebus.$emit("tour/updateStepElementInteraction", {
        tutorial: this.tutorial,
        index: this.index,
        interaction: !this.interaction
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
