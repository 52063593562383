 
<template lang="pug">
  #chart-container.brt-card.mt-3
    span.brt-caption BASIC ANALYTICS - TOUR STATS
    #brt-chart-stats.row.mt-4(data-testid="tour-analytics")
      .col-12.col-md-3.col-lg-4.col-xl-3.d-flex.justify-content-start.align-items-center.border-right
        Completion-Chart(:percentage="percCompleted")
        .stat-container.ml-0.ml-lg-4
          .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Completion rate
            .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.completionRate }}
          h2.mt-2 {{ percCompleted }}%
      .col-12.col-sm-6.col-md-2.d-flex.justify-content-start.justify-content-xl-center
        .inner-container
          .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Started
            .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.started }}
          h2.mt-2 {{ statStarted }}
      .col-12.col-sm-6.col-md-2.d-flex.justify-content-start.justify-content-xl-center
        .inner-container
          .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Completed
            .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.completed }}
          h2.mt-2 {{ statCompleted }}
      .col-12.col-sm-6.col-md-2.d-flex.justify-content-start.justify-content-xl-center
        .inner-container
          .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Stopped
            .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.stopped }}
          h2.mt-2 {{ statStopped }}
      .col-12.col-sm-6.col-md-2.d-flex.justify-content-start.justify-content-xl-center
        .inner-container
          .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Errors
            .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.errors }}
          h2.mt-2 {{ statErrors }}

</template>

<script>
import CompletionChart from '../../aspect/CompletionChart.vue'
import { tooltip } from '../../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  components: { CompletionChart },
  data() {
    return {
      tooltip
    }
  },
  computed: {
    percCompleted() {
      if (this.statStarted === 0) {
        return '0'
      }
      const percentage = (this.statCompleted / this.statStarted)  * 100;
      return percentage.toFixed(1)
    },
    ...mapGetters({
      statStarted: 'tutorial/statStarted',
      statCompleted: 'tutorial/statCompleted',
      statStopped: 'tutorial/statStopped',
      statErrors: 'tutorial/statErrors'
    }),

  }
}
</script>
