 
<template lang="pug">
.analytics-container.brt-card
  span.brt-caption ANALYTICS FOR ALL THE TOURS
  .d-flex.justify-content-between.mt-4(data-testid="tour-analytics")
    .part.d-flex
      Completion-Chart.mr-4(:percentage="completionAverage")
      .inner-container
        .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Completion rate
          .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.completionRate }}
        h2.text-center.mt-2
          .skeleton.w-75(v-if="loading") .
          div(v-else) {{ completionAverage }}%

    .part
      .inner-container
        .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Started
          .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.started }}
        h2.text-center.mt-2
          .skeleton.w-75(v-if="loading") .
          div(v-else) {{ totalStarted }}
    .part
      .inner-container
        .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Completed
          .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.completed }}
        h2.text-center.mt-2
          .skeleton.w-75(v-if="loading") .
          div(v-else) {{ totalCompleted }}
    .part
      .inner-container
        .brt-tooltip.brt-tooltip-dashed.small-copy.brt-muted-dark Stopped
          .brt-tooltiptext.brt-tooltip-left {{ tooltip.analytics.tour.stopped }}
        h2.text-center.mt-2
          .skeleton.w-75(v-if="loading") .
          div(v-else) {{ totalStopped }}
</template>

<script>
import CompletionChart from '../../components/aspect/CompletionChart.vue';
import { tooltip } from '../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  components: { CompletionChart },
  data() {
    return {
      completionAverage: '0.0',
      totalStarted: 0,
      totalCompleted: 0,
      totalStopped: 0,
      tooltip
    }
  },
  mounted() {
    this.calculateStats();
  },
  watch: {
    domainsTours() {
      this.calculateStats();
    }
  },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    },
    calculateStats() {
      let numberOfToursCountedForAverageCompletion = 0;
      let tourCompletionSum = 0;
      this.totalStarted = 0;
      this.totalCompleted = 0;
      this.totalStopped = 0;

      const domains = this.allDomains;
      domains.forEach(domain => {
        if (domain.tutorials.length) {
          domain.tutorials.forEach(tutorial => {
            if (tutorial.stats.started != 0) {
              numberOfToursCountedForAverageCompletion++;
              tourCompletionSum += (tutorial.stats.completed / tutorial.stats.started)
            }
            this.totalStarted += tutorial.stats.started;
            this.totalCompleted += tutorial.stats.completed;
            this.totalStopped += tutorial.stats.stopped;
          });
        }
      });
      if (numberOfToursCountedForAverageCompletion === 0) {
        this.completionAverage = '0.0'
      } else {
        this.completionAverage = (tourCompletionSum / numberOfToursCountedForAverageCompletion * 100).toFixed(1)
      }
    },
  },
  computed: {
    ...mapGetters({
      domainsTours: 'company/getAllDomainsTours',
      loading: 'company/getLoadingTeamTours',
      allDomains: 'company/getAllDomainsTours'
    }),
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'
</style>
