<template lang="pug">
  .theme-preview-container
    .theme-color(:style="`background-color: ${theme.general.bg}`")
    .theme-color.ml-3(:style="`background-color: ${theme.general.progress}`")
    .theme-color.ml-3(:style="`background-color: ${theme.buttons.idle.primary.bg}`")
    .theme-color.ml-3(:style="`background-color: ${theme.general.text}`")
    .theme-color.ml-3(:style="`background-color: ${theme.buttons.idle.primary.text}`")
</template>

<script>
export default {
  props: {
    theme: { 
      type: Object, 
      required: true
    },
  },
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'

  .theme-color
    width pxToRem(20)
    height pxToRem(20)
    border-radius 50%
    display inline-block
    border 1px solid $brt-black
</style>
