export const recaptcha = {
  data: () => {
    return {
      recaptcha: {
        siteKey: '6LejFCIgAAAAAECJRgepCD7Cf-zUzv_qQvvbBFzO',
        token: '',
        scriptId: 'recaptcha-script'
      }
    }
  },
  methods: {
    addRecaptchaScript() {
      const recaptchaScript = document.createElement('script');
      recaptchaScript.id = this.recaptcha.scriptId
      recaptchaScript.setAttribute('src',
        `https://www.google.com/recaptcha/api.js?render=${this.recaptcha.siteKey}`);
      document.head.appendChild(recaptchaScript);
    },
    removeRecaptchaScript() {
      document.getElementById(this.recaptcha.scriptId).remove();
    },
    runRecaptchaTest(cb) {
      grecaptcha.ready(() => {
        grecaptcha.execute(this.recaptcha.siteKey, {action: 'submit'}).then(token => {
          this.recaptcha.token = token;
          cb();
        });
      });
    }
  }
}