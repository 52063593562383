<template lang="pug">
  .container
    .row.mt-5.justify-content-md-center
      .col-12.col-md-8.col-lg-6.col-xl-4
        h1.h3.mb-5 Let's get you a new key.
        form.brt-form
          .brt-input-group
            input#password.brt-input(
              type='password'
              autocomplete='new-password'
              v-model='passEntry1'
              :class="{'brt-input-invalid': !passValidatedOnClient}"
              :readonly="passwordUpdatedSuccesfully"
              @mousedown="passValidatedOnClient=true"
              required)
            label.brt-label(
              for="password").
              Password
          .brt-input-group
            input#passwordConfirm.brt-input(
              type='password'
              autocomplete='new-password'
              v-model='passEntry2'
              :class="{'brt-input-invalid': !passValidatedOnClient}"
              :readonly="passwordUpdatedSuccesfully"
              @mousedown="passValidatedOnClient=true"
              required)
            label.brt-label(for="passwordConfirm") Password confirmation
            div.mb-5.mt-2
              .small-copy.brt-input-error(v-if="!passwordUpdatedSuccesfully" :class="{'hide': passValidatedOnClient}") {{ errMessage }}
              .body-copy.brt-dark.mb-3(v-else) Password successfully updated!
          button.cta(v-if="!passwordUpdatedSuccesfully" type='submit' @click.prevent='resetPassword')
            div(v-if='requestInProgress')
              .spinner-border
              span.sr-only Loading...
            div(v-else).
              Update password
          .action-container(v-else) 
            router-link(v-if="logged" :to="{path: '/login'}")
              button.cta.mt-3 Go To Dashboard
            router-link(v-else :to="{path: '/'}")
              button.cta.mt-3 Log In
</template>

<script>
import { auth } from '../../utils/auth.js';
export default {
  metaInfo: {
    title: 'Password Change | Bytes Route',
    meta: [{ name: 'description', content: 'Provide a new password for your Bytes Route account' }]
  },
  data: function () {
    return {
      passEntry1: '',
      passEntry2: '',

      passValidatedOnClient: true,
      requestInProgress: false,
      passwordUpdatedSuccesfully: false,

      errMessage: 'default error',
      errMsgMatching: 'Password entries must match',
      errMsgLength: 'Passwords must be at least 5 characters long',
      email: '',

      logged: false
    };
  },
  mounted() {
    this.logged = auth.isAuthenticated();
  },
  methods: {
    async resetPassword () {
      if (this.passEntry1 !== this.passEntry2) {
        this.passValidatedOnClient = false;
        this.errMessage = this.errMsgMatching;
      } else if (this.passEntry1.length < 5) {
        this.passValidatedOnClient = false;
        this.errMessage = this.errMsgLength;
      } else {
        this.passValidatedOnClient = true;
        this.mailToken = this.$route.query.token;

        const url = `${this.$config.default.apiUrl}/recovery/changePassword?token=${this.mailToken}`;

        try {
          this.requestInProgress = true;
          const res = await this.$http.put(url, {
            password: this.passEntry1 
          });
          if (res.status === 200) {
            this.passwordUpdatedSuccesfully = true;
          }
        } catch (err) {
          this.errMessage = err.response ? err.response.status : 'There was an error, please try again later!';
        } finally {
          this.requestInProgress = false;
        }
      }
    },
  },
};
</script>
