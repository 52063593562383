<template lang="pug">
.tutorial-row.py-2.px-4(:class="{'brt-bg-muted-faded': (index % 2 != 0)}" )
  .row.brt-muted.py-1.d-flex.justify-content-between
    .col-4.col-lg-5.d-flex.align-items-center
      router-link.body-copy.brt-dark.mb-1.text-break.link(:to='{ path: this.path}' data-testid="tutorial-name").
        {{ tutorial.name }}
  
    .col-lg-2.d-none.d-lg-inline.d-lg-flex.align-items-center(v-if="detailed" data-testid="stats-section")
      .inner-container.d-flex.flex-column.flex-lg-row.align-items-center
        .percentage-container.brt-muted-dark
          span(data-testid="completion-rate") {{ getTourCompletionRate }}
        .errors-number.small-copy.ml-2(v-show="tutorial.stats.errors")
          span.mr-1 {{ tutorial.stats.errors }}
          span(v-if="tutorial.stats.errors === 1") error
          span(v-else) errors

    .col-2.col-lg-1.d-flex.align-items-center(v-if="detailed")
      Toggle-Switch(
        v-model="tutorial.options.autoRun"
        :titleEnabled="tooltip.tour.autoRun.enabled"
        :titleDisabled="tooltip.tour.autoRun.disabled"
        :disabled="autoRunToggleDisabled"
        @change="toggleAutoRun()"
        data-testid="autorun-toggle")

    .col-2.col-lg-2.d-flex.align-items-center(
      :class="{\
        'justify-content-end' : !detailed,\
        'justify-content-center': detailed\
      }"
    )
      Toggle-Switch(
        v-model="tutorial.options.show"
        :titleEnabled="tooltip.tour.launcher.enabled"
        :titleDisabled="tooltip.tour.launcher.disabled"
        :disabled="launcherVisibilityToggleDisabled"
        @change="toggleLauncherVisibility()"
        data-testid="launcher-toggle")
    
    .col-2.col-lg-1.d-flex.align-items-center(v-if="detailed")
      button.small-cta.py-1(@click="goToDetails()" data-testid="details-link") See

    .col-2.col-lg-1.d-flex.align-items-center.justify-content-start(v-if="detailed")
      Tour-More-Options(:tutorial="tutorial" data-testid="more-options")
</template>

<script>
import ToggleSwitch from '../../utils/common/vuecomps/ToggleSwitch.vue';
import TourMoreOptions from './TourMoreOptions.vue';

import Modal from '../aspect/Modal.vue';

import { tooltip } from '../../jsons/tooltips.json';
import TutorialURL from '../../utils/common/utils/tutorialURL.js';
import { handleError } from '../../mixins/handleError.js';
import { mapGetters } from 'vuex';

export default {
  components: { ToggleSwitch, TourMoreOptions, Modal },
  mixins: [handleError],
  props: {
    tutorial: {
      type: Object,
      required: true
    },
    index: {
      type: [String, Number],
      required: true
    },
    domain: {
      type: Object,
      required: true
    },
    detailed: { 
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      path: '',
      tooltip
    }
  },
  mounted() {
    this.generateTutorialLink();
    this.handleNameUpdateListener();
  },
  updated() {
    this.generateTutorialLink();
  },
  methods: {
    goToDetails() {
      this.$router.push(this.path);
    },
    generateTutorialLink() {
      const tutorialURL = new TutorialURL(this.tutorial);
      this.path = tutorialURL.getPath();
    },
    toggleLauncherVisibility() {
      this.$ebus.$emit("tour/toggleLauncherVisibility", this.tutorial);
    },
    toggleAutoRun() {
      this.$ebus.$emit("tour/toggleAutoRun", this.tutorial);
    },
    handleNameUpdateListener() {
      this.$ebus.$on('tutorial/clientUpdateName', update => {
        if (this.tutorial._id == update.id) {
          this.tutorial.name = update.name;
        }
      })
    }
  },
  computed: {
    getTourCompletionRate() {
      let started = this.tutorial.stats.started;
      let completed = this.tutorial.stats.completed;
      let percentage = (100 * completed ) / started;
      if (started >= 1 && completed >= 1) {
        return `${percentage.toFixed(1)}%`;
      } else if(started >= 1 && completed === 0) {
        return '0%';
      } else {
        return '-';
      }
    },
    autoRunToggleDisabled() {
      return (
        !this.domain.hasScript ||
        this.loading
      )
    },
    launcherVisibilityToggleDisabled() {
      return (
        !this.domain.launcher.show ||
        !this.domain.hasScript ||
        this.loading
      )
    },
    ...mapGetters({
      loading: 'company/getLoadingTeamDetails'
    })
  },
};
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'

  .tutorial-row
    .errors-number
      color $brt-danger
      border pxToRem(1) solid $brt-danger
      border-radius 3rem
      padding-left 0.5rem
      padding-right 0.5rem
      padding-top 0.1rem
      padding-bottom 0.1rem
      
    .brt-description
      overflow hidden
      word-wrap break-word
      -webkit-line-clamp 5
      display -webkit-box
      -webkit-box-orient vertical
      text-overflow ellipsis
</style>
