<template lang="pug">
  main(:class="{ 'brt-bg-muted-faded': appRoute, 'd-flex flex-column': !isIframe, 'h-100': isIframe, 'overflowX': isIframe }")
    ActionManagers
    Header(v-if="!justThePages.includes($route.name)")
    .container-fluid.max-width(v-if='!isIframe && isAuth && onboardStep >= 3')
      .row
        .col-2
          Navbar
        .col-10
          router-view
    router-view(v-else)
    Footer(v-if="!justThePages.includes($route.name)")
</template>

<script>
import Header from "./components/header/Header.vue";
import Navbar from "./components/navbar/Navbar.vue";
import Footer from "./components/footer/Footer.vue";
import ActionManagers from "./components/managers/ActionManagers.vue";
import { auth } from "./utils/auth";
import { mapGetters } from 'vuex';

export default {
  name: "App",
  components: { Header, Navbar, Footer, ActionManagers },
  data() {
    return {
      isAuth: false,
      justThePages: ['register', 'editor'],
      isIframe: window.parent !== window,
    }
  },
  async mounted() {
    await this.setup();
    this.$ebus.$on("User:SignedOut", async () => {
      await this.$store.dispatch("company/setSelectedTeam", '');
      localStorage.removeItem("brt-selected-team");
    });

    if (window.parent !== window) {
      this.justThePages.push('login');
      this.handleIframeEvents();
    }
  },
  watch: {
    $route(to, from) {
      // if coming from login, initialize app
      if (from.name == "login" || from.name == "registerSuccess" || from.name == 'register') {
        this.setup();
      }
      this.isAuth = auth.isAuthenticated();
    },
  },
  methods: {
    handleIframeEvents() {
      this.$ievents.handleMessage('execute', 'visibilityToggle', state => {
        if (this.$route.name === 'login') {
          state === 'close' ? this.$ievents.sendMessage('execute', 'show') : this.$ievents.sendMessage('execute', 'close')
        } else {
          this.$ebus.$emit('visibilityToggle', state);
        }
      });
      if (!auth.isAuthenticated()) {
        this.$ievents.handleMessage('run', 'startFromLauncher', tut => {
            this.$ievents.sendMessage('run', 'start', tut);
        });
      }
    },
    async setup() {
      if (auth.isAuthenticated()) {
        this.isAuth = true;
        // MAIN APP LOGIC
        // vuex calls below are called in this order for a reason
        // please be careful when introducing new or reordering these
        
        // 1. get user data
        this.$store.commit('onboarding/setMinimizedState');
        this.$store.commit('onboarding/checkForExtension');
        await this.$store.dispatch("onboarding/getOnboardStepFromDB");
        await this.$store.dispatch("onboarding/checkForOnboardingModals");
        
        // 2. get user's teams
        await this.$store.dispatch("company/getTeams");
        if (this.$route.name === 'tour') {
          await this.$store.dispatch('tutorial/getTutorialFromDB', this.$route.params.id);
        }
        // 3. set one of the team as the selected one (to appear in the dropdown)
        if (this.tutorial && this.tutorial.company) {
          await this.$store.dispatch("company/setSelectedTeam", this.tutorial.company)
        } else {
          await this.$store.dispatch("company/setSelectedTeam", '');
        }

        // 4. retrieve data for the currently selected Team
        await this.$store.dispatch("company/getSelectedTeamData");
        
        // 5. update onboarding for certain conditions
        // these onboarding actions are called after the company ones above, since they depend on data from them
        await this.$store.dispatch("onboarding/updateOnboardingOnDomainsWithScriptChanges");
        await this.$store.dispatch("onboarding/advanceToStep3IfComingFromExtensionFlow");
      }
    },
  },
  computed: {
    appRoute() {
      return this.$route.name === "app";
    },
    ...mapGetters({
      onboardStep: 'onboarding/getOnboardStep',
      domainsWithScript: 'company/getDomainsWithScript',
      tutorial: 'tutorial/getSelectedTutorial'
    })
  },
};
</script>
<style lang='stylus'>
@require './stylus/typography.styl';
@require './utils/common/styleguide/brt-styleguide.styl';
@require './stylus/footer.styl';

html, body, main
  font-family: var(--sans)
  color: var(--dark)
  height: 100%
  >div, >article, >footer 
    background-color inherit

.grecaptcha-badge
  visibility hidden
.overflowX
  overflow-x hidden
.max-width
  max-width: 1500px !important
</style>
