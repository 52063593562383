<template lang="pug">
section.w-100
  div.brt-caption.brt-primary.mb-3(v-show="isEditable") step content
  #brt-content-container(ref="contentContainer" v-show='subscription.details')
    #upgrade-message(v-if="subscription.details && subscription.details.tier === 1 && isEditable")
      a.d-flex.align-items-center.link(:href="$urlConfig.getDashboardLink() + 'subscription'" target='_blank') 
        img(src='/assets/plans/purpleLock.svg')
        | Upgrade to unlock
    #editor(ref="editor" :class='{"readonly-editor": !isEditable}')
  
</template>

<script>
import Quill from 'quill';
import Validator from '../../../utils/common/utils/validator';
import { CustomLinkSanitizer } from './contentEditorOverwrites.js';

const font = Quill.import('attributors/class/font');
font.whitelist = [ 'Soleil', 'Alegreya', 'Anek-Malayalam', 'Lato', 'Montserrat', 'Open-Sans', 'Oswald', 'Poppins', 'Raleway', 'Roboto' ];
Quill.register(font, true);

Quill.register(CustomLinkSanitizer, true);

export default {
  props: {
    isEditable: { type: Boolean, default: true },
    oldContent: { type: Object, default: {} },
    subscription: { type: Object, default: function(){  return {} } },
    editMode: { type: Boolean, default: false}
  },
  data() {
    return {
      validator: {},
      contentEditor: {},
      imageMaxSize: 200 * 1024,
      isFirstTextChange: true,
      Delta: Quill.import('delta'),
      editorToolbar: {
        modules: {
          toolbar: [
            { 'font': font.whitelist },
            { 'header': [1, 2, 3, false] },
            { 'align': [] },
            'bold',
            'italic',
            'underline',
            { 'list': 'ordered'},
            { 'list': 'bullet' },
            { 'direction': 'rtl' },
            'link',
            'image',
            'video'
          ],
        },
        theme: 'snow'
      }
    }
  },
  created() {
    this.importSnowTheme();
    this.createFontStyles();
    if (!this.isEditable) {
      this.$watch('oldContent', (newVal, oldVal) => {
        if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
          this.contentEditor.setContents(newVal);
        }
      });
    }

    this.$watch('subscription', (newVal) => {
      this.checkForContentCenter(newVal);
      if (this.isEditable) {
        this.applyRestrictions();
      }
    });
  },
  mounted() {
    this.isFirstTextChange = true;
    this.validator = new Validator(this.$refs.contentContainer);
    this.initContentEditor(this.$refs.editor, this.oldContent, { isEditable: this.isEditable })
  },
  methods: {
    /**
     * This is the only method that matters, the rest are helpers
     * @param {DOMElement} DOMElement The DOM Element who will receive the editor
     * @param {Object} oldContent If the step already have content, just pass the content otherwsie pass an empty object {}
     * @param {Object} options isEditable: true|false -- initialize or NOT the toolbar
     */
    initContentEditor(DOMElement, oldContent, options) {

      if (!options.isEditable) {
        this.editorToolbar = {
          readOnly: true,
          modules: {
            toolbar: false
          },
          theme: 'snow'
        }
      }

      this.contentEditor = new Quill(DOMElement, this.editorToolbar);

      this.checkForContentCenter(this.subscription);

      if (options.isEditable) {
        this.applyRestrictions();
        this.addInteractEvents();
        this.contentEditor.root.style.height = '150px';
      } else {
        this.contentEditor.root.style.maxHeight = '300px';
        this.contentEditor.root.style.width = '95%';
      }

      if (oldContent) {
        this.contentEditor.setContents(oldContent);
      }
      this.contentEditor.root.style.fontSize = '16px';
      this.contentEditor.root.style.fontFamily = 'Soleil';
    },
    addInteractEvents() {
      this.contentEditor.container.addEventListener('click', () => {
        this.validator.removeErrorsOnFocus(this.contentEditor.container);
      });
      let timeOut;
      this.contentEditor.on('text-change', (changes, oldContent, source) => {
        if (source !== 'user') return;
        this.checkFontState();
        const delta = this.contentEditor.getContents();
        this.$parent.$emit('create:step:set:content', delta);
        if (this.isFirstTextChange && !this.editMode) {
          if (timeOut) {
            clearTimeout(timeOut);
            timeOut = null;
          }
          timeOut = setTimeout(() => {
            this.$parent.$emit('create:step:update:content', { delta: this.contentEditor.getContents() });
            this.isFirstTextChange = false;
          }, 1000);
        } else {
          this.$parent.$emit('create:step:update:content', { delta: oldContent, changes });
        }
      });
    },
    checkFontState() {
    const toolbar = this.contentEditor.getModule('toolbar');
      const fontActive = toolbar.container.querySelector('.ql-picker-label.ql-active');
      if (!fontActive) {
        const fontDefaultLabel = toolbar.container.querySelector('.ql-picker-label');
        fontDefaultLabel.dataset.value = 'Soleil';
      }
    },
    checkForContentCenter(subscription) {
      if (subscription.details && subscription.details.tier === 1) {
        this.contentEditor.root.style.textAlign = 'center';
      }
    },
    initCustomImageHandler() {
      const toolbar = this.contentEditor.getModule('toolbar');

      toolbar.addHandler('image', () => {

        const input = document.createElement('input');

        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.click();

        input.addEventListener('change', (e) => {
          const file = input.files ? input.files[0] : null;

          if (file && file.size <= this.imageMaxSize && file.type.includes('image')) {
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
              this.contentEditor.insertEmbed(this.contentEditor.getLength() - 1, 'image', fileReader.result, 'user');
              this.contentEditor.setSelection(this.contentEditor.getLength(), 0);
            }
            fileReader.readAsDataURL(file)
            return;
          }

          this.validator.invalidField(this.contentEditor.container, `Invalid image or larger than 200kb`);
          
        });
      });
    },
    applyRestrictions() {
      const textFormats = ['header', 'align', 'bold', 'italic', 'underline', 'list', 'direction', 'font', 'color', 'background'];
      const controls = this.contentEditor.getModule('toolbar').controls;
      for(let c in controls) {
        if (this.subscription.details
        && (!this.subscription.details.stepContent.textFormats && textFormats.includes(controls[c][0]) 
          || !this.subscription.details.stepContent.images && 'image' === controls[c][0]
          || !this.subscription.details.stepContent.videos && 'video' === controls[c][0]
          || !this.subscription.details.stepContent.links && 'link' === controls[c][0]
          )) {
          if (controls[c][0] === 'list') {
            this.blockEditorFormats(controls[c][1]);
          } else {
            const element = document.querySelector(`.ql-${controls[c][0]}`);
            this.blockEditorFormats(element);
          }
        }
      }

      if (this.subscription.details && !this.subscription.details.stepContent.images) {
        this.contentEditor.clipboard.addMatcher(Node.ELEMENT_NODE, this.preventImagesMatcher)
      } else {
        this.initCustomImageHandler();
        this.contentEditor.clipboard.addMatcher(Node.ELEMENT_NODE, this.imageSizeMatcher)
      }
      if (this.subscription.details && !this.subscription.details.stepContent.textFormats) {
        this.contentEditor.clipboard.addMatcher(Node.ELEMENT_NODE, this.extractPlainTextMather)
      } else {
        // this code is for @mihai.toma because he's a psycho.
        this.contentEditor.on('selection-change', (range, oldRange, source) => {
          if (range) {
            this.checkFontState();
          }
        });
      }
    },
    createFontStyles() {
      let styles = `
      @import url('https://fonts.googleapis.com/css2?family=Alegreya:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

      @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
        
      .ql-snow .ql-picker.ql-font {
        width: 125px;
      }
      `;

      for (const font of font.whitelist) {
        styles += `
          .ql-font-${font} {
            font-family: '${font.replace('-', ' ')}', sans-serif;
          }
          .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=${font}]::before,
          .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=${font}]::before {
              content: '${font}';  font-family: '${font.replace('-', ' ')}', 'sans-serif';
          }
        `;
      }

      const styleElement = document.createElement('style');
      styleElement.type = 'text/css';
      styleElement.innerHTML = styles;

      document.head.appendChild(styleElement);
    },
    importSnowTheme() {
      const link = document.createElement('link');
      link.href = 'https://cdn.quilljs.com/1.3.6/quill.snow.css'
      link.rel = 'stylesheet';
      link.type = 'text/css';
      document.head.appendChild(link)
    },
    imageSizeMatcher(node, delta) {
      const tagName = node.tagName.toLowerCase();
      const regex = /^data:image\/\w+;base64,.*$/;
      if (tagName === 'img' && regex.test(node.src.split(',')[0])) {
        const binData = atob(node.src.split(',')[1]);
        if (binData.length > this.imageMaxSize) {
          return new this.Delta();
        }
      }
      return delta;
    },
    preventImagesMatcher(node, delta) {
      const newDelta = new this.Delta();
      const tagName = node.tagName.toLowerCase();
      if (tagName === 'img') {
        return newDelta
      }
      return delta
    },
    extractPlainTextMather(node, delta) { 
      const newDelta = new this.Delta();
      const tagName = node.tagName.toLowerCase();

      if (tagName !== 'img') {
        const textDelta = delta.reduce((result, op) => {
            if (op.insert) {
              result.insert(op.insert);
            }
            return result;
        }, newDelta);
        return newDelta;
      }

      return delta;
    },
    blockEditorFormats(element) {
      element.disabled = true;
      element.style.opacity = '.1';
      element.style.pointerEvents = 'none';
    },
    isValid() {
      const content = this.contentEditor.getContents().ops;
      if (content.length === 1) {
        const string = content[0].insert.trim();
        if (string.length === 0) {
          this.validator.invalidField(this.contentEditor.container, 'Content cannot be empty')
          return false;
        }
      }
      return true
    }
  },
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl'
  @import '../../../utils/common/styleguide/functions.styl'

  
  #brt-content-container
    position relative
    #upgrade-message
      position absolute
      right 5px
    .readonly-editor
      border 0

</style>
