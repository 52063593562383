export default class IframeEvents {
  constructor(type, iframeWindow) {
    this.tails = {
      execute: { name: 'execute' },
      state: { name: 'state' },
      auth: { name: 'auth' },
      link: { name: 'link' },
      create: { name: 'create' },
      run: { name: 'run' },
    };
    this.availableTypes = {
      parent: 'parent',
      child: 'child'
    };
    this.type = type;
    this.iframeWindow = iframeWindow;

    this.setup();
  }

  setup () {
    window.addEventListener('message', (event) => {
      this.onMessage(event);
    });
    window.brtIframeEvents = this;
  }

  sendMessage (tailName, action, message, respCb) {
    const msg = {
      tail: tailName,
      action,
      message: message ? message : ''
    };

    if (respCb) {
      this.addAction(tailName, action, true, respCb);
    }

    if (this.type === this.availableTypes.parent) {
      this.iframeWindow.postMessage(msg, "*");
    } else if (this.type === this.availableTypes.child) {
      window.parent.postMessage(msg, "*");
    }
  }

  handleMessage (tailName, action, respCb) {
    this.addAction(tailName, action, false, respCb);
  }

  onMessage(event) {
    const response = event.data;
    if (response && this.tails[response.tail] && this.tails[response.tail].actions) {
      const action = this.tails[response.tail].actions[response.action];
      if (action && action.respCb) {
        const message = action.respCb(response.message);
        if (!action.temp || (action.temp && message)) {
          this.sendMessage(response.tail, response.action, message);
        }

        if (action.temp) {
          delete this.tails[response.tail].actions[response.action];
        }
      }
    }
  }

  addAction(tailName, action, temp, respCb) {
    if (respCb && this.tails[tailName]) {
      if(!this.tails[tailName].actions) {
        this.tails[tailName].actions = {};
      }
      this.tails[tailName].actions[action] = {
        temp,
        respCb
      };
    }
  }
}
