<template lang="pug">
  .row.px-4.pb-3.pt-4.position-relative
    img.green-lock(v-if='planActiveTourLimitHit' src='/assets/plans/greenLock.svg')
    .col-12.top-container.d-flex.justify-content-between
      .left-container.d-flex.align-items-center
        .url-container.d-flex.align-items-center
          span(data-testid="domain-url") {{ domain.url }}
        .status-container.ml-3
          .script-missing(v-if="domain.hasScript" data-testid="script-installed")
            img.px-1.pb-1(src="/assets/misc/greenCheck.svg" alt="green checkmark" width="24")
            span.small-copy.brt-secondary-dark Script Installed
          .script-detected.d-flex.align-items-center(v-else data-testid="script-missing")
            img.ml-2(src='/assets/misc/alert.svg' width="16")
            router-link.danger-link(to="/dashboard").small-copy.brt-danger-dark.ml-1.
              Install the script on this domain to gain full power
      .right-container.d-flex.align-items-center(:class="{'mr-5' : planActiveTourLimitHit }")
        .small-copy.brt-tooltip.brt-tooltip-dashed.brt-muted-dark.mr-3
          span Display Launch Widget on Domain 
          .brt-tooltiptext.brt-tooltip-top
            p.mb-1 {{ tooltip.launchWidget.explained.primary }}
            p.brt-muted-bright.font-italic.mb-0 {{ tooltip.launchWidget.explained.secondary }}
        Toggle-Switch(
          v-model="domain.launcher.show"
          :disabled="!(domain.hasScript && domain.tutorials.length >= 1)"
          :titleEnabled="tooltip.launchWidget.enabled"
          :titleDisabled="tooltip.launchWidget.disabled"
          @change="toggleLauncherVisibility()"
          data-testid="launcher-toggle")
</template>

<script>
import ToggleSwitch from '../../utils/common/vuecomps/ToggleSwitch.vue';
import { tooltip } from '../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  props: {
    domain: { type: Object, required: true },
  },
  components: { ToggleSwitch },
  data() {
    return {
      tooltip
    }
  },
  methods: {
    toggleLauncherVisibility() {
      this.$ebus.$emit('company/toggleLauncherVisibility', this.domain)
    }
  },
  computed: {
    ...mapGetters({
      planActiveTourLimitHit: 'company/planActiveTourLimitHit'
    })
  }
};
</script>