<template lang="pug">
  .tutorial-options(title="More Options")
    Dropdown(:type="'options'" :items="dropdownItems" data-testid="dropdown-options")
</template>

<script>
import Dropdown from '../aspect/Dropdown.vue';
import Modal from '../aspect/Modal.vue';

import clipboard from '../../utils/common/utils/clipboard.js';
import { handleError } from '../../mixins/handleError.js';
import { runTutorial } from '../../mixins/runTutorial.js';

import { TYPE } from "vue-toastification";

export default {
  components: { Dropdown, Modal },
  mixins: [ handleError, runTutorial],
  props: {
    tutorial: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      dropdownItems: [
        {
          name: 'Test Tour',
          cb: () => { this.runTutorial(this.tutorial)}
        },
        {
          name: 'Duplicate Tour',
          cb: () => { this.duplicateTutorial()}
        },
        {
          name: 'Clear tour errors',
          cb: () => { this.clearTourErrors()}
        },
        {
          name: 'Copy Tour ID',
          cb: () => { this.copyID() }
        },
        {
          name: 'Delete Tour',
          type: 'danger',
          cb: () => { this.deleteTour() }
        },
      ],
    };
  },
  methods: {
    copyID() {
      clipboard.copyFromValue(this.tutorial._id);
      this.$toast("Tour ID copied to clipboard.", { type: TYPE.SUCCESS });
    },
    clearTourErrors() {
      this.$ebus.$emit("tour/clearErrors", this.tutorial);
    },
    duplicateTutorial() {
      this.$ebus.$emit("tour/duplicateTour", this.tutorial);
    },
    changeTutorialDomain() {
      this.$ebus.$emit("tour/changeTourDomain", this.tutorial);
    },
    deleteTour() {
      this.$ebus.$emit("tour/delete", {
        tutorial: this.tutorial,
        confirm: true
      });
    }
  }
};
</script>
