<template lang="pug">
  .brt-card.mt-4.position-relative
    img.green-lock(v-if='!planHasSubdomains' src='/assets/plans/greenLock.svg')
    .d-flex.justify-content-between.align-items-center
      p.brt-caption.mb-3 Run on all subdomains
      button.small-cta.mr-5.mb-3(v-if="!planHasSubdomains" @click="upgradePlan()").
        Upgrade plan to unlock
    .d-flex.justify-content-between.align-items-center
      p.small-copy.brt-muted-dark.my-2 This option allows to run the tour on all subdomains.
      .toggle-container.d-flex.justify-content-between
        Toggle-Switch(
          v-model="tutorial.ignoreSubdomain"
          @change="toggleIgnoreSubdomain()"
          :disabled="!isCreatedOnSubdomain() || !planHasSubdomains"
          data-testid="ignoreDomain-toggle")
        Icon-Tooltip(
          :text="ignoreSubdomainTooltip"
          direction='left'
          :offsetY="this.isCreatedOnSubdomain() ? -0.5 : 0.8"
        )
</template>

<script>
import ToggleSwitch from "../../../utils/common/vuecomps/ToggleSwitch.vue";
import IconTooltip from "../../icons/IconTooltip.vue";
import { tooltip } from "../../../jsons/tooltips.json";
import { mapGetters } from "vuex";

export default {
  components: { ToggleSwitch, IconTooltip },
  data() {
    return {
      tooltip
    };
  },
  methods: {
    toggleIgnoreSubdomain() {
      this.$ebus.$emit("tour/toggleIgnoreSubdomain", this.tutorial);
    },
    isCreatedOnSubdomain() {
      return this.tutorial.domain && this.tutorial.domain.split('.').length > 2;
    },
    upgradePlan() {
      this.$router.push({ path: '/subscription' });
    },
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      domain: "tutorial/getDomain",
      planHasSubdomains: 'company/planHasSubdomains',
    }),
    ignoreSubdomainTooltip() {
      if (this.isCreatedOnSubdomain()) {
        return this.tutorial.ignoreSubdomain ? 
          this.tooltip.tour.ignoreSubdomain.off : this.tooltip.tour.ignoreSubdomain.on;
      } else {
        return this.tooltip.tour.ignoreSubdomain.notOnSubdomain;
      }
    } 
  }
};
</script>

<style lang="stylus" scoped>
.toggle-container
  min-width 6.5rem
.bg-image
  background-image url('/assets/language/language.svg')
  background-repeat no-repeat
  background-size 35%
  background-position-x 90%
  background-position-y 50%
  @media (max-width: 1200px)
    background-image none
.green-lock
  right: 1rem
</style>
