import axios from 'axios';
import { EventBus } from './eventbus';
import { cBus } from './custombus';
import B64Unicode from './common/utils/b64Unicode';

/* eslint-disable class-methods-use-this */

class Auth {
  /**
   * Class constructor.
   */
  constructor(ebus) {
    this.ebus = ebus;
    this.cbus = cBus;
    this.b64Unicode = new B64Unicode();
    if (this.token) {
      axios.defaults.headers.common = { Authorization: `bearer ${this.token}` };
    }
  }

  /**
   * Save the token to Local Storage.
   *
   * @param {String} token
   */
  set token(token) {
    axios.defaults.headers.common = { Authorization: `bearer ${token}` };
    localStorage.setItem(btoa('token'), token);
    this.cbus.emitTknToExt(token);
  }

  /**
   * Sets the profile data.
   *
   * @param { Object } data contains profile info (username, id, email)
   */
  set profile(data) {
    const profile = JSON.parse(this.b64Unicode.decode(data));

    localStorage.setItem(btoa('username'), profile.username);
    localStorage.setItem(btoa('id'), profile.id);
    localStorage.setItem(btoa('email'), profile.email);
  }

  /**
   * @return {Object} containing the logged user's username, id and email
   */
  get profile() {
    return {
      username: this.username,
      id: this.id,
      email: this.email,
      hasExtension: this.hasExtension(),
      isLogged: this.isAuthenticated()
    }
  }
  /**
   * Retrieves the profile name.
   *
   * @return { String } user name
   */
  get username() {
    return localStorage.getItem(btoa('username'));
  }

  /**
   * Retrieves the profile id.
   *
   * @return { String} user id
   */
  get id() {
    return localStorage.getItem(btoa('id'));
  }

  /**
   * Retrieves the profile email.
   *
   * @return { String} user email
   */
  get email() {
    return localStorage.getItem(btoa('email'));
  }

  /**
   * Get the token from Local Storage.
   *
   * @returns {String}
   */
  get token() {
    const token = localStorage.getItem(btoa('token'));

    if (!token) {
      return null;
    }

    return token;
  }

  /**
   * Remove the token from Local Storage.
   */
  destroyToken() {
    axios.defaults.headers.common = {};
    localStorage.removeItem(btoa('name'));
    localStorage.removeItem(btoa('token'));
    localStorage.removeItem(btoa('id'));
    localStorage.removeItem(btoa('username'));
    localStorage.removeItem(btoa('email'));
    localStorage.removeItem('brt-team-invite');
    this.cbus.unsetTknInExt();
  }

  /**
   *  Sign out from account
   */
  signOut() {
    this.destroyToken();
    this.ebus.$emit('User:SignedOut');
  }

  /**
   * Verify if the user it's authenticated or not.
   *
   * @returns {Boolean}
   */
  isAuthenticated() {
    return !!this.token;
  }

  /**
   * retrieve token that checks if the user has the extension
   */
  hasExtension() {
    if(sessionStorage.getItem('brtState')) {
      return true;
    } else {
      return false
    }
  }
}

export const auth = new Auth(EventBus);
