<template lang="pug">
  #spinner.brt-primary
    .spinner-border(role='status' v-if="type == 'inline'" data-testid='spinnerInlineMode')
      span.sr-only Loading...
    .spinner-brt-modal(v-if="type !== 'inline'" :class="{'full-page-spinner': type === 'full'}")
      .spinner-border(
        :class="{\
          'size-xl': size == 'xl',\
          'size-md': size == 'md',\
          'size-sm': size == 'sm'\
        }"
        role='status'
        data-testid='spinnerModalMode')
        span.sr-only Loading...
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'modal',
    },
    size: {
      type: String,
      default: 'xl'
    }
  },
};
</script>
<style lang="stylus" scoped>
.spinner-brt-modal
  display flex
  z-index 10
  justify-content center
  flex-direction column
  align-items center
.size-xl
  width 10rem
  height 10rem
.size-md
  width 6rem
  height 6rem
.size-sm
  width 3rem
  height 3rem
.full-page-spinner
  position absolute
  width 100%
  height 100%
  top 0
  display flex
  justify-content center
  background white
  align-items center
</style>
