import { handleError } from "../../../mixins/handleError";

export default {
  mixins: [handleError],
  methods: {
    async updateTourOptions(tutorial, newOptions) {
      const query = `${this.$config.default.apiUrl}/tutorial/${tutorial._id}`;
      const updatedOptions = tutorial.options;
      for (const [key] of Object.entries(newOptions)) {
        updatedOptions[key] = newOptions[key];
      }
      try {
        await this.$http.put(query, { options: updatedOptions });
      } catch (err) {
        this.handleError(err);
      }
    },
  }
};
