<template lang="pug">
.brt-tabs-container.d-flex.flex-column.sticky-top(v-if="showNavbar")
  .brt-tab(@click="changeTab(tabs.gettingStarted)" 
    :class="{'brt-active-tab': this.activeTab===tabs.gettingStarted}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/rocket.svg#Layer_1")
    span Getting Started

  .brt-tab(@click="changeTab(tabs.dashboard)" 
    :class="{'brt-active-tab': this.activeTab===tabs.dashboard}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/dashboard.svg#Layer_1")
    span Dashboard

  .brt-tab(@click="changeTab(tabs.experiences)" 
    :class="{'brt-active-tab': (this.activeTab===tabs.experiences || this.activeTab===tabs.tour)}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/productTours.svg#Layer_1")
    span Experiences

  .brt-tab(@click="changeTab(tabs.team)"
    :class="{'brt-active-tab': this.activeTab===tabs.team}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/teamDetails.svg#Layer_1")
    span Team Details
  
  .brt-tab(@click="changeTab(tabs.themes)"
    :class="{'brt-active-tab': (this.activeTab===tabs.themes || this.activeTab===tabs.themeCreation)}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/themeIcon.svg#Layer_1")
    span UI Themes
  
  .brt-tab(@click="changeTab(tabs.subscription)"
    :class="{'brt-active-tab': this.activeTab===tabs.subscription}")
    svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
      use(href="/assets/misc/subscription.svg#Layer_1")
    span Subscription
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      activeTab: 'dashboard',
      tabs: {
        gettingStarted: 'gettingStarted',
        dashboard: 'dashboard',
        experiences: 'experiences',
        team: 'team',
        tour: 'tour',
        themes: 'themes',
        themeCreation: 'theme',
        subscription: 'subscription'
      },
      currentPath: document.location.pathname,
    }
  },
  watch: {
    $route() {
      if (this.currentPath !== document.location.pathname) {
        this.setNavbarTab();
        this.currentPath = document.location.pathname;
      }
    }
  },
  mounted() {
    this.setNavbarTab();
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
      this.$router
        .push({ path: `/${this.activeTab}` })
        .catch((err)=>{
          if (err.name != "NavigationDuplicated") {
            console.log(err)
          }
      });
    },
    setNavbarTab() {
      const tab = this.$route.name;
      if (tab && this.tabs.hasOwnProperty(tab) && tab !== 'tour'
       && tab !== 'themeCreation') {
        this.changeTab(tab);
      }
    },
  },
  computed: {
    showNavbar() {
      const cond1 = this.onboardStep >= 3;
      const cond2 =  this.tabs.hasOwnProperty(this.$route.name);

      return cond1 && cond2
    },
    ...mapGetters({
      onboardStep: 'onboarding/getOnboardStep'
    })
  }
}
</script>
<style lang='stylus' scoped>
  @require '../../utils/common/styleguide/colors.styl'

  .brt-tabs-container
    border-radius 10px
    box-shadow: 0px 3px 6px #41009829
    max-width 8rem
    margin-top 0
    margin-left auto
    border-bottom none
    &.sticky-top
      top 2rem
    .brt-tab
      display flex
      flex-direction column
      align-items center
      padding 0 .5rem
      margin 1.3rem 0
      border-left 0.1875rem solid transparent
      span
        display inline-block
        text-align center
        font-size 14px
      &:first-child
        margin-top 3rem
      &:last-child
        margin-bottom 3rem
      .brt-tab-icon
        stroke: $brt-gray-dark
    .brt-active-tab
      border-left-color $brt-purple-bright;
      border-bottom none
      .brt-tab-icon
        stroke: $brt-purple-bright
</style>
