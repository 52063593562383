<template lang="pug">
  .manager-container
</template>

<script>
import updateTourStep from '../mixins/updateTourStep.js';
export default {
  mixins: [updateTourStep],
  mounted() {
    this.$ebus.$on("tour/updateStepNavigation", data => {
      this.updateTourStepNavigation(data.tutorial, data.index, data.hasNext);
    });
  },
  methods: {
    async updateTourStepNavigation(tutorial, index, hasNext) {
      const newStep = tutorial.steps[index];
      newStep.hasNext = hasNext;
      
      // if updating navigation to be by element click, also set block elem to true
      if (newStep.hasNext === false) {
        newStep.blockElem = false;
      }
      await this.updateTourStep(tutorial, index, newStep, "navigation");
    },
  }
};
</script>
