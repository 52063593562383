<template lang='pug'>
  .manager-container.mt-3
    Team-Members-List(v-if="teamNotEmpty")
    Empty-Team-List(v-else)
</template>

<script>
import TeamMembersList from './TeamMembersList.vue';
import EmptyTeamList from './EmptyTeamList.vue';
import { mapGetters } from 'vuex';

export default {
  components: { TeamMembersList, EmptyTeamList },
  computed: {
    teamNotEmpty () {
      return this.teamMembersCount > 1
    },
    ...mapGetters({
      teamMembersCount: 'company/getTeamMembersCount'
    })
  }
}
</script>