<template lang="pug">
  .edit-controls-container(:class="{'invisible': !show}")
    Spinner(v-if="loading" type='inline')
    .confirm-container(v-else)
      button.small-cta.py-2.mr-1(type='submit' @click="saveCb()").
        Save
      button.secondary-button.py-2(@click="cancelCb()").
        Cancel
</template>

<script>
import Spinner from '../../components/aspect/Spinner.vue';

export default {
  components: { Spinner },
  props: {
    loading: Boolean,
    takeSpace: Boolean,
    show: Boolean,
    saveSuccessText: {
      type: String,
      default: 'Updated'
    },
    saveCb: Function,
    cancelCb: Function,
  },
};
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/buttons.styl'
  @require '../../utils/common/styleguide/functions.styl'

  .edit-controls-container
    display flex
    justify-content start
    text-align center
    width fit-content
    .confirm-container
      button
        padding pxToRem(4) pxToRem(12)
</style>
