<template lang="pug">
  .container
    .dark-background
    .brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' title="Close Pop-up" @click="close" data-testid="close-button")
        span(aria-hidden='true') &times;
      .inner-container.py-3
        .pop-up-emoji.mt-4.mb-3 🎉
        .big-copy.mb-5.font-semibold(data-testid="welcome-message") Welcome, {{ username }}!

        .body-copy.brt-muted-dark.mb-1 Complete a few steps to get to full power.
        .body-copy.brt-muted-dark Don’t worry, we’ll guide you all the way.
        
        button.cta.mt-5.pt-3.mb-3(@click="close" title="Start new user onboarding" data-testid="begin-button") Begin
</template>

<script>
import { scrollLock } from '../../../mixins/scrollLock.js';
import { auth } from "../../../utils/auth";

import { mapGetters } from 'vuex';

export default {
  mixins: [scrollLock],
  methods: {
    close() {
      if (this.extensionDetected) {
        this.$store.dispatch('onboarding/updateOnboardStep', 2)
      } else {
        this.$store.dispatch('onboarding/updateOnboardStep', 1)
      }
      this.$store.commit('onboarding/toggleModalWelcome', false)
    }
  },
  computed: {
    username() {
      return auth.username;
    },
    ...mapGetters({
      extensionDetected: 'onboarding/getExtensionDetected'
    })
  }
}
</script>
