<template lang="pug">
Modal(v-if="show"
  emoji="🗑"
  primaryOFF="Are you sure you want to DELETE the following tour?"
  confirmTextOFF="Delete"
  :highlight="tutorial.name"
  :confirmCb="deleteTour"
  :cancelCb="() => { this.show = false }"
)
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import updateTeamTours from '../mixins/updateTeamTours'
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTeamTours],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      show: false,
      withConfirm: true,
    };
  },
  mounted() {
    this.$ebus.$on("tour/delete", data => {
      this.tutorial = data.tutorial;
      this.withConfirm = data.confirm;
      if (this.withConfirm) {
        this.show = true;
      } else {
        this.deleteTour();
      }
    });
  },
  methods: {
    async deleteTour() {
      try {
        const route = `${this.$config.default.apiUrl}/tutorial/${this.tutorial._id}`;
        await this.$http.delete(route);
        await this.$store.dispatch("company/getSelectedTeamTours");
        await this.$store.dispatch("company/getSelectedTeamDetails");

        this.show = false;
        this.$toast("Tour deleted successfully.", { type: TYPE.SUCCESS });

        // if delete was performed without the confirm (it came from delete last step)
        if (!this.withConfirm) {
          this.$router.push({ path: '/experiences'});
        }
      } catch (err) {
        this.handleError(err);
        this.$toast("Error deleting tour. Please retry", { type: TYPE.ERROR });
      }
    },
  }
};
</script>
