<template lang="pug">
.brt-card.h-auto
  .step-description
    Step-Description(:stepContent="step.content", :index="index")

  .line-splitter

  .step-next-btn-text
    p.brt-caption.
      Next Step Button Text
    Step-Next-Btn-Text(:nextBtnText="step.nextButtonText" :index="index")

  .line-splitter

  .background-overlay
    p.brt-caption.
      Background Overlay
    .d-flex.justify-content-between
      span.brt-muted-dark.small-copy Display a gray overlay behind the modal.
      Step-Has-Background(:hasBackground="step.hasBackground" :index="index")

  .line-splitter

  .optional-step-container
    p.brt-caption.
      Step Type
    Step-Type(:optional="step.optional" :index="index")

  .line-splitter

  .step-delete-container
    p.brt-caption.
      Delete Step
    Step-Delete(:index="index")
</template>

<script>
import StepDescription from "./StepDescription.vue";
import StepDelete from "./StepDelete.vue";
import StepType from "./StepType.vue";
import StepNavigation from "./StepNavigation.vue";
import StepNextBtnText from "./StepNextBtnText.vue";
import StepHasBackground from "./StepHasBackground.vue";

export default {
  components: {
    StepDescription,
    StepDelete,
    StepType,
    StepNavigation,
    StepHasBackground,
    StepNextBtnText
  },
  props: {
    step: Object,
    index: Number,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang='stylus' scoped>
@require '../../../utils/common/styleguide/colors.styl';
@require '../../../utils/common/styleguide/functions.styl';

.brt-card {
  position: sticky;
  top: 2rem;
}

.line-splitter {
  border-bottom: 1px solid $brt-muted;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.brt-caption {
  font-weight: 400;
  color: $brt-primary;
}
</style>