<template lang="pug">
  #domains-container
    ul.domain-list.mb-0.d-flex.align-items-center.pl-0
      li.domain-list-item(v-for="domain in domainsWithScript").
        {{ domain.url }}
      li.pb-0.ml-2
        button.secondary-button.d-inline(@click="detectScript()" title="Check for list of domains with your script" data-testid="refresh-list").
          Refresh List
</template>

<script>
import { checkForDomainsWithScript } from "../mixins/checkForDomainsWithScript.js";

export default {
  mixins: [checkForDomainsWithScript],
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'

  .domain-list
    display flex
    flex-wrap wrap
    list-style-type none

  .domain-list-item
    margin-right 0.5rem
    padding-left 1rem
    padding-right 1rem
    padding-top 0.5rem
    padding-bottom 0.5rem
    border-radius 2rem
    background-color $brt-secondary-faded
</style>
