<template lang="pug">
  #dashboard.container(data-testid="dashboard")
    Plan-Limit.mb-4
    .bottom-components(v-if="showDashboardComponents")
      .row
        .col-12.mb-3
          Capterra-Banner
        .col-12.col-xl-5
          Subscription-Status
        .col-12.col-xl-7
          Analytics-Overview
      .row
        .col-12.col-xl-6.mt-3
          Team-Overview
        .col-12.col-xl-6.mt-3
          Help-Links
</template>

<script>
import PlanLimit from "../components/plans/PlanLimit.vue";
import SubscriptionStatus from "../components/dashboard/SubscriptionStatus.vue";
import AnalyticsOverview from "../components/dashboard/AnalyticsOverview.vue";
import TeamOverview from "../components/dashboard/TeamOverview.vue";
import HelpLinks from "../components/dashboard/HelpLinks.vue";
import CapterraBanner from "../components/dashboard/CapterraBanner.vue"
import { mapGetters } from "vuex";

import { handleError } from "../mixins/handleError.js";

export default {
  name: "Dashboard",
  metaInfo: {
    title: "Your Bytes Route web application dashboard",
    meta: [
      { name: "description", content: "Create and manage your product tours" }
    ]
  },
  mixins: [handleError],
  components: {
    PlanLimit,
    SubscriptionStatus,
    AnalyticsOverview,
    TeamOverview,
    HelpLinks,
    CapterraBanner
  },
  mounted() {
    if (this.onboardStep < 3) {
      this.$router.push({ path: '/gettingStarted' });
    }
  },
  computed: {
    showDashboardComponents() {
      return this.onboardStep >= 3;
    },
    ...mapGetters({
      onboardStep: "onboarding/getOnboardStep"
    })
  }
};
</script>
