<template lang='pug'>
  .launcher-to-do.body-copy
    .left-container.mr-2
      img(v-if="completed"
        src="/assets/misc/greenCheck.svg"
        alt="green checkmark"
        width="24"
        height="100%")
      span.brt-pill.brt-white.brt-bg-secondary-dark(v-else).
        {{ index + 1 }}
    p.mb-0(:class="{'strikethrough': completed}")
      span {{ step }} on 
      span.font-weight-bold(v-if="specific") {{ domainURL }}.
      span(v-else) your domain.
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    specific: Boolean,
    step: String,
    index: Number,
    completed: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters({
      domainURL: 'company/getDomainUrl'
    })
  }
}
</script>


<style lang='stylus' scoped>
  .launcher-to-do
    display flex
    justify-content: start
</style>
