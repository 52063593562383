export const dateFormatter = {
  methods: {
    formatDate(rawDate) {
      if (rawDate) {
        const date = new Date(rawDate);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const displayedDate = `${month} ${day}, ${year}`;
        return displayedDate;
      }
      return false;
    }
  }
};
