<template lang="pug">
.container(data-testid="register-success-view")
  .row.justify-content-center
    .col-10.col-sm-8.col-lg-6.col-xl-4.brt-dark.mb-4
      h3.mt-5.pt-5.mb-0 Kudos! &#x1F389
      section
        p.small-copy.mt-2(
          :class="confirmationEmail.reqFailed ? 'brt-danger' : 'brt-primary'"
        ) {{ confirmationEmail.feedbackMessage }}
        p(v-show="confirmationEmail.status !== 'resent'") You haven't received any email? Please also check your spam folder or try to#[button.link(@click='trySendAccountConfirmationEmail(true)') resend]the confirmation email
        router-link.small-cta(v-show="confirmationEmail.status === 'confirmed'" to="/login") Login

</template>

<script>
import STORAGE from '../../utils/common/utils/storage.js';

export default {
  name: 'AccountConfirmation',
  metaInfo: {
    title: 'Account confirmation | Bytes Route',
    meta: [{
      name: 'description',
      content: 'Confirm your Bytes Route account'
    }]
  },
  data() {
    return {
      confirmationEmail: {
        reqFailed: false,
        feedbackMessage: null,
        status: null
      }
    }
  },
  mounted() {
    const savedEmail = STORAGE.get(this.$route.params.email);
    if (savedEmail) {
      this.confirmationEmail = STORAGE.get(this.$route.params.email);
    }
    this.trySendAccountConfirmationEmail();
  },
  methods: {
    resetConfirmationEmail() {
      this.confirmationEmail = {
        reqFailed: false,
        feedbackMessage: null,
        status: null
      };
      STORAGE.delete(this.$route.params.email);
    },
    async trySendAccountConfirmationEmail(resend = false) {
      if (resend) {
        this.resetConfirmationEmail();
      }
      if (this.$route.params.email && this.confirmationEmail.status !== 'resent') {
        try {
          const response = await this.$http.post(`${this.$config.default.apiUrl}/user/accountConfirmation`, {
            email: this.$route.params.email,
          });
          if (response.status === 200) {
            this.confirmationEmail = {
              status: resend ? 'resent': 'sent',
              feedbackMessage: response.data.message,
              reqFailed: false
            }
            STORAGE.save(this.confirmationEmail, this.$route.params.email);
          }
        } catch (e) {
          console.error(e.response.data.message);
          this.confirmationEmail = {
            status: 'failed',
            feedbackMessage: e.response.data.message,
            reqFailed: true
          }
          STORAGE.save(this.confirmationEmail, this.$route.params.email);
        }
      }
    },
  }
}
</script>