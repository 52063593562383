<template lang="pug">
.brt-card.d-flex.flex-column.justify-content-between
  #text-container.small-copy.brt-muted-dark.mb-4
    p.brt-caption Test TOUR
    p.my-3 Testing the tour will open a new tab where your tour will be displayed.

  #run-container.d-block.d-sm-flex.align-items-center.justify-content-between.pt-1
    #run-button-container
      button.w-100.cta.mb-2.mb-sm-0(:disabled="!userHasExtension" @click="runTutorial(tutorial)" data-testid="run-tutorial").
        Test tour
    #extension-not-installed.p-0.ml-sm-3(v-if="!userHasExtension" data-testid="extension-link")
      a.link.small-copy(:href="extensionURL" target='_blank' rel='noreferrer noopener').
        To run this tutorial you first need to install our Chrome Extension.
</template>

<script>
import { runTutorial } from "../../../mixins/runTutorial.js";
import { mapGetters } from "vuex";

export default {
  mixins: [runTutorial],
  data() {
    return {
      extensionURL: this.$config.default.extUrl,
      userHasExtension: false
    };
  },
  created() {
    this.userHasExtension = sessionStorage.getItem("brtState") ? true : false;
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
    })
  }
};
</script>

<style lang="stylus" scoped>
#extension-not-installed
  width 100%
  a
    color $brt-white
    border-bottom-color $brt-white
  @media (min-width: 577px)
    max-width 22rem
</style>
