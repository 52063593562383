<template lang="pug">
  .outer-container
    .d-flex.justify-content-between.align-items-center
      .body-copy Autorun on page start
      .toggle-container.d-flex.justify-content-between
        Toggle-Switch(
          v-model="tutorial.options.autoRun"
          :titleEnabled="tooltip.tour.autoRun.enabled"
          :titleDisabled="tooltip.tour.autoRun.disabled"
          :disabled="!domain.hasScript"
          @change="toggleAutoRun()"
          data-testid="autorun-toggle")
        Icon-Tooltip(:text="tooltip.tour.autoRun.explained")
</template>

<script>
import ToggleSwitch from "../../../utils/common/vuecomps/ToggleSwitch.vue";
import IconTooltip from "../../icons/IconTooltip.vue";
import { tooltip } from "../../../jsons/tooltips.json";
import { mapGetters } from "vuex";

export default {
  components: { ToggleSwitch, IconTooltip },
  data() {
    return {
      tooltip
    };
  },
  methods: {
    toggleAutoRun() {
      this.$ebus.$emit("tour/toggleAutoRun", this.tutorial);
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial",
      domain: "tutorial/getDomain"
    })
  }
};
</script>

<style lang="stylus" scoped>
.toggle-container
  min-width 6.5rem
</style>
