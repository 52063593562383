<template lang="pug">
  .switch-container.text-center.d-flex.flex-column.flex-xl-row.align-items-center.align-items-xl-baseline(data-testid="toggle-switch")
    input.toggle(
      @click.prevent="handleClick"
      type='checkbox' 
      :title="title"
      :checked="value"
      :disabled="disabled"
      data-testid="toggle-input")
    .label-text.small-copy.ml-1.mt-1.mt-xl-0(:class="{'brt-primary': value, 'brt-muted': !value, 'invisible': disabled}" data-testid="label")
      span {{ stateText }}
</template>

<script>
  /**
  * DOCUMENTATION
  * @author Silviu Iordache
  * Description =  A reusable Vue component for a toggle-able switch button
  * Properties
  * @prop {Boolean} value The calling component should bind to the v-model the variable that dictates the initial checked/unchecked state of the toggle
  * (can be state from DB)
  * @prop {String} titleEnabled = the title text that appears on hover.
  * @prop {String} titleDisabled = title text that appears on hover when the input is disabled
  * @prop {String} onText (optional)= the text that appears below the label when the state is ON
  * @prop {String} offText (optional)= the text that appears below the label when the state is OFF
  * @prop {Boolean} disabled (optional) = boolean that dictates if the input is disabled
  * @prop {@change} @change = in the calling component, bind to the @change event the function that you want to execute
  */
  export default {
    props: {
      value: {
        type: Boolean,
        required: true,
        default: false
      },
      titleEnabled: {
        type: String,
        default: 'Toggle ON/OFF'
      },
      titleDisabled: {
        type: String,
        default: 'Cannot toggle'
      },
      onText: {
        type: String,
        default: 'ON'
      },
      offText: {
        type: String,
        default: 'OFF'
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      stateText() {
        return this.value ? this.onText : this.offText;
      },
      title() {
        return this.disabled ? this.titleDisabled : this.titleEnabled
      }
    },
    methods: {
      handleClick () {
        setTimeout(() => { this.$emit('change'); })
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @require '../styleguide/colors.styl'
  @require '../styleguide/functions.styl'

  .switch-container
    .toggle
      -webkit-appearance none
      appearance none
      width 2.6rem
      height 1.2rem
      background-color $brt-muted
      border-radius 3rem
      position relative
      cursor pointer
      outline none
      transition all 0.2s ease-in-out
      &:checked
        background-color $brt-primary
        &:after
          left calc(100% - 1.2rem)
      &:disabled
        background-color $brt-muted-bright
        cursor not-allowed
      &:after
        position absolute
        content ""
        width 1.2rem
        height 1.2rem
        border-radius 50%
        background-color $brt-white
        box-shadow 0 0 0.25rem rgba(0, 0, 0, 0.3)
        transform scale(0.6)
        left 0
        transition all 0.2s ease-in-out
    .label-text
      cursor default
</style>
