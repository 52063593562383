<template lang="pug">
  section
    editor-header(v-if='isIframe')
    .container
      .row.mt-5.justify-content-md-center
        .col-12.col-md-8.col-lg-6.col-xl-4.brt-dark
          h1.h3.mb-4(v-if='!isIframe') Log in
          img(v-if='isIframe' src='/assets/doodle/final1-01.svg')
          form.brt-form
            .brt-input-group(@mousedown="emailValidOnClient=true, serverValidationFailed=false")
              input#email.brt-input(
                autocomplete='username'
                type='text'
                name='email'
                v-model='email'
                data-testid="emailInput"
                :class="{'brt-input-invalid': !emailValidOnClient}"
                required)
              label.brt-label(for='email') Email address
              span.small-copy.brt-input-error(:class="{'hide': emailValidOnClient}") {{ emailErr }}

            .brt-input-group(@mousedown="passwordValidOnClient=true, serverValidationFailed=false")
              input#current-password.brt-input(
                autocomplete='current-password'
                name='current-password'
                type='password'
                v-model='password'
                data-testid="passInput"
                :class="{'brt-input-invalid': !passwordValidOnClient }"
                required)
              label.brt-label(for='current-password') Password
              span.small-copy.brt-input-error(:class="{'hide': passwordValidOnClient}") {{ passwordErr }}

            .small-copy.brt-danger-dark.mb-2(
              v-model='serverValidationError'
              :class="{'hide': !serverValidationFailed}"
              data-testid="errMessage").
                {{ serverValidationError }}

            button.cta.mb-3(:class="isIframe ? 'w-100' : ''" type='submit' @click.prevent='login' data-testid="loginBtn")
              span.spinner-border(v-if='loginRequestInProgress' role='status')
                span.sr-only Loading...
              span(v-else) Login

            .row(v-if='isIframe')
              .col-5.text-left
                a.link.mx-1.small-copy(@click='openForgot') Forgot password?
              .col-7.text-right
                a.link.mx-1.small-copy(@click='openRegister') Register for a free account
          
            section.mt-5(v-else)
              .body-copy.mb-2
                | New user?
                router-link.link.mx-1(v-if='!isIframe' :to='{ path: "/register"}' data-testid="goToRegister") Register now
                | it's fast, easy & free
              .body-copy
                router-link.link(v-if='!isIframe' :to='{ path: "/passResetReq"}') Forgot password?

          .separator

          #google-button-wrap.d-flex.justify-content-center
            #google-signin-button

</template>


<script>
import { auth } from '../../utils/auth';
import { handleError } from '../../mixins/handleError.js';
import { emailValidator } from '../../mixins/emailValidator.js';
import { googleIdentity } from '../../mixins/googleIdentity.js';
import editorHeader from '../../editor/components/reusable/editorHeader.vue'

export default {
  mixins: [emailValidator, handleError, googleIdentity],
  metaInfo: {
    title: 'Log in to your Bytes Route account',
    meta: [{ name: 'description', content: 'Authenticate in the Bytes Route web-application.' }]
  },
  data: function () {
    return {
      isIframe: window.parent !== window,
      email: '',
      emailErr: 'defaultError',
      emailErrEmpty: 'Please type an email',
      emailErrInvalid: 'Please provide a valid email',
      emailValidOnClient: true,

      password: '',
      passwordErr: 'defaultError',
      passwordErrEmpty: 'Please type a valid password',
      passwordErrInvalid: 'Please provide a valid password',
      passwordValidOnClient: true,

      serverValidationFailed: false,
      serverValidationError: 'Incorrect email and/or password',

      loginRequestInProgress: false,
    };
  },
  components: {
    editorHeader
  },
  mounted() {
    // this method below will initialize google identity service 
    // check googleIdentity mixin
    this.addGoogleIdentityScript();
    this.$ebus.$on('login:google:failed', (errorMessage) => {
      this.serverValidationFailed = true;
      this.serverValidationError = errorMessage;
    });
  },
  methods: {
    async login() {
      if (this.credentialsValidOnClient()) {
        this.sendLoginRequest();
      }
    },
    credentialsValidOnClient() {
      this.serverValidationFailed = false;
      this.emailValidOnClient = this.validateEmailOnClient();
      this.passwordValidOnClient = this.validatePassOnClient();
      return (this.emailValidOnClient && this.passwordValidOnClient);
    },
    validateEmailOnClient() {
      if (this.email.length === 0) {
        this.emailErr = this.emailErrEmpty;
        return false;
      }
      if (this.validEmail(this.email) === false) {
        this.emailErr = this.emailErrInvalid;
        return false;
      }
      return true;
    },
    validatePassOnClient() {
      if(this.password.length === 0) {
        this.passwordErr = this.passwordErrEmpty;
        return false;
      }
      if (this.password.length < 5) {
        this.passwordErr = this.passwordErrInvalid;
        return false;
      }
      return true;
    },
    async sendLoginRequest () {
      this.loginRequestInProgress = true;
      const url = `${this.$config.default.apiUrl}/user/login`;
      try {
        const res = await this.$http.post(url, {
          email: this.email,
          password: this.password,
        });
        if (res.status === 200) {
          this.serverValidationFailed = false;

          auth.token = res.data.credentials.token;
          auth.profile = auth.token.split('.')[1];

          await this.checkForTeamInvite();

          if(this.isIframe) {
            this.$router.push({ path: '/editor' });
          } else {
            this.$router.push({ path: '/dashboard' });
          }
        }
      } catch (e) {
          switch (e.response.status) {
            case 403:
              this.serverValidationError = 'This account is not activated. Check your email and activate your account first';
              break;
          default:
            this.serverValidationError = 'Incorrect email and/or password';
            break;
        }
        this.serverValidationFailed = true;
      } finally {
        this.loginRequestInProgress = false;
      }
    },
    async checkForTeamInvite() {
      // check for invite params
      const { from, teamId, email } = this.$route.query;

      // add invite to localStorage if invite detected
      if (teamId && email) {
        localStorage.setItem('brt-team-invite', JSON.stringify({ teamId, email }));
      }

      // if user login matches params from local storage
      if (from === 'invitation' && this.email === email) {
        // activate the account
        try {
          await this.$http.put(`${this.$config.default.apiUrl}/user/${email}/confirm/${teamId}`);
        } catch (err) {
          this.handleError(err);   
        }
      }
    },
    openRegister() {
      this.$ievents.sendMessage('link', 'open', 'register');
    },
    openForgot() {
      this.$ievents.sendMessage('link', 'open', 'forgotPass');
    }
  }
}
</script>

<style lang="stylus" scoped>
@require '../../utils/common/styleguide/functions.styl';
@require '../../utils/common/styleguide/colors.styl';
section
  min-width 400px
.brt-form
  margin-bottom 0px
.separator
  position relative
  border-bottom .1px solid $brt-gray
  width 100%
  padding-top pxToRem(20)
  padding-bottom pxToRem(20)
  // margin-top pxToRem(40)
  margin-bottom pxToRem(40)
  &:before
    position absolute
    content 'or'
    text-transform uppercase
    font-weight bold
    letter-spacing 2px
    left 40%
    color $brt-black
    top 28px
    width: 20%
    text-align center
    background-color $brt-white
</style>
