<template lang="pug">
  .manager-container
    Modal(v-if="show"
      emoji="🙋"
      titleText="Upgrade your plan"
      secondary="You have reached the limit of active tours for your current plan."
      confirmTextOFF="Choose a plan"
      :confirmCb="goToPlans"
      :cancelCb="() => { this.show = false }"
    )
</template>

<script>
import Modal from "../../aspect/Modal.vue";

export default {
  components: { Modal },
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.$ebus.$on("plan/showUpgrade", () => {
      this.show = true;
    });
  },
  methods: {
    goToPlans() {
      this.show = false;
      this.$router.push({ path: "/subscription" });
    }
  }
};
</script>
