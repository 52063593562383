<template lang="pug">
Modal(v-if="showModal"
  :enabled="tutorial.options.show"
  primaryON="Hide the following tour from the Launch widget?"
  primaryOFF="Show the following tour in the Launch widget?"
  confirmTextON="Hide"
  confirmTextOFF="Show"
  :highlight="tutorial.name"
  :confirmCb="toggleLauncherVisibility"
  :cancelCb="() => { this.showModal = false }"
)
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import updateTourOptions from '../mixins/updateTourOptions';
import updateTeamTours from '../mixins/updateTeamTours';
import tourActiveCondition  from '../mixins/tourActiveCondition.js'

import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTourOptions, updateTeamTours, tourActiveCondition],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      showModal: false
    };
  },
  mounted() {
    this.$ebus.$on("tour/toggleLauncherVisibility", tutorial => {
      this.tutorial = tutorial;
      this.toggleLauncherVisibilityPre();
    });
  },
  methods: {
    toggleLauncherVisibilityPre() {
      if (this.tourCanBecomeActive) {
        this.showModal = true;
      } else {
        this.$ebus.$emit("plan/showUpgrade");
      }
    },
    async toggleLauncherVisibility() {
      try {
        this.showModal = false;
        await this.updateTourOptions(this.tutorial, {
          show: !this.tutorial.options.show
        });
        await this.updateTeamTours();
        this.$toast("Tour visibility in launcher updated", {
          type: TYPE.SUCCESS
        });
      } catch (err) {
        this.handleError(err);
        this.$toast(
          "Error updating tour visibility in launcher. Please retry.",
          { type: TYPE.ERROR }
        );
      }
    }
  }
};
</script>
