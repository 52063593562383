<template lang="pug">
  .onboard-container
    Onboard-Progress(v-if="!onboardMinimized")
    
    Onboard-Step-Skeleton(v-if="loading")
    .onboard-steps-container.p-5(v-else)
      Onboard-Step-1(v-if="showStep1")
      Onboard-Step-2(v-if="showStep2")

      //- Onboard-Step-3
      //- focus progress step 3, show step 3 contents
      .onboard-step-3-container(v-if="showStep(3, 3)")
        Onboard-Step-3-Min(v-if="onboardMinimized" )
        Onboard-Step-3(v-else)

      //- focus progress step 3, show step 4 contents
      .onboard-step-4-container(v-if="showStep(3, 4) || showStep(3, 5)")
        Onboard-Step-4-Min(v-if="onboardMinimized")
        Onboard-Step-4(v-else)
</template>

<script>
import OnboardProgress from '../progressbar/OnboardProgress.vue';
import OnboardStep1 from '../steps/OnboardStep1.vue';
import OnboardStep2 from '../steps/OnboardStep2.vue';
import OnboardStep3 from '../steps/OnboardStep3.vue';
import OnboardStep3Min from '../steps/OnboardStep3Min.vue';
import OnboardStep4 from '../steps/OnboardStep4.vue';
import OnboardStep4Min from '../steps/OnboardStep4Min.vue';
import OnboardStepSkeleton from '../steps/OnboardStepSkeleton.vue';
import { mapGetters } from "vuex";

export default {
  components: { OnboardProgress, OnboardStep1, OnboardStep2, OnboardStep3, OnboardStep3Min, OnboardStep4, OnboardStep4Min, OnboardStepSkeleton },
  methods: {
    showStep(focusStep, onboardStep) {
      const cond1 = this.focusedStep === focusStep;
      const cond2 = this.onboardStep === onboardStep;
      return cond1 && cond2
    },
  },
  computed: {
    showStep1() {
      return (
        this.focusedStep === 0 || 
        this.focusedStep === 1
      )
    },
    showStep2() {
      return this.focusedStep === 2
    },
    ...mapGetters({
      onboardStep: "onboarding/getOnboardStep",
      focusedStep: "onboarding/getFocusedStep",
      onboardMinimized: "onboarding/getMinimizedState",
      loading: "company/getLoadingTeamTours"
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'
    
  .onboard-container
    border-radius 0.71rem
    box-shadow 0rem pxToRem(3) pxToRem(6) alpha($brt-primary, 0.3)
    .onboard-steps-container
      background-color $brt-white-pure
</style>
