
import { handleError } from '../../../mixins/handleError';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  methods: {
    async updateTourStep(tutorial, stepIndex, newStep, info) {
      const query = `${this.$config.default.apiUrl}/tutorial/${tutorial._id}`;
      const updatedSteps = tutorial.steps;
      updatedSteps[stepIndex] = newStep;

      try {
        const response = await this.$http.put(query, { steps: updatedSteps });
        if (response.status == "201") {
          this.$toast(`Updated the step ${info}.`, { type: TYPE.SUCCESS });
          this.$ebus.$emit("Tutorial:updated", newStep);
        }
      } catch (err) {
        this.handleError(err);
        this.$toast(`Error updating the ${info}.`, { type: TYPE.ERROR });
      }
    }
  }
}
