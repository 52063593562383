<template lang="pug">
#brt-step-end.px-3.pt-5
  #details(ref="details")
    span.brt-caption.brt-primary Tour title
    input#brt-end-title.brt-input.my-2(
      v-model='details.name'
      type="text"
      placeholder="Tour Title"
      maxlength="45"
      brt-validate="notEmpty|alphaExtended|5-45"
    )
    .line-splitter
    #brt-options
      span.brt-caption.brt-primary Select a team
      select#brt-end-companies.brt-select.w-100.mt-2.mb-4(v-model='details.company' brt-validate="notEmpty")
        option(v-for="team in teams" :value="team._id")
          span {{ team.name }}
      #theme-container(v-if='planHasThemes')
        span.brt-caption.brt-primary Select a theme
        select#brt-end-theme.brt-select.w-100.mt-2.mb-4(v-model='details.theme')
          option(v-for="theme in themes" :value="theme._id")
            span {{ theme.name }}
      #language-container(v-if='planHasLanguages')
        span.brt-caption.brt-primary Select a language
        select#brt-language-selector.brt-select.w-100.mt-2.mb-4(v-model='details.language')
          option(v-for="language in languages" :value="language._id")
            span {{ language.name }}
  .btn-group.d-block
    button.brt-btn.btn-big.btn-primary.mb-2(
      @click="saveTour"
      title="Save the tour"
      data-testid="end-button") Save tour
    button.brt-btn.btn-big.btn-secondary(
      @click="cancelTour"
      title="Cancels tour creation."
      data-testid="end-button") Cancel
</template>
<script>
import Validator from '../../../../utils/common/utils/validator';
import { mapGetters } from 'vuex';
import { auth } from '../../../../utils/auth';
import SYNC from '../../../../utils/common/utils/sync';

export default {
  props: ['currentIndex', 'editedTour'],
  data () {
    return {
      validator: '',
      details: {
        name: '',
        visibility: 'public',
        company: this.$store.getters['company/getTeamID'],
        theme: '',
        language: ''
      },
      languages: [],
    }
  },
  watch: {
    'details.company': {
      async handler (id) {
        await this.$store.dispatch("company/setSelectedTeam", id);
        await this.$store.dispatch("company/getSelectedTeamData");
        this.setDefaultLangAndTheme();
      },
    },
    editedTour (details) {
      if (details) this.details = details;
    }
  },
  mounted() {
    this.validator =  new Validator( this.$refs.details);
    this.getLanguages();
    if (this.editedTour) {
      this.details = this.editedTour;
    } else {
      this.setDefaultLangAndTheme();
    }
  },
  methods: {
    saveTour() {
      const isValid = this.validateStep();
      if (isValid) {
        this.$ebus.$emit('create:step:end', this.details);
        this.$ievents.sendMessage('create', 'end');
      }
    },
    cancelTour() {
      this.$ebus.$emit('create:step:cancel');
    },
    validateStep() {
      this.validator.removeErrors();
      const validFields = this.validator.validateFields();
      const isValid = validFields;
      return isValid;
    },
    async getLanguages() {
      const result = await this.$http.get(`${this.$config.default.apiUrl}/language/`)
      this.languages = result.data;
    },
    async setDefaultLangAndTheme () {
      const defaultLang = await SYNC.getDefaultLanguage();
      const defaultTheme = await SYNC.getDefaultTheme(auth.token);
      if (defaultLang) {
        this.details.language = defaultLang._id;
      }
      if (defaultTheme) {
        this.details.theme = defaultTheme._id;
      }
    }
  },
  computed: {
    ...mapGetters({
      teams: 'company/getTeams',
      selectedCompanyId: 'company/getTeamID',
      planHasThemes: 'company/planHasThemes',
      planHasLanguages: 'company/planHasLanguages',
      themes: 'company/getThemes',
    })
  },
};
</script>
<style lang="stylus" scoped>
  @import '../../../../utils/common/styleguide/colors.styl';
  @import '../../../../utils/common/styleguide/functions.styl';
  #brt-step-end
    position absolute
    height 100%
    width 100%
    z-index 100
    top 0
    background white
    display flex
    flex-direction column
    justify-content: space-between
  .line-splitter 
    border-bottom 1px solid $brt-muted
    margin-top 2rem
    margin-bottom 2rem
</style>
