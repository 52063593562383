import { BaseClient } from './base';

/**
 * The google analytics client responsible for handling raw events.
 */
export default class GoogleAnalyticsClient extends BaseClient {
  /**
   * Constructor
   * @param {string} id
   * @param {bool} anonymization
   * @param {bool} spa
   */
  constructor(id, anonymization, spa, name = 't0') {
    super(id);
    //public function needed by the script.
    window.ga = window.ga || function() { (ga.q=ga.q||[]).push(arguments); }; ga.l = +new Date();
    this.name = name;
    window.ga('create', this.id, 'auto', name);
    window.ga(this.name + '.set', 'anonymizeIp', anonymization);
    // necessary to work with 'extension://' protocol
    window.ga(this.name + '.set', 'checkProtocolTask', null);
  }

  /**
   * Custom event sent to google with the specified params.
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {string} value
   */
  event (category, action, label, value) {
    window.ga(this.name + '.send', 'event', category, action, label, value);
  }

  /**
   * Send a pageview event.
   * @param {string} title
   * @param {string} path
   */
  pageview(title, path) {
    window.ga(this.name + '.set', 'page', path);
    window.ga(this.name + '.send', 'pageview');
  }
}
