<template lang="pug">
  .invite-member-popup
    .dark-background
    .brt-popup
      button.close.py-2.px-3(
        type='button'
        aria-label='Close'
        @click='closeModal()'
        title='Close this pop-up'
      )
        span(aria-hidden='true') &times;
      .inner-container.py-3
        .pop-up-emoji.mt-4.mb-3 🧭
        .medium-copy.mb-4.font-semibold Create a Tour
        .body-copy.brt-muted-dark.mb-1.
          Enter the webpage where you want to create the tour
        form(@submit.prevent='initiateTourCreation' @keyup.enter='initiateTourCreation')
          .my-5.px-5.brt-validate-container
            input#open-link-input.brt-input(
              type="search"
              placeholder="Website.com"
              v-model="url"
              brt-validate='notEmpty'
            )
          .mt-5.mb-3
            button.secondary-button(@click='closeModal()' title='Cancel tour creation').
              Cancel
            button#confirm-button.small-cta.px-4.ml-4(type='submit' title='Start tour creation').
              Start creating
</template>

<script>
import Validator from '../../../utils/common/utils/validator.js';
import { TutorialStorage } from '../../../utils/common/utils/storage.js';
import { scrollLock } from '../../../mixins/scrollLock.js';

export default {
  mixins: [scrollLock],
  data() {
    return {
      STORAGE: new TutorialStorage(localStorage),
      url: "",
      validator: ""
    };
  },
  async mounted() {
    this.validator = new Validator(
      document.querySelector(".brt-validate-container")
    );
  },
  methods: {
    initiateTourCreation() {
      this.validator.removeErrors();
      if (this.validator.validateFields()) {
        this.STORAGE.save(true, 'brtCreateFromDashboard');
        this.navigateToURL();
        this.closeModal();
      }
    },
    navigateToURL() {
      const brtParam = "?brt=create";
      let navigationUrl = this.url + brtParam;

      if (!this.url.includes("http://") && !this.url.includes("https://")) {
        navigationUrl = "http://" + this.url + brtParam;
      }
      window.open(navigationUrl);
    },
    closeModal() {
      this.$store.commit("onboarding/toggleModalCreateTour", false);
    }
  }
};
</script>
