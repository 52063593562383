import { enableNavigationGuards } from './guards';
import { enablePageviewInstrumentation } from './pageview';

import Register from '../views/auth/Register.vue';
import RegisterSuccess from '../views/auth/RegisterSuccess.vue';
import AccountConfirmation from '../views/auth/AccountConfirmation.vue';

import Login from '../views/auth/Login.vue';
import Logout from  '../views/auth/Logout.vue';
import PasswordResetForm from '../views/auth/PasswordResetForm.vue';
import PasswordChangeForm from '../views/auth/PasswordChangeForm.vue';

import GettingStarted from  '../views/GettingStarted.vue';
import Dashboard from  '../views/Dashboard.vue';
import ProductTours from  '../views/ProductTours.vue';
import LauncherManagement from  '../views/LauncherManagement.vue';
import TeamManagement from  '../views/TeamManagement.vue';
import ThemeManagement from  '../views/ThemeManagement.vue';
import SubscriptionView from '../views/SubscriptionView.vue';
import ThemeCreation from '../views/ThemeCreation.vue';

import TourDetails from '../views/TourDetails.vue';
import Experiences from '../views/Experiences.vue';

import Editor from '../editor/components/popup/Editor.vue';

import NotFound from '../views/NotFound.vue';

export function decorateRouter(VueRouter, probe, mode = 'history', base = '') {
  const router = new VueRouter(
    {
      mode,
      base,
      scrollBehavior: function(to) {
        if (to.hash) {
          return {
            selector: to.hash,
            behavior: 'smooth',
          }
        }
      },
      routes: [
        {
          path: '/gettingStarted',
          name: 'gettingStarted',
          component: GettingStarted,
          meta: { forAuth: true }
        },
        {
          path: '/dashboard',
          name: 'dashboard',
          component: Dashboard,
          meta: { forAuth: true }
        }, {
          path: '/tours',
          name: 'tours',
          component: ProductTours,
          meta: { forAuth: true }
        }, {
          path: '/launcher/:domain?',
          name: 'launcher',
          component: LauncherManagement,
          meta: { forAuth: true }
        }, {
          path: '/team',
          name: 'team',
          component: TeamManagement,
          meta: { forAuth: true }
        }, {
          path: '/themes',
          name: 'themes',
          component: ThemeManagement,
          meta: { forAuth: true }
        }, {
          path: '/theme/:id',
          name: 'themeCreation',
          component: ThemeCreation,
          meta: { forAuth: true }
        },
        {
          path: '/subscription',
          name: 'subscription',
          component: SubscriptionView,
          meta: { forAuth: true }
        },
        {
          path: '/tour/:id/:tab',
          name: 'tour',
          component: TourDetails,
          meta: { forAuth: true }
        },
        {
          path: '/experiences/:tab',
          name: 'experiences',
          component: Experiences,
          meta: { forAuth: true }
        },
        {
          path: '/experiences',
          name: 'experiencesRedirect',
          redirect: { path: '/experiences/tours' },
          meta: { forAuth: true }
        },
        {
          path: '/tour/:id',
          name: 'tourRedirect',
          redirect: { path: '/tour/:id/analytics' },
          meta: { forAuth: true }
        }, {
          path: '/tutorial/:id',
          name: 'redirectOldTourPathBase',
          redirect: { path: '/tour/:id/analytics' },
          meta: { forAuth: true }
        }, {
          path: '/tutorial/:id/analytics',
          name: 'redirectOldTourPathAnalytics',
          redirect: { path: '/tour/:id/analytics' },
          meta: { forAuth: true }
        }, {
          path: '/tutorial/:id/options',
          name: 'redirectOldTourPathOptions',
          redirect: { path: '/tour/:id/options' },
          meta: { forAuth: true }
        }, {
          path: '/login',
          name: 'login',
          component: Login,
        }, {
          path: '/register',
          name: 'register',
          component: Register,
        }, {
          path: '/register/success',
          name: 'registerSuccess',
          component: RegisterSuccess,
          props: true
        }, {
          path: '/register/confirm/:email',
          name: 'accountConfirmation',
          component: AccountConfirmation,
          props: true
        }, {
          path: '/passResetReq',
          name: 'passResetReq',
          component: PasswordResetForm,
        }, {
          path: '/passChangeForm',
          name: 'passChangeForm',
          component: PasswordChangeForm,
        }, {
          path: '/logout',
          name: 'Logout',
          component: Logout,
        }, {
          path: '/',
          redirect: { path : '/dashboard'}
        }, {
          path: '/notfound',
          name: 'NotFound',
          component: NotFound,
        }, {
          path: '*',
          component: NotFound,
        },
        ...(window.parent !== window ? [
          {
            path: '/editor',
            name: 'editor',
            component: Editor,
            meta: { forAuth: true }
          },
        ] : []),
      ]
    },
  );
  enableNavigationGuards(router);
  enablePageviewInstrumentation(router, probe);
  return router;
}
