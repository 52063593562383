<template lang="pug">
.brt-message-box(ref='container')
  #details.px-3.pt-4
    #header.mb-5
      span.brt-caption {{ 'Step ' + stepNo }} options
      button#anchor-btn.brt-btn.btn-primary(title="Change the selected element for this step" @click='changeElement')
        icon-anchor#anchor-icon.mr-1(width="1.2rem" height="1.2rem" aria-label="anchorIcon")
        | Change anchor page
    content-editor(
      :isEditable="true"
      :oldContent="step.content"
      :subscription="subscription"
      :editMode="!!this.edit"
      ref="contentContainer"
    )
    .line-splitter
    span.brt-caption.brt-primary next step button text
    .position-relative
      input#brt-end-title.brt-input.mb-2.mt-3(
        v-model='step.nextButtonText'
        type="text"
        :placeholder="nextBtnPlaceholder"
        maxlength="45"
        brt-validate="notEmpty|5-45"
      )
    .line-splitter
    span.brt-caption.brt-primary background overlay
    .d-flex.justify-content-between.align-items-center
      p.small-copy.brt-muted-dark.my-2 Display a gray overlay behind the modal.
      Toggle-Switch.mt-3(
        v-model="step.hasBackground"
        @change="toggleBackground()"
      )
    .line-splitter
    span.brt-caption.brt-primary step type
    #step-optional-container.d-flex.justify-content-between.align-items-center.mt-3
      .radio-buttons-container.d-flex
        .radio-group
          input#step-mandatory(type="radio" :value="false" v-model="step.optional") 
          label(for="step-mandatory") Mandatory
        .radio-group.ml-3
          input#step-optional(type="radio" :value="true" v-model="step.optional")
          label(for="step-optional") Optional
  .btn-group.d-block
    button.brt-btn.btn-big.btn-primary.mb-2(title="Save step to your tour" @click='save').
      Save step
    button.brt-btn.btn-big.btn-secondary(title="Cancel step options." @click='cancel').
      Cancel step options

</template>
<script>
import Validator from '../../../../utils/common/utils/validator';
import iconAnchor from '../../../images/anchor.vue';
import contentEditor from '../../reusable/contentEditor.vue';
import ToggleSwitch from "../../../../utils/common/vuecomps/ToggleSwitch.vue";
import IconTooltip from "../../../../components/icons/IconTooltip.vue";

import { mapGetters } from 'vuex';

export default {
  props: ['currentIndex', 'edit', 'details'],
  components: {
    'icon-anchor': iconAnchor,
    'content-editor': contentEditor,
    ToggleSwitch,
    IconTooltip
  },
  data () {
    return {
      step: {
        content: this.edit ? this.details.content : null,
        nextButtonText: this.edit ? this.details.nextButtonText : !this.currentIndex ? "Let's get started" : 'Next Step',
        hasBackground: this.edit ? this.details.hasBackground : true,
        optional: this.edit ? this.details.optional : false,
      },
      validator: '',
    }
  },
  watch: {
    step: {
      handler(updates){
        this.$parent.$emit('create:step:updates', this.step);
      },
      deep: true
    },
  },
  mounted() {
    this.validator =  new Validator(this.$refs.container);
    this.$on('create:step:update:content', (content) => {
      this.$parent.$emit('create:step:updates', { ...this.step, content });
    });
  },
  methods: {
    toggleBackground() {
      this.step.hasBackground = !this.step.hasBackground;
    },
    save() {  
      const isValid = this.validateStep();
      if (isValid) {
        this.step.content = this.$refs.contentContainer.contentEditor.getContents();
        this.$parent.$emit('create:step:save', {step: this.step, index: this.currentIndex, edit: this.edit});
        this.$ievents.sendMessage('create', 'continue');
      }
    },
    cancel() {
      this.validator.reset();
      this.$ievents.sendMessage('create', 'continue');
      this.$ebus.$emit('create:step:cancel');
    },
    validateStep() {
      this.validator.removeErrors();
      const validFields = this.validator.validateFields();

      const contentChecker = this.$refs.contentContainer.isValid();

      const isValid = validFields && contentChecker;
      return isValid;
    },
    changeElement() {
      this.$parent.$emit('create:step:changeElement');
    }
  },
  computed: {
    stepNo() {
      return this.currentIndex ? this.currentIndex + 1 : 1;
    },
    nextBtnPlaceholder() {
      return this.stepNo > 1 ? 'Next Step' : "Let's get started";
    },
    ...mapGetters({
      subscription: "company/getTeamPlan"
    })
  }
};
</script>
<style lang="stylus" scoped>
  @import '../../../../utils/common/styleguide/colors.styl';
  @import '../../../../utils/common/styleguide/functions.styl';

  .brt-message-box
    position absolute
    height 100%
    width 100%
    z-index 100
    top 0
    background white
    display flex
    flex-direction column
    justify-content: space-between
    #details
      height 70%
      justify-content center
      display flex
      flex-direction column
      #header
        position relative
        height auto
        .brt-caption
          font-size 1rem
      #brt-elem-interact-container
        position absolute  
      #anchor-btn
        position absolute
        top pxToRem(-5)
        right 0
        width pxToRem(180)
        padding .5rem 0
        #anchor-icon
          margin-top pxToRem(-3)
  .line-splitter 
    border-bottom 1px solid $brt-muted
    margin-top 2rem
    margin-bottom 2rem
</style>
