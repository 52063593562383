<template lang="pug">
.brt-card
  .small-copy.brt-muted-dark.mb-4
    p.brt-caption TOUR ID
    .my-3
      p Use this ID if you wish to setup the tour to trigger based on a custom event. 
      p For more info, read the
        a.link.ml-1(:href="$urlConfig.galleryUrl + '/docs/tour-run-methods/custom-launch.html'" data-testid="docs-link") Docs.
  #share-id-container
    Copy-Text(:copyString="tutorialID" data-testid="copy-id")

</template>

<script>
import CopyText from "../../aspect/CopyText.vue";
import { mapGetters } from "vuex";

export default {
  components: { CopyText },
  computed: {
    ...mapGetters({
      tutorialID: "tutorial/getTutorialID"
    })
  }
};
</script>
