<template lang="pug">
.tour-navigation
  .brt-tabs-container
    .brt-tab(@click="changeTab(tabs.analytics)" :class="{'brt-active-tab': activeTab==='analytics'}")
      svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
        use(href="/assets/misc/analytics.svg#Layer_1")
      span Analytics

    .brt-tab(@click="changeTab(tabs.options)" :class="{'brt-active-tab': activeTab==='options'}")
      svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
        use(href="/assets/misc/options.svg#Layer_1")
      span Options
    
    .brt-tab(@click="changeTab(tabs.editSteps)" :class="{'brt-active-tab': activeTab==='editSteps'}")
      svg.mr-2.brt-tab-icon(width="1.4rem" height="1.4rem")
        use(href="/assets/misc/edit-pencil.svg#Layer_1")
      span Edit Steps
        
</template>

<script>
export default {
  props: {
    activeTab: String
  },
  data() {
    return {
      tabs: {
        analytics: 'analytics',
        options: 'options',
        editSteps: 'editSteps'
      },
    };
  },
  methods: {
    changeTab(tab) {
      if (this.activeTab != tab) {
        this.$router
          .push({ path: `/tour/${this.$route.params.id}/${tab}` })
          .catch((err)=>{
            if (err.name != "NavigationDuplicated") {
              console.log(err)
            }
          });
        this.$parent.$emit('changeTab', tab);
      } 
    },
  }
};
</script>
