<template lang="pug">
  Modal-Invite-Member(v-if='show'
    :confirmCb="(email) => { inviteMember(email) }"
    :cancelCb="() => { this.show = false }"
  )
</template>

<script>
import ModalInviteMember from "../ModalInviteMember.vue";

import { handleError } from "../../../mixins/handleError";
import Validator from "../../../utils/common/utils/validator.js";
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

export default {
  mixins: [handleError],
  components: { ModalInviteMember },
  props: {
    event: Object
  },
  data() {
    return {
      show: false,
      member: {}
    };
  },
  mounted() {
    this.$ebus.$on("company/inviteTeamMember", member => {
      this.member = member;
      this.show = true;
    });
  },
  methods: {
    async inviteMember(email) {
      this.validateField();
      const url = `${this.$config.default.apiUrl}/company/${this.teamID}/addUser`;
      try {
        const res = await this.$http.post(url, { email });
        if (res.status === 201) {
          this.show = false;
          await this.$store.dispatch("company/getSelectedTeamDetails");
          this.$toast("Team member invite sent", { type: TYPE.SUCCESS });
        }
      } catch (err) {
        console.log(err);
        if (err.response.status === 409) {
          this.validator.invalidField(
            document.querySelector(".brt-validate-that input"),
            "This user is already a team member"
          );
        } else {
          console.error(err);
          this.$toast("Error sending team member invite. Please retry.", {
            type: TYPE.ERROR
          });
        }
      }
    },
    validateField() {
      this.validator = new Validator(
        document.querySelector(".brt-validate-that")
      );
    }
  },
  computed: {
    ...mapGetters({
      teamID: 'company/getTeamID'
    })
  }
};
</script>
