<template lang="pug">
  .manager-container
    //- tour run options change actions
    ToggleAutoRun
    ToggleLauncherVisibility
    ToggleRunOnce
    UpdateAutoRunDelay
    ToggleIgnoreSubdomain

    //- analytics actions
    ResetTourStats
    ClearTourErrors

    //- display change actions
    UpdateTheme
    UpdateLanguage
    UpdateTourName

    //- other actions
    DeleteTour
    DuplicateTour

    //- step actions
    UpdateStepDescription
    UpdateStepType
    UpdateStepTrigger
    UpdateStepElementInteraction
    UpdateStepNextBtnText
    UpdateStepHasBackground
    DeleteStep
</template>

<script>
import ToggleAutoRun from "./actions/ToggleAutoRun.vue";
import ToggleLauncherVisibility from "./actions/ToggleLauncherVisibility.vue";
import ToggleRunOnce from "./actions/ToggleRunOnce.vue";
import UpdateAutoRunDelay from "./actions/UpdateAutoRunDelay.vue";
import ToggleIgnoreSubdomain from "./actions/ToggleIgnoreSubdomain.vue";
import ResetTourStats from "./actions/ResetTourStats.vue";
import ClearTourErrors from "./actions/ClearTourErrors.vue";
import UpdateTheme from "./actions/UpdateTheme.vue";
import UpdateLanguage from "./actions/UpdateLanguage.vue";
import UpdateTourName from "./actions/UpdateTourName.vue";
import DeleteTour from "./actions/DeleteTour.vue";
import DuplicateTour from "./actions/DuplicateTour.vue";

import UpdateStepType from "./actions/UpdateStepType.vue";
import UpdateStepDescription from "./actions/UpdateStepDescription.vue";

import UpdateStepTrigger from "./actions/UpdateStepTrigger.vue";
import UpdateStepElementInteraction from "./actions/UpdateStepElementInteraction.vue";
import UpdateStepNextBtnText from "./actions/UpdateStepNextBtnText.vue";
import UpdateStepHasBackground from "./actions/UpdateStepHasBackground.vue";
import DeleteStep from "./actions/DeleteStep.vue";


export default {
  components: {
    ToggleAutoRun,
    ToggleLauncherVisibility,
    ToggleRunOnce,
    UpdateAutoRunDelay,
    ToggleIgnoreSubdomain,
    ResetTourStats,
    ClearTourErrors,
    UpdateTheme,
    UpdateLanguage,
    UpdateTourName,
    DeleteTour,
    DuplicateTour,
    UpdateStepType,
    UpdateStepDescription,
    UpdateStepTrigger,
    UpdateStepElementInteraction,
    UpdateStepNextBtnText,
    UpdateStepHasBackground,
    DeleteStep,
  }
};
</script>
