import { BaseClient } from './base';

// private function, do not export/call from outside this class
function ga () { dataLayer.push(arguments); }

/**
 * The google tag manager analytics client responsible for handling raw events.
 */
export default class GoogleTagManagerClient extends BaseClient {
  /**
   * Constructor
   * @param {string} id
   * @param {boolean} anonymization
   * @param {boolean} spa
   */
  constructor(id, anonymization, spa) {
    super(id);
    // datalayer is the array with wich the ga script communicates with our app
    window.dataLayer = window.dataLayer || [];

    ga('js', new Date());
    ga('config', this.id, {
      'anonymize_ip': anonymization ? anonymization : true,
      'send_page_view': spa ? !spa : false
    });
  }

  /**
   * Custom event sent to google with the specified params.
   * @param {string} category
   * @param {string} action
   * @param {string} label
   * @param {string} value
   */
  event (category, action, label, value) {
    ga('event', action, {
      'event_category' : category,
      'event_label' : label,
      'event_value' : value
    });
  }

  /**
   * Send a pageview event.
   * @param {string} title
   * @param {string} path
   */
  pageview(title, path) {
    ga('config', this.id, {
      'page_title' : title,
      'page_path': path
    });
  }
}
