<template lang="pug">
  .container
    .row.mt-2
      .col-12
        .d-flex.justify-content-center.align-items-center
          div#bill-yearly-info(v-if="billingType === 'month'") Get #[strong 2 months free] with yearly billing!
          h4#bill-yearly.mt-2(:class="billingType === 'year' ? 'active-state' : 'disabled-state' ") Yearly
          input#pricing-switch.toggle.mx-2.mb-1(type='checkbox' @change="switchToggle($event)")
          h4#bill-monthly.mt-2.brt-muted(:class="billingType === 'month' ? 'active-state' : 'disabled-state' ") Monthly
    
    .row.justify-content-center.align-items-center.my-5(v-if="teamPlan")
      Plan-Card(v-for="sub in subscriptions"
        :tier="sub.tier"
        :name="sub.name"
        :price="sub.price"
        :mainText="sub.mainText"
        :features="sub.features"
        :checkoutLink="sub.checkoutLink"
        :billingType="billingType"
      )
</template>

<script>
import PlanCard from '../components/plans/PlanCard.vue';
import plans from '../plans/plans.json';
import { mapGetters } from "vuex";

export default {
  name: 'SubscriptionView',
  components: { PlanCard },
  data() {
    return {
      billingType: 'year',
      subscriptions: [],
    }
  },
  mounted() {
    this.subscriptions = plans.list;
  },
  methods: {
    switchToggle(e) {
      this.billingType = e.target.checked ? 'month' : 'year'
    }
  },
  computed: {
    ...mapGetters({
      teamPlan: "company/getTeamPlan"
    })
  },
}
</script>

<style lang="stylus" scoped>
@require '../stylus/pricing-toggle.styl'  
</style>
