<template lang='pug'>
  .brt-card.mt-3.position-relative
    img.green-lock(v-if='planTeamLimitHit'  src='/assets/plans/greenLock.svg')
    .top-container
      .brt-caption MANAGE YOUR TEAM MEMBERS 
    .d-flex.justify-content-md-end.my-3.my-md-0
      button.small-cta.mr-5(v-if="planTeamLimitHit" @click='upgradePlan()').
        Upgrade plan to invite more people
      button.small-cta(v-else @click="inviteTeamMember()").
        Invite new team member
    .container.mt-3
      .row
        .col-12.col-xl-9
          .container.p-0.m-0
            .row.py-4.brt-muted-dark.small-copy
              .col-1.m-0.text-center #
              .col-3 Name
              .col-5 Email
              .col-2 Status
              .col-1(v-show='isOwner') Actions
            Team-Members-List-Item(v-for="(member, index) in members"
              :index="index"
              :member="member"
              :isOwner="isOwner")
        .d-none.d-xl-flex.align-items-end.col-xl-3
          .container
            .row
              .col-12
                img(src='/assets/team/team-members.svg')
</template>

<script>
import Spinner from '../../components/aspect/Spinner.vue';
import TeamMembersListItem from './TeamMembersListItem.vue'

import { auth } from '../../utils/auth';
import { mapGetters } from 'vuex';

export default {
  components: { Spinner, TeamMembersListItem },
  methods: {
    upgradePlan() {
      this.$router.push({ path: '/subscription'});
    },
    inviteTeamMember() {
      this.$ebus.$emit('company/inviteTeamMember')
    }
  },
  computed: {
    isOwner() {
      return auth.id === this.ownerID;
    },
    ...mapGetters({
      members: 'company/getTeamMembers',
      planTeamLimitHit: 'company/planTeamLimitHit',
      ownerID: 'company/getTeamOwnerID'
    })
  }
}
</script>
