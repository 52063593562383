<template lang="pug">
  .onboard-progress.mx-0.pt-3.d-flex.justify-content-around
    .step-tab.d-flex.align-items-center(
      v-for="(step, index) in onboardSteps" 
        :class="{\
          'navigatable-tab': index < onboardStep,\
          'active-tab': index === focusedStep - 1,\
         }" 
        @click="focusStep(index + 1)")
      .pill-container.d-inline.mr-2
        img(
          v-if="index < onboardStep - 1"
          src="../../../assets/misc/greenCheck.svg"
          alt="green checkmark"
          width="24"
          height="100%")
        span.brt-pill.brt-white.brt-bg-secondary-dark(
          v-else
          :class="{'brt-bg-muted': index> onboardStep - 1 }").
          {{ index + 1 }}
      span.body-copy.step-description.brt-muted(:class="{ 'brt-dark': index === focusedStep - 1 }").
        {{ step }}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      onboardSteps: [
        'Install the Chrome Extension',
        'Build a Product Tour',
        'Connect to Bytes Route',
      ],
    }
  },
  methods: {
    focusStep(step) {
      if (step <= this.onboardStep)
        this.$store.commit('onboarding/setFocusedStep', step)
    },
  },
  computed: {
    ...mapGetters({
      onboardStep: 'onboarding/getOnboardStep',
      focusedStep: 'onboarding/getFocusedStep'
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'

  .onboard-progress
    background-color $brt-muted-faded
    border-top-left-radius 0.71rem
    border-top-right-radius 0.71rem

    .step-tab
      padding 1rem .2rem      
      @media (min-width: 1050px)
        padding 1rem
      @media (min-width: 1200px)
        padding 1rem 2rem
      @media (min-width: 1300px)
        padding 1rem 3rem
    .active-tab
      background-color $brt-white-pure
      border-radius 0.5rem 0.5rem 0 0
      .step-description 
        color $brt-dark

    .navigatable-tab
      cursor pointer
      transition .2s ease-in-out
      .step-description 
        text-decoration underline $brt-muted
        transition .15s ease-in-out
        &:hover
          color $brt-dark
          text-decoration underline $brt-dark
    
    .no-arrow-container
      width 2.3rem

    
  .step-completed
    color $brt-muted
  
</style>
