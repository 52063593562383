<template lang="pug">
  .brt-dropdown(@click="isOpen=!isOpen")
    .dropdown-trigger(data-testid="dropdown-toggle")
      .options-button(v-if="type == 'options'" data-testid="options-button")
        Icon-Base(width="36" height="20" vbx="-4" vby="-4" :color="'#2B006A'")
          Icon-Options

      .navbar-element(v-if="type == 'navbar'" data-testid="navbar-element")
        .nav-elem.nav-link
          span {{ navbarElemName }}
          img.ml-2(src='/assets/misc/chevron.svg' width="18px" alt="chevron arrow up"
            :class="{'rotate-180': isOpen}")

    transition(name="fade" appear)
      .brt-dropdown-list(v-if="isOpen" data-testid="dropdown-list")
        .brt-dropdown-item.text-left(
          v-for="(item, i) in items"
          :key='i'
          @click="item.cb()" 
          :class="{'danger': item.type === 'danger'}"
          :data-testid="`dropdown-item-${i}`").
          {{ item.name }}
</template>

<script>
/**
 * DOCUMENTATION
 * 
 * Use this component as following in your templates:
 *     dropdown(:type="navbar"/"options" :toggle :items="items" @event1="action1() @event2="action2")
 * @prop { Array } items = an array of elements that will appear in the list.
 * 
 * @prop { String } type = can be either:
 *                            "navbar" for a navbar dropdown or 
 *                            "options" for a dropdown triggered by an 3-dots options button
 * 
 * @prop { String } navbarElemName = if "navbar" option is chosen, with this variable you can set the name of the navbar element
 * Declare this prop in your data() {} structure of the calling component.
 * The expected structure of elements inside the 'items' array is the following:
 * {
 *  name: 'First List Item',
 *  event: 'event1', (we use this to call our desired methods in the parent component)
 *  type: 'danger' (optional, use type: 'danger' if you want the element text to be red)
 * }
 */
import IconBase from '../icons/IconBase.vue';
import IconOptions from '../icons/IconOptions.vue';
export default {
  props: {
    items: {
      type: Array,
      default: [ { name: 'Default Elem', event: 'event:default'} ]
    },
    type: {
      type: String,
      default: 'options'
    },
    navbarElemName: {
      type: String,
      default: 'DefaultNavName'
    }
  },
  components: { IconBase, IconOptions },
  data() {
    return {
      isOpen: false,
    }
  },
  mounted() {
    this.closeOnClickOutside();
  },
  methods: {
    closeOnClickOutside() {
      window.addEventListener('click', (event) => {
        if (!this.$el.contains(event.target)){
          this.isOpen = false;
        } 
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  @require '../../utils/common/styleguide/functions.styl'
  .brt-dropdown
    cursor pointer
    position relative
    .brt-dropdown-toggle
      padding 0.5rem
      border none
      border-radius 0.5rem
      background-color $brt-muted-bright
      &:hover
        background-color $brt-muted
      &:focus
        outline none !important

    .brt-dropdown-list
      z-index 1000
      position absolute
      background-color $brt-white
      top pxToRem(40)
      right pxToRem(0)
      width max-content
      border-radius 0.5rem
      padding 0.5rem
      min-width pxToRem(165)
      box-shadow pxToRem(0) pxToRem(3) pxToRem(16) alpha($brt-primary-dark, .1)

      .brt-dropdown-item
        border-radius pxToRem(4)
        color $brt-black
        cursor pointer
        padding 0.5rem 1rem
        white-space: nowrap
        &:hover
          background-color $brt-muted-faded
        &:active
          background-color $brt-muted-bright
  
      .danger
        color $brt-danger-light


  .fade-enter-active,
  .fade-leave-active
      transition all .2s ease-out

  .fade-enter,
  .fade-leave-to
      opacity 0
</style>

