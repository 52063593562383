<template lang="pug">
.manager-container
  ToggleLauncherVisibility
  DeleteTheme
  RemoveTeamMember
  InviteTeamMember
  UpdateTeamName
  StartTrial
  ActivatePlan
  UpgradePlan
</template>

<script>
import ToggleLauncherVisibility from "./actions/ToggleLauncherVisibility.vue";
import DeleteTheme from "./actions/DeleteTheme.vue";
import RemoveTeamMember from "./actions/RemoveTeamMember.vue";
import InviteTeamMember from "./actions/InviteTeamMember.vue";
import UpdateTeamName from "./actions/UpdateTeamName.vue";
import StartTrial from "./actions/StartTrial.vue";
import ActivatePlan from "./actions/ActivatePlan.vue";
import UpgradePlan from './actions/UpgradePlan.vue';

export default {
  components: {
    ToggleLauncherVisibility,
    DeleteTheme,
    RemoveTeamMember,
    InviteTeamMember,
    UpdateTeamName,
    StartTrial,
    ActivatePlan,
    UpgradePlan
  }
};
</script>
