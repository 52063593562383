/**
 * Private variables
 */
 const configMap = {
  dev: {
    api: 'http://localhost:3000',
    gallery: 'https://www.bytesroute.com',
    webapp: 'http://localhost:8001'
  },
  prod: {
    api: 'https://bytesroute-backend.herokuapp.com',
    gallery: 'https://www.bytesroute.com',
    webapp: 'https://app.bytesroute.com'
  }
};

/**
 * Automatically sets the proper URLs.
 *
 * @author Cristi Minica
 */
export default class UrlConfig {
  /**
   * Class constructor.
   *
   * @param   {[Chrome]}  ichrome    inject chrome [optional]
   * @param   {[Document]}  idocument  inject document [optional]
   */
  constructor(ichrome, idocument) {
    this.chrome = ichrome;
    this.document = idocument ? idocument : document;
    // use node env, if it exists, to setup api mode
    if (process && process.env &&
      process.env.NODE_ENV === 'development') {
      this.mode = 'dev';
    } else {
      this.mode = 'prod';
    }

    this.suffix = '';
    this.from = '?from=';
    this.setUrls();
  }
  /**
   * Set up the configuration mode ('dev' or 'prod') from url.
   */
  setConfigModeFromUrl (url) {
    if (url.includes('localhost'))
      { this.mode = 'dev'; }
    else
      { this.mode = 'prod'; }
  }

  /**
   * Gets the proper URL for interogation.
   *
   * @return  {URL} The proper URL
   */
  getSpecificURL() {
    return new Promise((resolve, reject) => {
      if(this.document.location.href.includes('chrome-extension')) {
        if (!this.chrome) {
          if (chrome) {
            this.chrome = chrome;
          } else {
            resolve(this.document.location.href);
          }
        }
        this.chrome.tabs.query({ active: true },
          (tabs) => {
            const tab = tabs[0];
            // eslint-disable-next-line no-console
            console.log(`Making calls to this api: ${this.apiUrl}`);
            resolve(tab.url);
        });
      } else {
        resolve(this.document.location.href);
      }
    });
  }

  getMode() {
    return this.mode;
  }

  /**
   * Sets the internal URL based on the mode.
   */
  setUrls() {
    this.apiUrl = configMap[this.mode].api;
    this.galleryUrl = configMap[this.mode].gallery;
    this.webappUrl = configMap[this.mode].webapp;
  }

  /**
   * Full URL setup algorithm.
   */
  async setup() {
    // const url = await this.getSpecificURL();
    // this.setConfigModeFromUrl(url);
    this.setUrls();
  }

  /**
   * Builds a link to the gallery
   *
   * @param   {[type]}  page    gallery page
   * @param   {[type]}  from    [optional] origin of link
   *
   * @return  {[type]}          computed link
   */
  getGalleryLink(page, from) {
    let link = this.galleryUrl + this.suffix + '/' + page;
    if (from) link += this.from + from;
    return link;
  }

  /**
   * Builds a link to the webapp
   *
   * @param   {[type]}  page    webapp page
   * @param   {[type]}  from    [optional] origin of link
   *
   * @return  {[type]}          computed link
   */
  getWebAppLink(page, from) {
    let link = this.webappUrl + this.suffix + '/' + page;
    if (from) link += this.from + from;
    return link;
  }

  /**
   * Retrieves the gallery login link.
   *
   * @param   {[type]}  from    [optional] origin of link
   */
  getLoginLink(from) {
    return this.getWebAppLink('login', from);
  }

  /**
   * Retrieves the gallery register link.
   *
   * @param   {[type]}  from    [optional] origin of link
   */
  getRegisterLink(from) {
    return this.getWebAppLink('register', from);
  }

  /**
   * Retrieves the gallery forgot password link.
   *
   * @param   {[type]}  from    [optional] origin of link
   */
  getForgotLink(from) {
    return this.getWebAppLink('passResetReq', from);
  }

   /**
    * Retrieves the gallery logout link.
    */
  getLogoutLink(from) {
    return this.getWebAppLink('logout', from);
  }

  /**
   * Retrieves the dashboard/profile link
   */
  getDashboardLink(from) {
    return this.getWebAppLink('', from);
  }
}
