/**
 * Class that aids in managing Urls for navigation purposes.
 * Mostly for step navigation purposes.
 *
 * @author Cristi Minica
 */
class UrlNavigation {
  /**
   * Checks if the provided Url's are the same.
   * @param {string | URL} aCurrent
   * @param {string | URL} aNewUrl
   */
  isSameUrl (aCurrent, aNewUrl) {
    // create a new URL object if they are string,
    // if thery are URL it just passes through
    const current = new URL(aCurrent);
    const newUrl = new URL(aNewUrl);

    return newUrl.pathname === current.pathname
    && newUrl.hash === current.hash
    && newUrl.hostname === current.hostname
  }

  /**
   * Checks if a portion of a path looks dynamic.
   */
  looksDynamic(portion) {
    const found = portion.match(/\d+/g);
    return found;
  }

  /**
   * Finds the first difference, from left to right, in a path
   * @param {*} pathA
   * @param {*} pathB
   */
  getReplacementIndex(pathA, pathB) {
    pathA = pathA.split('/')
    pathB = pathB.split('/');
    const minlen = Math.min(pathA.length, pathB.length)
    for(let i = 1; i < minlen; ++i) {
      if (pathA[i] === '' || pathB[i] === '') {
        return false;
      }
      if (pathA[i] !== pathB[i] &&
          (this.looksDynamic(pathA[i]) || this.looksDynamic(pathB[i]))
        ) {
        return i;
      }
    }
    return false;
  }

  /**
   * Swaps the path that is different
   *
   * @param {String} currentUrl
   * @param {String} newDestinationUrl
   * @param {String} otherDestinationPath
   */
  swapPath(currentPath, newDestinationPath, otherDestinationPath) {
    let repIndex = this.getReplacementIndex(currentPath,
      otherDestinationPath);
    if (repIndex !== false) {
      const valueToBeChanged = currentPath.split('/')[repIndex];
      const newDestinationPathSplit = newDestinationPath.split('/');
      if (newDestinationPath !== otherDestinationPath
        || repIndex < newDestinationPathSplit.length - 1) {
          newDestinationPathSplit[repIndex] = valueToBeChanged;
      }
      newDestinationPath = newDestinationPathSplit.join('/');
    }
    return newDestinationPath;
  }

  /**
   * Applies the difference in path from lastUrl vs currentUrl
   * to the pathname cored of actual URL.
   * @param {URL} currentUrl
   * @param {URL} newDestinationUrl
   * @param {URL} otherDestinationUrl
   */
  getProperPath(currentUrl, newDestinationUrl, otherDestinationUrl) {
    let newDestinationPath = newDestinationUrl.pathname;
    if (otherDestinationUrl && currentUrl) {
      newDestinationPath = this.swapPath(currentUrl.pathname,
        newDestinationPath,
        otherDestinationUrl.pathname);
    }
    return newDestinationPath;
  }

  /**
   * Applies the difference in hash from lastUrl vs currentUrl
   * to the hash core of actual URL.
   * @param {URL} currentUrl
   * @param {URL} newDestinationUrl
   * @param {URL} otherDestinationUrl
   */
  getProperHash(currentUrl, newDestinationUrl, otherDestinationUrl) {
    let newDestinationHash = newDestinationUrl.hash.slice(1);
    if (otherDestinationUrl && currentUrl) {
      const currentUrlHash = currentUrl.hash.slice(1);
      const otherDestinationUrlHash = otherDestinationUrl.hash.slice(1);
      newDestinationHash = this.swapPath(currentUrlHash,
        newDestinationHash,
        otherDestinationUrlHash);
    }
    return newDestinationHash ? '#' + newDestinationHash : '';
  }

  /**
   * Computes the effective new location href.
   * @param {URL} currentLocation
   * @param {URL} newDestinationUrl
   * @param {URL} otherDestinationUrl
   */
  getNewLocationHref (currentLocation, newDestinationUrl, otherDestinationUrl, ignoreSubdomain) {
    let newLocationHref = Object.assign('', currentLocation.href);
    // a step is on a domain and the next step is on another domain
    if (newDestinationUrl.origin !== currentLocation.origin
        && currentLocation.hostname !== 'localhost') {
      newLocationHref = newDestinationUrl.href;
    // same domain but different location and different hash
    } else if ( newDestinationUrl.pathname !== currentLocation.pathname
                && newDestinationUrl.hash !== currentLocation.hash) {
      newLocationHref =
      currentLocation.origin
      + this.getProperPath(currentLocation, newDestinationUrl, otherDestinationUrl)
      + this.getProperHash(currentLocation, newDestinationUrl, otherDestinationUrl);
    // same domain but different location and different hash
    } else if (newDestinationUrl.pathname !== currentLocation.pathname) {
      newLocationHref =
        currentLocation.origin
        + this.getProperPath(currentLocation, newDestinationUrl, otherDestinationUrl)
        + newDestinationUrl.hash;
    // same domain but different hash, relevant for SPA
    } else if (newDestinationUrl.hash !== currentLocation.hash) {
      newLocationHref =
        currentLocation.origin
        + newDestinationUrl.pathname
        + this.getProperHash(currentLocation, newDestinationUrl, otherDestinationUrl);
    }

    if (ignoreSubdomain) {
      return this.replaceSubdomain(currentLocation, newDestinationUrl, newLocationHref);
    }

    return newLocationHref;
  }

  replaceSubdomain (currentLocation, newDestinationUrl, locationHref) {
    let newLocationHref = locationHref;
    const cHostName = currentLocation.hostname ? currentLocation.hostname : currentLocation;
    const nHostName = newDestinationUrl.hostname ? newDestinationUrl.hostname : newDestinationUrl;
    let subdomain = cHostName.split('.').slice(0, -2).join('.');
    let subdomainToBeRplcd = nHostName.split('.').slice(0, -2).join('.');

    if (subdomain && subdomain !== 'www' && subdomain !== subdomainToBeRplcd) {
      newLocationHref = newLocationHref.replace(`${subdomainToBeRplcd}.`, `${subdomain}.`);
    }

    return newLocationHref;
  }
}

// export as singleton because it does not have any internal state
export default new UrlNavigation();
