<template lang="pug">
  .tour-rows-header
    .row.mb-4.pl-4
      .col-12.small-copy.brt-muted-dark.font-weight-bold.
        ASSOCIATED TOURS ({{ domain.tutorials.length }})
    .row.small-copy.brt-muted-dark.mb-2.px-4.d-flex.justify-content-between
      .col-4.col-lg-5 Title
      .col-lg-2.d-none.d-lg-inline(v-if="detailed")
        .brt-tooltip.brt-tooltip-dashed Completion Rate
          .brt-tooltiptext.brt-tooltip-top {{ tooltip.analytics.tour.completionRate }}
      .col-2.col-lg-1(v-if="detailed")
        .brt-tooltip.brt-tooltip-dashed Autorun
          .brt-tooltiptext.brt-tooltip-top {{ tooltip.tour.autoRun.explained }}
      .col-2.col-lg-2.d-flex(
        :class="{\
          'justify-content-end' : !detailed,\
          'justify-content-center': detailed\
        }"
      )
        .brt-tooltip.brt-tooltip-dashed Widget visibility
          .brt-tooltiptext.brt-tooltip-left
            p.mb-1 {{ tooltip.tour.launcher.explained.primary }}
            p.brt-muted.font-italic.mb-0 {{ tooltip.tour.launcher.explained.secondary }}
      .col-2.col-lg-1(v-if="detailed") Details
      .col-2.col-lg-1(v-if="detailed") More
</template>

<script>
import { tooltip } from '../../jsons/tooltips.json';

export default {
  props: {
    domain: { type: Object, required: true },
    detailed: { type: Boolean, default: false }
  },
  data() {
    return {
      tooltip
    }
  },
};
</script>
