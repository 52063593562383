import InstrumentationProbe from './probe';
import GoogleAnalyticsClient from './clients/ga';
import GoogleTagManagerClient from './clients/gtag';

/**
 * The purpose of this class is to setup instrumentation probes.
 */
class Analytics {
  /**
   * Constructor.
   */
  constructor () {
    this.propertyIds = {
      presentation: 'UA-148482653-1',
      gallery: 'UA-148482653-2',
      extension: 'UA-148482653-3',
      include: 'UA-148482653-4',
      master: 'UA-148482653-6',
    };
    this.probeMap = new Map();
  }
  /**
   * [private] Creates and assigns to the property map the instrumentation probe.
   * @param {string} property {'presentation' | 'gallery' | 'extension' | 'include'}
   */
  _getProbe (property) {
    if (property !== 'presentation' && property !== 'gallery' &&  property !== 'extension' && property !== 'include') {
      throw new RangeError('Invalid property value');
    }

    if (this.probeMap.get(property)) {
      return this.probeMap.get(property);
    }

    const probe = new InstrumentationProbe();
    let ga;
    // tag manager is not compatible with the extension so we have to use an older client
    // there 3 google analytics client, ga.js, analytics.js and gtag.js. The ga.js is the oldest and we are not using it.
    const GoogleClient = document.location.protocol.includes('extension') ? GoogleAnalyticsClient : GoogleTagManagerClient;
    ga = new GoogleClient(this.propertyIds[property], true, true, property);
    probe.register(ga);

    // register master for hybrid mapping strategy
    ga = new GoogleClient(this.propertyIds.master, true, true, 'master');
    probe.register(ga);

    // add other clients to the probe.

    this.probeMap.set(property, probe);
    return probe;
  }
  /**
   * Retrieves the presentation probe.
   */
   getPresentationProbe () {
     return this._getProbe('presentation');
   }

  /**
   * Retrieves the gallery probe.
   */
  getGalleryProbe () {
    return this._getProbe('gallery');
  }

  /**
   * Retrieves the extension probe.
   */
  getExtensionProbe () {
    return this._getProbe('extension');
  }

  /**
   * Retrieves the include probe.
   */
  getIncludeProbe () {
    return this._getProbe('include');
  }
}

export default new Analytics();
