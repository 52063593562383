import axios from "axios";
import { auth } from '../utils/auth.js';

export const googleIdentity = {
  data: () => {
    return {
      googleIdentityOauth: {
        src: 'https://accounts.google.com/gsi/client',
        scriptId: 'google-identity-script',
        clientId: '1008022865658-9c2im1hilpnbq2pa3ki56p2cnd3j27ha.apps.googleusercontent.com',
        buttonId: 'google-signin-button',
      },
    }
  },
  computed: {
    scriptExists() {
      return document.getElementById(this.googleIdentityOauth.scriptId);
    }
  },
  methods: {
    addGoogleIdentityScript() {
      if (!this.scriptExists) {
        const gsi = document.createElement('script');
        gsi.src = this.googleIdentityOauth.src;
        gsi.defer = gsi.async = true;
        gsi.id = this.googleIdentityOauth.scriptId;
        document.head.appendChild(gsi);
      }

      let stateCheck = setInterval(() => {
        if (window.google && window.google.accounts) {
          clearInterval(stateCheck);
          this.initGoogleIdentityService();
        }
      }, 100);
    },
    initGoogleIdentityService() {
      try {
        window.google.accounts.id.initialize({
          client_id: this.googleIdentityOauth.clientId,
          callback: this.handleCredentialResponse
        });
        window.google.accounts.id.renderButton(
          document.getElementById(this.googleIdentityOauth.buttonId),
          { theme: 'outline', size: 'large' }
        );
      } catch (error) {
        // error when script it's not injected yet
        // this error can be ignored
        console.log(error);
      }
    },
    removeGoogleIndentityScript() {
      if (this.scriptExists) {
        document.getElementById(this.googleIdentityOauth.scriptId).remove();
      }
    },
    async handleCredentialResponse(details) {
      let registerUrl = `${this.$urlConfig.apiUrl}/user/connect/google`;

      if (this.$route.query.subscription && !registerUrl.includes('subscription')) {
        registerUrl = registerUrl.concat(`?subscription=${this.$route.query.subscription}`);
      }
      try {
        const response = await axios.post(registerUrl, { token: details.credential });
        if (response.status === 200) {
          auth.token = response.data.credentials.token;
          auth.profile = auth.token.split('.')[1];
          this.$router.push({ name: window.parent !== window ? 'editor' : 'dashboard' });
        } else if (response.status === 201) {
          this.$router.push({ name: 'accountConfirmation', params: { email: response.data.email } });
        }
      } catch (error) {
        this.$ebus.$emit('login:google:failed', error.response.data.message);
      }
    },
  }
}
