<template lang="pug">
  #run
    #run-header.d-flex.justify-content-between.align-items-center.py-3.px-2
      #title.ext-big-copy.w-75.pl-3(data-testid="tutorial-name").
        {{ name }}
      .w-25
        .subscription-pill.d-flex.justify-content-center
          span.brt-pill-text.caption {{ state }}
    #progress-header
      #progress-number.ext-xs-copy-up.brt-white.ml-3 Step {{ currentIndex + 1 }}/{{ steps.length }}
      #progress-bar(v-bind:style="{ width: `${(currentIndex + 1) * 100 / steps.length}%` }")
    #run-steps
      step-list(
        :steps="steps"
        :currentStep="currentIndex"
        :creating="false"
        :stepError="stepError"
        data-testid="steps-list"
      )
      #edit-tour-section.brt-bg-muted-faded.d-flex.align-items-center.justify-content-between.p-3(
        v-if="tutCompany === selectedCompany"
        :class="{'step-error': stepError !== false}"
        )
        span.body-copy.pl-4.ml-4 Edit options for step {{ currentIndex + 1 }}
        button.brt-btn.btn-primary.btn-small.px-3(@click='editTour') Edit
    button#stop-run-button.brt-btn.btn-big(@click="cancelTutorialRun" data-testid="create-button" title='Start creating a new tutorial')
      span End {{ state }}
</template>

<script>
import stepList from './stepList.vue';

export default {
  components: {
    'step-list': stepList
  },
  props:['name', 'description', 'steps', 'currentIndex', 'tutCompany', 'selectedCompany'],
  data() {
    return {
      state: 'Previewing',
      stepError: false
    }
  },
  watch: {
    currentIndex(newVal) {
      if (newVal !== this.stepError) {
        this.stepError = false;
      }
    }
  },
  mounted() {
    if(sessionStorage.getItem('testing')) {
      this.state = 'Testing';
    }
    this.$ebus.$on('highlightStep:error', (index) => {
      this.stepError = index;
    });
    this.$ebus.$on('highlightStep:error:remove', () => {
      this.stepError = false;
    });
  },
  methods: {
    cancelTutorialRun() {
      this.$parent.$emit('cancelRunning');
    },
    editTour() {
      this.$parent.$emit('onrunning:edit', this.currentIndex);
    }
  },
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  .step-error
    border-top 1px solid $brt-red-light
    border-bottom 1px solid $brt-red-light
    border-left 0.5px solid $brt-red-light
    border-right 0.5px solid $brt-red-light

    &:before
      top 24px
      left 30px 
      position absolute
      content 'i'
      width 24px
      height 24px
      text-align center
      color $brt-red-light
      border 2px solid $brt-red-light
      border-radius 50%
      line-height 1.2
  #run
    position relative
    height 100%
    #title
      display inline-block
      white-space nowrap
      overflow hidden !important
      text-overflow ellipsis
    .subscription-pill
      background-color $brt-cyan
      .brt-pill-text
        font-size .7rem
        text-transform uppercase
        letter-spacing 1px
        color $brt-white
    #progress-header
      height 1.6rem
      background-color alpha($brt-primary-gloom, 90%)
      #progress-number
        text-transform uppercase
        font-weight bold
        z-index 3
        position absolute
      #progress-bar
        z-index 2
        background-color $brt-secondary-dark
        height 100%
    #run-steps
      height calc(100% - 140px)
    #stop-run-button
      position absolute
      bottom 1%
    #edit-tour-section
      position relative
      .btn-big
        width auto!important
</style>
