<template lang="pug">
.step-optional-container.d-flex.justify-content-between.align-items-center
  .radio-buttons-container.d-flex
    .radio-group
      input#next-button(type="radio", :value="true", v-model="nextNav") 
      label(for="next-button") Next step button
    .radio-group.ml-3
      input#click-element(type="radio", :value="false", v-model="nextNav")
      label(for="click-element") Clicking on the element

  Icon-Tooltip(:text="tooltip.tour.step.navigation", direction="bottom")
</template>

<script>
import IconTooltip from "../../icons/IconTooltip.vue";
import { tooltip } from "../../../jsons/tooltips.json";
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  props: {
    nextNavigation: Boolean,
    index: Number
  },
  data() {
    return {
      nextNav: true,
      tooltip
    };
  },
  async mounted() {
    this.setInitialNavigation();
  },
  watch: {
    nextNavigation() {
      this.setInitialNavigation();
    },
    nextNav() {
      if (this.nextNav === this.nextNavigation) return;
      this.updateStepNavigation();
    }
  },
  methods: {
    setInitialNavigation() {
      this.nextNav = this.nextNavigation;
    },
    updateStepNavigation() {
      this.$ebus.$emit("tour/updateStepNavigation", {
        tutorial: this.tutorial,
        index: this.index,
        hasNext: this.nextNav
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
