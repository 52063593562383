<template lang='pug'>
  .brt-card
    .d-flex
      img(src='../../assets/misc/iconInfo.svg')
      p.mb-0.ml-3.brt-caption.brt-primary {{ title }}
    slot
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/colors.styl'
  .brt-card
    background-color $brt-secondary-faded
    height auto
</style>