<template lang="pug">
#experiences
  Experiences-Navigation(:activeTab="activeTab")
  Product-Tours(v-if="activeTab==='tours'")
  Launcher-Management(v-if="activeTab==='launcher'")
</template>

<script>
import ExperiencesNavigation from '../components/experiences/ExperiencesNavigation.vue';
import ProductTours from './ProductTours.vue'
import LauncherManagement from './LauncherManagement.vue';

export default {
  name: 'Experiences',
  components: { ExperiencesNavigation, ProductTours, LauncherManagement },
  data() {
    return {
      activeTab: 'tours',
    };
  },
  async mounted() {
    this.setNavbarTab();
    this.$on('changeTab', (tab) => {
      this.activeTab = tab;
    });
  },
  methods: {
    setNavbarTab() {
      const tabParam = this.$route.params.tab;
      if (tabParam) {
        this.activeTab = tabParam;
      }
    }
  },
};
</script>
