<template lang="pug">
  .brt-card.d-flex.justify-content-between
    .left-container.d-flex.flex-column.justify-content-between
      .brt-caption Team Members 
      h2.mt-2
        .skeleton.w-50(v-if="loading")  .
        div(v-else) {{ teamMembersCount }}
    .right-container.d-flex.align-items-end
      button.inverted-cta(@click="goToTeamManagement()") Manage Team

</template>

<script>
import { mapGetters } from 'vuex';
export default {
  methods: {
    goToTeamManagement() {
      this.$router.push({path: '/team'});
    }
  },
  computed: {
    ...mapGetters({
      loading: 'company/getLoadingTeamDetails',
      teamMembersCount: 'company/getTeamMembersCount'
    })
  }
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/functions.styl'

  .brt-card
    background-image url('../../assets/team/team-spirit.svg')
    background-size contain
    background-position right
    background-repeat no-repeat
</style>
