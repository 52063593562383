<template lang='pug'>
  .container.launcher-onboarding-container.mt-5
    .row.d-flex.justify-content-center
      .col-12.col-lg-8.col-xl-6
        .top-container.pb-4.mb-5.brt-dark
          h3.brt-primary-dark.mb-4.text-center.
            What is the Launch Widget?
          p.mb-4.
            The Launch Widget is an UI element that can help guiding and onboarding your users. It can appear on the bottom of your webpage.
          p.
            Create and manage within the widget a list of tours relevant for your users.
    .row.d-flex.justify-content-center
      .col-12.col-lg-8.col-xl-6
        p.brt-caption.brt-dark.mb-4 HOW CAN I USE THE LAUNCH WIDGET?
        .body-copy.steps-container.mb-3
          p.launcher-step
            span.brt-pill.brt-white.brt-bg-secondary-dark 1
            span You need to have at least 1 tour created
          p.launcher-step
            span.brt-pill.brt-white.brt-bg-secondary-dark 2
            span You need to have the Bytes Route script installed on a domain.
        .image-container.d-flex.justify-content-center.py-4
          img.ml-2(src='/assets/launcher/launcher-abstract.svg' width="50%")
        .d-flex.justify-content-center
          Launcher-External-Links
</template>

<script>
import LauncherExternalLinks from './LauncherExternalLinks.vue';

export default {
  components: { LauncherExternalLinks },
}
</script>

<style lang="stylus" scoped>
  @require '../../utils/common/styleguide/functions.styl'
  @require '../../utils/common/styleguide/colors.styl'

  .top-container
    border-bottom 1px solid $brt-muted
  
  .launcher-step
    margin-bottom 1rem
    span
      margin-right 0.5rem
</style>