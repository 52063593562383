 
<template lang="pug">
  .manager-container
</template>

<script>
import { handleError } from '../../../mixins/handleError';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError],
  data() {
    return {
      tutorial: {}
    }
  },
  mounted() {
    this.$ebus.$on("tour/updateLanguage", update => {
      this.tutorial = update.tutorial;
      this.updateTourLanguage(update.language);
      this.tutorial.language = update.language;
      this.$store.commit('tutorial/setSelectedTutorial', this.tutorial)
    });
  },
  methods: {
    async updateTourLanguage(language) {
      const query = `${this.$config.default.apiUrl}/tutorial/${this.tutorial._id}`;
      try {
        await this.$http.put(query, { language });
        this.$toast("Tour language updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour language. Please retry", { type: TYPE.ERROR });
      }
    },
  }
}
</script>
