<template lang="pug">
  .copy-script-string
    Copy-Text(:copyString="this.scriptString")
</template>

<script>
import CopyText from '../../aspect/CopyText.vue';
import { mapGetters } from 'vuex';

export default {
  components: { CopyText },
  computed: {
    scriptString() {
      // eslint-disable-next-line no-useless-escape
      let str = `<script id="brt-script" src="https://app.bytesroute.com/script.js?id=${this.teamID}"><\/script>`;
      return str;
    },
    ...mapGetters({
      teamID: 'company/getTeamID'
    })
  }
}
</script>