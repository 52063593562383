<template lang="pug">
  .outer-container
    .d-flex.justify-content-between.align-items-center.mb-3
      .text-container
        .body-copy Autorun delay
        .info.font-italic.small-copy(:class="{'brt-danger': !validDelayValue, 'brt-muted': validDelayValue}").
          number between 0 and 10000 ms
      .right-container.d-flex.justify-content-between.align-items-center.position-relative
        input#delay-input.brt-input.mr-1(
          v-model="delay"
          @focus="startDelayEdit()"
          :disabled="inputDisabled"
          :class="{'cursor-disabled': !domain.hasScript}"
          :title="delayTitle"
          type="number"
          min="0" max="10000" step="500"
          data-testid="delay-input")
        span.mr-2 ms
        Icon-Tooltip(:text="tooltip.tour.delay.explained")
    Confirm-Edit(
      :show="delayEditMode"
      :saveCb="updateDelay"
      :cancelCb="cancelDelayEdit"
    )
</template>

<script>
import IconTooltip from '../../icons/IconTooltip.vue';
import ConfirmEdit from '../../aspect/ConfirmEdit.vue';
import { tooltip } from '../../../jsons/tooltips.json';
import { mapGetters } from 'vuex';

export default {
  components: { IconTooltip, ConfirmEdit },
  data() {
    return {
      delayEditMode: false,
      delay: 0,
      validDelayValue: true,
      tooltip
    }
  },
  async mounted() {
    this.delay = this.tutorial.options.delay;
  },
  methods: {
    startDelayEdit() {
      this.delayEditMode = true;
    },
    cancelDelayEdit() {
      this.delayEditMode = false;
      this.validDelayValue = true;
      this.delay = this.tutorial.options.delay;
    },
    async updateDelay() {
      if (this.validDelay() && this.userEnteredNewValue()) {
        this.$ebus.$emit("tour/updateAutoRunDelay", {
          tutorial: this.tutorial,
          delay: this.delay
        });
      }
      this.delayEditMode = false;
    },
    validDelay() {
       if (this.delay >= 0 && this.delay <= 10000 && this.isPositiveNumber(this.delay)) {
        this.validDelayValue = true;
      } else {
        this.validDelayValue = false;
      }
      return this.validDelayValue;
    },
    userEnteredNewValue() {
      return (this.delay != this.tutorial.options.delay)  ? true : false;
    },
    isPositiveNumber(value) {
      return /^\d+$/.test(value);
    },
  },
  computed: {
    delayTitle() {
      const titleWithDomainScript = 'How much delay to launch the tour with';
      const titleWithNODomainScript = 'Cannot add delay when the script is not present on this domain'
      return (this.domain.hasScript) ? titleWithDomainScript : titleWithNODomainScript;
    },
    inputDisabled() {
      return (
        this.loading || 
        !this.domain.hasScript || 
        !this.tutorial.options.autoRun
      )
    },
    ...mapGetters({
      tutorial: 'tutorial/getSelectedTutorial',
      domain: 'tutorial/getDomain',
      loading: 'tutorial/getLoading'
    })
  }
};
</script>

<style lang="stylus" scoped>
  @require '../../../utils/common/styleguide/colors.styl'
  @require '../../../utils/common/styleguide/functions.styl'
    
  #delay-input
    height 1.5rem
    width 6rem
    font-size 1rem
    padding-bottom 1rem
    
  .cursor-disabled
    cursor not-allowed
</style>
