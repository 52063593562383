<template lang="pug">
  div#trash-container(@click.stop='remove' data-testid="trash-button")
    icon-delete#trash(width="1.3rem" height="1.3rem" aria-label="deleteIcon")
    span.ext-body Delete
</template>

<script>
import iconDelete from '../../images/iconDelete.vue';

export default {
  props: {
    forWhat: { type: String, default: '' }
  },
  components: {
    'icon-delete': iconDelete,
  },
  methods: {
    remove() {
      this.$parent.$emit(`tutorial:delete${this.forWhat}`);
    },
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  #trash-container
    color $brt-primary-dark
    cursor pointer
    #trash
      display block
      width 100%
      text-align center
      stroke $brt-primary-dark
    &:hover
      color $brt-danger
      #trash
        stroke $brt-danger
</style>
