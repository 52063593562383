<template lang="pug">
Modal(v-if="show"
  primaryOFF="Reset all ANALYTICS DATA for this tour? This is an irreversible action"
  confirmTextOFF="Reset"
  :highlight="tutorial.name"
  :confirmCb="resetTourStats"
  :cancelCb="() => { this.show = false }"
)
</template>

<script>
import Modal from "../../aspect/Modal.vue";
import { handleError } from "../../../mixins/handleError";
import updateTeamTours from '../mixins/updateTeamTours'
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTeamTours],
  components: { Modal },
  data() {
    return {
      tutorial: {},
      show: false,
    };
  },
  mounted() {
    this.$ebus.$on("tour/resetAnalytics", (tutorial) => {
      this.tutorial = tutorial;
      this.show = true;
    });
  },
  methods: {
    async resetTourStats() {
      try {
        this.show = false;
        await this.$http.put(`${this.$config.default.apiUrl}/tutorial/stats/${this.tutorial._id}/reset`);

        await this.updateTeamTours();
        await this.$store.dispatch('tutorial/getTutorialFromDB', this.tutorial._id);
        this.$toast("Tour stats reset", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error reseting tour stats. Please retry", {
          type: TYPE.ERROR
        });
      }
    }
  }
};
</script>
