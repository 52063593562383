import handleError from "../../commonjs/handleError.js";

export default {
  namespaced: true,
  state: () => ({
    teams: [],
    selectedTeam: {},
    plan: {},
    planTimeLeft: 0,
    themes: [],
    domains: {
      all: [],
      withTours: [],
      withoutTours: [],
      withScript: [],
      withoutScript: []
    },
    selectedDomain: {},
    loading: {
      getTeams: false,
      teamDetails: false,
      teamTours: false,
      teamThemes: false
    }
  }),
  getters: {
    // team generic getters
    getTeams(state) {
      return state.teams;
    },
    getTourCount(state) {
      const tourCount = {
        total: state && state.selectedTeam && state.selectedTeam.totalTutorials ? state.selectedTeam.totalTutorials.length : 0,
        totalActive: state && state.selectedTeam && state.selectedTeam.activeTutorials ? state.selectedTeam.activeTutorials.length : 0
      }
      return tourCount;
    },
    getActiveToursCount(state) {
      return (
        state.selectedTeam.activeTutorials &&
        state.selectedTeam.activeTutorials.length
      )
    },
    getTeamID(state) {
      return state.selectedTeam._id
    },
    getTeamName(state) {
      return state.selectedTeam.name;
    },
    getTeamOwnerID(state) {
      return state.selectedTeam.owner;
    },
    getTeamTours(state) {
      return state.selectedTeam.totalTutorials;
    },
    getTeamMembers(state) {
      return state.selectedTeam.members
    },
    getTeamMembersCount(state) {
      return (
        state.selectedTeam.members &&
        state.selectedTeam.members.length
      )
    },
    getThemes(state) {
      return state.themes;
    },
    // domain getters
    getSelectedDomain(state) {
      return state.selectedDomain;
    },
    getAllDomainsTours(state) {
      return state.domains.all;
    },
    getDomainsWithScript(state) {
      return state.domains.withScript;
    },
    getDomainsWithTours(state) {
      return state.domains.withTours;
    },
    getDomainsWithoutTours(state) {
      return state.domains.withoutTours;
    },
    getDomainUrl(state) {
      return state.selectedDomain.url
    },
    getSelectedDomainTourCount(state) {
      return (
        state.selectedDomain.tutorials &&
        state.selectedDomain.tutorials.length
      )
    },
    getSelectedDomainHasScript(state) {
      return (
        state.selectedDomain.hasScript
      )
    },
    // launcher getters
    getLauncherPosition(state) {
      if (state.selectedDomain.launcher) {
        return state.selectedDomain.launcher.position;
      } else {
        return false;
      }
    },
    getLauncherVisibility(state) {
      if (state.selectedDomain.launcher) {
        return state.selectedDomain.launcher.visibility;
      } else {
        return false;
      }
    },
    // loading getters
    getLoadingGetTeams(state) {
      return state.loading.getTeams;
    },
    getLoadingTeamDetails(state) {
      return state.loading.teamDetails;
    },
    getLoadingTeamTours(state) {
      return state.loading.teamTours;
    },
    getLoadingTeamThemes(state) {
      return state.loading.teamThemes
    },
    // plan getters
    companyTrialUsed(state) {
      return state.selectedTeam.subscription.trialUsed
    },
    planIsTrial(state) {
      return state.plan.isTrial
    },
    planHasThemes(state) {
      return state.plan.themes;
    },
    planHasLanguages(state) {
      return state.plan.languages;
    },
    planHasSubdomains(state) {
      return state.plan.subdomains;
    },
    planHasAdvancedAnalytics(state) {
      return state.plan.advancedAnalytics;
    },
    getTeamPlan(state) {
      return state.selectedTeam.subscription
    },
    getTeamPlanTier(state) {
      return state.plan.tier
    },
    getTeamPlanName(state) {
      return state.plan.name;
    },
    getPlanTeamMembersCount(state) {
      return state.plan.teamMembers;
    },
    getPlanActiveToursCount(state) {
      return state.plan.activeTutorials;
    },
    planActiveTourLimitHit(state) {
      let totalActiveTours = 0;

      if (state.selectedTeam.activeTutorials) {
        totalActiveTours = state.selectedTeam.activeTutorials.length;
      }
      const planActiveTours = state.plan.activeTutorials;
      return totalActiveTours >= planActiveTours;
    },
    planTeamLimitHit(state) {
      let memberCount;

      if (state.selectedTeam.members) {
        memberCount = state.selectedTeam.members.length;
      }
      const planMembers = state.plan.teamMembers;

      return memberCount >= planMembers;
    },
    teamPlanExpirationDate(state) {
      const options = {year: 'numeric', month: 'short', day: 'numeric' };

      if (state.selectedTeam.subscription) {
        const date = new Date(state.selectedTeam.subscription.endDate);
        return date.toLocaleDateString("en-US", options);
      } else {
        return '-'
      }
    },
  },
  mutations: {
    updateSelectedTeamInDropdown(state , id) {
      for (let i = 0; i < state.teams.length; i++) {
        if (state.teams[i]._id === id) {
          state.teams[i].selected = true;
        } else {
          state.teams[i].selected = false;
        }
      }
    },
    setTeamLoadingToTrue(state) {
      state.loading.teamDetails = true;
      state.loading.teamTours = true;
      state.loading.teamThemes = true;
    },
    setTeams(state, teams) {
      state.teams = teams;
    },
    setSelectedTeamData(state, team) {
      state.selectedTeam = team
    },
    setDomains(state, domains) {
      state.domains.all = domains.domains;
      state.domains.withTours = domains.domainsWithTours;
      state.domains.withoutTours = domains.domainsWithoutTours;
      state.domains.withScript = domains.domainsWithScript;
      state.domains.withoutScript = domains.domainsWithoutScript;
    },
    setThemes(state, themes) {
      state.themes = themes;
    },
    setLoading(state, payload) {
      state.loading[payload.key] = payload.loading;
    },
    setPlan(state, plan) {
      state.plan = plan.details;
      state.plan.isTrial = plan.isTrial;
    },
    setPlanTimeLeft(state, timeLeft) {
      state.planTimeLeft = timeLeft
    },
    setInitialSelectedDomain(state) {
      state.selectedDomain = {};
      if (state.domains.all.length) {
        // search storage for a domain
        const storageDomain = localStorage.getItem('brt-selected-launcher-domain');

        // check if exists and if it is in the domains list
        if (storageDomain) {
          const domainIndex = state.domains.all.findIndex(domain => domain.url === storageDomain)

          if (domainIndex != -1) {
            // if it is, set it as the selectedDomain
            state.selectedDomain = state.domains.all[domainIndex];
            return
          }
        }
        // otherwise set the first domain in the list as the selectedDomain
        state.selectedDomain = state.domains.all[0];
        localStorage.setItem('brt-selected-launcher-domain', state.selectedDomain.url)
      }
    },
    updateSelectedDomain(state, domain) {
      state.selectedDomain = domain;
      localStorage.setItem('brt-selected-launcher-domain', state.selectedDomain.url);
    }
  },
  actions: {
    setSelectedTeam({ commit, state }, id) {

      let storageTeamID = localStorage.getItem("brt-selected-team");
      let selectedTeamID;

      if (id) {
        // 1) try to set selected team from the id param, if present
        selectedTeamID = id;
      } else if (storageTeamID) {
        // 2) if not, try to read from local storage the id of the selected team
        selectedTeamID = storageTeamID;
      } else {
        // 3) otherwise, make the first team in the list selected
        selectedTeamID = state.teams[0]._id;
      }
      // check a team with this selectedTeamID exists in the array
      let selectedTeamIndex = state.teams.findIndex(
        item => item._id == selectedTeamID
      );
      if (selectedTeamIndex == -1) {
        localStorage.removeItem("brt-selected-team");
        selectedTeamIndex = 0;
      }

      commit("setSelectedTeamData", state.teams[selectedTeamIndex])
      commit("updateSelectedTeamInDropdown", selectedTeamID);

      // update local storage
      localStorage.setItem("brt-selected-team", state.selectedTeam._id);
    },
    async getTeams({ commit, state }) {
      commit("setLoading", {
        key: "getTeams",
        loading: true
      });
      commit("setTeams", []);

      try {
        const res = await this._vm.$http.get(
          `${this._vm.$config.default.apiUrl}/user/companies/`
        );
        const rawTeams = res.data.companies;
        const cleanTeams = [];
        rawTeams.forEach(rawTeam => {
          let newTeam = {
            _id: rawTeam._id,
            name: rawTeam.name,
            selected: false
          };
          cleanTeams.push(newTeam);
        });

        commit("setTeams", cleanTeams);
        commit("updateSelectedTeamInDropdown", state.selectedTeam._id);
      } catch (err) {
        console.log(err);
      } finally {
        commit("setLoading", { key: "getTeams", loading: false });
      }
    },
    async getSelectedTeamData({ dispatch, commit, state }) {
      if (state.selectedTeam._id) {
        commit("setTeamLoadingToTrue");

        await dispatch("getSelectedTeamDetails");
        await dispatch("getSelectedTeamTours");
        await dispatch("getPlanTimeLeft");
        await dispatch("getSelectedTeamThemes");
      }
    },
    async getSelectedTeamDetails({ commit, state }) {
      try {
        commit("setLoading", { key: "teamDetails", loading: true });
        const response = await this._vm.$http.get(
          `${this._vm.$config.default.apiUrl}/company/${state.selectedTeam._id}`
        );

        commit("setSelectedTeamData", response.data);

        commit("setPlan", {
          details:  response.data.subscription.details,
          isTrial: response.data.subscription.isTrial
        });

      } catch (err) {
        handleError(err);
      } finally {
        commit("setLoading", { key: "teamDetails", loading: false });
      }
    },
    async getSelectedTeamTours({commit, state}) {
      try {
        commit("setLoading", { key: "teamTours", loading: true });
        const response = await this._vm.$http.get(
          `${this._vm.$config.default.apiUrl}/company/${
            state.selectedTeam._id
          }/domains/extended`
        );
        commit("setDomains", response.data);
        commit("setInitialSelectedDomain");
      } catch (err) {
        handleError(err);
      } finally {
        commit("setLoading", { key: "teamTours", loading: false });
      }
    },
    async getSelectedTeamThemes({commit, state}) {
      try {
        commit("setLoading", { key: "teamThemes", loading: true });

        const result = await this._vm.$http.get(
          `${this._vm.$config.default.apiUrl}/theme/company/${
            state.selectedTeam._id
          }`
        );
        commit("setThemes", result.data);
      } catch (err) {
        handleError(err);
      } finally {
        commit("setLoading", { key: "teamThemes", loading: false });
      }
    },
    async getPlanTimeLeft({commit, state}) {
      try {
        const timeReq = await this._vm.$http.get(
          `${this._vm.$config.default.apiUrl}/company/${
            state.selectedTeam._id
          }/subscription/left`
        );
        commit("setThemes", timeReq.data.subscriptionTimeLeft);
      } catch (err) {
        handleError(err);
      }
    }
  }
};
