<template lang="pug">
  Modal(v-if="show"
    emoji="👋"
    titleText="Remove team member"
    secondary="Do you want to remove this team member?"
    confirmTextOFF="Remove"
    :highlight="member.email"
    cancelText="Close"
    :confirmCb="removeMember"
    :cancelCb="() => { this.show = false }"
  )
</template>

<script>
import Modal from '../../aspect/Modal.vue';

import { handleError } from '../../../mixins/handleError';
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

export default {
  mixins: [handleError],
  components: { Modal },
  data() {
    return {
      show: false,
      member: {}
    }
  },
  mounted() {
    this.$ebus.$on('company/removeTeamMember', (member) => {
      this.member = member;
      this.show = true;
    })
  },
  methods: {
    async removeMember() {
      const url = `${this.$config.default.apiUrl}/company/${this.teamID}/removeMember`;
      try {
        this.showTeamTableSpinner = true;
        const res = await this.$http.put(url, {
          memberId: this.member._id
        });
        if (res.status === 200) {
          await this.$store.dispatch("company/getSelectedTeamDetails");
          this.$toast("Team member removed", { type: TYPE.SUCCESS });
        }
      } catch (err) {
        console.error(err);
        this.$toast("Error removing team member. Please retry", { type: TYPE.ERROR });
      } finally {
        this.show = false
      }
    },
  },
  computed: {
    ...mapGetters({
      teamID: 'company/getTeamID'
    })
  }
}
</script>
