class CustomBus {

  constructor() {
    this.key = btoa('token');
  }

  emitTknToExt(token) {
    const setToken = new CustomEvent('setToken', {
      detail: {
        key: this.key,
        newValue: token,
      },
    });
    document.dispatchEvent(setToken);
  }

  unsetTknInExt() {
    const unsetToken = new CustomEvent('unsetToken', {
      detail: {
        key: this.key,
      },
    });
    document.dispatchEvent(unsetToken);
  }
}

export const cBus = new CustomBus();
