<template lang='pug'>
  .brt-card
    .d-flex.align-items-center.mb-4
      p.brt-caption.mb-0.mr-2 Visibility
      Icon-Tooltip(
        :text="tooltip.launchWidget.visibility.primary"
        :secondary="tooltip.launchWidget.visibility.secondary"
        offsetY='-0.4'
      )
    p Where to display the Launch Widget on the domain

    .radio-buttons-container
      .radio-group
        input#visibility-all(type='radio' value="all" v-model='visibility' )
        label(for='visibility-all') Display widget on all pages of the domain
      .radio-group
        input#visibility-related(type='radio' value="related" v-model='visibility' ) 
        label(for='visibility-related') Display only on the pages where tours exist

</template>

<script>
import IconTooltip from '../icons/IconTooltip.vue';
import { tooltip } from '../../jsons/tooltips.json';
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

export default {
  components: { IconTooltip },
  data() {
    return {
      visibility: false,
      modified: false,
      tooltip
    }
  },
  mounted() {
    this.visibility = this.launcherVisibility
  },
  watch: {
    visibility() {
      this.updateVisibility();
    },
    launcherVisibility() {
      this.visibility = this.launcherVisibility
    }
  },
  methods: {
    async updateVisibility() {
      if (this.visibility === this.launcherVisibility && !this.modified) {
        return
      }
      try {
        const teamID = this.teamID
        const url = `${this.$config.default.apiUrl}/company/${teamID}/launcher/visibility`;
        const reqBody = {
          visibility: this.visibility,
          domain: this.domainURL
        }
        const result = await this.$http.put(url, reqBody);
        if (result.status === 200) {
          this.$toast("Launch widget visibility updated", { type: TYPE.SUCCESS });
          this.modified = true
        }
      } catch (error) {
        console.error(error);
        this.$toast("Error updating visibility. Please retry", { type: TYPE.ERROR });
      } 
    }
  },
  computed: {
    ...mapGetters({
      teamID: 'company/getTeamID',
      domainURL: 'company/getDomainUrl',
      launcherVisibility: 'company/getLauncherVisibility'
    })
  }
}
</script>