<template lang="pug">
  .container.text-center
    .row
      .col-12
        h3.mt-5.pt-5 Bye-Bye 👋🏻
        p.body-copy.font-regular You signed out successfully

</template>

<script>
import { auth } from '../../utils/auth';
export default {
mounted() {
    auth.signOut();
  }
};
</script>
