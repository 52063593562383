<template lang="pug">
.delete-container
  button.secondary-button.danger-button(@click="deleteStep()")
    svg(width="1.2rem", height="1.2rem")
      use(href="../../../assets/misc/iconDelete.svg#Layer_1")
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: { index: Number },
  methods: {
    deleteStep() {
      this.$ebus.$emit("tour/deleteStep", {
        tutorial: this.tutorial,
        stepIndex: this.index
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
