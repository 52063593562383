<template lang="pug">
  #edit-button.d-inline-block.text-center(@click.stop="edit")
    .position-relative
      icon-edit#copy(width='1.2rem' height='1.2rem' aria-label='copyIcon')
    span.ext-body Edit
</template>

<script>
import iconEdit from '../../images/iconEdit.vue'

export default {
  components: {
    'icon-edit': iconEdit,
  },
  methods: {
    edit() {
      this.$parent.$emit('edit:edit');
    }
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  #edit-button
    cursor pointer
    color $brt-primary-dark
    stroke $brt-primary-dark
    width 3rem
    &:hover
      color $brt-primary-bright
      stroke $brt-primary-bright
</style>
