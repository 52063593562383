<template lang="pug">
  .onboard-container
    Modal-Team-Invite(v-if="showModalTeamInvite")
    Modal-Welcome(v-if="showModalWelcome")
    Modal-No-Script(v-if="showModalScriptNotFound")
    Modal-Create-Tour(v-if="showModalCreateTour")
</template>

<script>
import ModalWelcome from './modals/ModalWelcome.vue';
import ModalNoScript from './modals/ModalNoScript.vue';
import ModalTeamInvite from './modals/ModalTeamInvite.vue';
import ModalCreateTour from './modals/ModalCreateTour.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ModalWelcome, ModalNoScript, ModalTeamInvite, ModalCreateTour },
  computed: {
    ...mapGetters({
      showModalScriptNotFound: 'onboarding/showModalScriptNotFound',
      showModalWelcome: 'onboarding/showModalWelcome',
      showModalTeamInvite: 'onboarding/showModalTeamInvite',
      showModalCreateTour: 'onboarding/showModalCreateTour'
    }),
  }
}
</script>
