<template lang="pug">
  #confirmation-dialog(
    @click.stop=""
  )
    #dialog-body.d-flex.flex-column.align-items-center(
      :class="!!image ? 'h-75 justify-content-center' : 'h-50 justify-content-end'"
    )
      img.pb-5(v-show='!!image' :src='image', alt="dialog_image" height="200px" width="auto")
      .big-copy.font-semibold.brt-primary-dark.mx-3.text-center.mb-3 {{dialogTitle}}
      .body-copy.brt-dark.bolder.mx-3.text-center.mb-2(
        v-for="(message, index) in dialogMessages"
        ) {{ message }}
    #dialog-buttons.d-flex.flex-column.justify-content-end.align-items-center(
      :class="!!image ? 'h-25' : 'h-50'"
    )
      button.brt-btn.btn-primary.btn-big.mb-2.w-95(@click.stop="cofirm()" data-testid="confirm-button") {{confirmButtonText}}
      button.brt-btn.btn-secondary.btn-big.w-95.mb-2(@click.stop="cancel()" data-testid="cancel-button") {{cancelButtonText}}
</template>

<script>

export default {
  props: {
    image: { type: String, default: '' },
    dialogTitle: { type: String, default: 'Are you sure?' },
    dialogMessages: { type: Array, default: ['Confirm removal'] },
    confirmButtonText: { type: String, default: 'Delete' },
    cancelButtonText: { type: String, default: 'Cancel' },
    usedFor: { type: String, default: 'delete'}
  },
  methods: {
    cofirm() {
      this.$parent.$emit('confirmDialog:confirm', this.usedFor);
    },
    cancel() {
      this.$parent.$emit('confirmDialog:cancel', this.usedFor);
    },
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  
  .w-95
    width 95%
  #confirmation-dialog
    min-width 400px
    cursor default
    position absolute
    left 0
    top 0
    height 100%
    width 100%
    background-color $brt-white
    z-index 115
</style>
