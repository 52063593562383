<template lang="pug">
  #getting-started(
    data-testid="gettingStarted"
    :class="{'container': onboardStep >= 3, 'container-fluid max-width' : onboardStep < 3}"
  )
    Onboard-Main(data-testid="onboarding")
</template>

<script>
import OnboardMain from "../components/onboard/main/OnboardMain.vue";
import { mapGetters } from "vuex";

import { handleError } from "../mixins/handleError.js";

export default {
  name: "GettingStarted",
  metaInfo: {
    title: "Your Bytes Route web application getting started",
    meta: [
      { name: "description", content: "Onboarding process to get you started with Bytes Route" }
    ]
  },
  mixins: [handleError],
  components: {
    OnboardMain,
  },
  computed: {
    ...mapGetters({
      onboardStep: "onboarding/getOnboardStep"
    })
  }
};
</script>
