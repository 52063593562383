<template lang='pug'>
  .brt-card
    .d-flex.align-items-center.mb-4
      p.brt-caption.mb-0.mr-2 Positioning
      Icon-Tooltip(:text="tooltip.launchWidget.positioning" offsetY='0.3')
    p Where do you want the Launch Widget to be positioned on the screen?

    .radio-buttons-container
      .radio-group
        input#pos-bottom-left(type='radio' :value=0 v-model='positioning')
        label(for='pos-bottom-left') Bottom left of the screen
      .radio-group
        input#pos-bottom-right(type='radio' :value=1 v-model='positioning') 
        label(for='pos-bottom-right') Bottom right of the screen

</template>

<script>
import IconTooltip from '../icons/IconTooltip.vue';
import { tooltip } from '../../jsons/tooltips.json';
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

export default {
  components: { IconTooltip },
  data() {
    return {
      positioning: -1,
      positionPresets: [
        { x: 'left',  y: 'bottom' },
        { x: 'right', y: 'bottom' }
      ],
      modified: false,
      tooltip
    }
  },
  mounted() {
   this.setInitialPosition()
  },
  watch: {
    positioning() {
      this.updateLaunchWidgetPosition();
    }
  },
  methods: {
    setInitialPosition() {
       const currPos = this.launcherPosition;

      // find index of current positioning
      const presetIndex = this.positionPresets.findIndex((preset) => {
        return (
            preset.x === currPos.x
          && preset.y === currPos.y
        )
      });

      // if a valid positioning template index is found, bind it to the positioning index
      if (presetIndex != -1) {
        this.positioning = presetIndex;
      }
    },
    async updateLaunchWidgetPosition() {
      
      const currPos = this.launcherPosition;
      const newPos = this.positionPresets[this.positioning];

      const isUpdatingSamePosition = (
        currPos.x === newPos.x && 
        currPos.y === newPos.y
      )

      // if it's the same position and not modified, do NOT update 
      if (isUpdatingSamePosition && !this.modified) {
        return
      }

      // otherwise perform the position update
      try {
        const teamID = this.teamID
        const url = `${this.$config.default.apiUrl}/company/${teamID}/launcher/position`;
        const reqBody = {
          position: this.positionPresets[this.positioning],
          domain: this.domainURL
        }
        const result = await this.$http.put(url, reqBody);
        if (result.status === 200) {
          this.$toast("Launch widget position updated", { type: TYPE.SUCCESS });
          this.modified = true
        }

      } catch (error) {
        console.error(error);
        this.$toast("Error updating position. Please retry", { type: TYPE.ERROR });
      } 

    }
  },
  computed: {
    ...mapGetters({
      domainURL: 'company/getDomainUrl',
      launcherPosition: 'company/getLauncherPosition',
      teamID: 'company/getTeamID'
    })
  }
}
</script>