<template lang="pug">
  Toggle-Switch(
    v-model="hasBackgrnd"
    titleEnabled="Toggle background for a modal"
    :disabled="false"
    @change="updateHasBackgrnd()"
    data-testid="hasBackgrndToggle")
</template>

<script>
import IconTooltip from "../../icons/IconTooltip.vue";
import ToggleSwitch from '../../../utils/common/vuecomps/ToggleSwitch.vue';
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip, ToggleSwitch },
  props: {
    hasBackground: Boolean,
    index: Number
  },
  data() {
    return {
      hasBackgrnd: true
    };
  },
  async mounted() {
    this.setInitialValue();
  },
  watch: {
    hasBackground() {
      this.setInitialValue();
    }
  },
  methods: {
    setInitialValue() {
      this.hasBackgrnd = this.hasBackground;
    },
    updateHasBackgrnd() {
      this.$ebus.$emit("tour/updateStepHasBackground", {
        tutorial: this.tutorial,
        index: this.index,
        hasBackground: !this.hasBackgrnd
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
