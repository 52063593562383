<template lang="pug">
  .outer-container
    Modal-Reset-Password(
      v-if="showPassResetPopup"
      :closeCb="() => { showPassResetPopup = false }"
      :email="userEmail"
    )
    .brt-dropdown-container(
      v-if="showProfileDropdown"
      v-click-outside="hideProfileDropdown"
      @click="toggleDropdown('profile-dropdown')"
      data-testid="profile-dropdown"
    )
      .brt-dropdown-trigger.nav-elem.nav-dropdown.d-flex
        span {{ userName }}
        img.ml-2(src='/assets/misc/chevron.svg' width="18px" alt="chevron arrow up")
      #profile-dropdown.brt-dropdown-list
        .brt-dropdown-item(
          @click="showPassResetPopup = true"
          title="Initiate a password reset"
        )
          span Reset Password
        .brt-dropdown-item(
          @click="logout()"
          title="Log out of this session"
          data-testid="logout-button"
        )
          span.brt-danger Logout

</template>

<script>
import ModalResetPassword from './ModalResetPassword.vue';

import { auth } from '../../utils/auth';
import { ClickOutside } from '../../directives/clickOutside.js';
import { dropDownToggle } from '../../mixins/dropDownToggle.js';

export default {
  components: { ModalResetPassword  },
  directives: { 'click-outside': ClickOutside },
  mixins: [ dropDownToggle ],
  data() {
    return {
      showPassResetPopup: false,
      showProfileDropdown: false,
    };
  },
  mounted() {
    this.$ebus.$on('User:SignedOut', () => {
      this.showProfileDropdown = false;
    });
    this.showProfileDropdown = auth.isAuthenticated();
  },
  methods: {
    hideProfileDropdown() {
      this.hideDropDown('profile-dropdown');
    },
    logout() {
      auth.signOut();
      this.$router.push({name: 'login'});
    },
  },
  computed: {
    userEmail() {
      return auth.email;
    },
    userName() {
      return auth.username
    },
  }
};
</script>

<style lang="stylus" scoped>
@require '../../utils/common/styleguide/colors.styl'
@require '../../utils/common/styleguide/functions.styl'

#profile-dropdown
  right 0rem

</style>
