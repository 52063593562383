<template lang='pug'>
  .brt-card
    .d-flex.align-items-center.mb-4
      p.brt-caption.mb-0.mr-2 Description Text
      Icon-Tooltip(
        direction="top"
        :text="tooltip.launchWidget.description")
    p Provide a short description text for the Launch Widget.

    form(@submit.prevent="")
      .launch-description-container
        input.brt-input(
          placeholder="How it works"
          v-model='description'
          @input='enterEditMode'
          brt-validate='notEmpty|alphaExtended|3-20'
          maxlength='20'
          :disabled='loading')
      Confirm-Edit.mt-4(
        :show="editMode"
        :saveCb="updateDescription"
        :cancelCb="cancelEditMode"
        :loading="loading"
      )
</template>

<script>
import IconTooltip from '../icons/IconTooltip.vue';
import { tooltip } from '../../jsons/tooltips.json';
import ConfirmEdit from '../aspect/ConfirmEdit.vue';
import Validator from '../../utils/common/utils/validator.js';
import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

export default {
  name: 'LaunchWidgetDescription',
  components: { IconTooltip, ConfirmEdit },
  data() {
    return {
      loading: false,
      description: '',
      editMode: false,
      validator: '',
      tooltip
    }
  },
  mounted() {
    this.validator =  new Validator(document.querySelector('.launch-description-container'));
    this.description = this.domain.launcher.description;
  },
  watch: {
    domain() {
      this.description = this.domain.launcher.description;
    }
  },
  methods: {
    async updateDescription() {
      this.validator.removeErrors();
      if(this.validator.validateFields()){
        await this.sendUpdateDescriptionRequest();
      }
    },
    async sendUpdateDescriptionRequest() {
      try {
        this.loading = true;
        const selectedTeamID = this.teamID;
        const url = `${this.$config.default.apiUrl}/company/${selectedTeamID}/launcher/description`;
        const reqBody = {
          description: this.description,
          domain: this.domain.url
        }
        const result = await this.$http.put(url, reqBody);
        if (result.status === 200) {
          this.$toast("Launch widget description updated", { type: TYPE.SUCCESS });
          await this.$store.dispatch("company/getSelectedTeamTours");
          this.editMode = false;
        }
      } catch (error) {
        console.error(error);
        this.$toast("Error updating description. Please retry", { type: TYPE.ERROR });
      } finally {
        this.loading = false;
      }
    },
    enterEditMode() {
      this.editMode = true;
    },
    cancelEditMode() {
      this.description = this.domain.launcher.description;
      this.editMode = false;
      this.validator.reset();
    },
  },
  computed: {
    ...mapGetters({
      domain: 'company/getSelectedDomain',
      teamID: 'company/getTeamID'
    })
  }
}
</script>