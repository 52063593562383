/**
 *
 * Private function
 * Used for handle cyclic object value error
 * JSON.stringify() doesn't try to solve them and fails accordingly.
 */
 function getCircularReplacer() {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return false;
      }
      seen.add(value);
    }
    return value;
  }
}
/**
 *
 * Provide basic operation (eg. get, save, delete) for tutorial data
 * Tutorial is saved locally on user machine
 */
export class TutorialStorage {
  /**
   *
   * @param {string} storage
   * Set what storage want to use (eg. sessionStorage)
   */
  constructor (storage) {
    this.FIELDNAME = 'brtSavedTutorial';
    this.storage = storage;
  }
  /**
   * Return tutorial data and current step index
   * @returns {Object} An object
   */
  get (name) {
    return JSON.parse(this.storage.getItem(name ? name : this.FIELDNAME));
  }

  /**
   *
   * @param {Object} tutorial
   * Save tutorial object and current index step
   */
  save (tutorial, name) {
    this.storage.setItem(name ? name : this.FIELDNAME, JSON.stringify(tutorial, getCircularReplacer()));
  }

  /**
   * Delete saved tutorial from user local storage
   */
  delete (name) {
    this.storage.removeItem(name ? name : this.FIELDNAME);
  }
}

const TS = new TutorialStorage(sessionStorage);
export default TS;
