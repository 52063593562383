<template lang="pug">
  footer.mt-auto
    #footer-section
      .container.pb-4.pt-2
        .row.d-flex.justify-content-center
          .col-12.col-lg-3.col-sm-4.mb-5.offset-sm-1.offset-lg-1.pl-5.pl-sm-0
            p.body-copy.brt-danger-light.mb-5 Bytes Route
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/blog'" title='Bytes Route Blog') Blog
            a.footer-link.d-block.body-copy.mb-3(href='https://trello.com/b/yFzMZmOu/bytes-route-roadmap' title='Feature Roadmap' target='_blank' rel='noreferrer noopener' data-testid="roadmap") Roadmap
            a.footer-link.d-block.body-copy.mb-3(:href="pricingLink" title='Bytes Route Pricing Plans' data-testid="pricing") Pricing
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/eula.pdf'" title='Terms of service') Terms of service
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/privacy-policy.pdf'" title='Privacy')  Privacy

          .col-12.col-lg-3.col-sm-4.mb-5.offset-sm-1.offset-lg-1.pl-5.pl-sm-0
            p.body-copy.brt-danger-light.mb-5 Help
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/docs/getting-started/overview.html'" data-testid="getting-started") Getting Started
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/docs/tour-run-methods/overview.html'" data-testid="launch-options") Launch Options
            a.footer-link.d-block.body-copy.mb-3(:href="$urlConfig.galleryUrl + '/docs/introduction/overview.html'" data-testid="docs-intro") Docs
            a.footer-link.d-block.body-copy.mb-3(href="https://chrome.google.com/webstore/detail/bytes-route-product-onboa/kfdoklilbkbflmbmkinaojhdhkojlhbh?hl=en" target='_blank' rel='noreferrer noopener' data-testid="extension") Chrome Extension
            a.footer-link.d-block.body-copy.mb-3(href='mailto:support@bytesroute.com' title='support@bytesroute.com' data-testid="email") Contact Us
            
        .row.d-flex.justify-content-center
          .col-12.col-lg-3.col-sm-4.mb-3.offset-sm-1.offset-lg-1.pl-5.pl-sm-0
            .caphyon-copyright(data-testid="copyright")
              span.body-copy.brt-muted-bright.
                ©&nbsp;Caphyon 2002 - {{ currYear }}
          .col-12.col-lg-3.col-sm-4.mb-3.offset-sm-1.offset-lg-1.pl-5.pl-sm-0
            .other.d-flex
              a.small-copy.mr-3(href="https://www.facebook.com/BytesRoute" target='_blank' rel='noreferrer noopener' aria-label='Facebook')
                svg.footer-link(width="1.5rem" height="1.5rem")
                  use(href="/assets/social/iconFacebookLogo.svg#Layer_1")
              a.small-copy.mr-3(href="https://twitter.com/BytesRoute" target='_blank' rel='noreferrer noopener' aria-label='Twitter')
                svg.footer-link(width="1.5rem" height="1.5rem")
                  use(href="/assets/social/iconTwitterLogo.svg#Layer_1")
              a.small-copy.mr-3(href="https://www.linkedin.com/company/bytesroute" target='_blank' rel='noreferrer noopener' aria-label='LinkedIn')
                svg.footer-link(width="1.5rem" height="1.5rem")
                  use(href="/assets/social/iconLinkedinLogo.svg#Layer_1")
              a.small-copy.mr-3(href='mailto:support@bytesroute.com' title='support@bytesroute.com' aria-label='Contact email')
                svg.footer-link(width="1.5rem" height="1.5rem")
                  use(href="/assets/social/iconMail.svg#Layer_1")
</template>

<script>
import { auth } from "../../utils/auth";

export default {
  mounted() {
    this.generatePricingLink();
  },
  data() {
    return {
      pricingLink: ""
    };
  },
  methods: {
    generatePricingLink() {
      this.pricingLink = auth.isAuthenticated()
        ? "/subscription"
        : "https://www.bytesroute.com/pricing.html";
    }
  },
  computed: {
    currYear() {
      return new Date().getFullYear();
    }
  },
  watch: {
    $route(to, from) {
      this.generatePricingLink();
    }
  }
};
</script>
