<template lang="pug">
  .brt-card.position-relative
    .inner-container(v-if="themes.length > 1")
      img.green-lock(v-if='!planHasThemes' src='/assets/plans/greenLock.svg')
      span.brt-caption.
        manage your themes
      .buttons-container.float-right
        router-link.small-cta(v-if="planHasThemes" :to="{ name: 'themeCreation', params: { id: 'new' }}").
          Create a Theme
        router-link.small-cta(v-else :class="{'mr-5' : !planHasThemes }" :to="{ path: '/subscription'}").
          Upgrade plan to create custom themes

      table#brt-table.mt-5
        thead
          tr.small-copy.brt-muted-dark
            th.px-3.text-center(scope="col") #
            th.px-3(scope="col") Name
            th.px-3(scope="col") Colors in theme
            th.px-3(scope="col") Tours with theme
            th.px-3(scope="col") Edit
            th.px-3(scope="col") Delete
        tbody(v-for="(theme, index) in themes")
          Theme-Row(:theme="theme" :index="index")
</template>

<script>
import ThemeRow from './ThemeRow.vue';
import { mapGetters } from 'vuex';

export default {
  components: { ThemeRow },
  computed: {
    ...mapGetters({
      themes: 'company/getThemes',
      planHasThemes: 'company/planHasThemes'
    })
  }
}
</script>