<template lang="pug">
  .brt-tooltip.brt-muted
    svg(width="1.6rem" height="1.6rem")
      use(href="/assets/misc/questionTooltip.svg#Layer_1")
    .brt-tooltiptext(:class="{\
      'brt-tooltip-right': direction === 'right',\
      'brt-tooltip-left': direction === 'left',\
      'brt-tooltip-bottom': direction === 'bottom',\
      'brt-tooltip-top': direction === 'top' }")
      p.main-text.mb-0 {{ text }}
      p.secondary-text.brt-muted.font-italic.mb-1 {{ secondary }}
</template>

<script>

export default {
  props: {
    text: { type: String },
    secondary: { type: String },
    direction: { type : String, default: "right" },
    offsetX: { type: [String, Number] },
    offsetY: { type: [String, Number] }
  },
  mounted() {
    this.addStyleDynamically();
  },
  methods: {
    addStyleDynamically() {
      const tooltipBanner = this.$el.getElementsByClassName('brt-tooltiptext')[0];
      if (this.offsetY) {
        tooltipBanner.style.top = `${this.offsetY}rem`;
      }
      if (this.offsetX) {
        tooltipBanner.style.right = `${this.offsetX}rem`;
      }
    }  
  }
}
</script>