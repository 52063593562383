<template lang="pug">
  .container
    .dark-background
    .brt-popup
      button.close.py-2.px-3(type='button' aria-label='Close' title="Close Pop-up" @click="closeCb" data-testid="close-button")
        span(aria-hidden='true') &times;
      .inner-container.py-3

        .pop-up-emoji.mt-4.mb-3 🔑
        .big-copy.mb-5.font-semibold(data-testid="welcome-message") Reset Password

        .body-copy.brt-muted-dark.mb-3 An e-mail with the reset instructions will be sent to:
        .body-copy.brt-primary(data-testid="email") {{ email }}
        
        .request-container.d-flex.align-items-center.justify-content-center
          .button-container(v-if="!this.requestSent")
            button.cta.mt-5.pt-3.mb-3(v-if="this.loading == false" @click="sendPasswordResetEmail" data-testid="send-button").
              Send
            Spinner(type="inline" v-if="this.loading == true")
          .outcome-texts-container.body-copy(v-if="this.requestSent")
            p(v-if="response=='success'" data-testid="success-message").
              Email sent! Check your inbox.
            p.brt-danger(v-if="response=='failure'" data-testid="failure-message").
              There was an error on our side 😔 Please try again later. 
        
</template>

<script>
import { scrollLock } from '../../mixins/scrollLock.js';
import Spinner from '../aspect/Spinner.vue';
export default {
  mixins: [scrollLock],
  props: {
    email: {
      type: String,
      default: 'defaultUserEmail'
    },
    closeCb: {
      type: Function,
      required: true
    }
  },
  components: { Spinner },
  data() {
    return {
      loading: false,
      requestSent: false,
      response: ''
    }
  },
  methods: {
    async sendPasswordResetEmail () {
      try {
        this.loading = true;
        let result = await this.$http.post(`${this.$config.default.apiUrl}/recovery`, { email: this.email });
        if (result.status === 200) {
          this.response = 'success'
        }
      } catch (err) {
        this.respone = 'failure'
      } finally {
        this.loading = false;
        this.requestSent = true;
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
  .request-container
    height 8rem
</style>
