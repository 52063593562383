<template lang="pug">
  .container
    .brt-card.d-flex.justify-content-center(v-if="loading")
      Spinner(type='inline')
    .managers-containers.row(v-else)
      .col-12
        Theme-List(v-if="showThemes")
        Empty-Theme-List(v-else)
</template>

<script>
import ThemeList from '../components/themes/ThemeList.vue';
import EmptyThemeList from '../components/themes/EmptyThemeList.vue';
import Spinner from '../components/aspect/Spinner.vue'
import { mapGetters } from "vuex";

export default {
  name: 'ThemeManagement',
  components: { ThemeList, EmptyThemeList, Spinner },
  computed: {
    showThemes() {
      return this.themes.length > 1
    },
    ...mapGetters({
      themes: "company/getThemes",
      loading: "company/getLoadingTeamThemes"
    })
  }
}
</script>

