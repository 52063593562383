<template lang="pug">
  input#elem-next-btn-text.brt-input(type="text", v-model="nextBtnTxt" @change="updateText")
</template>

<script>
import IconTooltip from "../../icons/IconTooltip.vue";
import { mapGetters } from "vuex";

export default {
  components: { IconTooltip },
  props: {
    nextBtnText: Boolean,
    index: Number
  },
  data() {
    return {
      nextBtnTxt: ""
    };
  },
  async mounted() {
    this.setInitialText();
  },
  watch: {
    nextBtnText() {
      this.setInitialText();
    }
  },
  methods: {
    setInitialText() {
      this.nextBtnTxt = this.nextBtnText;
    },
    updateText() {
      this.$ebus.$emit("tour/updateStepNextBtnText", {
        tutorial: this.tutorial,
        index: this.index,
        nextButtonText: this.nextBtnTxt
      });
    }
  },
  computed: {
    ...mapGetters({
      tutorial: "tutorial/getSelectedTutorial"
    })
  }
};
</script>
