import { TutorialStorage } from '../utils/common/utils/storage';

const STORAGE = new TutorialStorage(localStorage);

export const runTutorial = {
  methods: {
    runTutorial(tutorial) {
      if(tutorial && tutorial.steps[0] && tutorial.steps[0].url) {
        STORAGE.save({tutorial});
        const url = `${this.$config.default.apiUrl}/tutorial/stats/${tutorial._id}`;
        this.$http.put(url, { type: {'stats.runs.started': 1 }});
        window.open(tutorial.steps[0].url, '_blank');
      }
    }
  }
};
