export default class Validator {

  constructor(container) {
    this.parent = container;
    this.fields = container.querySelectorAll('[brt-validate]');

    for(let i = 0; i < this.fields.length; i++) {
      this.fields[i].addEventListener('keydown', (e) => this.preventEnter(e));
      this.fields[i].addEventListener('focus', () => this.removeErrorsOnFocus(this.fields[i]));
    }
  }

  preventEnter(e) {
    if(e.keyCode === 13) {
      e.preventDefault();
    }
  }
  removeErrorsOnFocus(field) {
    field.classList.remove('brt-error-border');
    const error = field.nextSibling;
    if(error && error.classList && error.classList.contains('brt-error')) {
      const parent = field.nextSibling.parentNode;
      parent.removeChild(error);
    }
  }

  validateFields() {
    let counter = 0;
    for(const f of this.fields) {
      const options = this.getValidationOptions(f);
      const result = this.handleOption(options, f);
      if(!result) counter +=1;
    }
    if(counter === 0) return true;
    return false;
  }

  getValidationOptions(field) {
    let options = [];
    const stringOfoptions = field.getAttribute('brt-validate');
    options = stringOfoptions.split('|');
    return options;
  }

  handleOption(params, field) {
    for (const p of params) {
      if(p.includes('-')) {
        const min = p.split('-')[0];
        const max = p.split('-')[1];
        const between = this.isBetween(min, max, field);
        if (!between) {
          this.invalidField(field, `This field must contain between ${min} and ${max} chars`);
          return false;
        }
      }
      if(p === 'alpha') {
        const alpha = this.isAlpha(field);
        if (!alpha) {
          this.invalidField(field, 'Only letters, numbers and spaces are allowed');
          return false;
        }
      }
      if(p === 'isEmail') {
        const isEmail = this.isEmail(field);
        if (!isEmail) {
          this.invalidField(field, 'Please enter a valid email: example@domain.com');
          return false;
        }
      }
      if(p === 'alphaExtended') {
        const alphaExt = this.isAlphaExtended(field);
        if (!alphaExt) {
          this.invalidField(field, `Only letters, numbers, spaces and (') character are allowed`);
          return false;
        }
      }
      if(p === 'notEmpty') {
        const notEmpty = this.notEmpty(field);
        if(!notEmpty) {
          this.invalidField(field, 'This field cannot be empty');
          return false;
        }
      }
      if(p.includes('min')) {
        const minC = p.split('=')[1];
        const notMin = this.hasMin(minC, field);
        if(!notMin) {
          this.invalidField(field, `This field must contain at least ${minC} characters`);
          return false;
        }
      }
      if(p === 'isHex') {
        const isHex = this.isHex(field);
        if (!isHex) {
          this.invalidField(field, 'Invalid hex code color');
          return false;
        }
      }
    }
    field.classList.remove('brt-error-border');
    return true;
  }

  isAlpha(field) {
    const regExp = new RegExp(/^[a-zA-Z0-9 ]+$/);
    if (!regExp.test(field.value)) return false;
    return true;
  }

  isAlphaExtended(field) {
    const regExp = new RegExp(/^[a-zA-Z0-9 ']+$/);
    if (!regExp.test(field.value)) return false;
    return true;
  }

  isBetween(min, max, field) {
    const length = field.value.trim().length;
    if (length < min || length > max) return false;
    return true;
  }

  isEmail(field) {
    const regExp = new RegExp(/^[\w-+\.]+@([\w-]+\.)+[\w-]{2,4}$/); // eslint-disable-line no-useless-escape
    return regExp.test(field.value);
  }

  hasMin(min, field) {
    const length = field.value.trim().length;
    if (length < min) return false;
    return true;
  }

  notEmpty(field) {
    const length = field.value.trim().length;
    if (length === 0) return false;
    return true;
  }

  isHex(field) {
    const regExp = new RegExp(/^#([0-9a-f]{6}|[0-9a-f]{3})$/i);
    if (!regExp.test(field.value)) return false;
    return true;
  }

  invalidField(field, err) {
    if (!field.classList.contains('brt-error-border')) {
      field.classList += ' brt-error-border';
      this.showError(field, err);
    }
  }

  showError(field, err) {
    const response = document.createElement('small');
    response.classList = 'brt-error';
    response.innerHTML = err;
    field.parentNode.insertBefore(response, field.nextSibling);
  }

  removeErrors() {
    const errs = this.parent.querySelectorAll('.brt-error');
    if (errs) {
      for(const e of errs) {
        const parent = e.parentNode;
        parent.removeChild(e);
      }
    }
  }

  reset() {
    this.removeErrors();
    const cls = 'brt-error-border';
    for (const f of this.fields) {
      if (f.classList.contains(cls)) f.classList.remove(cls);
    }
  }
}
