<template lang='pug'>
  form(@submit.prevent="")
    .tour-name-container
      input.brt-input.h3.mr-4(
        v-show='editMode'
        v-model='tourName'
        brt-validate='notEmpty|alphaExtended|5-45')
      Confirm-Edit.w-15(
        v-show='editMode'
        :show="editMode"
        :saveCb="updateTourName"
        :cancelCb="cancelEditMode"
        :loading="loading"
      )
      .read-container(v-show='!editMode' @click="editMode = !editMode")
        h3.brt-primary-dark
          | {{ tourName }}
          svg.ml-3.brt-tab-icon(
            width="1.5rem"
            height="1.5rem"
          ) 
            use(href="/assets/misc/edit-pencil.svg#Layer_1")
</template>

<script>
import ConfirmEdit from '../aspect/ConfirmEdit.vue';
import { auth } from '../../utils/auth';
import Validator from '../../utils/common/utils/validator.js';
import { mapGetters } from 'vuex';

export default {
  components: { ConfirmEdit },
  data() {
    return {
      editMode: false,
      validator: '',
      tourName: ''
    };
  },
  async mounted() {
    this.tourName = this.tutorialName;
    this.validator = new Validator(document.querySelector('.tour-name-container'));
  },
  watch: {
    tutorialName() {
      this.tourName = this.tutorialName
    }
  },
  methods: {
    async updateTourName() {
      this.validator.removeErrors();
      if(this.validator.validateFields()){
        this.$ebus.$emit('tutorial/updateName', {
          id: this.tourID,
          name: this.tourName
        })
        this.$ebus.$emit('tutorial/clientUpdateName', {
          id: this.tourID,
          name: this.tourName
        })
        this.editMode = false;
      } else {
        const error = document.querySelector('.brt-error');
        error.style.marginTop = '65px';
      }
    },
    cancelEditMode() {
      this.tourName = this.tutorialName;
      this.editMode = false;
      this.validator.reset();
    },
  },
  computed: {
    isOwner() {
      return auth.id === this.teamOwner;
    },
    ...mapGetters({
      tourID: "tutorial/getTutorialID",
      tutorialName: "tutorial/getTutorialName",
      teamOwner: 'company/getTeamOwnerID',
      loading: "tutorial/getLoading"
    })
  }
}
</script>

<style lang='stylus' scoped>
  @require '../../utils/common/styleguide/colors.styl'

  .tour-name-container
    display flex
    align-items center
  .disabledTitle
    color $brt-primary-dark !important
    box-shadow none !important
    border none !important
    background-color inherit !important
  .w-15
    width 15rem
  .edit-container
    display flex
    align-items center
  .read-container
    cursor pointer
</style>
