<template lang="pug">
  .container
    .row.mt-5.justify-content-md-center
      .col-12.col-md-8.col-lg-6.col-xl-4.brt-dark
        h1.h3.mb-4 Forgot password
        form.brt-form
          .brt-input-group(@mousedown="validInput=true")
            input#email.brt-input(
              autocomplete='username'
              type='text'
              name='email'
              v-model='email'
              data-testid="emailInput"
              :class="{'brt-input-invalid': !validInput }"
              :disabled="passChangeRequestSent"
              required)
            label.brt-label(for="email") {{ label }}
            span.small-copy.brt-input-error.mt-1( :class="{'hide': validInput }").
              {{ emailErr }}

          button.cta(v-if="!passChangeRequestSent" type='submit', @click.prevent='reset')
            span.spinner-border(v-if='reqInProgress' role='status')
              span.sr-only Loading...
            span(v-else) Reset my password

        .body-copy.mb-2.mt-4(v-if="!passChangeRequestSent")
          | New user?
          router-link.ml-1.link(:to='{ path: "/register"}' data-testid="goToRegister") Register now
          |  it's fast, easy & free
        .body-copy.mb-2(v-else).
          We've sent you an email with the reset instructions.
        span.body-copy
          | Return to
          router-link.ml-1.link(:to='{ path: "/login"}') log in
</template>

<script>
import { emailValidator } from '../../mixins/emailValidator.js';
export default {
  metaInfo: {
    title: 'Request a new password for your Bytes Route account',
    meta: [{ name: 'description', content: 'Send an email containing a reset link. Access the link to set up a new password for your Bytes Route account.' }]
  },
  mixins: [emailValidator],
  data: function () {
    return {
      reqInProgress: false,
      email: '',
      emailErr: 'defaultError',
      emailErrEmpty: 'Please type an email',
      emailErrInvalid: 'Please provide a valid email',
      validInput: true,
      label: 'Email address',
      passChangeRequestSent: false
    };
  },
  methods: {
    async reset() {
      if (this.emailValidOnClient()) {
        this.validInput = true;
        this.sendResetEmailRequest();
      } else {
        this.validInput = false;
      }
    },
    emailValidOnClient() {
      if(this.email.length === 0) {
        this.emailErr = this.emailErrEmpty;
        return false;
      }
      if (!this.validEmail(this.email)) {
        this.emailErr = this.emailErrInvalid;
        return false;
      }
      return true;
    },
    async sendResetEmailRequest () {
      try {
        this.reqInProgress = true;

        const recoveryUrl = `${this.$config.default.apiUrl}/recovery`;
        await this.$http.post(recoveryUrl, {
          email: this.email
        });

      } catch (err) {
        if (err.response.status === 500 || err.response.status === 501) {
          this.emailErr = 'There is a problem on our side. Sorry :(';
        }
      } finally {
        this.passChangeRequestSent = true;
        this.label = '';
        this.reqInProgress = false;
      }
    }
  },
};
</script>
