 
<template lang="pug">
  .manager-container
    Modal(v-if="showAutoRun"
      :enabled="tutorial.options.autoRun"
      primaryON="Disable autorun for this tour?"
      primaryOFF="Enable autorun for this tour?"
      confirmTextON="Disable"
      confirmTextOFF="Enable"
      :highlight="tutorial.name"
      :confirmCb="toggleAutoRun"
      :cancelCb="() => { this.showAutoRun = false }"
    )

    Modal(v-if="showAutoRunConflict"
      confirmTextOFF="Proceed"
      :confirmCb="toggleAutoRunConflict"
      :cancelCb="() => { this.showAutoRunConflict = false }")
      AutoRunConflictContent(
        :tutorial="tutorial"
        :conflictingTutorial="conflictingTutorial"
      )
</template>

<script>
import Modal from '../../aspect/Modal.vue';
import AutoRunConflictContent from './AutoRunConflictContent.vue';
import { handleError } from '../../../mixins/handleError';
import updateTourOptions from '../mixins/updateTourOptions';
import updateTeamTours from '../mixins/updateTeamTours';
import tourActiveCondition  from '../mixins/tourActiveCondition.js'
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, tourActiveCondition, updateTourOptions, updateTeamTours],
  components: { Modal, AutoRunConflictContent },
  data() {
    return {
      showAutoRun: false,
      showAutoRunConflict: false,
      tutorial: {},
      conflictingTutorial: {}
    }
  },
  mounted() {
    this.$ebus.$on("tour/toggleAutoRun", tutorial => {
      this.tutorial = tutorial;
      this.toggleAutoRunPre();
    });
  },
  methods: {
    async toggleAutoRunPre() {
      if (!this.tourCanBecomeActive) {
        this.$ebus.$emit("plan/showUpgrade")
      } else {
        await this.autoRunModalPre();
      }
    },
    async autoRunModalPre() {
      try {
        const response = await this.$http.get(`${this.$config.default.apiUrl}/tutorial/${this.tutorial._id}/check/autorun`);
        // if one is found, show conflict confirm
        if (response.data.tutorials.length > 0) {
          this.showAutoRunConflict = true;
          this.conflictingTutorial = response.data.tutorials[0];
        // otherwise show the normal confirm
        } else {
          this.showAutoRun = true;
        }
      } catch (err) {
        this.handleError(err);
      }
    },
    async toggleAutoRun() {
      try {
        this.showAutoRun = false;
        await this.updateTourOptions(this.tutorial, { autoRun: !this.tutorial.options.autoRun });
        await this.updateTeamTours();
        this.$toast("Tour auto run updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour auto run. Please retry", { type: TYPE.ERROR });
      }
    },
    async toggleAutoRunConflict() {
      try {
        this.showAutoRunConflict = false;
        await this.updateTourOptions(this.conflictingTutorial, { autoRun: false });
        await this.updateTourOptions(this.tutorial, { autoRun: true });
        await this.updateTeamTours();
        this.$toast("Tour auto run updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.handleError(err);
        this.$toast("Error updating tour auto run. Please retry", { type: TYPE.ERROR });
      }
    },
  }
}
</script>
