<template lang="pug">
  li.list-group-item.tutorial-container(
    @click="runTutorial"
    @mouseover="hover=true"
    @mouseleave="hover=false"
    :class="{'owned': deleteHovered}"
    data-testid="run-tutorial")

    .tutorial-content.pb-2.pr-3
      
      span.brt-muted-dark.ext-xs-copy.mr-1 {{ tutorial.domain }}

      span.tutorial-title.d-flex.align-items-center.w-100.py-1
        span.ext-body.brt-primary.bolder.m-0.text-truncate(data-testid="tutorial-name").
          {{ tutorial.name }}

      span.tutorial-description.ext-body(v-if="tutorial.demo").
        {{ tutorial.description }}

      .brt-muted-dark.text-left.mb-0.mt-1(v-if="!tutorial.demo")
        span.ext-xs-copy.
          {{ creationDate ? ` ${creationDate}` : ', a long time ago' }}

    options-menu(
      v-if="hover && !tutorial.demo"
      @mouseover="deleteHovered=true"
      @mouseleave="deleteHovered=false"
      :url="tutorialLink"
      :tutorial="this.tutorial"
      data-testid="menu")

</template>

<script>
import OptionsMenu from './optionsMenu.vue';
import { dateFormatter } from '../../../utils/common/utils/dateFormatter';
import UrlConfig from '../../../utils/common/utils/urlConfig.js';
import TutorialURL from '../../../utils/common/utils/tutorialURL.js'

export default {
  mixins: [dateFormatter],
  components: {
    'options-menu': OptionsMenu,
  },
  props: ['tutorial'],
  data() {
    return {
      hover: false,
      deleteHovered: false,
    };
  },
  computed: {
    creationDate() {
      return this.formatDate(this.tutorial.created);
    },
    tutorialLink() {
      const tutorialURL = new TutorialURL(this.tutorial)
      return tutorialURL.getLink();
    },
  },
  async mounted() {
    this.$ebus.$on('onIdle:switchTab', () => this.clearTutorialList() );
    this.$ebus.$on('tutorial:removeFromList', () =>  this.clearTutorialList());

    this.$on('options:delete', () => {
      this.$parent.$emit('tutorial:delete', this.tutorial);
    });

    this.$on('options:edit', () => {
      this.enterEditMode();
    });

    const urlConfig = new UrlConfig();
    await urlConfig.setup();
  },
  methods: {
    clearTutorialList() {
      this.hover = false;
    },
    runTutorial() {
      if (!this.tutorial.demo) {
        this.$ebus.$emit('runTutorial', this.tutorial);
      }
    },
    async enterEditMode() {
      this.$ebus.$emit('editTutorial', this.tutorial);
    },
  }
};
</script>

<style lang="stylus" scoped>
  @import '../../../utils/common/styleguide/colors.styl';
  @import '../../../utils/common/styleguide/functions.styl';
  
  .tutorial-title
    transition padding-left .2s ease-out
    &::before
      content url('../../images/iconPlay.svg')
      position absolute
      opacity 0
      top 48%
      transform translateY(-50%)
      left pxToRem(10)
      width pxToRem(20)
      transition opacity .2s ease-in
  .tutorial-container
    position relative
    border none
    padding pxToRem(10) pxToRem(15)
    cursor pointer
    margin-bottom 0
    border-bottom 1px solid $brt-gray
    &:last-of-type
      border-bottom none
    &:hover
      background-color $brt-muted-faded

  .tutorial-container:hover >.tutorial-content >.tutorial-title
    padding-left pxToRem(25)
    &::before
      opacity 1

</style>
