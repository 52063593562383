<template lang="pug">
  #step-2-container.row.mx-0.brt-onboard-card.p-0
    .col-12.offset-lg-2.col-lg-8.d-flex.justify-content-center.align-items-center
      Build-Product-Tour(v-if="getTourCount && getTourCount.total === 0")
      #tour-created(v-else)
        .d-flex.align-items-center.mb-4
          svg.mr-3.mb-2.brt-tab-icon(width="55px" height="55px")
            use(href="/assets/misc/productTours.svg#Layer_1") 
          h2
            | Create a product tour
        .validation-container.d-flex.align-items-center.border-box.py-3.px-2.mt-4.mb-5
          .validation-text.flex-grow-1
            img.px-1.pb-1.mr-2(src="../../../assets/misc/greenCheck.svg" alt="green checkmark" width="30")
            h5.body-copy.d-inline-block Your first tour was created successfully!
          router-link.link.body-copy(to="/experiences")
            | See tours →
        p.body-copy.brt-muted-dark Don't worry, your creation will only be seen by you, at this point in time.
        p.body-copy.brt-muted-dark.mb-5 After connecting your web app to Bytes Route, you can publish your content live.
        a.link(
          @click="nextStep()"
          title="Proceed to the next step"
          data-testid="continue-button")
          span Connect to Bytes Route →
</template>

<script>
import BuildProductTour from '../common/BuildProductTour.vue';
import { mapGetters } from 'vuex';
export default {
  components: { BuildProductTour },
  data() {
    return {
      tourCreated: false,
      getTourCount: false,
    }
  },
  mounted() {
    this.getTourCount = this.$store.getters["company/getTourCount"];
    window.addEventListener('storage', this.checkForTour.bind(this));
  },
  methods: {
   async nextStep() {
      if (this.onboardStep === 2) {
        await this.$store.dispatch('onboarding/updateOnboardStep', 3);
      } else {
        this.$store.commit('onboarding/setFocusedStep', 4)
      }
    },
    async checkForTour() {
      const brtOnboarding = window.localStorage.getItem('brtOnboarding');
      if (brtOnboarding === 'firstTour') {
        window.removeEventListener('storage', this.checkForTour);
        await this.$store.dispatch("company/getSelectedTeamData");
        this.nextStep();
      }
    }
  },
  computed: {
    ...mapGetters({
      onboardStep: 'onboarding/getOnboardStep',
    })
  }
}
</script>

<style lang="stylus">
  @require '../../../utils/common/styleguide/colors.styl'
  #step-2-container
    min-height 24rem
    .link
      text-decoration underline !important
    .brt-tab-icon
      fill: $brt-red-light
</style>
