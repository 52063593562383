<template lang="pug">
  header.container-fluid.zindex-9998.max-width
    .row.my-5
      .col-2.d-flex.justify-content-end.align-items-end.flex-column.order-1(:class="{'justify-content-end': onboardStep >= 3, 'justify-content-start' : onboardStep < 3}")
        Home-Button
        CapterraHeader.border-left.d-block.d-xl-none
      .col-5.d-flex.justify-content-around.justify-content-md-start.align-items-center.order-2
        Team-Selector.pr-3(v-if="logged" :onchange='changeSelectedTeam')
        CapterraHeader.border-left.d-xl-block.d-none
      .col-5.d-flex.justify-content-end.mt-5.mt-md-0.order-3
          ul.list-inline.d-flex.align-items-center.mb-0.mr-3
            li.list-inline-item(title="Documentation")
              a.nav-elem.nav-link(
                :href="$urlConfig.galleryUrl + '/docs/introduction/overview.html'"
                target="_blank"
                data-testid="docs-link")
                span Docs
            li.list-inline-item(v-if="!logged" title="Go to login" )
              router-link.nav-elem.nav-link(to="/login" :class="{ 'nav-active-link': $route.name === 'login' }" data-testid="login").
                Log in
            li.list-inline-item(v-else data-testid="profile")
              Profile-Dropdown
            li.d-none.d-lg-inline-block.list-inline-item.ml-3(
              v-if="!extensionDetected"
              title="Navigate to store to get extension"
            )
              Extension-Download
</template>

<script>
import HomeButton from './HomeButton.vue';
import TeamSelector from './TeamSelector.vue';
import ProfileDropdown from './ProfileDropdown.vue';
import ExtensionDownload from './ExtensionDownload.vue';
import CapterraHeader from './CapterraHeader.vue';

import { auth } from '../../utils/auth';

import { mapGetters } from 'vuex';

export default {
  components: { HomeButton, TeamSelector, ExtensionDownload, ProfileDropdown, CapterraHeader },
  data() {
    return {
      logged: false,
    }
  },
  updated() {
    if (auth.isAuthenticated()) {
      this.logged = true
    }
  },
  mounted() {
    this.$ebus.$on('User:SignedOut', () => {
      this.logged = false;
    });
    this.logged = auth.isAuthenticated();

    window.addEventListener('storage', this.checkForExtension.bind(this));
  },
  methods: {
    async changeSelectedTeam(id) {
      if (this.$route.path != '/dashboard') {
        this.$router.push({path: '/dashboard'});
      }
      await this.$store.dispatch("company/setSelectedTeam", id);
      await this.$store.dispatch("company/getSelectedTeamData");
      await this.$store.dispatch("onboarding/updateOnboardingOnDomainsWithScriptChanges");
      this.$store.commit("tutorial/resetSelectedTutorial");
    },
    checkForExtension() {
      const brtOnboarding = window.localStorage.getItem('brtOnboarding');
      if (brtOnboarding === 'extensionInstalled') {
        this.$store.commit('onboarding/checkForExtension');
        window.removeEventListener('storage', this.checkForExtension);
        if (this.onboardStep <= 1) {
          this.$store.dispatch('onboarding/updateOnboardStep', 2)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      onboardStep: 'onboarding/getOnboardStep',
      extensionDetected: 'onboarding/getExtensionDetected'
    })
  }
};
</script>

<style lang='stylus' scoped>
  .zindex-9998
    z-index 9998
  .max-width
    max-width: 1500px !important

  @media (max-width: 1199px) {
    .border-left {
      border 0px !important 
    }
  }
</style>
