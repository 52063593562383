<template lang="pug">
.brt-card.h-auto
  .step-description
    Step-Description(:stepContent="step.content", :index="index")

  .line-splitter

  .optional-step-container
    p.brt-caption.
      Step Type
    Step-Type(:optional="step.optional" :index="index")

  .line-splitter

  .step-triggered-by
    p.brt-caption.
      Next Step Triggered by
    Step-Navigation(:nextNavigation="step.hasNext"  :index="index")
    Step-Element-Interaction.mt-2(
      v-if="step.hasNext"
      :allowInteraction="!step.blockElem"
      :index="index"
    )

  .line-splitter

  .step-delete-container
    p.brt-caption.
      Delete Step
    Step-Delete(:index="index")
</template>

<script>
import StepDescription from "./StepDescription.vue";
import StepDelete from "./StepDelete.vue";
import StepType from "./StepType.vue";
import StepNavigation from "./StepNavigation.vue";
import StepElementInteraction from "./StepElementInteraction.vue";

export default {
  components: {
    StepDescription,
    StepDelete,
    StepType,
    StepNavigation,
    StepElementInteraction
  },
  props: {
    step: Object,
    index: Number,
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style lang='stylus' scoped>
@require '../../../utils/common/styleguide/colors.styl';
@require '../../../utils/common/styleguide/functions.styl';

.brt-card {
  position: sticky;
  top: 2rem;
}

.line-splitter {
  border-bottom: 1px solid $brt-muted;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.brt-caption {
  font-weight: 400;
  color: $brt-primary;
}
</style>