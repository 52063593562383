import Analytics  from '../instrumentation/analytics';

export default class Events {
  constructor(type) {
    this.tails = {
      state: 'state',
      resume: 'resume',
      create: 'create',
      run: 'run',
      iframe: 'iframe'
    };
    this.availableTypes = {
      runtime: 'runtime',
      tabs: 'tabs'
    };
    this.scriptsPaths = {
      webComponents: 'webcomponents.js',
      main: 'scripts/main.js',
    };
    this.knownErrors = [
      'The extensions gallery cannot be scripted', // error trowed by chrome webstore
      'Cannot access contents of url', // domain that cannot be accessed
      'Cannot access a chrome:// URL', // run just on http and https
      'ExtensionsSettings policy', // not allowed from manifest
      'The tab was closed', // weird error, only on Cristi's PC
      'No tab with id: ' // trying to inject script on inexistent tab
    ];
    this.type = type;
    this.probe = Analytics.getExtensionProbe();
  }
  // name, action, details, done
  sendMessage (tailName, reqType, message, respCb) {
    this.probe.event(tailName, reqType, message);
    const msg = {
      tail: this.tails[tailName],
      reqType: reqType,
      message: message ? message : ''
    };
    if (this.type === this.availableTypes.runtime) {
      if (typeof respCb === 'function') {
        chrome.runtime.sendMessage( msg, resp => { return respCb(resp); });
      } else {
        chrome.runtime.sendMessage(msg);
      }
    } else if (this.type === this.availableTypes.tabs) {
      chrome.tabs.query({active: true, currentWindow: true}, tabs => {
        if (typeof respCb === 'function') {
          chrome.tabs.sendMessage(tabs[0].id, msg, resp => { return respCb(resp); });
        } else {
          chrome.tabs.sendMessage(tabs[0].id, msg);
        }
      });
    }
  }

  handleMessage (tailName, reqType, cb) {
    if (this.type === this.availableTypes.runtime) {
      chrome.runtime.onMessage.addListener((req, sender, resp) => {
        const cbResp = this.sendResponse(cb, req,
          sender.tab ? sender.tab.id : '',
          reqType, tailName);
        if (cbResp) {
          if (cbResp === 'default') {
            resp(false);
          } else {
            resp(cbResp);
          }
        }
      });
    } else if (this.type === this.availableTypes.tabs) {
      chrome.tabs.onMessage.addListener((req, sender, resp) => {
        const cbResp = this.sendResponse(cb, req,
          sender.tab ? sender.tab.id : '',
          reqType, tailName);
        if (cbResp) {
          if (cbResp === 'default') {
            resp(false);
          } else {
            resp(cbResp);
          }
        }
      });
    }
  }

  sendResponse (cb, req, tabId, reqType, tailName) {
    if (req.tail === this.tails[tailName] && req.reqType === reqType) {
      const cbResp = cb(req.message, tabId);
      return cbResp ? cbResp : 'default';
    }
  }

  executeScript (script, cb, tabId) {
    let details;
    if (script.hasOwnProperty('code')) {
      details = script;
    } else if (this.scriptsPaths.hasOwnProperty(script)) {
      details = {file: this.scriptsPaths[script]};
    } else {
      // eslint-disable-next-line no-throw-literal
      throw 'Invalid script!';
    }

    chrome.tabs.query({active: true, currentWindow: true},
      tabs => {
        if (tabs.length) {
          tabId = tabId ? tabId : tabs[0].id;
          chrome.tabs.executeScript(
            tabId,
            details,
            (resp) => {
              this.handleChromeErrors(tabId);
              if (typeof cb === 'function') {
                cb(resp);
              }
            }
          );
        } else {
          // eslint-disable-next-line no-console
          console.log('no tabs');
        }
      });
  }

  onExtensionInstall (cb) {
    chrome.runtime.onInstalled.addListener(() => {
      this.getAllTabs((tabs) => cb(tabs));
    });
  }

  onTabUpdated (cb) {
    chrome.tabs.onUpdated.addListener((tabId, changeInfo, tab) => {
      cb(changeInfo, tab);
    });
  }

  onTabActivated (cb) {
    chrome.tabs.onActivated.addListener((activeInfo) => {
      cb(activeInfo);
    });
  }

  onTabReplaced(cb) {
    chrome.tabs.onReplaced.addListener((addedTabId, removedTabId) => {
      cb(addedTabId, removedTabId);
    });
  }

  onTabRemoved(cb) {
    chrome.tabs.onRemoved.addListener((tabId) => {
      cb(tabId);
    });
  }

  onTabNavigation(cb) {
    chrome.webNavigation.onBeforeNavigate.addListener((details) => {
      if (details && details.frameId == 0 && details.url.includes('http')) {
        chrome.tabs.query({active: true, currentWindow: true}, (tabs) => {
          if (tabs[0] && tabs[0].url && details.url) {
            cb(tabs[0].id, new URL(tabs[0].url), new URL(details.url));
          }
        });
      }
    });
  }

  reloadTab(tabId) {
    chrome.tabs.reload(tabId);
  }

  getAllTabs(cb) {
    chrome.tabs.query({}, tabs => {
      cb(tabs);
    });
  }

  handleChromeErrors(tabId) {
    let knownError = false;
    const lastErr = chrome.runtime.lastError ? chrome.runtime.lastError.message : '';

    if (lastErr) {
      for (let i = 0; i < this.knownErrors.length; i++) {
          if(lastErr.includes(this.knownErrors[i])) {
          this.addChromeErrorToStorage(lastErr, tabId);
          // eslint-disable-next-line no-console
          console.log('Known Error: ' + lastErr);
          knownError = true;
          break;
        }
      }

      if (!knownError) {
        throw lastErr;
      }
    }
  }

  addChromeErrorToStorage (error, tabId) {
    if(!error.includes(this.knownErrors[4]) && !error.includes(this.knownErrors[5])) {
      chrome.storage.local.get(['brtErrors'], (resp) => {
        const errors = resp.hasOwnProperty('brtErrors') ? resp.brtErrors : {};
        if (!errors.hasOwnProperty(tabId)) {
          errors[tabId] = error;
          chrome.storage.local.set({'brtErrors': errors});
        }
      });
    }
  }

  removeChromeErrorFromStorage (tabId) {
    chrome.storage.local.get(['brtErrors'], (resp) => {
      const errors = resp.brtErrors;
      if (errors && errors.hasOwnProperty(tabId)) {
        delete errors[tabId];
        chrome.storage.local.set({'brtErrors': errors});
      }
    });
  }

  removeAllErrorsExcept(tabs) {
    const tabsIds = tabs.map((tab) => tab.id);
    const validErrors = {};
    chrome.storage.local.get(['brtErrors'], (resp) => {
      const errors = resp.brtErrors;
      if (errors) {
        for(let i = 0; i < tabsIds.length; i++) {
          if (errors.hasOwnProperty(tabsIds[i])) {
            validErrors[tabsIds[i]] = errors[tabsIds[i]];

          }
        }
      }
      chrome.storage.local.set({'brtErrors': validErrors});
    });
  }

  onWindowClose(cb) {
    chrome.windows.onRemoved.addListener((windowId) => {
      cb(windowId);
    });
  }

  onPortConnect(cb) {
    chrome.runtime.onConnect.addListener((port) => {
      cb(port);
    });
  }

  setDevBadge() {
    chrome.browserAction.setBadgeText({text:"D"});
  }

  onExtensionClick(cb) {
    chrome.browserAction.onClicked.addListener((tab) => {
      cb(tab)
    });
  }
}
