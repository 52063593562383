<template lang="pug">
#bytesroute.main.h-100
  spinner(type='full' v-if='loading')
  section.h-100(v-else)
    editor-header#header(:state="currState")
    #brt-body
      on-idle(v-if="currState === 'idle'")
      on-running(v-if="currState === 'run'"
                :tutCompany='tutorial.company'
                :selectedCompany='selectedCompanyId'
                :name='tutorial.name'
                :description='tutorial.description'
                :steps='tutorial.steps'
                :currentIndex='tutorialIndex')
      on-creating(v-if="currState === 'create'"
                :steps='tutorial.steps'
                :editedTour='editMode ? tutorial : false'
                :currentIndex='tutorialIndex'
                :editStepFromStorage='editStepFromStorage'
                :key='forceRerender'
                :stepError='stepError')
    confirmation-dialog(v-show="pendingConfirmation"
      :image="confirmDialogProperties.image"
      :dialogTitle="confirmDialogProperties.dialogTitle"
      :dialogMessages="confirmDialogProperties.dialogMessages"
      :confirmButtonText="confirmDialogProperties.confirmButtonText"
      :cancelButtonText="confirmDialogProperties.cancelButtonText"
      :usedFor="confirmDialogProperties.usedFor"
      data-testid="confirmation-dialog")
</template>

<script>
import SYNC from '../../../utils/common/utils/sync';
import { TutorialStorage } from '../../../utils/common/utils/storage';
import URLNAVIGATION from '../../../utils/common/utils/urlNavigation';
import Events from '../../../utils/common/utils/chromeEvents';
import { auth } from '../../../utils/auth';

import onIdle from './onIdle.vue';
import onRunning from './onRunning.vue';
import onCreating from './onCreating.vue';
import editorHeader from '../reusable/editorHeader.vue';
import ConfirmationDialog from '../reusable/confirmationDialog.vue';

import { TYPE } from "vue-toastification";
import { mapGetters } from 'vuex';

import Spinner from '../../../components/aspect/Spinner.vue';

export default {
  data () {
    return {
      EVENTS: new Events('tabs'),
      STORAGE: new TutorialStorage(localStorage),
      currState: 'idle',
      currDomain: '',
      loading: false,
      page: '',
      tutorial: {
        name: '',
        steps: [],
        domain: 'test',
      },
      stepError: false,
      stepIndex: '',
      editMode: false,
      directEdit: false,
      tutorialIndex: 0,
      tutorialsOnPage: 0,
      onboardSeen: false,
      logged: false,
      pendingConfirmation: false,
      confirmDialogProperties: {
        image: '',
        dialogTitle: 'Are you sure?',
        dialogMessages: ['Confirm removal'],
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel',
        usedFor: 'delete'
      },
      forceRerender: 1,
      editStepFromStorage: null,
    };
  },
  components: {
    onIdle,
    onRunning,
    onCreating,
    editorHeader,
    ConfirmationDialog,
    Spinner
  },
  mounted() {
    this.eventsSetup();
    this.checkStorage();
    this.vueEventsSetup();
    this.initConfirmationModalEvents();
    localStorage.setItem('brtOnboarding', 'extensionInstalled');
    setTimeout(() => {
      localStorage.removeItem('brtOnboarding');
    }, 1000);
  },
  methods: {
    initConfirmationModalEvents() {
      this.$on('confirmDialog:confirm', async (type) => {
        let tutorialInTest;
        switch (type) {
          case 'closeIframe':
            this.cancelCreation();
            this.$ievents.sendMessage('execute', 'stopAll');
            this.$ievents.sendMessage('execute', 'close');
            this.pendingConfirmation = false;
            break;
          case 'deleteTour':
            SYNC.delete(this.tutorial._id, auth.token, () => {
              this.$ebus.$emit('tutorial:removeFromList', this.tutorial._id);
              this.tutorial = {
                name: '',
                steps: [],
                domain: 'test',
              };
              this.pendingConfirmation = false;
            });
          break;
          case 'cancelCreation':
            this.cancelCreation();
            this.pendingConfirmation = false;
            if (sessionStorage.getItem('testing')) {
              sessionStorage.removeItem('testing');
            }
            if (sessionStorage.getItem('retest')) {
              sessionStorage.removeItem('retest');
            }
          break;
          case 'testTour':
            this.runTutorial(JSON.parse(sessionStorage.getItem('testing')));
            this.pendingConfirmation = false;
          break;
          case 'testComplete':
            tutorialInTest = JSON.parse(sessionStorage.getItem('testing'));
            if (tutorialInTest.options.autoRun || tutorialInTest.options.show) {
              window.open(`/tour/${tutorialInTest._id}/analytics`, '_blank');
            } else {
              localStorage.setItem('runTour', true);
              window.open(`/tour/${tutorialInTest._id}/options`, '_blank');
            }
            this.pendingConfirmation = false;
            sessionStorage.removeItem('testing');
          break;
          case 'edit:lastStepError':
            this.editTutorial(JSON.parse(sessionStorage.getItem('testing')));
            this.pendingConfirmation = false;
          break;
          case 'deleteStep':
            this.deleteStep(this.stepIndex);
            this.tutorialIndex--;
            this.$ievents.sendMessage('create', 'resumeCreating');
            this.pendingConfirmation = false;
            if (this.editMode) {
              sessionStorage.setItem('retest', true);
            }
          break;
          case 'logout':
            this.$ievents.sendMessage('execute', 'stopAll', '', () => {
              auth.signOut();
              this.$router.push({name: 'login'});
              this.pendingConfirmation = false;
            });
        }
      });
      this.$on('confirmDialog:cancel', (type) => {
        this.pendingConfirmation = false;
        if(type === 'cancelCreation' || type === 'deleteStep' || type === 'closeIframe' || type === 'logout') {
          this.$ievents.sendMessage('create', 'resumeCreating');
        }
        if (type === 'testTour') {
          sessionStorage.removeItem('testing');
          this.cancelRunning();
        }
        if (type === 'testComplete') {
          sessionStorage.removeItem('testing');
        }
        if (type === 'edit:lastStepError') {
          this.reset();
          this.stepError = false;
        }
      });
    },
    closeIframe() {
      if (this.currState === 'create') {
        this.setConfirmDialogProperties({
          image: '',
          dialogTitle: 'Close extension',
          dialogMessages: ['You have unsaved changes. All of your progress will be lost. Do you want to proceed?'],
          confirmButtonText: 'Close extension',
          cancelButtonText: 'Cancel',
          usedFor: 'closeIframe'
        });
        this.pendingConfirmation = true;
        this.$ievents.sendMessage('create', 'preventCreating');
      } else {
        this.$ievents.sendMessage('execute', 'stopAll');
        this.$ievents.sendMessage('execute', 'close');
      }
    },
    logout() {
      if (this.currState === 'create') {
        this.setConfirmDialogProperties({
          image: '',
          dialogTitle: 'Logging out',
          dialogMessages: ['You have unsaved changes. All of your progress will be lost. Do you want to proceed?'],
          confirmButtonText: 'Log out',
          cancelButtonText: 'Cancel',
          usedFor: 'logout'
        });
        this.pendingConfirmation = true;
        this.$ievents.sendMessage('create', 'preventCreating');
      } else {
        this.$ievents.sendMessage('execute', 'stopAll', '', () => {
          auth.signOut();
          this.$router.push({name: 'login'});
          this.pendingConfirmation = false;
        });
      }
    },
    showIframe() {
      this.$ievents.sendMessage('execute', 'show');
    },
    setConfirmDialogProperties(props) {
      for (let p in props) {
        this.$set(this.confirmDialogProperties, p, props[p]);
      }
    },
    vueEventsSetup() {
      this.$on('cancelCreation', () => {
        this.showCancelCreationConfirm();
        this.$ievents.sendMessage('create', 'preventCreating');
      });

      this.$on('step:removeError', () => {
        this.stepError = false;
      });

      this.$ebus.$on('create:step:end', (details) => {
        this.endTutorial(details);
      });
      this.$ebus.$on('iframe:close', () => {
        this.closeIframe();
      });
      this.$ebus.$on('visibilityToggle', state => {
        if (state === 'show') {
          this.closeIframe()
        } else {
          this.showIframe();
        }
      });
      this.$ebus.$on('logout', () => {
        this.logout();
      })
      this.$on('cancelRunning', () => {
        this.cancelRunning();
      });
      this.$on('createTutorial', () => {
        this.createTutorial();
      });
      this.$on('getStorage', () => {
        this.getStorageAndSetLocalData();
      });
      this.$on('tutorial:delete', tutorial => {
        this.deleteTutorial(tutorial);
      });

      this.$on('tutorial:deleteStep', (index) => {
        this.setConfirmDialogProperties({
          image: '',
          dialogTitle: 'Delete Step',
          dialogMessages: ['Are you sure you want to delete the step?'],
          confirmButtonText: 'Yes, detele the step',
          cancelButtonText: 'No',
          usedFor: 'deleteStep'
        });
        this.$ievents.sendMessage('create', 'preventCreating');
        this.stepIndex = index;
        this.pendingConfirmation = true;
      })

      this.$on('onrunning:edit', async (stepError) => {
        this.stepError = stepError;
        this.directEdit = true;
        const tutorialToEdit = this.tutorial;
        await this.cancelRunning();
        this.editTutorial(tutorialToEdit);
      });

      this.$ebus.$on('runTutorial', (tut) => {
        this.runTutorial(tut);
      });
      this.$ebus.$on('editTutorial', (tutorialToEdit) => { this.editTutorial(tutorialToEdit); });
      this.$ebus.$on('step:editSaved', (newStep) => {
        this.editStep(newStep);
      });
      this.$ebus.$on('onIdle:tutorialsLength', (tutorialsOnPage) => {
        this.tutorialsOnPage = tutorialsOnPage;
      });
      this.$ebus.$on('step:reorder', (reorderData) => {
        this.reorderSteps(reorderData);
      });
      this.$on('create:step:save', (details) => {
        this.saveStep(details);
        this.editStepFromStorage = null;
      });
    },
    eventsSetup() {
      this.$ievents.sendMessage('state', 'get', '', (state) => {
        if (state && state !== 'idle') this.currState = state;

        if (sessionStorage.getItem('testing') && this.currState === 'idle') {
          sessionStorage.removeItem('testing');
        }
      });
      this.$ievents.sendMessage('auth', 'token', auth.token);
      this.$ievents.sendMessage('run', 'resume', 'get', (tutorialInfo) => {
        if (tutorialInfo.tutorial) {
          this.resumeTutorial(tutorialInfo);
          this.currState = 'run';
        }
      });
      this.$ievents.sendMessage('create', 'resume', 'get', (details) => {
        if (details) {
          this.resumeTutorial({
            tutorial : details.tutorial,
            index: details.tutorial.steps.length
          });
          this.editStepFromStorage = {
            step: details.tutorial.steps[details.editedStepIndex],
            index: details.editedStepIndex,
            anchor: details.editedStepAnchor,
          }
          this.currState = 'create';
        }
      });
      this.$ievents.handleMessage('create', 'discard', () => {
        this.showCancelCreationConfirm();
      });
      this.$ievents.handleMessage('run', 'notFound:anchor', (index) => {
        if (sessionStorage.getItem('testing')) {
          this.stepError = parseInt(index);
          this.$ebus.$emit('highlightStep:error', parseInt(index));
          this.showIframe();
        }
      });
      this.$ievents.handleMessage('run', 'notFound:anchor:removeError', () => {
        if (sessionStorage.getItem('testing')) {
          this.stepError = false;
          this.$ebus.$emit('highlightStep:error:remove');
        }
      });
      this.$ievents.handleMessage('create', 'finish', () => {
        this.reset();
        this.$store.dispatch("company/getSelectedTeamData");
      });
      this.$ievents.handleMessage('state', 'run', (tutorial) => {
        if (tutorial) {
          this.currState = 'run';
          this.tutorial = tutorial;
          this.$ievents.sendMessage('execute', 'hide');
        } else {
          this.$ievents.sendMessage('state', 'get', '', (state) => {
            this.currState = state;
          });
        }
      });
      this.$ievents.handleMessage('run', 'stop', (isLastStep) => {
        this.reset();
        if (sessionStorage.getItem('testing')) {
          this.showIframe();
          if (this.stepError === false) {
            if (isLastStep) {
              let tutorialInTest = JSON.parse(sessionStorage.getItem('testing'));
              console.log(tutorialInTest)
              if (tutorialInTest.options.show || tutorialInTest.options.autoRun) {
                this.setConfirmDialogProperties({
                  image: '/spotTourLive@2x.png',
                  dialogTitle: 'You successfully tested the tour!',
                  dialogMessages: [
                    'You can check the analytics for this tour, and iterate based on user data.',
                  ],
                  confirmButtonText: 'See tour analytics →',
                  cancelButtonText: 'Go to Tours list',
                  usedFor: 'testComplete'
                });
              } else {
                this.setConfirmDialogProperties({
                  image: '/spotTestingComplete@2x.png',
                  dialogTitle: 'Testing complete!',
                  dialogMessages: [
                    'You successfully tested the product tour.',
                    'Now, you are one step closer to sharing the tour live with your users.'
                  ],
                  confirmButtonText: 'See how to run the tour live →',
                  cancelButtonText: 'Go to Tours list',
                  usedFor: 'testComplete'
                });
              }
              this.pendingConfirmation = true;
            } else {
              this.setConfirmDialogProperties({
                image: '/spotTestingComplete@2x.png',
                dialogTitle: 'Testing ended!',
                dialogMessages: [
                'You ended the testing of the product tour earlier.',
                'We recommend fully testing your product tour before sharing it live with your users.',
                ],
                confirmButtonText: 'Re-test the tour →',
                cancelButtonText: 'Go to Tours list',
                usedFor: 'testTour'
              });
              this.pendingConfirmation = true;
            }
          } else {
            if (!this.directEdit) {
              this.setConfirmDialogProperties({
                image: '/spotTestingComplete@2x.png',
                dialogTitle: 'Testing ended!',
                dialogMessages: [
                  'You ended the testing of the product tour earlier, but there were errors in your tour.',
                  'We recommend editing your product tour before sharing it live with your users.',
                ],
                confirmButtonText: 'Edit the tour →',
                cancelButtonText: 'Go to Tours list',
                usedFor: 'edit:lastStepError'
              });
              this.pendingConfirmation = true;
            }
          } 
        }
      });
      this.$ievents.handleMessage('run', 'startFromLauncher', (tut) => {
        if (this.state !== 'create') {
          const fromLauncher = true;
          this.runTutorial(tut, false, fromLauncher);
        }
      });
      this.$ievents.handleMessage('run', 'currStep', (tutorialIndex) => {
        this.tutorialIndex = parseInt(tutorialIndex);
        if(parseInt(tutorialIndex) === this.tutorial.steps.length) {
          this.currState = 'idle';
        }
      });

      this.$ievents.sendMessage('link', 'currDomain', 'get', (currDomain) => {
        this.currDomain = currDomain;
      });
    },
    checkStorage() {
      const storage = this.STORAGE.get();
      if (storage && storage.tutorial) {
        this.tutorialIndex = storage.index ? storage.index : 0;
        this.tutorial = storage.tutorial;
        this.runTutorial(this.tutorial, true);
        this.STORAGE.delete();
      }

      const brtCreateFromDashboard = this.STORAGE.get('brtCreateFromDashboard');
      if (brtCreateFromDashboard) {
        this.STORAGE.delete('brtCreateFromDashboard');
        this.sendCreateOrEditMessage();
      }
    },
    createTutorial(tutorialToEdit) {
      if (!this.allow(this.currState)) return;
      this.sendCreateOrEditMessage(tutorialToEdit);
    },
    sendCreateOrEditMessage(tutorialToEdit) {
      if (tutorialToEdit) {
        this.$ievents.sendMessage('create', 'edit', tutorialToEdit, () => {
          this.currState = 'create';
        });
      } else {
        this.$ievents.sendMessage('create', 'getDomain', '', (domain) => {
          this.tutorial.domain = domain;
        });
        this.$ievents.sendMessage('create', 'start', '', () => {
          this.currState = 'create';
        });
      }
    },
    editTutorial(tutorialToEdit) {
      this.tutorial = tutorialToEdit;
      this.tutorialIndex = tutorialToEdit.steps.length;
      this.editMode = true;
      this.createTutorial(tutorialToEdit);
    },
    saveStep(details) {
      if (details.edit) {
        this.tutorial.steps[details.index] = details.step;
        this.forceRerender += 1;
        this.$toast('Your step updates have been successfully saved', { type: TYPE.SUCCESS });
      } else {
        this.tutorial.steps.push(details.step);
        this.tutorialIndex++;
        this.$toast('Your step has been successfully recorded', { type: TYPE.SUCCESS });
        if (this.editMode) {
          sessionStorage.setItem('retest', true);
        }
      }

      this.$ievents.sendMessage('create', 'step:saved', JSON.stringify(details));
    },
    reorderSteps(reorderData) {
      this.$ievents.sendMessage('create', 'reorderSteps', reorderData);
      const i = reorderData.currentIndex;
      const change = reorderData.indexChange;
      const arr = JSON.parse(JSON.stringify(this.tutorial.steps));

      // swap 2 array elements via destructuring assignment
      [arr[i], arr[i + change]] = [arr[i + change], arr[i]];
      this.tutorial.steps = arr;
    },
    deleteStep(idx) {
      this.tutorial.steps.splice(idx, 1);
      this.$ievents.sendMessage('create', 'deleteStep', idx, () => {});
    },
    deleteTutorial(tutorial) {
      this.setConfirmDialogProperties({
        image: '',
        dialogTitle: 'Delete the tour',
        dialogMessages: ['Are you sure you want to delete the tour?'],
        confirmButtonText: 'Yes, delete the tour',
        cancelButonText: 'No',
      usedFor: 'deleteTour'
      });
      this.tutorial = tutorial;
      this.pendingConfirmation = true;
    },
    runTutorial(tut, fromStorage, fromLauncher = false) {
      if (!this.allow(this.currState)) return;
      if (!fromLauncher) {
        sessionStorage.setItem('testing', JSON.stringify(tut));
      }

      if (tut.steps && tut.steps[0] && tut.steps[0].url) {
        let url = new URL(tut.steps[0].url);
        let urlHostName = url.hostname;
        if (tut.ignoreSubdomain && this.planHasSubdomains) {
          urlHostName = URLNAVIGATION.replaceSubdomain(this.currDomain, urlHostName, urlHostName);
        }
        if (urlHostName !== this.currDomain && !fromStorage) {
          this.STORAGE.save({tutorial: tut});
          this.$ievents.sendMessage('run', 'goto', tut.steps[0].url);
          return;
        }
      }

      this.$ievents.sendMessage('run', 'start', tut);

      this.currState = 'run';
      this.tutorial = tut;
    },
    resumeTutorial(tutorialInfo) {
      this.tutorial = tutorialInfo.tutorial;
      if (tutorialInfo.hasOwnProperty('index')) {
        this.tutorialIndex = tutorialInfo.index;
      } else if (this.tutorial && this.tutorial.steps.length) {
        this.tutorialIndex = this.tutorial.steps.length - 1;
      }
    },
    cancelCreation() {
      this.$ievents.sendMessage('create', 'cancel', '', () => {
        this.reset();
        this.$store.dispatch("company/getSelectedTeamData");
      });
    },
    endTutorial(details) {
      // edit logic (if _id is present we are editing)
      this.loading = true;
      if (this.tutorial._id) {
        SYNC.put(this.tutorial._id, { ...this.tutorial, ...details }, auth.token, (resp) => {
          if (resp._id && resp.name) {
            if (sessionStorage.getItem('retest')) {
              this.testTutorialDialog(resp, 'updated');
              sessionStorage.removeItem('retest');
            }
            this.cancelCreation();
            this.$toast("Tour successfully updated!", { type: TYPE.SUCCESS });
          }
        })
        .then(() => {
          this.loading = false;
        })
      // normal creation logic
      } else {
        SYNC.post( { ...this.tutorial, ...details }, auth.token, (resp) => {
          if (resp && resp.tutorial && resp.tutorial._id) {
            this.cancelCreation();
            // store created tutorial for testing mode
            this.testTutorialDialog(resp.tutorial, 'created');
            localStorage.setItem('brtOnboarding', 'firstTour');
            setTimeout(() => {
              localStorage.removeItem('brtOnboarding');
            }, 1000);
          }
        })
        .then(() => {
          this.loading = false;
        })
      }
    },
    testTutorialDialog(tut, action) {
      this.setConfirmDialogProperties({
          image: '/spotCongratulations@2x.png',
          dialogTitle: `Tour ${action} successfully!`,
          dialogMessages: [
            `Congrats, you successfully ${action} a product tour.`,
            'We strongly recommend testing the tour before sharing it live to your users.'
          ],
          confirmButtonText: 'Test tour',
          cancelButtonText: 'Go to Tours list',
          usedFor: 'testTour'
      });
      sessionStorage.setItem('testing', JSON.stringify(tut));
      this.pendingConfirmation = true;
    },
    cancelRunning() {
      return new Promise((resolve) => {
        this.$ievents.sendMessage('run', 'cancel', '', () => {
          this.reset();
          resolve();
        });    
      });
    },
    allow (state) {
      return state !== 'idle' ? false : true;
    },
    validWebsite(tabs, cb) {
      chrome.storage.local.get(['brtErrors'], (resp) => {
        if(resp.hasOwnProperty('brtErrors') && tabs[0].hasOwnProperty('id')
          && resp.brtErrors.hasOwnProperty(tabs[0].id)) {
            this.page = 'nonHttp';
        }
        if (!tabs[0].url.match(/(http|https):\/\//gi)) {
          this.page = 'nonHttp';
        }
        if (this.page !== 'nonHttp') {
          cb();
        }
      });
    },
    setOnboardState() {
      const onboardSeenState = localStorage.getItem('runTutOnboardSeen');
      if(!onboardSeenState) {
        localStorage.setItem('runTutOnboardSeen', 'false');
      }
      this.onboardSeen = (onboardSeenState === 'true');
    },
    reset() {
      this.tutorialIndex = 0;
      this.tutorial = { name: '', description: '', domain: 'test', steps: [] };
      this.currState = 'idle';
      this.editMode = false;
    },
    showCancelCreationConfirm() {
      this.setConfirmDialogProperties({
        image: '',
        dialogTitle: 'Cancel tour',
        dialogMessages: ['Are you sure you want to cancel the tour? All your progress will be lost.'],
        confirmButtonText: 'Yes, cancel the tour',
        cancelButtonText: 'No',
        usedFor: 'cancelCreation'
      });
      this.pendingConfirmation = true;
    }
  },
  computed: {
    ...mapGetters({
      selectedCompanyId: 'company/getTeamID',
      planHasSubdomains: 'company/planHasSubdomains',
    })
  },
};
</script>

<style lang="stylus">
  @import '../../../utils/common/styleguide/functions.styl'
  @import '../../../utils/common/styleguide/colors.styl'
  @import '../../../utils/common/styleguide/links.styl'
  @import '../../../utils/common/styleguide/nav.styl'
  @import '../../../utils/common/styleguide/buttons.styl'
  @import '../../../utils/common/styleguide/utils.styl'

  $extension-width = pxToRem(400)

  .extension-width
    width $extension-width

  #bytesroute
    width $extension-width
    max-height 100%
    
    #header
      height pxToRem(65)
    #brt-body
      height calc(100% - 65px)
      background-color $brt-white-pure

    #onboard-container
      cursor default
      height 100%
      width 100%
      position absolute
      z-index 100
      background-color alpha($brt-white, 0.9)
      #onboard-text
        margin-top pxToRem(200)
    .brt-btn
      padding-left 1.5rem
      padding-right 1.5rem
      color $brt-white
    .btn-danger
      background-color $brt-danger!important
      &:hover
        background-color $brt-danger-light!important
      &:focus, &:active
        background-color $brt-danger-dark!important
    .btn-primary
      background-color $brt-primary
      &:hover
        background-color $brt-primary-bright
      &:focus, &:active
        background-color $brt-primary-dark
    .btn-success
      background-color $brt-cyan-dark!important
      &:hover
        background-color $brt-cyan!important
      &:focus, &:active
        background-color $brt-cyan-dark!important
    .btn-secondary
      background-color $brt-muted-faded!important
      color $brt-primary!important
      &:hover
        background-color alpha($brt-primary, 0.1)!important
      &:focus, &:active
        background-color alpha($brt-primary, 0.2)!important


    .btn-big
      position relative
      width calc(100% - 1.3rem)
      margin pxToRem(0) pxToRem(10)
      padding pxToRem(12) pxToRem(0)
      background-color $brt-primary-dark
      color $brt-white
      font-size pxToRem(20)

    .list-group
      border none
      &::-webkit-scrollbar,
      &::-webkit-scrollbar-track
        -webkit-box-shadow inset 0 0 pxToRem(6) $brt-white
        background-color $brt-white
      &::-webkit-scrollbar
        width pxToRem(6)
        background-color $brt-white
      &::-webkit-scrollbar-thumb
        background-color $brt-primary-dark

</style>
