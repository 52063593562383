<template lang="pug">
  #link-container.position-relative
    .brt-pre-container
      pre.brt-pre
        code.brt-code(data-testid="copy-string") {{ copyString }}
    Copy-Button.small-cta.copy-text.position-absolute(
      :valueToCopy="copyString"
      data-testid="copy-text-btn")
</template>

<script>
import CopyButton from './CopyButton.vue';

export default {
  props: ['copyString'],
  components: { CopyButton }
}
</script>

<style lang="stylus" scoped>
  #link-container
    .brt-pre-container
      .brt-pre
        width calc(100% - 4rem)

    .copy-text
      right 0.5rem
      top 1rem
</style>
