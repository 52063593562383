import { auth } from '../utils/auth';
import { cBus } from '../utils/custombus';

export function enableNavigationGuards(router) {
  router.beforeEach(
    (to, from, next) => {
      window.scrollTo(0, 0);
      if (to.matched.some((record) => record.meta.forAuth) &&
        !auth.isAuthenticated()) {
        next({ path: '/login' });
      } else if((to.fullPath.includes('/login?')
        || to.fullPath === '/login'
        || to.fullPath.includes('/register?')
        || to.fullPath === '/register')
        && auth.isAuthenticated()) {
          // if login request is sent from extension and user is already logged in, 
          // just sync extension with gallery
          if (to.fullPath.includes('/login?from=extension') || to.fullPath.includes('/register?from=extension')) {
            cBus.emitTknToExt(auth.token);
          }

          if (to.fullPath.includes('/login?from=invitation&teamId=')
            || to.fullPath.includes('/register?from=invitation&teamId=')) {
            auth.signOut();
            return next();
          }
          next({ path: '/' });
        } else if (to.fullPath.includes('/logout') && to.query.from !== 'extension') {
            next({ path: '/' });
        } else {
          next();
        }
    },
  );
}
