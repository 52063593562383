import UrlConfig from './urlConfig.js';

/**
 * Purpose of this class is to provide syncronization for tutorials
 * @author Cristi Minica
 */
class Sync {
  constructor (fetch) {
    this.fetch = fetch;
    this.urlConfig = new UrlConfig();
  }

  // method that returns ALL the tours in ALL the Companies/Teams 
  // that the user who made the request is part of
  async getAllJoinedTeamsTutorials(token) {
    await this.urlConfig.setup();
    if (token) {
      let props = {headers:{'Authorization': `Bearer ${token}`}};
      let reqURL = `${this.urlConfig.apiUrl}/tutorial/all`;
      const response = await this.fetch(reqURL, props);
      if (response.status === 200) {
        const tutorials = await response.json();
        return tutorials.reverse();
      }
    }
    return false;
  }

  async getAllLanguages() {
    await this.urlConfig.setup();
    let reqURL = `${this.urlConfig.apiUrl}/language`;
    const response = await this.fetch(reqURL);
    if (response.status === 200) {
      const languages = await response.json();
      return languages;
    }
    return false;
  }

  async getDefaultLanguage() {
    await this.urlConfig.setup();
    let reqURL = `${this.urlConfig.apiUrl}/language/default`;
    const response = await this.fetch(reqURL);
    if (response.status === 200) {
      const language = await response.json();
      return language;
    }
    return false;
  }

  async sendPublishTag(tutorial) {
    await this.urlConfig.setup();
    let reqUrl = `${this.urlConfig.apiUrl}/user/tutorialPublished`;
    const props = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({tutorial})
    };
    await this.fetch(reqUrl, props);
    return true;
  }

  // get a language by id
  async getLanguageById(id) {
    await this.urlConfig.setup();
    let reqURL = `${this.urlConfig.apiUrl}/language/${id}`;
    const response = await this.fetch(reqURL);
    if (response.status === 200) {
      const language = await response.json();
      return language;
    }
    return false;
  }

  /**
   * 
   * @param {String} component There are 3 components: 'script' - translation for modals, 'launcher' - for tour launcher side, and for website.
   * @param {String} langauge Id of selected langauge
   * @returns An object for language translation
   */
  async getTranslationById(component, langauge) {
    await this.urlConfig.setup();
    let reqURL = `${this.urlConfig.apiUrl}/translation/${component}/${langauge}`;
    const response = await this.fetch(reqURL);
    if (response.status === 200) {
      const translation = await response.json();
      return translation;
    }
    return false;
  }

  async getTeamSubscription(id) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/company/${id}/subscription`);
    if (response.status === 200) {
      const { companySubscription } = await response.json();
      return companySubscription;
    }
    return false;
  }
  
  // get bytes route default theme
  async getDefaultTheme(token) {
    await this.urlConfig.setup();
    if (token) {
      let props = {headers:{'Authorization': `Bearer ${token}`}};
      let reqURL = `${this.urlConfig.apiUrl}/theme/default`;
      const response = await this.fetch(reqURL, props);
      if (response.status === 200) {
        const theme = await response.json();
        return theme;
      }
    }
    return false;
  }

  // get all themes using company id
  async getCompanyThemes(companyId, token) {
    await this.urlConfig.setup();
    if (token) {
      let props = {headers:{'Authorization': `Bearer ${token}`}};
      let reqURL = `${this.urlConfig.apiUrl}/theme/company/${companyId}`;
      const response = await this.fetch(reqURL, props);
      if (response.status === 200) {
        const themes = await response.json();
        return themes;
      }
    }
    return false;
  }

  // 
  async checkCompanyDomains(companyId, domain) {
    await this.urlConfig.setup();
    const reqURL = `${this.urlConfig.apiUrl}/company/addDomain`;
    const props = {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ domain, id: companyId })
    };
    this.fetch(reqURL, props);
    return true;
  }

  // for now, this get method is used only in include script
  // this will return all public company tours from a specific domain
  // or if the company isn't specified will return all public tours from specifed domain
  async getCompanyTutorials(domain, company) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/company/${company}/publicTutorials?domain=${domain}`);
    if (response.status === 200) {
      const tutorials = await response.json();
      return tutorials.reverse();
    }
    return false;
  }

  async getThemeById(themeId) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/theme/${themeId}`);
    if (response.status === 200) {
      const result = await response.json();
      return result;
    }
    return false;
  }

  // method to get demo tutorial
  async getDemoTutorial() {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/tutorial/demo`);
    if (response.status === 200) {
      const demoTutorial = await response.json();
      return demoTutorial;
    }
    return false;
  }

  async getUserCompanies(token) {
    await this.urlConfig.setup();
    let response;

    if (token) {
      let props = {headers:{'Authorization': `Bearer ${token}`}};
      response = await this.fetch(`${this.urlConfig.apiUrl}/user/companies`, props);
      const companies = await response.json();
      return companies;
    }
    return false;
  }

  async getLauncherProperties(id, domain) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/company/${id}/domain?name=${domain}`);
    return await response.json();
  }

  async getUserPublicTutorials(userId, domain) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/tutorial/user/${userId}?domain=${domain}`);
    if (response.status === 200) {
      const tutorials = await response.json();
      return tutorials;
    }
    return false;
  }

  async getAll(token) {
    await this.urlConfig.setup();
    const props = {headers:{'Authorization': `Bearer ${token}`}};
    const response = await this.fetch(`${this.urlConfig.apiUrl}/tutorial/own`, props);
    const tutorials = await response.json();
    return tutorials;
  }

  async getById(id, token) {
    await this.urlConfig.setup();

    const headers = {
      'Authorization': `Bearer ${token}`
    };
    const props = {
      method: 'GET',
      headers
    }

    const response = await this.fetch(`${this.urlConfig.apiUrl}/tutorial/${id}`, props);
    if (response.status === 200) {
      const tutorial = await response.json();
      return tutorial;
    }
    return false;
  }

  async post(tutorial, token, done) {
    await this.urlConfig.setup();

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const props = {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(tutorial), // data can be `string` or {object}!
      headers
    }

    const response = await this.fetch(this.urlConfig.apiUrl + '/tutorial', props);
    const data = await response.json();

    if (response.status === 201) {
      done(data);
    }
  }

  async put(route, updates, token, done) {
    await this.urlConfig.setup();

    const headers = { 'Content-Type': 'application/json' };
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }

    const url = `${this.urlConfig.apiUrl}/tutorial/${route}`
    const options = {
      method: 'PUT',
      body: JSON.stringify(updates),
      headers
    }
    const response = await this.fetch(url, options);
    const data = await response.json();

    if (response.status === 201) {
      done(data);
    }
  }

  async delete(id, token, done) {
    // must check before every request
    await this.urlConfig.setup();

    // setup props for delete request
    const props = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    const response = await this.fetch(`${this.urlConfig.apiUrl}/tutorial/${id}`, props);
    const jsonRes = await response.json();
    done(jsonRes);
  }

  async login(email, password) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/user/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'email': email,
        'password':password
      })
    });
    if(response.status === 200) {
      const { token } = await response.json();
      return true;
    }
    const errors = await response.json();
    return errors;
  }

  async register(username, email, password) {
    await this.urlConfig.setup();
    const response = await this.fetch(`${this.urlConfig.apiUrl}/user/register`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        'username': username,
        'email': email,
        'password':password
      })
    });
    if(response.status !== 201) {
      const errors = await response.json();
      return errors;
    }
    return true;
  }
}
const SYNC = new Sync(window.fetch.bind ? window.fetch.bind(window) : window.fetch);
export default SYNC;
