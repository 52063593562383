 
<template lang="pug">
  .manager-container
</template>

<script>
import { handleError } from '../../../mixins/handleError';
import updateTourOptions from '../mixins/updateTourOptions';
import { TYPE } from "vue-toastification";

export default {
  mixins: [handleError, updateTourOptions],
  data() {
    return {
      tutorial: {},
      delay: 0
    }
  },
  mounted() {
    this.$ebus.$on("tour/updateAutoRunDelay", update => {
      this.tutorial = update.tutorial;
      this.delay = update.delay;
      this.updateAutoRunDelay();
    });
  },
  methods: {
    async updateAutoRunDelay() {
      try {
        await this.updateTourOptions(this.tutorial, { delay: this.delay });
        this.$toast("Tour run delay value updated", { type: TYPE.SUCCESS });
      } catch (err) {
        this.$toast("Error updating tour run delaly value. Please retry", { type: TYPE.ERROR });
        this.handleError(err);
      }
    },
  },
}
</script>
